import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Drawer, IconButton, Typography, CircularProgress, Checkbox, Button, MenuItem, InputBase, Popper, ClickAwayListener, MenuList, Paper } from '@material-ui/core';
import { getDispFields, getIconComponent, getLabelPlural, getLineageIcon, getTrustScoreIcon, mapSearchObjectName, onClickResultItem, sendMessage, titleCaseObjectName } from '../../../../../utilities';
import DrawerDragTrigger from '../../../DrawerDragTigger/DrawerDragTrigger';
import axiosCerebrum from '../../../../../axios-cerebrum';
import axiosSolr from '../../../../../axios-solr';
import { constructChildNodes, getLineageField } from '../dataLoadUtils';
import { addAdditionalNodesIntoGroup, removeNodesFromGroup } from '../layoutUtils';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../../../ContextMenu/ContextMenu'
import KTooltip from '../../../KTooltip/KTooltip';
import { checkCanAddToCart } from '../../../../../permissionChecker';
import { addBadgeToIcon, getIconLabel } from '../../../SearchResults/utils';
import { useStore } from 'react-redux';
import CartAdder from '../../../CartAdder/CartAdder';

const styles = theme => ({

  drawerPaper:{
    borderLeft:`1px solid ${theme.palette.listItemDivider.main}`,
    height:'100vh',
    overflow:'hidden',
    paddingRight:24,
    maxWidth:'90vw',
    display:'flex',
    flexDirection:'column',
    background:theme.palette.background.main
  },
  customScroll:{
    overflow:"auto",
    paddingBottom:24,
    overscrollBehaviorY:"contain",
    ...theme.components.hideScroll
  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
    width:'max-content',
    maxWidth:"100%",
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    cursor:"pointer",
    '&:hover':{
      textDecoration:"underline"
    }
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  listItem:{
    height:56,
    display:"flex",
    alignItems:"center",
    overflow:"hidden",
    paddingLeft:16,
    paddingRight:16,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:"default",
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  listItemTitle:{
    fontSize:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    color:theme.palette.primary.main,
  },
  listItemSubTitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  iconButton:{
    padding:4
  },
  sectionTitle:{
    fontSize:16,
    color:theme.palette.primary.main,
    marginBottom:16
  },
  cartItem:{
    '& .select-checkbox':{
      width:0,
      transition:'width 0.2s ease-in-out',
    },
    '& .item-icon':{
      width:24,
      transition:'width 0.2s ease-in-out',
    },
    '&:hover':{
      overflow:'hidden',
      '& .item-icon':{
        width:0,
        overflow:"hidden",
      },
      '& .select-checkbox':{
        width:24,
      }
    }
  },
  iconWrapper:{
    flex:'0 0 24px',
    height:24,
    width:24,
    marginRight:16,
    display:'flex',
    // overflow:'hidden'
  },
  columnHeader:{
    color:theme.palette.primaryText.main,
    fontSize:12,
    letterSpacing:1.5
  },
  selector:{
    ...theme.components.selector,
    width:120,
    flexGrow:0,
    flexShrink:0
  },
  inputBase:{
    ...theme.components.inputBase,
    // maxWidth:350,
    // width: '100%',
    height:42,
    flexGrow:1,
    marginRight:24
  },
  tagSelector:{
    ...theme.components.selector,
    height:28,
    borderRadius:14,
    width:100,
    flexGrow:0
  }
})


function ParentDrawer(props) {
  const {
    classes,
    theme,
    drawerOpen,
    setDrawerOpen,
    selectedItem,
    mapControls,
    childrenMap,
    currentGroups
  } = props;


  const store = useStore();
  let sessionData = store.getState().auth.session_user;

  const [viewSelectorAnchor, setViewSelectorAnchor] = useState(false)

  let initialWidth = 600;
  try{
    if(localStorage.getItem('objectDrawerWidth')){
      initialWidth = Number(localStorage.getItem('objectDrawerWidth')).valueOf() || 600;
    }
  }catch{}

  // const {
  //   sendAlert
  // } = useAlert({})

  const [paperWidth, setPaperWidth] = useState(initialWidth)
  const [childList, setChildList] = useState({})
  const listRef = useRef()

  const [selectedChildren, setSelectedChildren] = useState([])
  const [addToCartIds, setAddToCartIds] = useState()
  const [isSelectAll, setIsSelectAll] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const searchTimeout = useRef()
  const [sort, setSort] = useState('score:DESC')
  const [tagView, setTagView] = useState('visibility')

  useEffect(() => {
    const handleEsc = (event) => {
      // check if key is 'ESC`
      if (event.key==='Escape') {
        setDrawerOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line
  }, [])

  const loadChildList = ({page=1, sortVal=sort, searchVal=searchValue}) => {
    if(!drawerOpen)return;
    let prevItem = selectedItem.prevLevelRef;
    if(!prevItem){
      setChildList({error:true})
      return;
    }
    setChildList({loading:true,data:page===1?undefined:childList.data})
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(mapSearchObjectName(prevItem.data.obj.object_type_txt||prevItem.data.obj.object_type, prevItem.data.obj.code_type_txt))}/${prevItem.data.obj.id}/lineage`,{
          params:{
            page,
            per_page:10,
            direction:drawerOpen.direction,
            group_object_id:selectedItem.data.obj.id,
            include_inactive:mapControls.isShowActiveOnly?false:true,
            include_reference:mapControls.isShowReference?true:false,
            'search.name':searchVal||undefined,
            ['sort.'+sortVal.split(':')[0]]:sortVal.split(':')[1],
            level:mapControls.lineageLevel
          }
        }
      )
      .then(response=>{
        if(response.data.total===0){
          setChildList({data:response.data})
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,{params:{
              q:"*",
              fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
              rows:response.data.items.length
            }}
          )
          .then(solrRes=>{
            let items = response.data.items.map(e=>({
              ...e,
              ...(solrRes.data.response.docs.find(d=>d.id===e.id)||{})
            }))
            setChildList({
              data:{
                ...response.data,
                items:page===1?items:childList.data.items.concat(items)
              }
            })
          })
          .catch(error=>{
            console.log(error)
            setChildList({error:true})
          })
      })
      .catch(error=>{
        console.log(error)
        setChildList({error:true})
      })
  }

  const shouldLoadMore = () => {
    if(childList.loading || childList.error || !childList.data) return false;
    return childList.data.page < childList.data.pages;
  }

  useEffect(()=>{
    if(shouldLoadMore() && listRef.current){
      if(listRef.current.scrollHeight<=listRef.current.clientHeight){
        loadChildList({page:childList.data.page+1})
      }
    }
  // eslint-disable-next-line
  },[childList.data, childList.loading])

  useEffect(()=>{
    if(selectedItem){
      setSearchValue('')
      setSort('score:DESC')
      loadChildList({page:1, sortVal:'score:DESC', searchVal:''})
    }
    // eslint-disable-next-line
  },[selectedItem])

  // const isItemVisible = (el) => {
  //   return Boolean(childrenMap[selectedItem.id]?.find(e=>e.data?.obj?.id===el.id))
  // }

  const onClickShowInSwimlane = () => {
    let existingChildren = childrenMap[selectedItem.id];

    let nodesToRemove = existingChildren.filter(e=>!selectedChildren.includes(e.data.obj.id))
    let nodesToAdd = childList.data.items.filter(el=>selectedChildren.includes(el.id) && !existingChildren.find(e=>e.data?.obj?.id===el.id))

    if(nodesToRemove.length>0)removeNodesFromGroup({nodes:nodesToRemove, targetGroup:selectedItem, childrenMap, currentGroups})

    if(nodesToAdd.length>0){
      let childNodes = constructChildNodes({
        childNodes:nodesToAdd,
        groupNode:selectedItem,
        detailMap:{},
        direction:drawerOpen.direction,
        mapControls
      });
      addAdditionalNodesIntoGroup({nodes:childNodes, targetGroup:selectedItem, childrenMap,currentGroups})
    }

    setSelectedChildren([])
    setIsSelectAll(false)
    sendMessage({lineage_alert_message:`Map updated`})
    sendMessage({lineage_update_map:true})
  }

  const onAddToCart = () => {
    let canAddToCartItems = childList.data.items.filter(e=>selectedChildren.includes(e.id) && checkCanAddToCart({sessionData, objectType:e.object_type}));
    setAddToCartIds(canAddToCartItems.map(e=>e.id))
    setSelectedChildren([])
    setIsSelectAll(false)
  }

  const onChangeSort = value => {
    setSort(value)
    loadChildList({page:1, sortVal:value})
  }

  const onChangeSearch = value => {
    setSearchValue(value)
    clearTimeout(searchTimeout.current)
    searchTimeout.current = setTimeout(()=>{
      loadChildList({page:1, searchVal:value})
    },350)
  }

  const checkIsVisible = el => {
    return Boolean(childrenMap[selectedItem.id]?.find(e=>e.data?.obj?.id===el.id))
  }

  const getVisibilityButton = el => {
    let child = childrenMap[selectedItem.id]?.find(e=>e.data?.obj?.id===el.id)
    let isVisible = checkIsVisible(el)
    return (
      <KTooltip title={`${isVisible?'Hide':'Show'} in group`}>
        <IconButton
          className={classes.iconButton}
          style={{marginRight:4}}
          onClick={(event)=>{
            event.stopPropagation();
            if(isVisible){
              removeNodesFromGroup({nodes:[child], targetGroup:selectedItem, childrenMap, currentGroups})
            }else{
              childrenMap[selectedItem.id] = childrenMap[selectedItem.id]||[]
              let nodes = constructChildNodes({
                childNodes:[el],
                groupNode:selectedItem,
                detailMap:{},
                direction:drawerOpen.direction,
                mapControls
              });
              addAdditionalNodesIntoGroup({nodes, targetGroup:selectedItem, childrenMap, currentGroups})
            }
            sendMessage({lineage_update_map:true})
          }}
        >
          {
            isVisible?
            getIconComponent({label:"visibility_on",size:24,colour:theme.palette.primary.main}):
            getIconComponent({label:"visibility_off",size:24,colour:theme.palette.primaryText.light})
          }
        </IconButton>
      </KTooltip>
    )
  }

  const viewOptions = [
    {value:"visibility",label:"Visibility"},
    {value:"lineage",label:"Lineage"},
    {value:"trust",label:"Trust"},
  ]

  const onChangeTagView = view => {
    setTagView(view)
    if(view==='trust'){
      onChangeSort('score:DESC')
    }
  }

  const generateSortIcon = () => {
    if(!['trust'].includes(tagView))return <></>
    let ascIcon = getIconComponent({label:'triangle_up',size:20,colour:theme.palette.primaryText.main})
    let descIcon = getIconComponent({label:'triangle_down',size:20,colour:theme.palette.primaryText.main})
    let icon, onClick;

    if(tagView==='trust'){
      if(sort==='score:ASC'){
        icon = ascIcon;
        onClick = ()=>onChangeSort('score:DESC')
      }else if(sort==='score:DESC'){
        icon = descIcon;
        onClick = ()=>onChangeSort('score:ASC')
      }
    }

    return <IconButton className={classes.iconButton} style={{marginLeft:4}} onClick={onClick}>{icon}</IconButton>
  }

  if(!drawerOpen)return <></>

  return (
    <Drawer anchor="right" open={drawerOpen} PaperProps={{style:{width:paperWidth,minWidth:500}}} classes={{paper:classes.drawerPaper}} onClose={()=>setDrawerOpen(false)} variant="persistent">
      {
        selectedItem &&
        <>
          <DrawerDragTrigger
            onChangeWidthOffset={diff=>{
              setPaperWidth(paperWidth+diff)
              localStorage.setItem('objectDrawerWidth',paperWidth+diff)
            }}
          />
          <div style={{display:'flex',alignItems:'flex-start',paddingLeft:24,paddingTop:16,paddingBottom:16}}>
            <div style={{flexGrow:1}}></div>
            <IconButton
              onClick={()=>setDrawerOpen(false)}
              style={{width:28,height:28,padding:8}}
            >
              {
                getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
              }
            </IconButton>
          </div>
          <div
            className={classes.customScroll}
            ref={listRef}
            onScroll={e=>{
              e.stopPropagation();
              if(shouldLoadMore() && e.target.scrollTop+e.target.clientHeight+1>=e.target.scrollHeight){
                loadChildList({page:childList.data.page+1})
              }
            }}
            style={{paddingLeft:24}}
          >
            <div style={{position:"sticky",top:0,zIndex:10,background:theme.palette.background.main}}>
              <Typography className={classes.header} onClick={()=>onClickResultItem({item:selectedItem.data.obj,id:selectedItem.data.obj.id,label:selectedItem.data.obj.object_type, newWindow:true})}>
                {getDispFields(selectedItem?.data?.obj, 'dispTitle')}
                <span style={{position:"relative",marginLeft:8}}>
                  {getIconComponent({label:"open",size:20,colour:theme.palette.header.main})}
                </span>
              </Typography>
              <Typography className={classes.subTitle}>
                {getDispFields(selectedItem?.data?.obj, 'dispSubtitle')}
              </Typography>
              {
                selectedItem.data.obj.object_count>0 &&
                <>
                  <Typography className={classes.sectionTitle}>
                    {
                      `${childrenMap[selectedItem.id].length} of ${selectedItem.data.obj.object_count} item(s) linked are visible in swimlane`
                    }
                  </Typography>
                  <div style={{display:'flex',alignItems:'center',marginBottom:24,justifyContent:"space-between"}}>
                    <InputBase
                      value={searchValue}
                      onChange={e=>{
                        onChangeSearch(e.target.value)
                      }}
                      placeholder='Search'
                      className={classes.inputBase}
                      endAdornment={
                        <IconButton
                          disabled={searchValue===''}
                          onClick={()=>onChangeSearch('')}
                          style={{width:32,height:32,marginRight:6}}
                        >
                          {getIconComponent({label:searchValue===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                        </IconButton>
                      }
                    />
                  </div>
                  <div style={{display:'flex',marginBottom:26}}>
                    <Button onClick={onClickShowInSwimlane} color='primary' variant="contained" disabled={selectedChildren.length===0}>
                      SEE IN SWIMLANE
                    </Button>
                    <Button style={{marginLeft:8}} onClick={onAddToCart} color='primary' variant="contained" disabled={selectedChildren.length===0}>
                      ADD TO CART
                    </Button>
                  </div>
                </>
              }

              {
                childList.data?.items?.length>0 &&
                <div style={{display:'flex',alignItems:'center',paddingLeft:16,paddingBottom:8}}>
                  <Checkbox
                    style={{
                      color:isSelectAll?theme.palette.secondary.main:theme.palette.primaryText.light,
                      padding:0,
                      marginRight:16,
                    }}
                    checked={isSelectAll}
                    onClick={event=>{
                      event.stopPropagation();
                      if(isSelectAll){
                        setSelectedChildren([])
                      }else{
                        setSelectedChildren(childList.data.items.map(e=>e.id))
                      }
                      setIsSelectAll(!isSelectAll)
                    }}
                  />
                  <Typography className={classes.columnHeader} style={{flexGrow:1,marginRight:16}}>NAME</Typography>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <Typography
                      className={classes.columnHeader}
                      onClick={(event)=>{
                        setViewSelectorAnchor(viewSelectorAnchor?false:event.currentTarget)
                      }}
                      style={{textDecoration:'underline',cursor:'pointer',textTransform:'uppercase'}}
                    >
                      {viewOptions.find(e=>e.value===tagView)?.label}
                    </Typography>
                    {
                      generateSortIcon()
                    }
                    <Popper open={Boolean(viewSelectorAnchor)} anchorEl={viewSelectorAnchor} placement='bottom-end'>
                      <ClickAwayListener onClickAway={()=>setTimeout(()=>setViewSelectorAnchor(false))}>
                        <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                          <MenuList className={classes.listContainer}>
                            {
                              viewOptions.map(el=>(
                                <MenuItem
                                  onClick={()=>{
                                    setViewSelectorAnchor(false)
                                    onChangeTagView(el.value)
                                  }}
                                  className={classes.menuItem}
                                >
                                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>{el.label}</Typography>
                                </MenuItem>
                              ))
                            }
                          </MenuList>
                        </Paper>
                      </ClickAwayListener>
                    </Popper>
                  </div>
                </div>
              }
            </div>

            {
              selectedItem.data.obj.object_count===0 &&
              <div style={{marginTop:40}}>
                <Typography color='primary' style={{fontSize:16,marginBottom:16}}>No {titleCaseObjectName(selectedItem.data.obj.object_type_txt || selectedItem.data.obj.object_type)} assets linked to view on map</Typography>
                <Typography style={{fontSize:13.75,marginBottom:16,color:theme.palette.primaryText.light}}>
                  Lineage is currently available only to the {titleCaseObjectName(selectedItem.data.obj.object_type_txt || selectedItem.data.obj.object_type)}.
                </Typography>
                <Typography
                  style={{fontSize:13.75,marginBottom:16,color:theme.palette.hyperLink.main,textDecoration:"underline",cursor:'pointer'}}
                  onClick={()=>{
                    window.open('https://docs.kada.ai/home/lineage-limitations','_blank')
                  }}
                >
                  There are several reasons why lineage is not available.
                </Typography>
                <Typography style={{fontSize:13.75,marginBottom:40,color:theme.palette.primaryText.light}}>
                  Open the {titleCaseObjectName(selectedItem.data.obj.object_type_txt || selectedItem.data.obj.object_type)} to see more details.
                </Typography>
                <div style={{textAlign:'center'}}>
                  <Button
                    color='primary'
                    variant='outlined'
                    onClick={()=>onClickResultItem({item:selectedItem.data.obj,id:selectedItem.data.obj.id,label:selectedItem.data.obj.object_type, newWindow:true})}
                  >
                    GO TO {titleCaseObjectName(selectedItem.data.obj.object_type_txt || selectedItem.data.obj.object_type)} PROFILE
                  </Button>
                </div>
              </div>
            }
            {
              selectedItem.data.obj.object_count>0 &&
              <div>
                {
                  childList.data?.items?.map(el=>(
                    <>
                      <ContextMenuTrigger id={el.id}>
                        <div
                          className={classes.listItem + ' ' + classes.cartItem}
                          style={{
                            background:childrenMap[selectedItem.id]?.find(e=>e.data?.obj?.id===el.id)?theme.palette.primary.main+'10':undefined
                          }}
                        >
                          <div className={classes.iconWrapper}>
                            {
                              <Checkbox
                                data-test-classname="select-checkbox"
                                className='select-checkbox'
                                style={{
                                  width:selectedChildren.includes(el.id)?24:undefined,
                                  height:24,
                                  padding:0,
                                  color: selectedChildren.includes(el.id)?theme.palette.secondary.main:theme.palette.primaryText.light,
                                  float:'left',
                                  overflow:'hidden'
                                }}
                                checked={selectedChildren.includes(el.id)}
                                onClick={event=>{
                                  event.stopPropagation();
                                  if(selectedChildren.includes(el.id)){
                                    setIsSelectAll(false)
                                    setSelectedChildren(selectedChildren.filter(e=>e!==el.id))
                                  }else{
                                    // if(selectedChildren.length>=1000){
                                    //   sendAlert({type:'info',message:`A limit of 1000 can be selected at a time. You can not select more items`})
                                    //   return;
                                    // }
                                    setSelectedChildren([...selectedChildren,el.id])
                                  }
                                }}
                              />
                            }
                            <div
                              style={{
                                width:selectedChildren.includes(el.id)?0:undefined,
                                height:24,
                                overflow:selectedChildren.includes(el.id)?'hidden':undefined,
                              }}
                              className='item-icon'
                            >
                              {
                                addBadgeToIcon({
                                  icon: getIconComponent({label:getIconLabel({label:el.object_type,item:el}),size:24,colour:checkIsVisible(el)?theme.palette.primary.main:theme.palette.primaryText.light}),
                                  modification_badge: el.modification_badge_txt,
                                  active: el.active_txt,
                                  opacity: checkIsVisible(el)?undefined:0.5
                                })
                              }
                            </div>
                          </div>
                          <div style={{overflow:'hidden',flexGrow:1,marginRight:16}}>
                            <KTooltip title={getDispFields(el, 'dispTitle')}>
                              <Typography className={classes.listItemTitle} style={{color:checkIsVisible(el)?undefined:theme.palette.primaryText.light}}>
                                {getDispFields(el, 'dispTitle')}
                              </Typography>
                            </KTooltip>
                            <KTooltip title={getDispFields(el, 'dispSubtitle')}>
                              <Typography className={classes.listItemSubTitle}>
                                {getDispFields(el, 'dispSubtitle')}
                              </Typography>
                            </KTooltip>
                          </div>
                          {
                            tagView==='lineage' &&
                            <KTooltip title={el[getLineageField({isShowReference:mapControls.isShowReference?true:false, includeInactive:mapControls.isShowActiveOnly?false:true})]}>
                              {
                                getLineageIcon({
                                  item:el,
                                  size:24,
                                  colour:theme.palette.primary.main,
                                  forceField:getLineageField({isShowReference:mapControls.isShowReference?true:false, includeInactive:mapControls.isShowActiveOnly?false:true})
                                })
                              }
                            </KTooltip>
                          }
                          {
                            tagView==='trust' &&
                            getTrustScoreIcon({trustScore:el.score, size:24})
                          }
                          {
                            tagView==='visibility' &&
                            getVisibilityButton(el)
                          }
                        </div>
                      </ContextMenuTrigger>
                      <ContextMenu
                        id={el.id}
                      >
                        <CustomMenu
                          item={el}
                          actions={[
                            'open_new_tab'
                          ]}
                        />
                      </ContextMenu>
                    </>
                  ))
                }
                {
                  childList.loading &&
                  <div style={{display:"flex",justifyContent:'center'}}>
                    <CircularProgress color='secondary'/>
                  </div>
                }
                {
                  childList.error &&
                  <Typography>Error occurred loading items</Typography>
                }
                {
                  childList.data?.items?.length===0 &&
                  <Typography>No items found</Typography>
                }
              </div>
            }
          </div>

          <CartAdder
            objectIds={addToCartIds}
            onFinish={()=>{setAddToCartIds()}}
          />

        </>
      }

    </Drawer>
  )
}

export default withTheme()(withStyles(styles)(ParentDrawer));
