
import React from 'react';
import { Typography } from '@material-ui/core';
import BarChart from '../../Grid/Components/BarChart'
import StepLineChart from '../../Grid/Components/StepLineChart';
import KTooltip from '../../UI/KTooltip/KTooltip';
import { getColourPalette, KadaBadge } from 'kada-component-library';
import { checkIsDarkMode } from '../../../theme';


export const generateChartGroup = ({classes,data}) => {

  let chartData = [];

  let title = `Issues by priority`
  let subTitle = `Count of issues by issue priority`;
  if(data[`BY_PRIORITY`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...data[`BY_PRIORITY`]})
  }

  title = `Issues by DQ Dimension`
  subTitle = `Count of issues by DQ Dimension`;
  if(data[`BY_DIMENSION`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...data[`BY_DIMENSION`]})
  }

  title = `Issues by assigned status`
  subTitle = `Count of issues by assigned status`;
  if(data[`IS_ASSIGNED`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...data[`IS_ASSIGNED`]})
  }

  title = `Issues ageing`
  subTitle = `Count of issues by created date`;
  if(data[`BY_CREATED`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...data[`BY_CREATED`]})
  }

  title = `Issues open rate`
  subTitle = `Count of issues opened each day for the last 30 days`;
  if(data[`OPEN_RATE`]){
    chartData.push({type:'step_line',width:'80%',height:250,title,subTitle,...data[`OPEN_RATE`]})
  }

  title = `Top assignees`
  subTitle = `Count of issues by assignee. Top 5 assignees shown`;
  if(data[`TOP_ASSIGNEE`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...data[`TOP_ASSIGNEE`]})
  }

  title = `Top reporters`
  subTitle = `Count of issues by reporter. Top 5 reporters shown`;
  if(data[`TOP_REPORTER`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...data[`TOP_REPORTER`]})
  }

  if(chartData.length===0)return <Typography style={{marginBottom:24}}>No insights found</Typography>

  return (
    <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',gap:24,marginBottom:24}}>
      {
        chartData.map(el=>(
          <div
            className={"w-[48%] flex-grow p-4 border border-(--color-base-border) rounded"}
            style={{ width: el.title==='Issues open rate' ? '100%' : undefined }}
          >
            <KTooltip title={el.tooltip}>
              <div>
                <h3 className="text-(--color-header-text)">{el.title}</h3>
                <p className="!text-xs text-(--color-light-text)">{el.subTitle}</p>
              </div>
            </KTooltip>

            {
              el.type==='text' &&
              <div style={{width:el.width,height:el.height}}>
                <p className="text-(--color-light-text) mt-4">{el.text}</p>
              </div>
            }
            {
              el.type==='bar' &&
              <BarChart
                id={el.title}
                key={el.title}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
              />
            }
            {
              el.type==='step_line' &&
              <StepLineChart
                id={el.title}
                key={el.title}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
              />
            }

          </div>
        ))
      }

    </div>
  )
}

export const generateRecommendationList = ({classes, data}) => {

  if(data.length===0)return <p>No recommendations currently provided</p>

  return (
    <div style={{marginBottom:500}}>
      {
        data.map(el=>(
          <div
            className="hover:bg-(--color-base-200) h-[48px] pl-4 pr-4 flex items-center overflow-hidden min-w-[600px] cursor-pointer border-b border-(--color-base-border)"
            style={{
              width: 'calc(50% - 12px)',
            }}
            onClick={el.onClick}
          >
            <p className='!text-[1rem] flex-grow mr-4 text-overflow'>{el.title}</p>
            <KadaBadge
              text={el.countTxt}
              color={getColourPalette({mode: checkIsDarkMode()?'dark':'light'}).primaryDark}
            />
          </div>
        ))
      }
    </div>
  )
}
