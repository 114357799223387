import {  IconButton, Typography, withStyles, withTheme } from '@material-ui/core';
import React, { useState } from 'react';
import theme, { checkIsDarkMode } from '../../../theme';
import { getIconComponent, mapSearchObjectName } from '../../../utilities';
import { getIconLabel } from '../SearchResults/utils';
import { Handle } from 'react-flow-renderer';
import KTooltip from '../KTooltip/KTooltip';
import { getBackgroundColour, graphPalette } from '../Lineage/utils';
import {  lighten } from '@material-ui/core/styles/colorManipulator';


const styles = theme => ({
  root:{
    '& .node-hover-button':{
      visibility:'hidden'
    },
    '&:hover .node-hover-button':{
      visibility:'visible'
    },
  },
  // nodeHoverable:{
  //   '&:hover':{
  //     filter: checkIsDarkMode()?`saturate(200%)`:`saturate(400%)`
  //   },
  // },
  // nodeSelected:{
  //   filter: checkIsDarkMode()?`saturate(200%)`:`saturate(400%)`
  // },
  nodeContainer:{
    borderRadius:2,
    pointerEvents:'auto',
    cursor:'pointer',
  },
  actionButton:{
    border:`1px solid ${theme.palette.background.main}`,
    background:theme.palette.primaryText.light,
    padding:2,
    width:24,
    height:24,
    position:'absolute',
    lineHeight: 'normal',
    zIndex:999,
    '&:hover':{
      width:28,
      height:28,
      background:theme.palette.primaryText.light,
    }
  },
  leftActionPlaceholder:{
    position:'absolute',
    left:-35,
    width:36,
    background:'transparent'
  },
  leftActionButton:{
    left:-35,
    alignSelf:'center',
    '&:hover':{
      left:-37,
    }
  },
  rightActionPlaceholder:{
    position:'absolute',
    right:-35,
    width:36,
    background:'transparent'
  },
  rightActionButton:{
    right:-35,
    alignSelf:'center',
    '&:hover':{
      right:-37,
    }
  },
  bottomActionButton:{
    marginTop:-12,
    background:theme.palette.background.main,
    '&:hover':{
      marginTop:-14,
      background:theme.palette.background.main,
    }
  },
  loadingIcon:{
    border:`1px solid ${theme.palette.background.main}`,
    width:24,
    height:24,
    alignItems:'center',
    justifyContent:"center",
    display:'inline-flex',
    position:'absolute',
    borderRadius:'50%',
    background:theme.palette.primaryText.light,
    animationName: 'loading-spin',
    animationDuration: '800ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    cursor:'default'
  },
  [`@keyframes loading-spin`]: {
    from: {
      transform:'rotate(0deg)',
    },
    to: {
      transform:'rotate(360deg)',
    },
  },
  leftLoadingIcon:{
    left:-35,
    alignSelf:'center',
  },
  rightLoadingIcon:{
    right:-35,
    alignSelf:'center',
  },
})


const LineageNodeV3 = (prop) => {

  const { id, data, selected, classes, viewOnly } = prop;
  const [hovered, setHovered] = useState(false)

  let isConnectedToCentre = data.isConnectedToCentre;

  const objectType = data.obj?.object_type_txt || data.obj?.object_type || data.obj?.type || data.obj?.object?.name
  let typeColour = graphPalette[mapSearchObjectName(objectType,data.obj?.code_type_txt)]
  let background = getBackgroundColour(mapSearchObjectName(objectType,data.obj?.code_type_txt))
  if(selected || hovered || data.forceFocus){
    background = checkIsDarkMode()?lighten(background,0.2):lighten(typeColour,0.4)
  }

  let defaultBorderWidth = 2;
  // let borderWidth =  selected?3:defaultBorderWidth
  let borderWidth =  defaultBorderWidth
  // let borderWidthOffset = Math.max(0, borderWidth - defaultBorderWidth)
  let width = data.width
  let height = data.height

  let titleColour = data.titleColour || theme.palette.primaryText.main
  let borderColour = data.borderColour || typeColour || theme.palette.border.main

  return (
    <div
      id={id}
      style={{
        width,
        height,
      }}
      className={classes.root}
    >
      <div style={{display:'flex',alignItems:'center', width:width,opacity:data.faded?0.5:1}}>
        {
          !viewOnly &&
          <Handle
            type="target"
            position="left"
            style={{
              background: '#555',
              visibility:'hidden',
              position:'relative',
              top:4,
              width:0,
              ...(isConnectedToCentre?{left:width/2}:{left:data.leftHandleOffset || (data.leftAction?-40:-9)})
            }}
          />
        }
        {
          data.leftAction && !viewOnly &&
          <div className={classes.leftActionPlaceholder} style={{height}}>
          </div>
        }
        {
          data.leftAction && !viewOnly &&
          (
            data.leftActionIcon==='loading'?
            <div className={classes.loadingIcon + ' ' + classes.leftLoadingIcon}>
              {getIconComponent({label:'refresh', size:20, colour:theme.palette.background.main})}
            </div>
            :
            <KTooltip title={data.leftActionTooltip || ''}>
              <IconButton
                className={classes.actionButton + ' ' + classes.leftActionButton + ' node-action' + (data.isLeftActionHover?' node-hover-button':"")}
                onClick={event=>{
                  data.leftAction(event)
                }}
                style={{visibility:selected?'visible':undefined}}
              >
                {getIconComponent({label:data.leftActionIcon || 'add', size:16, colour:theme.palette.background.main})}
              </IconButton>
            </KTooltip>
          )
        }
        <div
          style={{
            display:'flex',
            alignItems:'center',
            overflow:'hidden',
            boxSizing:'border-box',
            background,
            width,
            height,
            // padding:`0px ${16-borderWidthOffset}px`,
            padding:`0px 16px`,
            borderBottom:`${borderWidth}px solid ${background}`,
            borderTop:`${borderWidth}px solid ${borderColour}`
            // ...(
              // selected?
              // {
              //   borderTop:`${borderWidth}px solid ${borderColour}`,
              //   borderBottom:`${borderWidth}px solid ${borderColour}`,
              //   position:'relative',
              //   // bottom:-borderWidthOffset,
              //   height:height+borderWidthOffset*2,
              //   paddingBottom:borderWidthOffset*2
              // }:
            //   {
            //     borderBottom:`${borderWidth}px solid ${background}`,
            //     borderTop:`${borderWidth}px solid ${borderColour}`
            //   }
            // )
          }}
          className={
            classes.nodeContainer + (selected?' '+classes.nodeSelected:' '+classes.nodeHoverable)
          }
          onMouseEnter={()=>setHovered(true)}
          onMouseLeave={()=>setHovered(false)}
          onContextMenu={(event)=>{
            event.preventDefault()
            if(viewOnly)return;
            // setContextMenuOpen(true)
            data.onContextMenu?.()
          }}
          onClick={(event)=>{
            data.onClick?.()
          }}
        >
          {
            data.obj &&
            <div
              style={{width:24,height:24,marginRight:8}}
            >
              {
                getIconComponent({
                  label:getIconLabel({label:objectType, item:data.obj}),
                  size:24,
                  colour: selected || hovered || checkIsDarkMode()?titleColour:(typeColour || titleColour || theme.palette.primaryText.main)
                })
              }
            </div>
          }
          <KTooltip title={data.label}>
            <Typography
              style={{
                fontSize:13.75,
                flexGrow:1,
                marginTop:1,
                color: titleColour,
                ...(
                  data.wrapTitle?
                  {whiteSpace:'normal',wordBreak:'break-word'}:
                  {}
                )
              }}
              className={'overflow-text '}
              id={`${id}-title`}
            >
              {data.label}
            </Typography>
          </KTooltip>

          {
            data.tags?.length>0 &&
            <div style={{marginLeft:8,flexGrow:0,flexShrink:0,display:"flex",gap:4, alignItems:'center',height:16}}>
              {
                data.tags.map(t=>(
                  <KTooltip title={t.tooltip}>
                    <div style={{height:16,width:16}}>{t.icon}</div>
                  </KTooltip>
                ))
              }
            </div>
          }
        </div>
        {
          data.rightAction && !viewOnly &&
          <div className={classes.rightActionPlaceholder} style={{height}}>
          </div>
        }
        {
          data.rightAction && !viewOnly &&
          (
            data.rightActionIcon==='loading'?
            <div
              className={classes.loadingIcon + ' ' + classes.rightLoadingIcon}
              style={{marginRight:(data.borderWidth||2)-2}}
            >
              {getIconComponent({label:'refresh', size:20, colour:theme.palette.background.main})}
            </div>
            :
            <KTooltip title={data.rightActionTooltip || ''}>
              <IconButton
                className={classes.actionButton + ' ' + classes.rightActionButton + ' node-action' + (data.isRightActionHover?' node-hover-button':"")}
                onClick={event=>{
                  data.rightAction(event)
                }}
                style={{visibility:selected?'visible':undefined}}
              >
                {getIconComponent({label:data.rightActionIcon || 'add', size:16, colour:theme.palette.background.main})}
              </IconButton>
            </KTooltip>
          )
        }
        {
          !viewOnly &&
          <Handle
            type="source"
            position="right"
            style={{
              background: '#555',
              visibility:'hidden',
              position:'relative',
              top:4,
              width:0,
              ...(isConnectedToCentre?{right:width/2}:{right:data.rightHandleOffset||(data.rightAction?-16:-4)})
            }}
          />
        }
      </div>

    </div>
  );
}

export default withTheme()(withStyles(styles)(LineageNodeV3));
