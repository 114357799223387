import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Button, Typography } from '@material-ui/core';
import { getFontColourByBackground, getIconComponent, getInitials, sendMessage } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import moment from 'moment';
import KTooltip from '../KTooltip/KTooltip';
import SmallBurgerButton from '../Buttons/smallBurgerButton';
import { withRouter } from 'react-router-dom'
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  bannerContainer: {
    background: theme.palette.warning.main,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 3,
    padding: '0 16px',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  bodyText: {
    fontSize: 13.75,
    flexShrink: 1,
    marginRight: 16
  },
  nextButton: {
    height: 24,
    border: `1px solid ${getFontColourByBackground(theme.palette.warning.main)}`,
    color: getFontColourByBackground(theme.palette.warning.main),
    padding: '0px 10px'
  },
  creatorChip: {
    height: 24,
    width: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
    borderRadius: 12,
    background: getFontColourByBackground(theme.palette.warning.main),
    fontSize: 12,
    color: '#666',
    flexShrink: 0
  },
  expiryChip: {
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    borderRadius: 12,
    background: getFontColourByBackground(theme.palette.warning.main),
    padding: '0 8px',
    fontSize: 12,
    color: '#666',
    flexShrink: 0
  }
});



const WarningBanner = props => {

  const {
    theme,
    classes,
    data,
    warnings,
    history
  } = props;

  const [viewedWarning, setViewedWarning] = useState(0)
  const [warningCreatorMap, setWarningCreatorMap] = useState({})

  const {
    sendAlert
  } = useAlert({})

  const loadWarningCreator = ({ setter, currentValue, warningId }) => {
    if (!currentValue[warningId]) {
      axiosCerebrum
        .get(
          `/api/collectioninstances/${warningId}/related`, {
          params: {
            relationship: 'CREATED_BY',
            object_name: 'USER',
          }
        }
        )
        .then(response => {
          setter({ ...currentValue, [warningId]: response.data.items[0] });
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  }

  const onRemoveWarningNotice = (warningId) => {
    axiosCerebrum
      .delete(`/api/collectioninstances/${warningId}/related`, {
        params: {
          relationship: 'MEMBERS',
          object_id: data.id,
        }
      })
      .then(response => {
        sendMessage({ reload_warning_notice: true })
      })
      .catch(error => {
        console.log('error', error);
        sendAlert({ message: 'Failed to remove warning notice', type: 'error' })
      });
  }

  useEffect(() => {
    if (!warnings || !warnings[viewedWarning]) {
      setViewedWarning(0);
      return;
    }
    loadWarningCreator({ setter: setWarningCreatorMap, currentValue: warningCreatorMap, warningId: warnings[viewedWarning]?.id })
  }, [warnings, viewedWarning, warningCreatorMap, setWarningCreatorMap])

  const onClickNext = () => {
    if (viewedWarning + 1 < warnings.length) {
      setViewedWarning(viewedWarning + 1);
    } else {
      setViewedWarning(0);
    }
  }

  let textColour = getFontColourByBackground(theme.palette.warning.main);
  let detail = warnings[viewedWarning];
  let creator = warningCreatorMap[warnings[viewedWarning]?.id];

  return (
    <div className={classes.bannerContainer} id='profile-header-banner' >
      <div style={{ width: 24, height: 24, flexShrink: 0, flexGrow: 0, marginRight: 16 }}>
        {getIconComponent({ label: "warning_notice", size: 24, colour: textColour })}
      </div>
      <Typography className={classes.bodyText + ' overflow-text'} style={{ color: textColour }}>
        {warnings[viewedWarning]?.name}
      </Typography>
      {
        warnings.length > 1 &&
        <Button className={classes.nextButton} onClick={onClickNext}>
          NEXT ({viewedWarning + 1} OF {warnings.length})
        </Button>
      }
      <div style={{ flexGrow: 1 }}></div>
      {
        creator &&
        <KTooltip title={detail ? `Added by ${creator.name} at ${moment(detail.created_at_srt).format('llll')}` : `Added by ${creator.name}`}>
          <div className={classes.creatorChip}>
            {getInitials(creator.name)}
          </div>
        </KTooltip>
      }
      {
        detail && !moment(detail.expiry_srt).isBefore(moment())  &&
        <KTooltip title={`Expires at ${moment(detail.expiry_srt).format('llll')}`}>
          <div className={classes.expiryChip}>
            <div style={{ width: 16, height: 16, marginRight: 8 }}>
              {getIconComponent({ label: 'expiry', size: 16, colour: '#666' })}
            </div>
            {moment(detail.expiry_srt).fromNow(true)}
          </div>
        </KTooltip>
      }
      {
        <SmallBurgerButton
          iconColour={textColour}
          testId='warning-banner-action-button'
          options={[
            {
              name: 'Actions',
              actions: [
                {
                  text: 'Go to warning notice',
                  onClick: () => history.push('/profile/collection_instance/' + warnings[viewedWarning]?.id)
                },
                {
                  text: 'Remove warning notice for this object',
                  onClick: () => { onRemoveWarningNotice(warnings[viewedWarning]?.id) }
                }
              ]
            }
          ]}
        />
      }
    </div>
  )
}


WarningBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  warnings: PropTypes.array.isRequired,
}



export default withTheme()(withStyles(styles)(withRouter(WarningBanner)));
