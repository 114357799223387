
import React from 'react';
import { Typography, CircularProgress, Button} from '@material-ui/core';
import DataTile from '../../Components/DataTile'
import KTooltip from '../../../UI/KTooltip/KTooltip';
import QuestionBoardChip from '../../../QuestionBoard/QuestionBoardChip/QuestionBoardChip';
import { processObjectName } from '../loadUtils';
import { getIconComponent, mapSearchObjectName } from '../../../../utilities';
import theme, { checkIsDarkMode } from '../../../../theme';
import BarChart from '../../Components/BarChart';
import { getColourPalette, KadaBadge } from 'kada-component-library';

export const generateDataTiles = ({classes, data, setData}) => {
  return (
    <div style={{display:'flex',width:'100%',marginBottom:24}}>
      {
        data.tileDataLoading && <CircularProgress color='secondary' style={{margin:'0px auto'}} />
      }
      {
        data.tileDataError && <Typography className={classes.normalText}>Error occurred loading details</Typography>
      }
      {
        data.tileData && data.tileData.map((el,index)=>(
          <DataTile tooltip={el.tooltip} clickable={el.clickable} tileId={el.id}  title={el.title} value={el.value} footer={el.footer}/>
        ))
      }
      {
        data.tileData && data.tileData.length===0 && <Typography className={classes.normalText}>No data to display</Typography>
      }
    </div>
  )
}

export const generateChartGroup = ({classes,data,tabState,tabOptions}) => {
  if(data[`${data.selectedTile}_graphDataLoading`]){
    return <div style={{textAlign:'center',marginTop:36,marginBottom:24}}><CircularProgress color='secondary'/></div>
  }
  if(data[`${data.selectedTile}_graphDataError`]){
    return <Typography className={classes.normalText} style={{marginBottom:24}}>Error occurred loading graphs</Typography>
  }
  if(!data[`${data.selectedTile}_graphData`] || !data.selectedTile){
    return <div></div>
  }
  let graphData = data[`${data.selectedTile}_graphData`];
  let chartData = [];
  let objectType = data.selectedTile;
  let displayObjectType = processObjectName(objectType);
  let title, subTitle;

  switch(tabOptions[tabState]){
    case 'SUMMARY':

      title = `Classification by Tables`
      subTitle = `Count of tables by classification`
      if(graphData[`TABLE_BY_CLASSIFICATION_TOTAL`]){
        chartData.push({type:'bar',width:'80%',height:280,title,subTitle,series:graphData[`TABLE_BY_CLASSIFICATION_TOTAL`].series,labels:graphData[`TABLE_BY_CLASSIFICATION_TOTAL`].labels})
      }
      title = `Classification by Columns`
      subTitle = `Count of columns by classification`
      if(graphData[`COLUMN_BY_CLASSIFICATION_TOTAL`]){
        chartData.push({type:'bar',width:'80%',height:280,title,subTitle,series:graphData[`COLUMN_BY_CLASSIFICATION_TOTAL`].series,labels:graphData[`COLUMN_BY_CLASSIFICATION_TOTAL`].labels})
      }
      break;
    case 'TABLE USAGE CLASSIFICATION':
      break;
    default:
  }

  if(chartData.length===0)return (
    <div style={{marginBottom:24}}>
      <Typography>No data available</Typography>
    </div>
  )

  return (
    <div>
      {
        chartData.map(el=>(
          <div className={classes.chartContainer} data-test-id={`chart-${el.title.replace(`${displayObjectType}s `,'').toLowerCase()}`}>
            <div>
              <div style={{display:'flex',alignItems:'center',overflow:'hidden'}}>
                <Typography className={classes.chartTitle}>{el.title}</Typography>
                {
                  el.tooltip &&
                  <KTooltip title={el.tooltip}>
                    <div style={{width:16,height:16,marginLeft:6}}>
                      {getIconComponent({label:'info',size:16,colour:theme.palette.primary.main})}
                    </div>
                  </KTooltip>
                }
              </div>
              <Typography className={classes.chartSubtitle}>{el.subTitle}</Typography>
            </div>

            {
              el.type==='text' &&
              <div style={{width:el.width,height:el.height}}>
                <Typography style={{marginTop:16}} className={classes.subTitle}>{el.text}</Typography>
              </div>
            }
            {
              el.type==='bar' &&
              <BarChart
                key={el.title}
                id={el.title}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
                isFileSize={el.isFileSize}
              />
            }
          </div>
        ))
      }

    </div>
  )
}



export const generateExtractDownloader = ({  history, data, filterValues, state, dispatch, dbList}) => {

  let button;

  let objectName =  mapSearchObjectName(data.selectedTile);
  if(!objectName)return <div></div>
  let code = `0200`;

  button = (
    <QuestionBoardChip
      key={objectName + filterValues.sourceId}
      buttonText={'USAGE REPORT'}
      buttonOnly
      state={state}
      dispatch={dispatch}
      code={code}
      defaultFilters={{
        source_id:filterValues.sourceId,
        object_type: objectName,
      }}
      removeButtonMargin
    />
  )

  return (
    <div style={{display:'flex',justifyContent:'flex-end'}}>
      <Button color='primary' variant='outlined' style={{marginRight:button?16:0}} onClick={()=>history.push(`/profile/source/${dbList.find(d=>d.id===filterValues.sourceId)?.host_ids[0]}`)}>OPEN PROFILE</Button>
      {button}
    </div>
  )
}

export const generateRecommendationList = ({classes, data, tabState, tabOptions}) => {

  if(data[`${data.selectedTile}_recommendationLoading`]){
    return <div style={{textAlign:'center',marginTop:36}}><CircularProgress color='secondary'/></div>
  }

  if(data[`${data.selectedTile}_recommendationError`]){
    return <Typography className={classes.normalText}>Error occurred loading recommendations</Typography>
  }

  if(!data[`${data.selectedTile}_recommendationData`] || !data.selectedTile){
    return <div></div>
  }

  let recommendations = [];

  switch(tabOptions[tabState]){
    case 'SUMMARY':
      recommendations = []
      break;
    default:
      recommendations = []
  }

  if(recommendations.length===0)return <Typography className={classes.normalText}>No recommendations currently provided</Typography>
  return (
    <div style={{marginBottom:500}}>
      {
        recommendations.map(el=>(
          <div className={classes.recommendationContainer} onClick={el.onClick}>
            <Typography className={classes.recommendationTitle}>{el.title}</Typography>
            <KadaBadge
              text={el.countTxt}
              color={getColourPalette({mode: checkIsDarkMode()?'dark':'light'}).primaryDark}
            />
          </div>
        ))
      }
    </div>
  )

}
