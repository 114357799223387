import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { globalListenerRef } from '../../../GlobalListenerRef';

function ContextMenuHolder(props) {
  const { popperId, children } = props;
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const holderRef = useRef(null);

  useEffect(() => {
    window.removeEventListener('customContextMenuEvent', globalListenerRef[`context_menu_holder_${popperId}`]);
    window.removeEventListener('mousedown', globalListenerRef[`context_menu_holder_out_click_${popperId}`]);

    globalListenerRef[`context_menu_holder_${popperId}`] = (event) => {
      const { detail } = event;
      if (detail.id === popperId) {
        setPosition({ x: detail.x, y: detail.y });
        setVisible(true);
      } else {
        setVisible(false);
      }
    };

    globalListenerRef[`context_menu_holder_out_click_${popperId}`] = (event) => {
      if (holderRef.current && !holderRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    window.addEventListener('customContextMenuEvent', globalListenerRef[`context_menu_holder_${popperId}`]);
    document.addEventListener('mousedown', globalListenerRef[`context_menu_holder_out_click_${popperId}`]);

    return () => {
      window.removeEventListener('customContextMenuEvent', globalListenerRef[`context_menu_holder_${popperId}`]);
      document.removeEventListener('mousedown', globalListenerRef[`context_menu_holder_out_click_${popperId}`]);
    };
  }, [popperId]);

  return createPortal(
    <div
      ref={holderRef}
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        display: visible ? 'block' : 'none',
        zIndex: 1000,
      }}
    >
      {children}
    </div>,
    document.body
  );
}

ContextMenuHolder.propTypes = {
  popperId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContextMenuHolder;
