import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, MenuItem, } from '@material-ui/core';
import { getDispFields, getIconComponent, getItemChildType, getPartialMatchSearchString, titleCaseObjectName, toTitleCase } from '../../../utilities';
import SelectionChip from '../Components/SelectionChip';
import SearchSelector from '../../UI/SearchSelector/SearchSelector';
import { getRightArrowIcon } from '../../../IconManager';
import MatchInput from './MatchInput';
import axiosSolr from '../../../axios-solr';

const styles = theme => ({
  header: {
    fontSize: 20,
    color: theme.palette.primaryText.main,
    marginBottom: 24
  },
  blockTitle: {
    fontSize: 13.75,
    color: theme.palette.primaryText.main,
    marginBottom: 24
  },
  block: {
    marginBottom: 40,
  },
  exampleRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 40,
    marginTop: 16,
    justifyContent: 'space-between',
    maxWidth: 1000
  },
  exampleChip: {
    maxWidth: 250,
    lineHeight: 'normal',
    width:'max-content',
    fontSize: 13.75,
    display: 'flex',
    alignItems: 'center',
    height: 24,
    borderRadius: 12,
    padding: '0px 8px',
    overflow: 'hidden',
    background: theme.palette.chip.main,
    border: `1px solid ${theme.palette.chipBorder.main}`,
    color: theme.palette.primaryText.main,
  }
})

function LinkingRulesSelect(props) {

  const {
    theme,
    classes,
    state,
    dispatch,
  } = props;

  const [upstreamParentSearch, setUpstreamParentSearch] = useState('');
  const [downstreamParentSearch, setDownstreamParentSearch] = useState('');
  const [upstreamExampleObj, setUpstreamExampleObj] = useState();
  const [downstreamExampleObj, setDownstreamExampleObj] = useState();
  const [upstreamChildExampleObj, setUpstreamChildExampleObj] = useState();
  const [downstreamChildExampleObj, setDownstreamChildExampleObj] = useState();

  const loadExampleObject = (parentId, onSet) => {
    axiosSolr
      .get(
        `/solr/search/select`, {
        params: {
          q: "*",
          fq: `hierarchy_parent_id_srt:${parentId}`,
          sort: "name_srt asc",
          rows: 3
        }
      })
      .then(res => {
        onSet(res.data.response.docs)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (state.upstreamParentObject) {
      loadExampleObject(state.upstreamParentObject.id, setUpstreamExampleObj)
    }
  }, [state.upstreamParentObject])

  useEffect(() => {
    if (state.downstreamParentObject) {
      loadExampleObject(state.downstreamParentObject.id, setDownstreamExampleObj)
    }
  }, [state.downstreamParentObject])

  useEffect(() => {
    if (!state.isMapChild) return;
    if (upstreamExampleObj?.[0]) {
      loadExampleObject(upstreamExampleObj[0].id, setUpstreamChildExampleObj)
    }
  }, [upstreamExampleObj, state.isMapChild])

  useEffect(() => {
    if (!state.isMapChild) return;
    if (downstreamExampleObj?.[0]) {
      loadExampleObject(downstreamExampleObj[0].id, setDownstreamChildExampleObj)
    }
  }, [downstreamExampleObj, state.isMapChild])

  const generateMatchName = (direction, isChildLevel) => {
    let config = state[`${direction}MatchConfig`]
    let childType = state[`${direction}ChildType`]
    if (isChildLevel) {
      config = state[`children${toTitleCase(direction)}MatchConfig`]
      childType = getItemChildType({ object_type_txt: childType })
    }
    return [
      config?.prefix !== undefined ? 'Prefix' : undefined,
      `${titleCaseObjectName(childType)} name`,
      config?.suffix !== undefined ? 'Suffix' : undefined
    ].filter(el => el).join(' + ')
  }

  const generateMacthOption = (direction, isChildLevel) => {
    let childType = titleCaseObjectName(state[`${direction}ChildType`]);
    let setConfig = config => {
      dispatch({ type: `set_${direction}_match_config`, [`${direction}MatchConfig`]: config })
      // dispatch({ type: `set_upstream_match_config`, [`upstreamMatchConfig`]: config })
      // dispatch({ type: `set_downstream_match_config`, [`downstreamMatchConfig`]: config })
      document.getElementById(`modal-close-btn`).click()
    }

    if (isChildLevel) {
      childType = getItemChildType({ object_type_txt: state[`${direction}ChildType`] })
      setConfig = config => {
        dispatch({ type: `set_children_${direction}_match_config`, [`children${toTitleCase(direction)}MatchConfig`]: config })
        // dispatch({ type: `set_children_upstream_match_config`, [`childrenUpstreamMatchConfig`]: config })
        // dispatch({ type: `set_children_downstream_match_config`, [`childrenDownstreamMatchConfig`]: config })
        document.getElementById(`modal-close-btn`).click()
      }
    }

    return (
      <>
        <MenuItem onClick={() => setConfig()}>
          {childType} name
        </MenuItem>
        <MenuItem onClick={() => setConfig({ prefix: '' })}>
          {'<prefix>'} + {childType} name
        </MenuItem>
        <MenuItem onClick={() => setConfig({ suffix: '' })}>
          {childType} name + {'<suffix>'}
        </MenuItem>
        <MenuItem onClick={() => setConfig({ prefix: '', suffix: '' })}>
          {'<prefix>'} + {childType} name + {'<suffix>'}
        </MenuItem>
      </>
    )
  }

  const generateMatchConfigInput = (direction, isChildLevel) => {
    let config = state[`${direction}MatchConfig`];
    let setConfig = config => {
      dispatch({ type: `set_${direction}_match_config`, [`${direction}MatchConfig`]: config })
    }

    if (isChildLevel) {
      config = state[`children${toTitleCase(direction)}MatchConfig`]
      setConfig = config => {
        dispatch({ type: `set_children_${direction}_match_config`, [`children${toTitleCase(direction)}MatchConfig`]: config })
      }
    }

    let components = [];

    if (config?.prefix !== undefined) {
      components.push(
        <MatchInput
          value={config.prefix}
          prefix="Prefix"
          onChange={value => setConfig({ ...config, prefix: value })}
        />
      )
    }
    if (config?.suffix !== undefined) {
      components.push(
        <MatchInput
          value={config.suffix}
          prefix="Suffix"
          onChange={value => setConfig({ ...config, suffix: value })}
        />
      )
    }
    return components
  }

  const generateExampleComponent = (objects, parent, prefix, suffix) => {
    return (
      <div style={{ width: 320, paddingLeft: 16, boxSizing: 'border-box' }}>
        <Typography style={{ fontSize: 13.75, marginBottom: 8, maxWidth: '100%' }} className='overflow-text'>Examples from {parent.name_txt}:</Typography>
        {
          objects.map(o=>{
            return (
              <div className={classes.exampleChip} style={{marginBottom: 8}} key={o.name_txt}>
                <div style={{ width: 16, height: 16, marginRight: 8 }}>
                  {getIconComponent({ label: o.object_type_txt, size: 16, colour: theme.palette.primaryText.main })}
                </div>
                <span className='overflow-text'>{prefix}{o.name_txt}{suffix}</span>
              </div>
            )
          })
        }
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        Select linking scope
      </Typography>
      <div className={classes.block}>
        <Typography className={classes.blockTitle}>
          Select upstream {titleCaseObjectName(state.upstreamParentType)} & downstream {titleCaseObjectName(state.downstreamParentType)}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: 1000 }}>
          <SelectionChip
            object={state.upstreamParentObject || { name_txt: `Select a ${titleCaseObjectName(state.upstreamParentType)}` }}
            id="upstream-parent"
            inputWidth={650}
            interactiveHeader={`Select a ${titleCaseObjectName(state.upstreamParentType)}`}
            interactiveBody={
              <SearchSelector
                url={`/solr/search/select`}
                params={{
                  q: getPartialMatchSearchString(upstreamParentSearch),
                  fq: `object_type_srt:${state.upstreamParentType} AND source_id_srt:${state.upstreamSource?.id}`,
                }}
                autoFocus={true}
                searchValue={upstreamParentSearch}
                setSearchValue={setUpstreamParentSearch}
                scrollable
                onResultClick={o => {
                  dispatch({ type: 'set_upstream_parent_object', upstreamParentObject: o })
                  document.getElementById('modal-close-btn').click()
                }}
              />
            }
          />
          {getRightArrowIcon({ length: 200, colour: props.theme.palette.primaryText.light })}
          <SelectionChip
            object={state.downstreamParentObject || { name_txt: `Select a ${titleCaseObjectName(state.downstreamParentType)}` }}
            id="downstream-parent"
            inputWidth={650}
            interactiveHeader={`Select a ${titleCaseObjectName(state.downstreamParentType)}`}
            interactiveBody={
              <SearchSelector
                url={`/solr/search/select`}
                params={{
                  q: getPartialMatchSearchString(downstreamParentSearch),
                  fq: `object_type_srt:${state.downstreamParentType} AND source_id_srt:${state.downstreamSource?.id}`,
                }}
                autoFocus={true}
                searchValue={downstreamParentSearch}
                setSearchValue={setDownstreamParentSearch}
                scrollable
                onResultClick={o => {
                  dispatch({ type: 'set_downstream_parent_object', downstreamParentObject: o })
                  document.getElementById('modal-close-btn').click()
                }}
              />
            }
          />
        </div>
      </div>
      {
        state.upstreamParentObject && state.downstreamParentObject &&
        <>
          <div className={classes.block} style={{ marginBottom: 0 }}>
            <Typography className={classes.blockTitle}>
              Add matching rules to link {titleCaseObjectName(state.upstreamChildType)} in {getDispFields(state.upstreamParentObject, 'dispTitle')} to {titleCaseObjectName(state.downstreamChildType)} in {getDispFields(state.downstreamParentObject, 'dispTitle')}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', maxWidth: 1000 }}>
              <div>
                <SelectionChip
                  object={{ name_txt: generateMatchName('upstream') }}
                  id="upstream-match"
                  interactiveHeader={'Select a match rule for upstream'}
                  interactiveBody={
                    <div>
                      {generateMacthOption('upstream')}
                    </div>
                  }
                />
                {generateMatchConfigInput('upstream')}
              </div>
              <Typography style={{ width: 200, fontSize: 13.75, textAlign: 'center', margin: 16 }}>MATCHES</Typography>
              <div>
                <SelectionChip
                  object={{ name_txt: generateMatchName('downstream') }}
                  id="downstream-match"
                  interactiveHeader={'Select a match rule for downstream'}
                  interactiveBody={
                    <div>
                      {generateMacthOption('downstream')}
                    </div>
                  }
                />
                {generateMatchConfigInput('downstream')}
              </div>
            </div>
          </div>
          <div className={classes.exampleRow}>
            {
              upstreamExampleObj && downstreamExampleObj &&
              <>
                {generateExampleComponent(upstreamExampleObj, state.upstreamParentObject, state.upstreamMatchConfig?.prefix, state.upstreamMatchConfig?.suffix)}
                <div style={{ width: 200, margin: 16 }}></div>
                {generateExampleComponent(downstreamExampleObj, state.downstreamParentObject, state.downstreamMatchConfig?.prefix, state.downstreamMatchConfig?.suffix)}
              </>
            }
          </div>
        </>
      }
      {
        state.isMapChild && state.upstreamParentObject && state.downstreamParentObject &&
        <>
          <div className={classes.block} style={{ marginBottom: 0 }}>
            <Typography className={classes.blockTitle}>
              Add matching rules to link {titleCaseObjectName(getItemChildType({ object_type_txt: state.upstreamChildType }))} to {titleCaseObjectName(getItemChildType({ object_type_txt: state.downstreamChildType }))} for {titleCaseObjectName(state.upstreamChildType)} to {titleCaseObjectName(state.downstreamChildType)} matched
            </Typography>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', maxWidth: 1000 }}>
              <div>
                <SelectionChip
                  object={{ name_txt: generateMatchName('upstream', true) }}
                  id="upstream-match-child"
                  interactiveHeader={generateMatchName('upstream', true)}
                  interactiveBody={
                    <div>
                      {generateMacthOption('upstream', true)}
                    </div>
                  }
                />
                {generateMatchConfigInput('upstream', true)}
              </div>
              <Typography style={{ width: 200, fontSize: 13.75, textAlign: 'center', margin: 16 }}>MATCHES</Typography>
              <div>
                <SelectionChip
                  object={{ name_txt: generateMatchName('downstream', true) }}
                  id="downstream-match-child"
                  interactiveHeader={generateMatchName('downstream', true)}
                  interactiveBody={
                    <div>
                      {generateMacthOption('downstream', true)}
                    </div>
                  }
                />
                {generateMatchConfigInput('downstream', true)}
              </div>
            </div>
          </div>
          <div className={classes.exampleRow}>
            {
              upstreamChildExampleObj && downstreamChildExampleObj &&
              <>
                {generateExampleComponent(upstreamChildExampleObj, state.upstreamParentObject, state.childrenUpstreamMatchConfig?.prefix, state.childrenUpstreamMatchConfig?.suffix)}
                <div style={{ width: 200, margin: 16 }}></div>
                {generateExampleComponent(downstreamChildExampleObj, state.downstreamParentObject, state.childrenDownstreamMatchConfig?.prefix, state.childrenDownstreamMatchConfig?.suffix)}
              </>
            }
          </div>
        </>
      }
    </div>
  )
}

LinkingRulesSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(LinkingRulesSelect));
