import React  from 'react';
import { withStyles} from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import AdditionalPropertyAdder from '../../UI/AdditionalPropertyAdder/AdditionalPropertyAdder'
import AccessAdder from '../../UI/AccessAdder/AccessAdder'
import SourceDescription from '../../UI/SourceDescription/SourceDescription';

const styles = theme => ({

})

const Details = props => {

  const {
    state,
    fetchList,
    dispatch,
  } = props;

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/datapipelines/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        data={state.basicData}
        label="data_pipeline"
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        state={state}
        fetchList={fetchList}
        collection="content"
        enableMarkDown={true}
        enableWidget={true}
      />

      <SourceDescription
        state={state}
        dispatch={dispatch}
      />

      <div style={{ marginTop: 60 }} >
        <AdditionalPropertyAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.additionalPropertiesModalOpen}
          setModalOpen={value=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
        />
      </div>

      <div style={{ marginTop: 24 }} >
        <AccessAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.accessModalOpen}
          setModalOpen={value=>dispatch({type:'set_access_modal_open',accessModalOpen:value})}
        />
      </div>

	  </div>
  )
}


export default withStyles(styles)(Details);
