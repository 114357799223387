import React from 'react';
import PropTypes from 'prop-types';
import useAlert from '../../../../hooks/useAlert';
import { KadaBtn, KadaDropdownBtn, KadaLoadingBar, KadaModal, KadaSnackBar, kButtonVariants, kDropdownBtnVariants } from 'kada-component-library';
import { closeKadaModal, openKadaModal, sendMessage } from '../../../../utilities';
import axiosCerebrum from '../../../../axios-cerebrum';
import DQModal from '../../DQModal/DQModal';
import useGetIssueIntegrations from '../../../../hooks/useGetIssueIntegration';

const IssueActionBtn = props => {
  const {
    object,
  } = props;

  const [modalOpen, setModalOpen] = React.useState(false);
  const { sendAlert } = useAlert({});
  const [unlinking, setUnlinking] = React.useState(false);
  const [alertDetails, setAlertDetails] = React.useState({});

  const {
    data: issueIntegrations,
  } = useGetIssueIntegrations({})

  const onCreateJiraTicket = () => {
    openKadaModal('issue-create-ext-modal');
    axiosCerebrum
      .put(`/api/integrations/issues/${object.id}`)
      .then(response=>{
        sendMessage({ reload_issue_profile: true });
        closeKadaModal('issue-create-ext-modal');
        sendMessage({
          issue_profile_external_created:{
            url: response.data.external_url,
          }
        });
      })
      .catch(error=>{
        let msg = error.response?.data?.errors || 'Failed to create JIRA ticket';
        console.log(error);
        sendAlert({ message: msg, type: 'error' });
        closeKadaModal('issue-create-ext-modal');
      })
  };

  const onRefresh = () => {
    sendAlert({ message: 'Refreshing JIRA ticket...', type: 'info' });
    axiosCerebrum
      .patch(`/api/integrations/issues/${object.id}`)
      .then(response=>{
        sendAlert({ message: 'JIRA ticket refreshed successfully', type: 'info' });
        sendMessage({ reload_issue_profile: true });
      })
      .catch(error=>{
        let msg = error.response?.data?.errors || 'Failed to refresh JIRA ticket';
        console.log(msg);
        sendAlert({ message: msg, type: 'error' });
      })
  }

  const onUnlink = () => {
    setUnlinking(true);
    axiosCerebrum
      .patch(`/api/issues/${object.id}`,{external_url: null})
      .then(response=>{
        sendAlert({ message: 'JIRA ticket unlinked successfully', type: 'info' });
        sendMessage({ reload_issue_profile: true });
        closeKadaModal('issue-unlink-modal');
        setUnlinking(false);
      })
      .catch(error=>{
        let msg = error.response?.data?.errors || 'Failed to unlink JIRA ticket';
        console.log(msg);
        setAlertDetails({ message: msg, type: 'error' });
        setUnlinking(false);
      })
  }

  const onLink = () => {
    setModalOpen(true);
  }

  let isIntegrationNotSet = !issueIntegrations || issueIntegrations.length === 0;

  return (
    <div className="mb-4">
      <KadaDropdownBtn
        value={''}
        className="hover:bg-(--color-base-200) rounded-[4px]"
        // iconOnly
        // iconName='more_horiz'
        textOnly
        placeholder='Actions'
        disabled={isIntegrationNotSet}
        tooltip={isIntegrationNotSet ? 'Issue management integration needs to be configured to enable' : ''}
        alwaysDisplayPlaceholder
        variant={kDropdownBtnVariants.roundedIconButton}
        options={[
          {
            text: 'Create JIRA ticket',
            value: 'create',
            onClick: onCreateJiraTicket,
            disabled: object.external_url ? true : false,
            tooltip: object.external_url ? 'JIRA ticket already created' : '',
          },
          {
            text: 'Link JIRA ticket',
            value: 'link',
            onClick: onLink,
            disabled: object.external_url ? true : false,
            tooltip: object.external_url ? 'JIRA ticket already linked' : '',
          },
          {
            text: 'Refresh details from JIRA ticket',
            value: 'refresh',
            onClick: onRefresh,
            disabled: !object.external_url,
            tooltip: !object.external_url ? 'No JIRA ticket linked' : '',
          },
          {
            text: 'Unlink JIRA ticket',
            value: 'unlink',
            onClick: () => openKadaModal('issue-unlink-modal'),
            disabled: !object.external_url,
            tooltip: !object.external_url ? 'No JIRA ticket linked' : '',
          },
        ]}
      />
      <DQModal
        isExternal
        object={object}
        dqModalOpen={modalOpen}
        setDqModalOpen={setModalOpen}
      />
      <KadaModal id="issue-unlink-modal" width="480px" maxWidth="90vw">
        <div slot="header">
          <h4>UNLINK EXTERNAL TICKET</h4>
        </div>
        <div slot="content">
          <p>
            Unlinking the external ticket will reenable editing for this issue.
          </p>
          <p className='mt-4'>
            Any further updates on the external ticket will not be synchronised to this issue.
          </p>
        </div>
        <div slot="actions">
          <KadaBtn
            text="UNLINK"
            style={{ marginRight: 16 }}
            onClick={onUnlink}
            disabled={unlinking}
          />
          <KadaBtn
            text="CANCEL"
            daisyClass="btn-primary btn-outline"
            onClick={() => {
              closeKadaModal('issue-unlink-modal')
            }}
            variant={kButtonVariants.secondaryBorderless}
          />
        </div>
        <div slot="message">
          <KadaSnackBar
            alertDetails={alertDetails}
            isChipOnly
          />
        </div>
      </KadaModal>
      <KadaModal id="issue-create-ext-modal" width="480px" maxWidth="90vw">
        <div slot="header">
          <h4>CREATE JIRA TICKET</h4>
        </div>
        <div slot="content" className='p-6'>
          <KadaLoadingBar className="w-full" text="Creating ticket and syncing details"/>
        </div>
      </KadaModal>
    </div>
  );
};

IssueActionBtn.propTypes = {
  history: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
};

export default React.memo(IssueActionBtn);
