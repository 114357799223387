import React  from 'react';
import { withStyles, Typography, Button} from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import AdditionalPropertyAdder from '../../UI/AdditionalPropertyAdder/AdditionalPropertyAdder'
import AccessAdder from '../../UI/AccessAdder/AccessAdder'
import GlossaryAdder from '../../UI/GlossaryAdder/GlossaryAdder';
import SourceDescription from '../../UI/SourceDescription/SourceDescription';
import theme from '../../../theme';
import CodeDisplay from '../../UI/CodeDisplay/CodeDisplay';
import sqlFormatter from "sql-formatter";
import useAlert from '../../../hooks/useAlert';
import { copyToClipboard } from '../../../utilities';

const styles = theme => ({

})

const Details = props => {

  const {
    state,
    fetchList,
    dispatch,
    history,
  } = props;


  const {
    sendAlert
  } = useAlert({})

  const handleCodeClick = () => {
    copyToClipboard(sqlFormatter.format(state.basicData.codeData.code,{language:'pl/sql'}))
    sendAlert({message:"Copied code to clipboard",type:'info'})
  }

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/datasets/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        data={state.basicData}
        label="dataset"
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        state={state}
        fetchList={fetchList}
        collection="content"
        enableMarkDown={true}
        enableWidget={true}
      />

      <SourceDescription
        state={state}
        dispatch={dispatch}
      />

      <div style={{ marginTop: 60 }} >
        <GlossaryAdder
          state={state}
          dispatch={dispatch}
          history={history}
          disableModal
        />
      </div>

      <div style={{ marginTop: 24 }} >
        <AdditionalPropertyAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.additionalPropertiesModalOpen}
          setModalOpen={value=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
        />
      </div>

      <div style={{ marginTop: 24 }} >
        <AccessAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.accessModalOpen}
          setModalOpen={value=>dispatch({type:'set_access_modal_open',accessModalOpen:value})}
        />
      </div>

      {
        state.basicData.codeData?.code &&
        <div style={{marginTop:60}}>
          <div style={{display:'flex',alignItems:'flex-start',marginBottom: 24}}>
            <Typography style={{ fontSize: 20,color:theme.palette.header.main}}>CODE</Typography>
            <Button style={{marginLeft:16,marginTop:-2}} color='primary' onClick={handleCodeClick}>COPY</Button>
          </div>
          <CodeDisplay
            code={state.basicData.codeData.code}
          />
        </div>
      }
	  </div>
  )
}


export default withStyles(styles)(Details);
