import React from 'react';
import { withStyles, Typography, CircularProgress, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import CodeDisplay from '../../UI/CodeDisplay/CodeDisplay';
import {copyToClipboard} from '../../../utilities'
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import sqlFormatter from "sql-formatter";
import AccessAdder from '../../UI/AccessAdder/AccessAdder'
import GlossaryAdder from '../../UI/GlossaryAdder/GlossaryAdder';
import SourceDescription from '../../UI/SourceDescription/SourceDescription';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {

  },
  title: {
    fontSize: '1.25rem',
    color:theme.palette.header.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
})

function Details(props) {

  const {
    classes,
    fetchList,
    state,
    history,
    dispatch,
    loading,
    error,
    cerebrumLabel
  } = props;

  const {
    sendAlert
  } = useAlert({})

  if(loading)return ( <div style={{ textAlign: 'center', marginTop: '20%' }}>
  <CircularProgress color="secondary" />
  <Typography className={classes.normalText}>Loading</Typography>
</div>)

  if(error || !state.basicData)return <Typography className={classes.normalText}>Error loading the content</Typography>


  const handleCodeClick = () => {
    copyToClipboard(sqlFormatter.format(state.basicData.code,{language:'pl/sql'}))
    sendAlert({message:"Copied code to clipboard",type:'info'})
  }

  return (
    <div className={classes.root}>

      <div className={classes.grouping}>
        <UpdateInputCerebrum
          url={`/api/${cerebrumLabel}/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
          label={state.basicData.object.name.toLowerCase()}
          initialValue={state.basicData.description}
          property="description"
          placeholder="Add a description"
          multiline
          data={state.basicData}
          fetchList={fetchList}
          collection="content"
          enableMarkDown={true}
          enableWidget={true}
          state={state}
        />
      </div>

      <SourceDescription
        state={state}
        dispatch={dispatch}
      />

      <div style={{ marginTop: 60 }} >
        <GlossaryAdder
          state={state}
          dispatch={dispatch}
          history={history}
          disableModal
        />
      </div>

      <div style={{ marginTop: 24 }} >
        <AccessAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.accessModalOpen}
          setModalOpen={value=>dispatch({type:'set_access_modal_open',accessModalOpen:value})}
        />
      </div>

      {
        state.basicData.code &&
        <div style={{ marginTop: 60 }} >
          <div style={{display:'flex',alignItems:'flex-start'}}>
            <Typography className={classes.title}>CODE</Typography>
            <Button style={{marginLeft:16,marginTop:-2}} color='primary' onClick={handleCodeClick}>COPY</Button>
          </div>
          <CodeDisplay code={state.basicData.code}/>
        </div>
      }

    </div>
  )
}

Details.propTypes = {
  root: PropTypes.object.isRequired,
  statement: PropTypes.string,
  description: PropTypes.string,
  userName: PropTypes.string,
  fetchList: PropTypes.func.isRequired
}

export default withStyles(styles)(Details);
