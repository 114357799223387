
import React from 'react';
import { Typography, CircularProgress, IconButton } from '@material-ui/core';
import BarChart from '../../Grid/Components/BarChart'
import KTooltip from '../../UI/KTooltip/KTooltip';
import { toTitleCase, titleCaseObjectName, getIconComponent } from '../../../utilities';
import HeatMap from '../../Grid/Components/HeatMap';
import theme, { checkIsDarkMode } from '../../../theme';
import { getColourPalette, KadaBadge } from 'kada-component-library';

export const generateChartGroup = ({ classes, state, type, object, dispatch }) => {
  if (state.graphDataLoading) {
    return <div style={{ textAlign: 'center', marginTop: 36, marginBottom: 24 }}><CircularProgress color='secondary' /></div>
  }
  if (state.graphDataError) {
    return <Typography style={{ marginBottom: 24 }}>Error occurred loading graphs</Typography>
  }
  if (!state.graphData) {
    return <div></div>
  }

  let graphData = state.graphData;
  let chartData = [];

  let dispObjectType = titleCaseObjectName(object)

  if (type === 'LINEAGE COMPLEXITY') {
    if (graphData['lineage_complexity']) {
      let title = `${dispObjectType}s by lineage count`
      let subTitle = `${dispObjectType}s by the number of upstream & downstream assets connected`
      let width =  Math.max(Math.min(window.innerWidth/3, 700), 500);
      let height = width;
      chartData.push({ type: 'heatmap', width, height, title, subTitle, ...graphData[`lineage_complexity`] })
    }
  }

  else if (type === 'UPSTREAM LINEAGE') {
    if (graphData['by_lineage_count']) {
      let title = `${dispObjectType}s by Upstream lineage count`
      let subTitle = `Count of ${dispObjectType}s by Upstream lineage count`
      chartData.push({ type: 'bar', width: '80%', height: 260, title, subTitle, ...graphData[`by_lineage_count`] })
    }
  }
  else if (type === 'DOWNSTREAM LINEAGE') {
    if (graphData['by_lineage_count']) {
      let title = `${dispObjectType}s by Downstream lineage count`
      let subTitle = `Count of ${dispObjectType}s by Downstream lineage count`
      chartData.push({ type: 'bar', width: '80%', height: 260, title, subTitle, ...graphData[`by_lineage_count`] })
    }
  }

  if (chartData.length === 0) return <Typography style={{ marginBottom: 24 }}>No insights found</Typography>

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {
        chartData.map(el => (
          <div className={classes.chartContainer} data-test-id={`chart-${el.title.replace(`${toTitleCase(object)}s `, '').toLowerCase()}`}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <KTooltip title={el.tooltip} classes={{ tooltip: classes.tooltip }}>
                <div style={{ flexGrow: 1, marginRight: 16, overflow: 'hidden' }}>
                  <Typography className={classes.chartTitle}>{el.title}</Typography>
                  <Typography className={classes.chartSubtitle}>{el.subTitle}</Typography>
                </div>
              </KTooltip>
              <KTooltip title={'Configure ranges'}>
                <IconButton onClick={() => dispatch({ type: 'set_by_source_range_editor_open', bySourceRangeEditorOpen: true })}>
                  {getIconComponent({ label: 'setting', size: 24, colour: theme.palette.primaryText.light })}
                </IconButton>
              </KTooltip>
            </div>
            {
              el.type === 'text' &&
              <div style={{ width: el.width, height: el.height }}>
                <Typography style={{ marginTop: 16 }} className={classes.subTitle}>{el.text}</Typography>
              </div>
            }
            {
              el.type === 'bar' &&
              <BarChart
                id={el.title}
                key={el.title + object}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
                toggleLabel={el.toggleLabel}
              />
            }
            {
              el.type === 'heatmap' &&
              <HeatMap
                id={el.title}
                key={el.title + object}
                width={el.width}
                height={el.height}
                data={el.data}
                xLabels={el.xLabels}
                yLabels={el.yLabels}
                colourRange={el.colourRange}
              />
            }

          </div>
        ))
      }

    </div>
  )
}

export const generateRecommendationList = ({ classes, state }) => {

  if (!state.graphData) {
    return <div></div>
  }

  let recommendations = state.graphData.recommendations;

  if (!recommendations) {
    return <div></div>
  }

  return (
    <div style={{ marginBottom: 500 }} id="recommendation-section">
      <Typography className={classes.header} >RECOMMENDATIONS</Typography>
      <Typography className={classes.subHeader}>Select a recommendation to see more details</Typography>
      {
        recommendations.length === 0 && <Typography className={classes.normalText}>No recommendations currently provided</Typography>
      }
      {
        recommendations.map(el => (
          <div className={classes.recommendationContainer} onClick={el.onClick}>
            <Typography className={classes.recommendationTitle}>{el.title}</Typography>
            <KadaBadge
              text={el.countTxt}
              color={getColourPalette({mode: checkIsDarkMode()?'dark':'light'}).primaryDark}
            />
          </div>
        ))
      }
    </div>
  )
}
