import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, MenuItem, Select, Checkbox, IconButton, InputBase } from '@material-ui/core';
import { toTitleCase, getIconComponent, mapObjectName, mapSearchObjectName } from '../../../utilities'
import ListLineage from './ListLineage/ListLineage';
import LineageChart from './LineageGraph/LineageChart';
import { getLineageRelation, isV3Lineage, lineageObjectsMap } from './utils';
import LineageV3 from './LineageV3/LineageV3';

const styles = theme => ({
  root:{
  },
  title:{
    fontSize:20,
    color:theme.palette.primary.main,
    marginBottom:16
  },
  headerText:{
    fontSize:20,
    marginLeft:16,
    color:theme.palette.header.main
  },
  checkbox:{
    paddingLeft:0
  },
  selector: {
    ...theme.components.titleSelector,
  },
  listSelector:{
    ...theme.components.selector,
    width:150
  },
  dropdownTitle:{
    fontSize:12,
    letterSpacing:2,
    marginLeft:16,
    marginBottom:8,
    marginTop:8,
    color:theme.palette.primary.main,
    width:190,
  },
  nameFilter:{
    ...theme.components.inputBase,
		width:200,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
	menuItem:{
		padding:'10px 10px 10px 16px',
		color:theme.palette.primaryText.main,
		'&:hover':{
      background: theme.palette.hovered.main
    }
	},
})

const dedupeList = (list) => {
  let newList = [];
  list.forEach(el=>{
    if(!newList.includes(el))newList.push(el)
  })
  return newList
}

function LineageContainer(props) {
  const {
    classes,
    theme,
    history,
    state,
    dispatch,
    isApp,
    target,
    isLinkable
  } = props;

  const listSearchRef = useRef()

  const [selectedObjects, setSelectedObjects] = useState()
  const [view, setView] = useState()
  const [listDirection, setListDirection] = useState()
  const [listSearch, setListSearch] = useState('')

  let label;
  if(isApp){
    label = target.labels || target.object_type_txt;
  }else{
    label = state.basicData.object.name;
  }

  if(isApp)label = mapSearchObjectName(label, target.code_type_txt)
  if(label)label = label.toUpperCase()
  let upstreamObjects = lineageObjectsMap[label]
  let downstreamObjects = lineageObjectsMap[label]
  let upstreamRelations = getLineageRelation(label,'upstream');
  let downstreamRelations = getLineageRelation(label,'downstream');

  useEffect(()=>{
    if(state.lineageData.view){
      setView(state.lineageData.view)
    }else{
      setView('map')
    }
    if(state.lineageData.listDirection){
      setListDirection(state.lineageData.listDirection)
    }else{
      setListDirection('upstream')
    }
    if(state.lineageData.listSearch){
      setListSearch(state.lineageData.listSearch)
    }else{
      setListSearch('')
    }
    if(state.lineageData.selectedObjects){
      setSelectedObjects(state.lineageData.selectedObjects)
    }else{
      let defaultObjects = dedupeList([...upstreamObjects,...downstreamObjects])
      setSelectedObjects(defaultObjects)
    }
  // eslint-disable-next-line
  },[state.view])

  const onChangeView = view => {
    setView(view)
    dispatch({
      type:"set_lineage_data",
      lineageData:{
        ...state.lineageData,
        selectedObjects:undefined,
        view,
      }
    })
  }


  const onChangeSelectedObjects = objects => {
    setSelectedObjects(objects)
    dispatch({
      type:"set_lineage_data",
      lineageData:{
        ...state.lineageData,
        selectedObjects:objects
      }
    })
    // clearTimeout(objectChangeRef.current)
    // objectChangeRef.current = setTimeout(()=>{
    //   window.postMessage({refreshLineage:'true'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
    // },200)
  }


  const onChangeListDirection = direction => {
    setListDirection(direction)
    dispatch({
      type:"set_lineage_data",
      lineageData:{
        ...state.lineageData,
        listDirection:direction
      }
    })
  }


  const onListSearchChange = value => {
    setListSearch(value)
    clearTimeout(listSearchRef.current)
    listSearchRef.current = setTimeout(()=>{
      dispatch({
        type:"set_lineage_data",
        lineageData:{
          ...state.lineageData,
          listSearch:value
        }
      })
    },250)
  }


  if(!view || !selectedObjects)return <div></div>;


  let finalSelectedObjects = selectedObjects.filter(el=>!['QUERY','MACRO','PROCEDURE'].includes(el))

  return (
    <div className={classes.root} >
      {
        !isApp && !isV3Lineage() &&
        <div style={{display:'flex',justifyContent:'space-between',paddingBottom:8,position:'sticky',width:view==='map'?'max-content':'100%',top:156,zIndex:30,background:theme.palette.background.main}}>
          <div style={{display:'flex',alignItems:'center'}}>
            <Select
              className={classes.selector}
              value={view}
              disableUnderline
              MenuProps={{
                classes:{
                  paper:classes.selectPaper
                }
              }}
              onChange={event=>onChangeView(event.target.value)}
            >
              <MenuItem  className={classes.menuItem} value='map'>MAP VIEW</MenuItem>
              <MenuItem  className={classes.menuItem} value='list'>LIST VIEW</MenuItem>
            </Select>

            {
              view==='list' &&
              <Typography className={classes.headerText}>OF</Typography>
            }

            {
              view==='list' &&
              <Select
                style={{marginLeft:16}}
                className={classes.selector}
                value={listDirection}
                disableUnderline
                MenuProps={{
                  classes:{
                    paper:classes.selectPaper
                  }
                }}
                onChange={event=>onChangeListDirection(event.target.value)}
              >
                <MenuItem  className={classes.menuItem} value='upstream'>UPSTREAM SOURCES</MenuItem>
                <MenuItem  className={classes.menuItem} value='downstream'>DOWNSTREAM SOURCES</MenuItem>
              </Select>
            }
          </div>
          {
            ['list'].includes(view) &&
            <div style={{display:'flex',alignItems:'center'}}>
              <InputBase
                value={listSearch}
                onChange={event => onListSearchChange(event.target.value)}
                variant={'filled'}
                placeholder={'Search'}
                className={classes.nameFilter}
                endAdornment={
                  <IconButton
                    disabled={listSearch===''}
                    onClick={()=>onListSearchChange('')}
                    style={{width:32,height:32,marginRight:6}}
                  >
                    {getIconComponent({label:listSearch===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                  </IconButton>
                }
              />
              <Select
                style={{width:200,marginLeft:16}}
                multiple={true}
                className={classes.listSelector}
                value={selectedObjects}
                disableUnderline
                renderValue={value=>{
                  let validValue = value.filter(el=>!['QUERY'].includes(el));
                  if(validValue.length===dedupeList([...upstreamObjects,...downstreamObjects]).length)return 'All item(s)'
                  return validValue.join(', ')
                }}
                MenuProps={{
                  classes:{
                    paper:classes.selectPaper
                  }
                }}
                onChange={event=>onChangeSelectedObjects(event.target.value)}
              >
                <Typography className={classes.dropdownTitle}>FILTER</Typography>
                {
                  dedupeList([...upstreamObjects,...downstreamObjects]).map(el=>(
                    <MenuItem   className={classes.menuItem} value={el}>
                      <Checkbox color='primary' className={classes.checkbox} checked={selectedObjects.includes(el)}/>
                      {toTitleCase(mapObjectName(el,true).replace(/_/g,' '))}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
          }
        </div>
      }
      {
        ['map'].includes(view) &&
        (
          isV3Lineage()?
          <LineageV3
            history={history}
            root={target || state.basicData}
            state={state}
            dispatch={dispatch}
            isLinkable={isLinkable}
          />
          :
          <LineageChart
            history={history}
            root={target || state.basicData}
            rootLabel={label}
            state={state}
            dispatch={dispatch}
            isLinkable={isLinkable}
          />
        )
      }
      {
        ['list'].includes(view) &&
        <ListLineage
          history={history}
          state={state}
          dispatch={dispatch}
          direction={listDirection}
          upstreamObjects={upstreamObjects.filter(el=>finalSelectedObjects.includes(el))}
          downstreamObjects={downstreamObjects.filter(el=>finalSelectedObjects.includes(el))}
          upstreamRelations={upstreamRelations}
          downstreamRelations={downstreamRelations}
        />
      }
    </div>
  )
}

LineageContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(LineageContainer));
