import React, {  useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles, CircularProgress } from '@material-ui/core';
import { useStore } from 'react-redux'
import { generateMainData } from './utils';
import AccessChip from './Tiles/AccessChip';
import TileSection from './TileSection';
import { checkProfileEditable } from '../../../permissionChecker';
import { collectionIds } from '../../../utilities';
import ExpiryChip from './Tiles/ExpiryChip';
import StatusSelector from './ActionButtons/StatusSelector';
import IssueActionBtn from './ActionButtons/IssueActionBtn';

const styles = theme => ({
  root: {
  },
  block: {
    marginBottom: '2rem'
  },
  editButtonContainer: {
    cursor: 'pointer',
    marginBottom: '1rem',
    height:36,
    width: '100%',
    background:theme.palette.background.main,
    textAlign: 'right',
    zIndex:999,
  },
  editButton:{
    marginRight:40,
    padding:0,
    minWidth:0
  },
  '@media (max-width: 1350px)': {
    editButton: {
      marginRight: 0
    },
  },
  modal:{
    width:'100vw',
    height:'100vh',
  }
});

const ProfileSideBar = props => {
  const {
    classes,
    history,
    tabOptions,
    state,
    mapping,
    dispatch,
    data,
    topOffset=0,
    ignoreGrouping,
    alwaysOpenNewTab,
    defaultExpanded
  } = props;

  // const [editModalData, setEditModalData] = useState({});
  const [loading, setLoading] = useState(false);
  const tiles = useRef({});
  const store = useStore();
  let sessionData = store.getState().auth.session_user;

  // status
  const [status, setStatus] = useState();
  const [validStatus, setValidStatus] = useState();

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const onAddTileData = databox => {
    let isUpdate = Boolean(tiles.current[databox.id]);
    tiles.current[databox.id] = databox;
    isUpdate && forceUpdate();
  }


  window.onmessage = event => {
    if(event.data.sidebar_action){
      window.scrollTo(0,0)
      if(event.data.sidebar_action==='open_lineage'){
        if(!isNaN(event.data.sub_tab_index)){
          dispatch({type:'set_lineage_data',lineageData:{...state.lineageData,forceLandingState:event.data.sub_tab_index,tabState:event.data.sub_tab_index}})
        }
        dispatch({type:'set_tab_state',tabState:tabOptions.indexOf('LINEAGE')})
      }
      if(event.data.sidebar_action==='open_usage'){
        dispatch({type:'set_usage_period',usagePeriod:event.data.sub_tab_index})
        dispatch({type:'set_tab_state',tabState:tabOptions.indexOf('USAGE')})
      }
      if(event.data.sidebar_action==='open_joins'){
        dispatch({type:'set_tab_state',tabState:tabOptions.indexOf('JOINS')})
      }
      if(event.data.sidebar_action==='open_knowledge'){
        if(event.data.sub_tab_id){
          dispatch({type:'set_notes',notes:{type:event.data.sub_tab_id}})
        }
        dispatch({type:'set_tab_state',tabState:tabOptions.indexOf('KNOWLEDGE')})
      }
      if(event.data.sidebar_action==='reload_sidebar'){
        generateDatabox()
      }
    }
  }

  const generateDatabox = () => {
    // tiles.current = {};
    // if(state.insightsData)state.insightsData = state.insightsData.filter(i=>['tags','alias'].includes(i.id));
    // setTimeout(()=>{
      generateMainData({setLoading,loading,dispatch,data,history,mapping,sessionData,onAddTileData,alwaysOpenNewTab});
    // })
  }

  useEffect(()=>{
    if(!data || !mapping || mapping.length===0)return;
    if(state.insightsData && state.insightsData.length>0){
      state.insightsData.forEach(i=>{
        tiles.current[i.id] = i;
      })
      return;
    }else{
      generateDatabox()
    }

    return ()=>{
      let savedTiles = {...tiles.current}
      dispatch({type:'set_insights_data',insightsData:Object.keys(savedTiles).map(k=>savedTiles[k]) })
      tiles.current = {};
    }
   // eslint-disable-next-line
  },[])


  if(loading)return <div style={{display:'flex',justifyContent:'center',marginTop:'15vh'}}><CircularProgress color='secondary'/></div>

  if (!data) return <div></div>;

  let databoxes = Object.keys(tiles.current).map(k=>tiles.current[k])

  // if ( databoxes.length===0) return <div></div>;

  let customChip;

  if(data && data.object && data.source_data){
    let infoBox = databoxes.find(el=>el.id==='hasAccess');
    if(infoBox){
      customChip = (
        <AccessChip infoBox={infoBox} data={data}/>
      )
    }
  }
  if(data?.parent?.id === collectionIds.warningNotice) {
    customChip = (
      <ExpiryChip infoBox={{id:'warningNotice'}} data={data}/>
    )
  }

  let isEditable = checkProfileEditable({sessionData, isStewardOrOwner: state.isStewardOrOwner})

  return (
    <div className={classes.root} style={{marginTop:topOffset}}>
      <div
        className={classes.body}
        id='profile-sidebar-body'
      >
        <div className={classes.block}>
          <div
            className={"flex items-center gap-4"}
          >
            <StatusSelector
              history={history}
              object={data}
              isEditable={isEditable}
              status={status}
              setStatus={setStatus}
              validStatus={validStatus}
              setValidStatus={setValidStatus}
            />
            {
              state.basicData?.object?.name==='ISSUE' &&
              <IssueActionBtn
                object={data}
              />
            }
          </div>
          {customChip}
          <TileSection
            tiles={databoxes}
            history={history}
            mapping={mapping}
            object={data}
            isEditable={isEditable}
            onAddTileData={onAddTileData}
            ignoreGrouping={ignoreGrouping}
            alwaysOpenNewTab={alwaysOpenNewTab}
            defaultExpanded={defaultExpanded}
          />
        </div>
      </div>

    </div>
  )
}

ProfileSideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.object.isRequired,
  mapping: PropTypes.array,
  fetchList: PropTypes.func.isRequired,
  insightsFetchList: PropTypes.func.isRequired,
  sessionData: PropTypes.object.isRequired,
  onTagUpdate: PropTypes.func,
  ignoreGrouping: PropTypes.bool,
  alwaysOpenNewTab: PropTypes.bool,
  defaultExpanded: PropTypes.bool
}

export default withStyles(styles)(ProfileSideBar);
