import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import axiosCerebrum from '../axios-cerebrum';

function useGetIssueIntegrations({ preventAuto = false }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const source = useRef(axios.CancelToken.source());

  const fetchIssueIntegrations = useCallback(async () => {
    source.current.cancel();
    source.current = axios.CancelToken.source();

    setLoading(true);
    setError(false);
    try {
      const response = await axiosCerebrum.get(
        `/api/sources`,
        {
          params: {
            capabilities:'ISSUE_MANAGEMENT',
            internal_flag:false,
            types:'EXTERNAL_INTEGRATION',
            sort:'ALPHABETICAL',
            per_page:10
          },
          cancelToken: source.current.token
        }
      );
      setData(response.data.items);
    } catch (error) {
      setLoading(false);
      if (error.message) setError(error);
      console.log(error);
    }
    setLoading(false);
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (preventAuto) return;
    fetchIssueIntegrations();
  // eslint-disable-next-line
  }, [preventAuto, fetchIssueIntegrations, source]);

  return {
    data,
    loading,
    error,
    fetchIssueIntegrations
  }
}

export default useGetIssueIntegrations;
