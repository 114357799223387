import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles, Button } from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import GlossaryAdder from '../../UI/GlossaryAdder/GlossaryAdder';
import AdditionalPropertyAdder from '../../UI/AdditionalPropertyAdder/AdditionalPropertyAdder';
import SourceDescription from '../../UI/SourceDescription/SourceDescription';
import CodeDisplay from '../../UI/CodeDisplay/CodeDisplay';
import theme from '../../../theme';
import sqlFormatter from "sql-formatter";
import useAlert from '../../../hooks/useAlert';
import { copyToClipboard } from '../../../utilities';

const styles = theme => ({

})

const Details = props => {

  const {
    history,
    state,
    fetchList,
    dispatch,
  } = props;


  const {
    sendAlert
  } = useAlert({})

  const handleCodeClick = () => {
    copyToClipboard(sqlFormatter.format(state.basicData.codeData.code,{language:'pl/sql'}))
    sendAlert({message:"Copied code to clipboard",type:'info'})
  }

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/datasettables/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        label="dataset_table"
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        data={state.basicData}
        fetchList={fetchList}
        collection="data"
        enableMarkDown={true}
        enableWidget={true}
        state={state}
      />

      <SourceDescription
        state={state}
        dispatch={dispatch}
      />

      <div style={{ marginTop: 60 }} >
        <GlossaryAdder
          state={state}
          dispatch={dispatch}
          history={history}
          disableModal
        />
      </div>

      <div style={{ marginTop: 24 }} >
        <AdditionalPropertyAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.additionalPropertiesModalOpen}
          setModalOpen={value=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
        />
      </div>

      {
        state.basicData.codeData?.code &&
        <div style={{marginTop:60}}>
          <div style={{display:'flex',alignItems:'flex-start',marginBottom: 24}}>
            <Typography style={{ fontSize: 20,color:theme.palette.header.main}}>CODE</Typography>
            <Button style={{marginLeft:16,marginTop:-2}} color='primary' onClick={handleCodeClick}>COPY</Button>
          </div>
          <CodeDisplay
            code={state.basicData.codeData.code}
          />
        </div>
      }
    </div>

  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  userName: PropTypes.string,
  tableData: PropTypes.object,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withStyles(styles)(Details);
