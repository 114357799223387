import {  IconButton, Typography, withStyles, withTheme, Button, Popper } from '@material-ui/core';
import React, { memo, useEffect, useState } from 'react';
import theme from '../../../theme';
import { getIconComponent } from '../../../utilities';
import { getIconLabel } from '../SearchResults/utils';
import { Handle } from 'react-flow-renderer';
import KTooltip from '../KTooltip/KTooltip';
// import ChildListPopup from './ChildListPopup';
import CustomMenu from '../ContextMenu/ContextMenu'
import { graphPalette } from '../Lineage/utils';


const styles = theme => ({
  nodeHoverable:{
    '&:hover':{
      filter: `saturate(400%)`
    },
  },
  nodeSelected:{
    filter: `saturate(400%)`
  },
  nodeContainer:{
    borderRadius:2,
    // height:42,
    pointerEvents:'auto',
    cursor:'pointer',
    flexGrow:1
  },
  actionButton:{
    border:`1px solid ${theme.palette.background.main}`,
    background:theme.palette.primaryText.light,
    padding:2,
    width:24,
    height:24,
    position:'absolute',
    zIndex:999,
    '&:hover':{
      width:28,
      height:28,
      background:theme.palette.primaryText.light,
    }
  },
  leftActionButton:{
    left:-35,
    alignSelf:'center',
    '&:hover':{
      left:-37,
    }
  },
  rightActionButton:{
    right:-35,
    alignSelf:'center',
    '&:hover':{
      right:-37,
    }
  },
  bottomActionButton:{
    marginTop:-12,
    background:theme.palette.background.main,
    '&:hover':{
      marginTop:-14,
      background:theme.palette.background.main,
    }
  },
  loadingIcon:{
    border:`1px solid ${theme.palette.background.main}`,
    width:24,
    height:24,
    alignItems:'center',
    justifyContent:"center",
    display:'inline-flex',
    position:'absolute',
    borderRadius:'50%',
    background:theme.palette.primaryText.light,
    animationName: 'loading-spin',
    animationDuration: '800ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    cursor:'default'
  },
  [`@keyframes loading-spin`]: {
    from: {
      transform:'rotate(0deg)',
    },
    to: {
      transform:'rotate(360deg)',
    },
  },
  leftLoadingIcon:{
    left:-35,
    alignSelf:'center',
  },
  rightLoadingIcon:{
    right:-35,
    alignSelf:'center',
  },
})


const LineageNode = memo((prop) => {

  // const [childListExpand, setChildListExpand] = useState(false)
  const [contextMenuOpen, setContextMenuOpen] = useState(false)

  const { id, data, selected, classes, viewOnly } = prop;
  const [anchor, setAnchor] = useState()

  useEffect(()=>{
    if(!selected){
      // setChildListExpand(false)
      setContextMenuOpen(false)
    }
  },[selected])

  useEffect(()=>{
    if(contextMenuOpen){
      let doc = document.getElementById(id);
      // get the position of the element
      let positionInfo = doc.getBoundingClientRect();
      // create an anchor el under document.body
      let el = document.createElement('div');
      el.id = `${id}-map-anchor`
      el.style.position = 'absolute';
      el.style.top = positionInfo.top + window.scrollY - 160 + 'px';
      el.style.left = positionInfo.left + window.scrollX + 180 + 'px';
      document.body.appendChild(el);
      setAnchor(el)
    }else{
      let el = document.getElementById(`${id}-map-anchor`)
      el && el.remove()
      setAnchor()
    }
  // eslint-disable-next-line
  },[contextMenuOpen])

  let isConnectedToCentre = data.isConnectedToCentre;
  let typeColour = graphPalette[data.obj.object_type_txt || data.obj.type || data.obj.object?.name]

  let defaultBorderWidth = 2;
  let borderWidth = data.borderWidth || defaultBorderWidth
  let borderWidthOffset = Math.max(0, borderWidth - defaultBorderWidth)
  let width = data.width - borderWidthOffset*2
  let height = data.height - borderWidthOffset

  let background = data.backgroundColour || theme.palette.background.main

  return (
    <div
      id={id}
      style={{
        width,
        height,
        border:`${borderWidth}px solid ${data.overwriteBorderColour || data.borderColour || typeColour || theme.palette.border.main}${data.faded?'50':''}`,
        borderRadius:3,
      }}
    >
      <div style={{display:'flex',alignItems:'center', width:width,opacity:data.faded?0.5:1}}>
        {
          !viewOnly &&
          <Handle
            type="target"
            position="left"
            style={{
              background: '#555',
              visibility:'hidden',
              position:'relative',
              top:4,
              width:0,
              ...(isConnectedToCentre?{left:width/2}:{left:data.leftHandleOffset || (data.leftAction?-40:-9)})
            }}
          />
        }
        {
          data.leftAction && !viewOnly &&
          (
            data.leftActionIcon==='loading'?
            <div className={classes.loadingIcon + ' ' + classes.leftLoadingIcon}>
              {getIconComponent({label:'refresh', size:20, colour:theme.palette.background.main})}
            </div>
            :
            <KTooltip title={data.leftActionTooltip || ''}>
              <IconButton
                className={classes.actionButton + ' ' + classes.leftActionButton + ' node-action'}
                onClick={event=>{
                  // event.stopPropagation();
                  data.leftAction()
                }}
              >
                {getIconComponent({label:data.leftActionIcon || 'add', size:16, colour:theme.palette.background.main})}
              </IconButton>
            </KTooltip>
          )
        }
        <div
          style={{
            display:'flex',
            alignItems:'flex-start',
            overflow:'hidden',
            boxSizing:'border-box',
            flexShrink:0,
            width:width,
            marginLeft:viewOnly?0:-(borderWidth-borderWidthOffset), // shifts because of handle border
            background,
            borderTop:data.borderTopColour?`1px solid ${data.borderTopColour || 'transparent'}`:undefined,
            marginTop:data.borderTopColour?-1:0,
            padding:`${9-borderWidthOffset}px ${16-borderWidthOffset}px 9px ${16-borderWidthOffset+(data.indentSize||0)}px`
          }}
          className={
            classes.nodeContainer + (data.ignoreHoverEffect?'':(selected?' '+classes.nodeSelected:' '+classes.nodeHoverable))
          }
          onContextMenu={(event)=>{
            event.preventDefault()
            data.onContextMenu && data.onContextMenu()
            setContextMenuOpen(true)
          }}
          onClick={(event)=>{
            data.onClick && data.onClick()
          }}
        >
          {
            data.obj &&
            <div
              style={{width:24,height:24,marginRight:8,marginTop:0}}
            >
              {
                getIconComponent({
                  label:getIconLabel({label:data.obj.object_type_txt || data.obj.type || data.obj.object?.name, item:data.obj}),
                  size:24,
                  colour: selected?(data.titleColour||theme.palette.primaryText.main):(data.iconColour || typeColour || data.titleColour || theme.palette.primaryText.main)
                })
              }
            </div>
          }
          <div
            style={{flexGrow:1,overflow:'hidden',alignItems:'flex-start',justifyContent:'space-between',display:'flex'}}
          >
            <KTooltip title={data.label}>
              <Typography
                style={{
                  fontSize:13.75,
                  flexGrow:1,
                  marginTop:1,
                  color: data.titleColour || theme.palette.primaryText.main,
                  ...(data.wrapTitle?{whiteSpace:'normal',wordBreak:'break-word'}:{})
                }}
                className={'overflow-text ' + (!data.tags||data.tags.length===0?'no-tag-title':'single-tag-title')}
              >
                {data.label}
              </Typography>
            </KTooltip>
            <div style={{marginLeft:0,width:24,marginRight:2,display:"flex",justifyContent:'center',height:16,marginTop:4}}>
              {
                data.tags?.map(t=>(
                  <KTooltip title={t.tooltip}>
                    <div style={{height:16,width:16}}>{t.icon}</div>
                  </KTooltip>
                ))
              }
            </div>
          </div>
        </div>
        {
          data.rightAction && !viewOnly &&
          (
            data.rightActionIcon==='loading'?
            <div className={classes.loadingIcon + ' ' + classes.rightLoadingIcon} style={{marginRight:(data.borderWidth||2)-2}}>
              {getIconComponent({label:'refresh', size:20, colour:theme.palette.background.main})}
            </div>
            :
            <KTooltip title={data.rightActionTooltip || ''}>
              <IconButton
                className={classes.actionButton + ' ' + classes.rightActionButton + ' node-action'}
                style={{marginRight:(data.borderWidth||2)-2}}
                onClick={event=>{
                  // event.stopPropagation();
                  data.rightAction()
                }}
              >
                {getIconComponent({label:data.rightActionIcon || 'add', size:16, colour:theme.palette.background.main})}
              </IconButton>
            </KTooltip>
          )
        }
        {
          !viewOnly &&
          <Handle
            type="source"
            position="right"
            style={{
              background: '#555',
              visibility:'hidden',
              position:'relative',
              top:4,
              width:0,
              ...(isConnectedToCentre?{right:width/2}:{right:data.rightHandleOffset||(data.rightAction?-16:-4)})
            }}
            // isConnectable={isConnectable}
          />
        }
      </div>

      {
        data?.bodyActions?.map((a,index)=>(
          <Button
            key={a.text}
            onClick={e=>{e.stopPropagation();a.action()}}
            style={{
              width:'100%',
              height:46,
              boxSizing:'border-box',
              background: selected && !data.forceStyle?theme.palette.chartNodeSelected.main:(data.backgroundColour || theme.palette.background.main),
              borderTop:index===0?`1px solid ${data.borderTopColour || data.borderColour || 'transparent'}`:undefined,
              borderRadius:0,
              opacity:data.faded?0.5:1
            }}
            color='primary'
          >
            {a.text}
          </Button>
        ))
      }


      {
        data.customContextMenuItems && !viewOnly &&
        <Popper anchorEl={anchor} open={Boolean(anchor)} placement="right-start">
          <div
            style={{
              transform: 'scale(0.75)',
            }}
          >
            <CustomMenu
              item={data.obj}
              actions={[
                'open_new_tab',
                'bookmark',
                'glossary'
              ]}
              customActions={data.customContextMenuItems}
            />
          </div>
        </Popper>
      }
    </div>
  );
});

export default withTheme()(withStyles(styles)(LineageNode));
