import React, {useEffect} from 'react';
import { loadGraphData} from './dataLoader'
import { generateChartGroup, generateRecommendationList } from './componentGenerator'
import { KadaFilter, KadaLoadingBar, kLoadingBarVariants } from 'kada-component-library';
import useGetWorkflowStatus from '../../../hooks/useGetWorkflowStatus';
import useGetPriorities from '../../../hooks/useGetPriorities';
import axiosSolr from '../../../axios-solr';

const Summary = props => {

  const {
    state,
    dispatch,
  } = props;

  const [domainData, setDomainData] = React.useState([]);

  const loadDomainData = () => {
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q: '*:*',
          fq:'object_type_srt:ISSUE',
          rows: 0,
          'json.facet': {
            domain: {
              type: 'terms',
              field: 'top_domains_msrt',
              limit: 500,
              sort: 'index asc',
            }
          }
        }}
      )
      .then(res=>{
        setDomainData(res.data.facets?.domain?.buckets?.map(el=>el.val) || [])
      })
      .catch(err=>{
        console.log(err)
      })

  }

  useEffect(()=>{
    loadDomainData()
    if(state.issueData || state.issueData?.loading)return;
    loadGraphData({ state, dispatch, status: state.status, priority: state.priority, domain: state.domain })
    // eslint-disable-next-line
  },[])

  const {
    data: statusData
  } = useGetWorkflowStatus({ objectType: 'ISSUE' });

  const {
    data: priorityData
  } = useGetPriorities({ objectType: 'ISSUE' });



  return (
    <div>
      <h2>INSIGHTS</h2>
      <p className="text-sm text-(--color-light-text) mb-2">Select a metric to drill through to the details in Search</p>
      <div
        className='flex gap-4 mb-4'
      >
        <KadaFilter
          value={state.domain}
          isHidePlaceholderMenuItem
          isNativeSearch
          options={[
            {text:'All',value:'all'},
            ...domainData?.map(e=>{
              return {text:e,value:e}
            })
          ]}
          valueDisplayPrefix='Domain: '
          onChange={event=>{
            dispatch({type:'set_domain',domain: event.detail.value})
            loadGraphData({ state, dispatch, status: state.status, priority: state.priority, domain: event.detail.value })
          }}
        />
        <KadaFilter
          value={state.status}
          isHidePlaceholderMenuItem
          isHideSearch
          options={[
            {text:'All',value:'all'},
            ...statusData?.map(e=>{
              return {text:e.name,value:e.name}
            })
          ]}
          valueDisplayPrefix='Status: '
          onChange={event=>{
            dispatch({type:'set_status',status: event.detail.value})
            loadGraphData({ state, dispatch, status: event.detail.value, priority: state.priority, domain: state.domain })
          }}
        />
        <KadaFilter
          value={state.priority}
          isHidePlaceholderMenuItem
          isHideSearch
          options={[
            {text:'All',value:'all'},
            ...priorityData?.map(e=>{
              return {text:e.name,value:e.name}
            })
          ]}
          valueDisplayPrefix='Priority: '
          onChange={event=>{
            dispatch({type:'set_priority',priority: event.detail.value})
            loadGraphData({ state, dispatch, status: state.status, priority: event.detail.value, domain: state.domain })
          }}
        />
      </div>
      {
        state.issueData?.loading &&
        <div className='mt-16'>
          <KadaLoadingBar
            variant={kLoadingBarVariants.secondary}
            text="Loading dashboard data"
          />
        </div>
      }
      {
        state.issueData?.error &&
        <div className='mt-16'>
          <KadaLoadingBar
            variant={kLoadingBarVariants.error}
            isStatic
            text="Error occurred loading dashboard data"
          />
        </div>
      }
      {
        state.issueData?.data &&
        generateChartGroup({data: state.issueData.data})
      }
      {
        state.issueData?.recommendations &&
        <div id="recommendation-section">
          <h2>RECOMMENDATIONS</h2>
          <p className="text-sm text-(--color-light-text) mb-2">Select a recommendation to see more details</p>
          {generateRecommendationList({data: state.issueData.recommendations})}
        </div>
      }
    </div>
  )
}

export default Summary;
