import axiosCerebrum from "../../../../axios-cerebrum"
import axiosSolr from "../../../../axios-solr";
import { palette } from "../../../../theme";
import { getDispFields, getLabelPlural, mapObjectName, mapSearchObjectName, onClickResultItem, sendMessage, titleCaseObjectName } from "../../../../utilities"
import { childrenNumPerGroup, insertLinks, onCollapseSameLevelNodes } from "./layoutUtils";
import { groupHeaderHeight, insertGroups, insertNodesIntoGroup, nodeHeight, nodeTypes, nodeWidth } from "./layoutUtils";

export const getNodeSearchObjectType = node => {
  if(!node.data?.obj)return '';
  if(node.data.obj.object_type_txt)return mapSearchObjectName(node.data.obj.object_type_txt, node.data.obj.code_type_txt)
}

export const constructGroupId = ({nodeObj, target, direction, level}) => {
  return  (target?.data?.obj?.id || 'root') + '-to-' + nodeObj.id + '-level-' + level + '-' + (direction||'root')
}

export const constructChildId = ({nodeObj, group}) => {
  return nodeObj.id + '-under-' + group.id
}

export const getLineageField = ({isShowReference, includeInactive}) => {
  if(includeInactive && isShowReference){
    return 'lineage_inc_reference_txt';
  }
  if(includeInactive && !isShowReference){
    return 'lineage_txt';
  }
  if(!includeInactive && isShowReference){
    return 'active_lineage_inc_reference_txt';
  }
  if(!includeInactive && !isShowReference){
    return 'active_lineage_txt';
  }
}

export const checkHasLineage = ({obj, direction, mapControls}) => {
  if(!obj)return false;

  let includeInactive = mapControls.isShowActiveOnly?false:true;
  let includeReference = mapControls.isShowReference?true:false;
  let lineageTxt = obj[getLineageField({isShowReference:includeReference, includeInactive})];

  if(!lineageTxt)return false;
  return lineageTxt.includes(direction);
}

export const checkIsEndQuery = ({obj,direction, mapControls}) => {
  if(!obj)return false;
  if(direction==='DOWNSTREAM')return false;
  let includeInactive = mapControls.isShowActiveOnly?false:true;
  let includeReference = mapControls.isShowReference?true:false;
  let lineageTxt = obj[getLineageField({isShowReference:includeReference, includeInactive})];
  if(!lineageTxt)return false;
  return lineageTxt.includes('UPSTREAM_QUERY');
}

export const constructGroupNodes = ({groups, targetNode, direction, detailMap, isLoading}) => {
  return groups.map(g=>{
    let detail = detailMap[g.id]||g;
    let level = targetNode?targetNode.level + (direction==='DOWNSTREAM'?1:-1):0;
    let newGroup = {
      id: constructGroupId({nodeObj:g, target:targetNode, direction, level}),
      type: nodeTypes.groupNodeType,
      prevLevelRef: targetNode,
      level,
      data:{
        label: getDispFields(detail, 'dispTitle') || 'Unknown',
        subTitle: getDispFields(detail, 'dispSubtitle') || 'Unknown',
        subTitleStyled: getDispFields(detail, 'dispSubtitle', true) || 'Unknown',
        icon: detail.object_type_txt || detail.object_type || 'Unknown',
        iconColour: g.reference?palette.primaryText.light:undefined,
        rightIcon:detail.source_type_txt,
        width:nodeWidth,
        height:groupHeaderHeight,
        childLoading:isLoading,
        obj:{
          ...g,
          ...(detailMap[g.id]||{})
        },
        onContextMenu:g.id==='unknown'?undefined:()=>{
          sendMessage({
            lineage_group_context_menu_id:constructGroupId({nodeObj:g, target:targetNode, direction, level}),
          })
        },
        onClick:g.id==='unknown'?undefined:()=>{
          sendMessage({
            lineage_clicked_parent_id:constructGroupId({nodeObj:g, target:targetNode, direction, level}),
            direction
          })
        }
      }
    }

    if(level && g.object_count===0){

      let actionFieldName = level<0?'leftAction':'rightAction';
      let actionIconFieldName = level<0?'leftActionIcon':'rightActionIcon';
      let actionIconName = level<0?'expand_left':'expand_right';
      let tooltipFieldName = level<0?'leftActionTooltip':'rightActionTooltip';

      newGroup.data[actionFieldName] = () => {
        window.open(onClickResultItem({item:newGroup.data.obj, id:g.id, label:g.object_type, urlOnly:true}),'_blank')
      }
      newGroup.data[actionIconFieldName] = actionIconName;
      newGroup.data[tooltipFieldName] = `Visit ${titleCaseObjectName(mapObjectName(newGroup.data.obj.object_type))} profile page for detailed lineage`;
    }

    return newGroup;
  })
}

export const constructChildNodes = ({childNodes, isRoot, groupNode, detailMap, direction, mapControls}) => {
  return childNodes.map(c=>{
    let node = {
      id: constructChildId({nodeObj:c, group: groupNode}),
      type: nodeTypes.childNodeType,
      parentNode: groupNode.id,
      parentNodeRef: groupNode,
      nextLevelRefs:[],
      level:groupNode.level,
      extent: 'parent',
      data:{
        label: getDispFields(detailMap[c.id]||c, 'dispTitle'),
        width:nodeWidth,
        height:nodeHeight,
        onClick:()=>{
          sendMessage({
            lineage_clicked_node_id:constructChildId({nodeObj:c, group: groupNode}),
            parent_node_id:groupNode.id,
          })
        },
        onContextMenu:()=>{
          sendMessage({
            lineage_context_menu_id:constructChildId({nodeObj:c, group: groupNode}),
            parent_node_id:groupNode.id,
          })
        },
        obj:{
          ...c,
          ...(detailMap[c.id]||{})
        }
      }
    }

    if(isRoot){
      if(
        checkIsEndQuery({obj:detailMap[c.id]||c, direction:'UPSTREAM', mapControls})
      ){
        let action = () => {
          sendMessage({
            lineage_detail_node_id:node.id,
            parent_node_id:groupNode.id,
            is_code_only:true
          })
        }
        node.data.leftAction = action;
        node.data.leftActionIcon = 'code';
        node.data.leftActionTooltip = 'See upstream query details';
      }
    }

    if(direction && checkHasLineage({obj:detailMap[c.id]||c, direction, mapControls})){
      let actionOnclickName = direction==='UPSTREAM' ? 'leftAction' : 'rightAction';
      let actionIconName = direction==='UPSTREAM' ? 'leftActionIcon' : 'rightActionIcon';
      let actionIconTooltipName = direction==='UPSTREAM' ? 'leftActionTooltip' : 'rightActionTooltip';
      if(checkIsEndQuery({obj:detailMap[c.id]||c, direction, mapControls})){
        let action = () => {
          sendMessage({
            lineage_detail_node_id:node.id,
            parent_node_id:groupNode.id,
            is_code_only:true
          })
        }
        node.data[actionOnclickName] = action;
        node.data[actionIconName] = 'code';
        node.data[actionIconTooltipName] = 'See upstream query details';
      }else{
        let action = () => {
          sendMessage({
            lineage_expand_node_id:node.id,
            parent_node_id:groupNode.id,
            direction
          })
        }
        node.data[actionOnclickName] = action;
        node.data[actionIconTooltipName] = `Expand lineage`;
      }
    }
    if(direction){
      let actionOnclickName = direction==='UPSTREAM' ? 'rightAction' : 'leftAction';
      let actionIconName = direction==='UPSTREAM' ? 'rightActionIcon' : 'leftActionIcon';
      let actionIconTooltipName = direction==='UPSTREAM' ? 'rightActionTooltip' : 'leftActionTooltip';
      let actionIsHoverName = direction==='UPSTREAM' ? 'isRightActionHover' : 'isLeftActionHover';
      let action = (event) => {
        event.stopPropagation();
        sendMessage({
          lineage_detail_node_id:node.id,
          parent_node_id:groupNode.id
        })
      }
      let edgeTypes = node.data.obj.edge_types || [];
      let icon = edgeTypes.find(e=>e.includes('SOURCE'))?'lineage_manual_edge':'lineage_auto_edge';
      node.data[actionIsHoverName] = true;
      node.data[actionOnclickName] = action;
      // node.data[actionIconName] = icon 'info_no_border';
      node.data[actionIconName] = icon;
      node.data[actionIconTooltipName] = 'See lineage details';
    }
    return node;
  })
}

export const loadSolrDetails = async ids => {
  let detailMap = {};
  await axiosSolr
    .get(
      `/solr/search/select`,{
        params:{
          q:"*",
          fq:`id:(${ids.join(' OR ')})`,
          rows:ids.length
        }
      }
    )
    .then(response=>{
      response.data.response.docs.forEach(e=>{
        detailMap[e.id] = e;
      })
    })
    .catch(error=>{
      console.log(error)
    })

  return detailMap;
}

export const loadChildrenByGroups = async({groups, targetNode, direction, currentChildrenMap, mapControls}) => {
  let childrenMap = {};
  let chunkSize = 10;

  for(let i=0; i<groups.length; i+=chunkSize){
    let promises = []
    for(let j=i; j<i+chunkSize; j++){
      let g = groups[j];
      if(!g)break;
      promises.push(
        new Promise((resolve, reject)=>{
          if(g.data.obj.object_count===0){
            g.data.childLoading = false;
            resolve();
            return;
          }
          axiosCerebrum
            .get(
              `/api/${getLabelPlural(getNodeSearchObjectType(targetNode))}/${targetNode.data.obj.id}/lineage`,{
                params:{
                  per_page:3,
                  direction,
                  group_object_id:g.data.obj.id,
                  include_inactive:mapControls.isShowActiveOnly?false:true,
                  include_reference:mapControls.isShowReference?true:false,
                  'sort.score':'DESC',
                  level:mapControls.lineageLevel
                }
              }
            )
            .then(async response=>{
              g.data.childLoading = false;
              if(response.data.total===0){
                resolve();
              }else{
                let detailMap = await loadSolrDetails(response.data.items.map(el=>el.id))
                let childNodes = constructChildNodes({childNodes:response.data.items, groupNode:g, detailMap, direction, mapControls})

                childrenMap[g.id] = childNodes;
                insertNodesIntoGroup({nodes:childNodes, targetGroup:g});
                if(g.data.obj.object_count>childrenNumPerGroup){
                  g.data.bodyAction = () => {
                    sendMessage({
                      lineage_clicked_parent_id:g.id,
                      direction
                    })
                  }
                  g.data.bodyActionText = 'SEE MORE'
                }
                resolve();
              }
            })
            .catch(error=>{
              console.log(error)
              reject(error)
              sendMessage({lineage_error:true})
            })
        })
      )
    }
    await Promise
      .all(promises)
      .then(()=>{
        Object.keys(childrenMap).forEach(k=>{
          currentChildrenMap[k] = childrenMap[k];
        })
        sendMessage({lineage_update_map:true})
      })
      .catch(error=>{
        console.log(error)
      })
  }

}

export const onNodeExpandToggle = ({node, direction, forceExpand}) => {
  node.expanded = typeof(forceExpand)==='boolean'?forceExpand:(node.expanded?false:true);
  let iconFieldName = direction==='UPSTREAM'?'leftActionIcon':'rightActionIcon';
  if(node.data[iconFieldName] === 'code')return;
  if(node.expanded){
    node.data[iconFieldName] = 'remove';
  }else{
    node.data[iconFieldName] = 'add';
  }
}

const isNodeLoading = ({node, direction}) => {
  let iconFieldName = direction==='UPSTREAM'?'leftActionIcon':'rightActionIcon';
  return node.data[iconFieldName]==='loading';
}

const onNodeStartLoad = ({node, direction}) => {
  let iconFieldName = direction==='UPSTREAM'?'leftActionIcon':'rightActionIcon';
  node.data[iconFieldName] = 'loading';
}

const removeNodeAction = ({node, direction}) => {
  let iconFieldName = direction==='UPSTREAM'?'leftActionIcon':'rightActionIcon';
  let actionFieldName = direction==='UPSTREAM'?'leftAction':'rightAction';
  delete node.data[iconFieldName];
  delete node.data[actionFieldName];
}

export const onClickExpandNode = ({
  targetNode,
  currentGroups,
  currentLinks,
  currentChildrenMap,
  mapControls,
  direction,
  mode='focus',
  isRoot,
  forceReload
}) => {
  if(isNodeLoading({node:targetNode, direction}) && !isRoot)return;
  if(targetNode.loaded && !isRoot && !forceReload){
    onNodeExpandToggle({node:targetNode, direction})
    if(targetNode.expanded && mode==='focus'){
      onCollapseSameLevelNodes({
        sourceNode:targetNode,
        currentGroups,
        childrenMap:currentChildrenMap,
        direction
      })
    }
    sendMessage({lineage_update_map:true})
  }else{
    if(forceReload)targetNode.nextLevelRefs = [];
    onNodeStartLoad({node:targetNode, direction})
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(getNodeSearchObjectType(targetNode))}/${targetNode.data.obj.id}/lineage`,{
          params:{
            per_page:10,
            direction,
            include_inactive:mapControls.isShowActiveOnly?false:true,
            include_reference:mapControls.isShowReference?true:false,
            'sort.score':'DESC',
            level:mapControls.lineageLevel
          }
        }
      )
      .then(async response=>{
        targetNode.loaded = true;
        if(response.data.total===0){
          sendMessage({
            lineage_empty_message:true,
            direction
          })
          removeNodeAction({node:targetNode, direction})
          sendMessage({lineage_update_map:true})
          return;
        }else{
          let detailMap = await loadSolrDetails(response.data.items.map(el=>el.id))
          let groups = constructGroupNodes({groups:response.data.items, targetNode, direction, detailMap, isLoading:true})

          insertLinks({
            newGroups:groups,
            sourceNode:targetNode,
            currentLinks:currentLinks,
            direction
          })

          insertGroups({
            newGroups:groups,
            currentGroups,
            sourceNode:targetNode,
            childrenMap:currentChildrenMap,
            direction
          })

          groups.forEach(g=>{
            currentChildrenMap[g.id] = [];
          })

          sendMessage({lineage_update_map:true})

          loadChildrenByGroups({
            groups,
            targetNode,
            direction,
            currentGroups,
            currentChildrenMap,
            mapControls
          })

          if(isRoot)removeNodeAction({node:targetNode, direction})
          onNodeExpandToggle({node:targetNode, direction, forceExpand:true})
        }
      })
      .catch(error=>{
        console.log(error)
        sendMessage({lineage_error:true})
      })
  }
}

export const onInitialiseRoot = async ({rootObj, currentGroups, currentLinks, currentChildrenMap, mapControls, upstreamOnly, downstreamOnly, onFinish}) => {
  let parent = rootObj.parent;
  if(!parent){
    if(rootObj.hierarchy_parent_id_txt){
      parent = {id:rootObj.hierarchy_parent_id_txt}
    }else{
      parent = {id:'unknown'}
    }
  }
  let detailMap = await loadSolrDetails([rootObj.id, parent.id])

  let parentNodes = constructGroupNodes({groups:[parent], detailMap})
  let childNodes = constructChildNodes({childNodes:[rootObj], isRoot:true, groupNode:parentNodes[0], detailMap, mapControls})

  parentNodes[0].position = {x:0, y:0};
  parentNodes[0].isRoot = true;
  parentNodes[0].data.height = groupHeaderHeight + nodeHeight;
  childNodes[0].isRoot = true;
  childNodes[0].data.rightHandleOffset = -4;
  childNodes[0].data.leftHandleOffset = -9;
  insertNodesIntoGroup({nodes:childNodes, targetGroup:parentNodes[0]});

  currentGroups.push(...parentNodes);
  currentChildrenMap[parentNodes[0].id] = childNodes;
  sendMessage({lineage_update_map:true});
  onFinish();

  let directions = [];
  if(
    checkHasLineage({obj:childNodes[0].data.obj, direction:'UPSTREAM', mapControls}) &&
    !checkIsEndQuery({obj:childNodes[0].data.obj,direction:'UPSTREAM',mapControls}) &&
    !downstreamOnly)
  {
    directions.push('UPSTREAM');
    childNodes[0].data.leftAction = () => {}
    childNodes[0].data.leftActionIcon = 'loading';
  }
  if(checkHasLineage({obj:childNodes[0].data.obj, direction:'DOWNSTREAM', mapControls}) && !upstreamOnly){
    directions.push('DOWNSTREAM');
    childNodes[0].data.rightAction = () => {}
    childNodes[0].data.rightActionIcon = 'loading';
  }

  if(directions.length===0){
    sendMessage({
      lineage_alert_message:`No lineage found for this item`
    })
    return;
  }

  directions.forEach(el=>{
    onClickExpandNode({
      targetNode:childNodes[0],
      currentGroups,
      currentLinks,
      currentChildrenMap,
      mapControls,
      direction:el,
      isRoot:true
    })
  })
}
