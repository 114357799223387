import { loadIssueAssignedToChart, loadIssueCreatedByChart, loadIssueCreatedChart, loadIssueDimension, loadIssueIsAssignedChart, loadIssueOpenRateChart, loadIssueSeverityChart, loadRecommendations, } from './loadUtils';

export const loadGraphData = async ({ state, dispatch, status, priority, domain }) => {
  dispatch({
    type:'set_issue_data',
    issueData:{loading:true}
  })

  try{
    let chartsData = {}
    let recommendations;

    let promises = [
      loadIssueSeverityChart({status, priority, domain}).then(d=>chartsData[`BY_PRIORITY`] = d),
      loadIssueIsAssignedChart({status, priority, domain}).then(d=>chartsData[`IS_ASSIGNED`] = d),
      loadIssueDimension({status, priority, domain}).then(d=>chartsData[`BY_DIMENSION`] = d),
      loadIssueCreatedChart({status, priority, domain}).then(d=>chartsData[`BY_CREATED`] = d),
      loadIssueOpenRateChart({status, priority, domain}).then(d=>chartsData[`OPEN_RATE`] = d),
      loadIssueAssignedToChart({status, priority, domain}).then(d=>chartsData[`TOP_ASSIGNEE`] = d),
      loadIssueCreatedByChart({status, priority, domain}).then(d=>chartsData[`TOP_REPORTER`] = d),
      loadRecommendations({status, priority, domain}).then(d=>recommendations = d)
    ].filter(el=>el)
    await Promise.all(promises)

    dispatch({
      type:'set_issue_data',
      issueData:{
        loading:false,
        data:chartsData,
        recommendations,
        error:false
      }
    })
  }catch(error){
    console.log(error);
    dispatch({
      type:'set_issue_data',
      issueData:{
        loading:false,
        data:undefined,
        recommendations:undefined,
        error:true
      }
    })
  }
}
