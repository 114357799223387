import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles,  CircularProgress, Typography,} from '@material-ui/core';
import Editor from '../Editor/Editor';
import {withRouter} from 'react-router'
import '@toast-ui/editor/dist/toastui-editor-viewer';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
import {useStore} from 'react-redux'
import { getUserRoles } from '../../../utilities';
import InteractiveInputBody from './InteractiveInputBody';
import { processDescriptionWithTerm } from '../UpdateInput/utils';
import { globalListenerRef } from '../../../GlobalListenerRef';
import InteractiveViewer from './InteractiveViewer';
import { generateDescription } from '../UpdateInput/genAIUtils';
import { GenAILoadingIcon, explainationText, hasLLMKeySet, overwriteText, triggerText } from '../UpdateInput/genAIComponents';
import useAlert from '../../../hooks/useAlert';
import { cleanUpMountedEls } from './Templates';


const styles = theme => ({
  textWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  textField: {
    ...theme.components.inputBase,
    flexGrow: 1,
    marginRight: '1rem',
    width: '100%',
    '& div':{
      padding:10
    },
  },
  bodyText: {
    flexGrow: 1,
    color: theme.palette.primaryText.main,
    width: '65%',
    minWidth: 540,
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  multilineColor:{
    color:theme.palette.primaryText.main,
  },
  customScroll:{
    ...theme.components.customScroll
  },
  viewerContainer:{
    ...theme.components.MDViewer
  },
  header:{
    fontSize:20,
    color:theme.palette.primary.main,
    marginBottom:16
  },
  genAIButton: {
    ...theme.components.genAIButton
  },
  overwriteGenAIButton:{
    ...theme.components.overwriteGenAIButton
  },
})

function InteractiveInput(props) {

  const {
    classes,
    theme,
    // history,
    id,
    shouldLoadSuggestedTerms,
    initialValue,
    title,
    subtitle,
    disableEditing,
    wordLimit=3000,
    onSave,
    triggerChar,
    generatePopper,
    placeholder,
    object,
    disableWidget,
    enableGenAI,
    isModal,
    enableCustomContextMenu,
    titleStyle
  } = props;

  const [resetFlag, setResetFlag] = useState(-1)
  const [isAIGenerated, setIsAIGenerated] = useState(false)
  const [AIGenerating, setAIGenerating] = useState(false)

  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  const roles = getUserRoles(sessionData.user_role)

  let isDisableEdit = disableEditing || AIGenerating;
  if(roles.every(r=>r==='90')){
    isDisableEdit = true;
  }

  const [text, setText] = useState(initialValue||'');
  const [displayText, setDisplayText] = useState(initialValue||'');
  const [updating, setUpdating] = useState(false);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  const loadTermSuggestion = (originalText) => {
    if(!document.getElementById('profile-glossary-adder') || !object){
      return;
    };

    processDescriptionWithTerm({
      text: initialValue,
      onFinish: ({ newText}) => {setDisplayText(newText)},
      targetData:object,
      // relatedTerms: state.terms?state.terms.items.filter(el=>el.relationship==='RELATED'):undefined
    })
  }

  useEffect(()=>{
    if(shouldLoadSuggestedTerms){
      loadTermSuggestion(initialValue)
    }
    return () => {
      cleanUpMountedEls()
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.reload_description_highlight ){
        loadTermSuggestion(text)
      }
    }
    window.removeEventListener('message',globalListenerRef.reloadTermSuggestionListener);
    globalListenerRef.reloadTermSuggestionListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.reloadTermSuggestionListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.reloadTermSuggestionListener);})
    // eslint-disable-next-line
  },[text])


  const onSaveClick = (currentTxt=text) => {
    if ( initialValue === currentTxt) {
      return;
    }
    setUpdating(true)
    onSave(currentTxt.replace(/^(<br>|\s)*/,'').replace(/(<br>|\s)*$/,''))
      .then(()=>{
        setUpdating(false)
      })
  }


  const onCloseClick = () => {
    setText(initialValue)
    setResetFlag(resetFlag*-1)
    setIsAIGenerated(false)
  }

  const maxWidth = 720;

  const genAIButton = (
    !updating && !isAIGenerated && enableGenAI && !AIGenerating && hasLLMKeySet() &&
    <div
      className={text.trim()===''?classes.genAIButton:classes.overwriteGenAIButton}
      onClick={()=>{
        generateDescription({
          data:object,
          onLoading:()=>{
            if(isModal){
              postMessage({[`open-editor-${id}`]:true})
              // setResetFlag(resetFlag*-1)
            }
            setAIGenerating(true)
          },
          onError:(msg)=>{
            setAIGenerating(false)
            sendAlert({
              message:msg || 'Error generating description, please try again',
              type:'error',
              timeout: 99999999
            })
          },
          onSuccess:(value)=>{
            setAIGenerating(false)
            setText(value)
            setResetFlag(resetFlag*-1)
            // postMessage({[`open-editor-${id}`]:true})
            setIsAIGenerated(true)
          }
        })
      }}
    >
      {text.trim()===''?triggerText:overwriteText}
    </div>
  )

  let maxHeight = 500;
  if(isModal)maxHeight = 700;
  let minHeight = 200;
  if(isModal)minHeight = 400;

  return (
    <div>
      <Editor
        id={id}
        title= {title}
        subtitle = {subtitle}
        buttonAlignment={isModal?'flex-end':undefined}
        body={
          AIGenerating && !isModal ?
          <div>
            <GenAILoadingIcon/>
          </div>
          :
            updating ?
            <CircularProgress color='secondary' style={{ margin: '1rem 3rem' }} />
            :
            <div style={{maxWidth}}>
              {
                displayText.trim()!==''?
                <InteractiveViewer
                  key={displayText}
                  object={object}
                  initialValue={displayText}
                  id={id || title}
                  enableCustomContextMenu={enableCustomContextMenu}
                />
                :
                <Typography  data-test-id={`md-input-viewer-${id}`}  style={{color:disableEditing?theme.palette.primaryText.main:theme.palette.primaryText.light,fontSize:13.75,paddingTop:4,paddingBottom:4}}>{placeholder || 'No text to display'}</Typography>
              }
            </div>
        }
        onSave={onSaveClick}
        onClose={onCloseClick}
        disableSaveButton={text?.length>wordLimit}
        editWindow={
          <>
            {
              isModal &&
              <Typography className={classes.header}>{title}</Typography>
            }
            {
              AIGenerating?
              <div style={{marginBottom:36}}>
                <GenAILoadingIcon/>
              </div>
              :
              <div style={{marginTop:12, width:isModal?undefined:maxWidth+34, maxWidth:'100%'}}>
                <InteractiveInputBody
                  initialValue={text}
                  onChange={setText}
                  generatePopper={generatePopper}
                  triggerChar={triggerChar}
                  resetFlag={resetFlag}
                  height={Math.min(maxHeight,Math.max(minHeight,(text||'').length/2.5))+'px'}
                  disableWidget={disableWidget}
                />
                {
                  enableGenAI && <div style={{marginLeft:16}}>{genAIButton}</div>
                }
                {
                  text?.length>wordLimit &&
                  <Typography style={{fontSize:13.75,marginTop:5,marginLeft:16,color:theme.palette.error.main,whiteSpace:'pre-wrap'}}>
                    Exceeds {wordLimit} character limit
                  </Typography>
                }
                {
                  isAIGenerated &&
                  <Typography style={{fontSize:12,marginTop:5,marginLeft:16,color:theme.palette.primaryText.light}}>
                    {explainationText}
                  </Typography>
                }
              </div>
            }
          </>
        }
        isModal={isModal}
        footer={enableGenAI && genAIButton}
        hideActionButton={AIGenerating}
        disableEditing={isDisableEdit}
        titleStyle={{ color: theme.palette.header.main, fontWeight: '400', fontSize: '1.25rem', ...(titleStyle||{})}}
      />

    </div>
  )
}


InteractiveInput.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  initialValue: PropTypes.string.isRequired ,
  placeholder: PropTypes.string.isRequired ,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  disableEditing: PropTypes.bool,
  wordLimit:PropTypes.number.isRequired,
  onSave: PropTypes.func,
  triggerChar: PropTypes.string.isRequired,
  shouldLoadSuggestedTerms: PropTypes.bool,
  generatePopper:PropTypes.func.isRequired,
  object: PropTypes.object.isRequired,
  enableGenAI: PropTypes.bool,
  disableWidget: PropTypes.bool,
  isModal: PropTypes.bool,
  enableCustomContextMenu: PropTypes.bool,
  titleStyle: PropTypes.object,
}

export default withTheme()(withStyles(styles)(withRouter(InteractiveInput)));
