import React from 'react';
import Summary from '../Summary/Summary';

const Body = props => {

  const {
    history,
    state,
    dispatch,
  } = props;

  let bodyContent = <Summary state={state} dispatch={dispatch} history={history}/>

  return (
    <div>
      {bodyContent}
    </div>
  )
}

export default Body;
