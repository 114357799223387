import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import axiosCerebrum from '../axios-cerebrum';

function useGetWorkflowStatus({ objectType, preventAuto = false }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const source = useRef(axios.CancelToken.source());

  const fetchWorkflowStatus = useCallback(async () => {
    if (!objectType) return;
    source.current.cancel();
    source.current = axios.CancelToken.source();

    let cachedData;
    try {
      cachedData = JSON.parse(localStorage.getItem('workflowStatus'));
    } catch (e) {
      console.log(e);
      cachedData = {};
    }

    // If data is not expired (1 hour), use it
    if (cachedData && cachedData[objectType] && cachedData[objectType].timestamp && new Date().getTime() - cachedData[objectType].timestamp < 3600000 && cachedData[objectType].items) {
      setData(cachedData[objectType].items);
      setLoading(false);
      setError(false);
      return;
    }

    setLoading(true);
    setError(false);
    try {
      const response = await axiosCerebrum.get(
        "/api/workflowstatus",
        {
          params: {
            object_type: objectType,
            "sort.position": "ASC",
            per_page: 20
          },
          cancelToken: source.current.token
        }
      );
      setData(response.data.items);
      localStorage.setItem('workflowStatus', JSON.stringify({
        ...cachedData,
        [objectType]: { items: response.data.items, timestamp: new Date().getTime() }
      }));
    } catch (error) {
      setLoading(false);
      if (error.message) setError(error);
      console.log(error);
    }
    setLoading(false);
  // eslint-disable-next-line
  }, [objectType]);

  useEffect(() => {
    if (preventAuto) return;
    fetchWorkflowStatus();
  // eslint-disable-next-line
  }, [objectType, preventAuto, fetchWorkflowStatus, source]);

  return {
    data,
    loading,
    error,
    fetchWorkflowStatus
  }
}

export default useGetWorkflowStatus;
