import React,{ useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum'
import { getLabelPlural, onClickResultItem, mapObjectName, toTitleCase } from '../../../utilities'
import axiosSolr from '../../../axios-solr'
import KTooltip from '../KTooltip/KTooltip';
import { useStore } from 'react-redux'
import { checkTermLinkable } from '../../../permissionChecker';
import { KadaBadge, KadaBtn, KadaDropdownBtn, KadaHorizExpansion, KadaIcon, KadaIconBtn, KadaInput, KadaLoadingSpinner, kBadgeVariants, kButtonVariants, kDropdownBtnVariants, kLoadingSpinnerVariants } from 'kada-component-library';
import useGetWorkflowStatus from '../../../hooks/useGetWorkflowStatus';
import GlossaryDetailPopper from './GlossaryDetailPopper';


function GlossaryAdder(props) {

  const {
    state,
    dispatch,
    history,
    title,
    forceEditable,
  } = props;

  let sessionData = useStore().getState().auth.session_user;
  let editable = typeof(forceEditable)==='boolean'?forceEditable:checkTermLinkable({sessionData})

  const [expanded, setExpanded] = useState(false)
  const [forceSearchExpand, setForceSearchExpand] = useState(false)
  const [search, setSearch] = useState('')

  const hoverTimeout = useRef(null)
  const [hoveredId, setHoveredId] = useState(null)
  const [hoverPos, setHoverPos] = useState({x:0,y:0})

  const {
    data: statusData
  } = useGetWorkflowStatus({ objectType: 'COLLECTION_INSTANCE' });

  const loadGlossaryTerms = () => {
    const load = ({pervData=[],page=1}) => {
      axiosCerebrum
        .get(
          `/api/${getLabelPlural(state.basicData.object.name)}/${state.basicData.id.toLowerCase()}/related/collections`,
          {params:{
            category:'GLOSSARY',
            relationship:'RELATED,RELATES_TO',
            per_page:100,
            page
          }}
        )
        .then(response=>{
          if(response.data.items.length>0){
            axiosSolr
              .get(
                `/solr/search/select`,{
                  params:{
                    q:"*",
                    fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
                    rows:response.data.items.length
                  }
                }
              )
              .then(solrResponse=>{
                let data = response.data.items.map(el=>({
                  ...el,
                  ...(solrResponse.data.response.docs.find(s=>s.id===el.id)||{})
                }));
                data = [...pervData, ...data]
                if(response.data.page<response.data.pages){
                  load({pervData:data,page:response.data.page+1})
                }else{
                  data = data.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
                  dispatch({
                    type:'set_terms',
                    terms:{
                      ...response.data,
                      items:data,
                      shownPage:1
                    }
                  })
                }
              })
              .catch(error=>{
                console.log(error)
                dispatch({
                  type:'set_terms',
                  termsError:true
                })
              })
          }else{
            dispatch({
              type:'set_terms',
              terms:{
                ...response.data,
                shownPage:1
              }
            })
          }
        })
        .catch(error=>{
          console.log(error)
          dispatch({
            type:'set_terms',
            termsError:true
          })
        })
    }
    dispatch({
      type:'set_terms',
      termsLoading:true
    })
    load({})
  }

  const onChangeSort = ( sort ) => {
    let newTerms = state.terms?.items.sort((a,b)=>{
      if(sort==='name asc'){
        return a.name.localeCompare(b.name)
      }else if(sort==='name desc'){
        return b.name.localeCompare(a.name)
      }
      return 0
    })

    dispatch({
      type:'set_terms',
      terms:{
        ...state.terms,
        items:newTerms
      }
    })
  }

  const getTermList = ( list ) => {
    return list.filter(el=>{
      return el.name.toLowerCase().includes(search.trim().toLowerCase()) || !search.trim()
    })
  }

  useEffect(()=>{
    if(!state.termsLoading && !state.terms && !state.termsError){
      loadGlossaryTerms()
    }
  // eslint-disable-next-line
  },[state.terms, state.termsLoading])

  let header = 'TERMS'
  let objectName = toTitleCase(mapObjectName(state.basicData.object.name).replace(/_/g,' '))
  if(state.basicData.object.name==='COLLECTION_INSTANCE' && state.basicData.parent.category==='GLOSSARY'){
    header = 'LINKED TERMS'
    objectName = 'Term'
  }
  let subTitle = `Terms linked to this ${objectName}`

  const onClickEdit = () => {
    window.postMessage({open_link_term_modal:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
  }

  const onClickShowMore = () => {
    let newPage = state.terms.shownPage+1
    dispatch({
      type:'set_terms',
      terms:{
        ...state.terms,
        shownPage:newPage
      }
    })
  }

  const onClickSeeLess = () => {
    dispatch({
      type:'set_terms',
      terms:{
        ...state.terms,
        shownPage:1
      }
    })
  }

  return (
    <>
    <div id="profile-glossary-adder" tabIndex={0} className={`collapse collapse-arrow overflow-visible`}>
      {
        expanded &&
        <div className="flex justify-end items-center absolute right-12 top-2 z-10">
          <KadaHorizExpansion
            direction="left"
            bodyWidth="200px"
            forceExpand={forceSearchExpand}
          >
            <div slot="root">
              <KadaIconBtn iconName="search" size={24} />
            </div>
            <div slot="body">
              <KadaInput
                placeholder="Search for terms"
                isSearch
                value={search}
                onInput={(e) => {
                  setSearch(e.detail.value)
                }}
                onMouseEnter={()=>{
                  setForceSearchExpand(true)
                }}
                onMouseLeave={()=>{
                  if(search)return;
                  setForceSearchExpand(false)
                }}
              />
            </div>
          </KadaHorizExpansion>
          {
            editable &&
            <KadaIconBtn
              iconName='edit'
              size={26}
              onClick={()=>onClickEdit()}
              className="ml-2"
              tooltip='Edit linked terms'
              color='var(--color-light-text)'
            />
          }
          <KadaDropdownBtn
            iconName='more_horiz'
            size={26}
            dropdownPlacement='bottom-left'
            iconOnly={true}
            tooltip='Sort terms'
            iconColor='var(--color-light-text)'
            options={[
              { text: 'Sort A-Z', onClick: () => onChangeSort('name asc') },
              { text: 'Sort Z-A', onClick: () => onChangeSort('name desc') },
            ]}
            className="ml-2"
            variant={kDropdownBtnVariants.roundedIconButton}
          />
        </div>
      }
      <input
        type="checkbox"
        onChange={()=>{
          setExpanded(!expanded)
        }}
      />
      <div className="collapse-title flex items-center">
        <div>
          <span className="text-sm text-(--color-header-text)">
            {title || header} ({state.terms?.total || 0})
          </span>
          <p className='!text-xs text-(--color-light-text)'>
            {subTitle}
          </p>
        </div>
      </div>
      <div className="collapse-content overflow-hidden">
        {
          state.terms && state.terms.total > 0 &&
          <>
            {
              getTermList(state.terms.items).length > 0 ?
              <div style={{display:'flex',width:'100%',height:24,alignItems:'center'}}>
                <p className="!text-xs pr-4 text-(--color-light-text) tracking-widest" style={{marginLeft:56,flexGrow: 1}}>TERM</p>
              </div>
              :
              <p>No terms found</p>
            }
            {
              state.terms && getTermList(state.terms.items).slice(0,state.terms.shownPage*5).map((el,index)=>(
                <div
                  key={el.id}
                  data-test-id={`term-list-${el.name.toLowerCase().replace(/\s/g,'-')}`}
                  id={`term_list_${index}`}
                  className={"hover:bg-base-200 flex w-full items-center h-12 cursor-pointer border-b border-(--color-base-border)"}
                  onClick={()=>{
                    onClickResultItem({item:el,id:el.id,label:'COLLECTION_INSTANCE',history,collection:'collection'})
                  }}
                  onMouseEnter={(e)=>{
                    clearTimeout(hoverTimeout.current);
                    hoverTimeout.current = setTimeout(() => {
                      setHoveredId(el.id);
                      let pos = {
                        x: Math.max(0, e.target.getBoundingClientRect().right - e.target.getBoundingClientRect().width/2),
                        y: (e.clientY > window.innerHeight / 2 ? e.target.getBoundingClientRect().top + window.scrollY - 300 : e.target.getBoundingClientRect().bottom + window.scrollY - 20)
                      }
                      setHoverPos(pos);
                    }, 600);
                  }}
                  onMouseLeave={()=>{
                    clearTimeout(hoverTimeout.current);
                    setHoveredId(null);
                  }}
                >
                  <KTooltip title={el.description && el.description.trim()!==''?el.description:'No description'}>
                    <div style={{width:24,height:24,margin:'12px 16px 12px 16px',flexShrink:0,flexGrow:0}}>
                      <KadaIcon
                        iconName="glossary"
                        size={24}
                        color='var(--color-primary)'
                      />
                    </div>
                  </KTooltip>
                  <KTooltip placement='bottom-start' title={el.name}>
                    <p data-test-classname="term-list-name" className="flex-grow truncate pr-4">
                      {el.name}
                    </p>
                  </KTooltip>
                  <div className="flex items-center flex-shrink-0 w-max">
                    {
                      el.workflow_status_txt ?
                      <KadaBadge
                        text={toTitleCase(el.workflow_status_txt)}
                        variant={kBadgeVariants.outlined}
                        className="mr-2"
                        color={el.workflow_status_txt ? statusData.find(s => s.name.toUpperCase() === el.workflow_status_txt.toUpperCase())?.colour : undefined}
                      />
                      :
                      null
                    }
                  </div>
                </div>
              ))
            }
          </>
        }
        {
          state.termsLoading &&
          <KadaLoadingSpinner style={{marginTop:16}} variant={kLoadingSpinnerVariants.secondary}></KadaLoadingSpinner>
        }
        {
          state.termsError &&
          <p>Error occurred loading terms</p>
        }
        {
          state.terms?.items && getTermList(state.terms.items).length > 5 &&
          <div style={{marginTop:8}}>
            {
              state.terms.shownPage*5<state.terms.total &&
              <KadaBtn text='SEE MORE' variant={kButtonVariants.primaryBorderless} onClick={()=>{onClickShowMore()}} style={{marginRight:8}}></KadaBtn>
            }
            {
              state.terms.shownPage>1 &&
              <KadaBtn text='SEE LESS' variant={kButtonVariants.primaryBorderless} onClick={()=>{onClickSeeLess()}}></KadaBtn>
            }
          </div>
        }
        {
          state.terms && state.terms.total===0 &&
          <p>No terms linked</p>
        }
      </div>
    </div>
    <GlossaryDetailPopper termId={hoveredId} forceOpen={hoveredId?true:false} disabledListener forcePos={hoverPos} />
    </>
  )
}

GlossaryAdder.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.string,
  forceEditable: PropTypes.bool,
}

export default GlossaryAdder;
