import React, { useEffect, useRef } from 'react';
import { getIconComponent, formatNumber, collectionIds, onClickResultItem} from '../../../utilities';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../../UI/ContextMenu/ContextMenu'
import axiosCerebrum from '../../../axios-cerebrum'
import FollowButton from '../../UI/Buttons/FollowButton'
import KTooltip from '../../UI/KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';
import { KadaBadge, KadaBtn, kButtonVariants } from 'kada-component-library';

function RightSideBar(props) {
  const {
    state,
    dispatch,
    history
  } = props;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])

  const handleFollowClick = (el) => {
    if(el.following){
      axiosCerebrum
        .delete(`/api/me/follows/${el.id.toLowerCase()}`)
        .then(()=>{
          dispatch({
            type:'set_collection_recommendations',
            collectionRecommendations:{
              ...state.collectionRecommendations,
              [el.parent.id]:{
                ...state.collectionRecommendations[el.parent.id],
                items:state.collectionRecommendations[el.parent.id].items.map(i=>{
                  if(i.id!==el.id)return i;
                  return {...i,following:false}
                })
              }
            }
          })
        })
        .catch(error=>{
          console.log(error);
          sendAlert({ message: 'Error occurred unfollowing this item', type: 'error' })
        })
    }else{
      axiosCerebrum
      .put(`/api/me/follows/${el.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        dispatch({
          type:'set_collection_recommendations',
          collectionRecommendations:{
            ...state.collectionRecommendations,
            [el.parent.id]:{
              ...state.collectionRecommendations[el.parent.id],
              items:state.collectionRecommendations[el.parent.id].items.map(i=>{
                if(i.id!==el.id)return i;
                return {...i,following:true}
              })
            }
          }
        })
      })
      .catch(error=>{
        console.log(error);
        sendAlert({ message: 'Error occurred following this item', type: 'error' })
      })
    }
  }

  return (
    <div className="w-full flex flex-col">
      {
        state.sourceCountData &&
        <div>
          <h2 style={{color:'var(--color-header-text)',marginBottom:8}}>Ecosystem</h2>
          {
            (state.sourceCountData.slice(0,5)).map(el=>(
              <div data-test-classname="ecosystem-list-item">
                <ContextMenuTrigger id={el.source_id+'_home_source'}>
                  <div className="flex justify-between items-center overflow-hidden p-4 border-b border-(--color-base-border) cursor-pointer hover:bg-(--color-base-200)" onClick={el.onClick}>
                    <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                      {
                        getIconComponent({label:el.source_type,size:24,colour:'var(--color-primary)'}) || getIconComponent({label:'SOURCE',size:24,colour:'var(--color-primary)'})
                      }
                    </div>
                    <KTooltip title={el.name}>
                      <p className="text-primaryText truncate" style={{textDecoration:'underline',marginRight:12,flexGrow:1}}>{el.name}</p>
                    </KTooltip>
                    <KadaBadge
                      text={formatNumber(el.maxCount.count)}
                      className='flex-shrink-0'
                    >
                      <div slot="icon">
                        {getIconComponent({label:el.maxCount.val,size:16,colour:'var(--color-base-content)'})}
                      </div>
                    </KadaBadge>
                  </div>
                </ContextMenuTrigger>

                <ContextMenu id={el.source_id+'_home_source'}>
                  <CustomMenu
                    itemUrl={el.url}
                    actions={[
                      'open_new_tab'
                    ]}
                  />
                </ContextMenu>
              </div>
            ))
          }
          <KadaBtn text="SEE MORE" variant={kButtonVariants.primaryBorderless} data-test-id="ecosystem-see-more-button" style={{marginTop:4}} onClick={()=>history.push('/my_ecosystem?tabName=SOURCES')}></KadaBtn>
        </div>
      }
      {
        state.filterData && state.filterData.items.length>0 &&
        <div style={{marginTop:48}}>
          <h2 style={{color:'var(--color-header-text)',marginBottom:8}}>Filters</h2>
          {
            state.filterData.items.slice(0,3).map(el=>(
              <div data-test-classname="filter-list-item">
                <ContextMenuTrigger id={el.id+'_home_filter'}>
                  <div className="flex justify-between items-center overflow-hidden p-4 border-b border-(--color-base-border) cursor-pointer hover:bg-(--color-base-200)" onClick={()=>history.push(`/basic_search?filterListId=${el.id}`)}>
                    <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                        {getIconComponent({label:'filter_list',size:24,colour:'var(--color-primary)'})}
                    </div>
                    <KTooltip title={el.name}>
                      <p className="text-primaryText truncate" style={{textDecoration:'underline',marginRight:12,fontSize:13.75,flexGrow:1}}>{el.name}</p>
                    </KTooltip>
                  </div>
                </ContextMenuTrigger>

                <ContextMenu id={el.id+'_home_filter'}>
                  <CustomMenu
                    itemUrl={`/basic_search?filterListId=${el.id}`}
                    actions={[
                      'open_new_tab'
                    ]}
                  />
                </ContextMenu>
              </div>
            ))
          }
          {
            state.filterData.total >  3 &&
            <KadaBtn text="SEE MORE" variant={kButtonVariants.primaryBorderless} data-test-id="filter-see-more-button" style={{marginTop:4}} onClick={()=>history.push('/filters')}></KadaBtn>
          }
        </div>
      }
      {
        state.listData && state.listData.items.length>0 &&
        <div style={{marginTop:48}}>
          <h2 style={{color:'var(--color-header-text)',marginBottom:8}}>Lists</h2>
          {
            state.listData.items.slice(0,3).map(el=>(
              <div data-test-classname="list-list-item">
                <ContextMenuTrigger id={el.id+'_home_list'}>
                  <div className="flex justify-between items-center overflow-hidden p-4 border-b border-(--color-base-border) cursor-pointer hover:bg-(--color-base-200)" onClick={el.onClick}>
                    <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                        {getIconComponent({label:'list',size:24,colour:'var(--color-primary)'})}
                    </div>
                    <KTooltip title={el.name}>
                      <p className="text-primaryText truncate" style={{textDecoration:'underline',marginRight:12,flexGrow:1}}>{el.name}</p>
                    </KTooltip>
                  </div>
                </ContextMenuTrigger>

                <ContextMenu id={el.id+'_home_list'}>
                  <CustomMenu
                    itemUrl={el.url}
                    actions={[
                      'open_new_tab'
                    ]}
                  />
                </ContextMenu>
              </div>
            ))
          }
          {
            state.listData.total >  3 &&
            <KadaBtn text="SEE MORE" variant={kButtonVariants.primaryBorderless} data-test-id="list-see-more-button" style={{marginTop:4}} onClick={()=>history.push('/my_data?tabName=LIST')}></KadaBtn>
          }
        </div>
      }

      {
        state.collectionRecommendations &&
        <div style={{marginTop:48}}>
          <h2 style={{color:'var(--color-header-text)'.main,marginBottom:8}}>Discover your Ecosystem</h2>
          {
            state.collectionRecommendations[collectionIds.domain] &&
            <div style={{marginTop:16}}>
              <p style={{color:'var(--color-header-text)',marginBottom:8,fontSize:16}}>Discover Domains</p>
              {
                state.collectionRecommendations[collectionIds.domain].items.slice(0,3).map(el=>(
                  <div data-test-classname="your-ecosystem-list-item">
                    <ContextMenuTrigger id={el.id+'_home_domain'}>
                      <div className="flex justify-between items-center overflow-hidden p-4 border-b border-(--color-base-border) cursor-pointer hover:bg-(--color-base-200)" onClick={()=>{onClickResultItem({label:'collection_instance',id:el.id,item:el,history})}}>
                        <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                            {getIconComponent({label:'domain',size:24,colour:'var(--color-primary)'})}
                        </div>
                        <KTooltip title={el.name}>
                          <p className="text-primaryText truncate" style={{textDecoration:'underline',marginRight:12,flexGrow:1}}>{el.name}</p>
                        </KTooltip>
                        <FollowButton
                          onClick={(event)=>{event.stopPropagation();handleFollowClick(el)}}
                          following={el.following}
                          object={el}
                          disableCount={true}
                          disableMargin={true}
                          size='small'
                        />
                      </div>
                    </ContextMenuTrigger>

                    <ContextMenu id={el.id+'_home_domain'}>
                      <CustomMenu
                        item={el}
                        actions={[
                          'open_new_tab'
                        ]}
                      />
                    </ContextMenu>
                  </div>
                ))
              }
            </div>
          }

          {
            state.collectionRecommendations[collectionIds.verifiedUseCase] &&
            <div style={{marginTop:16}}>
              <p style={{color:'var(--color-header-text)',marginBottom:8,fontSize:16}}>Discover Use Cases</p>
              {
                state.collectionRecommendations[collectionIds.verifiedUseCase].items.slice(0,3).map(el=>(
                  <div data-test-classname="use-case-list-item">
                    <ContextMenuTrigger id={el.id+'_home_verified'}>
                      <div className="flex justify-between items-center overflow-hidden p-4 border-b border-(--color-base-border) cursor-pointer hover:bg-(--color-base-200)" onClick={()=>{onClickResultItem({label:'collection_instance',id:el.id,item:el,history})}}>
                        <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                            {getIconComponent({label:'verified',size:24,colour:'var(--color-primary)'})}
                        </div>
                        <KTooltip title={el.name}>
                          <p className="text-primaryText truncate" style={{textDecoration:'underline',marginRight:12,flexGrow:1}}>{el.name}</p>
                        </KTooltip>
                        <FollowButton
                          onClick={()=>handleFollowClick(el)}
                          following={el.following}
                          object={el}
                          disableCount={true}
                          disableMargin={true}
                          size='small'
                        />
                      </div>
                    </ContextMenuTrigger>

                    <ContextMenu id={el.id+'_home_verified'}>
                      <CustomMenu
                        item={el}
                        actions={[
                          'open_new_tab'
                        ]}
                      />
                    </ContextMenu>
                  </div>
                ))
              }
            </div>
          }

          {
            (state.collectionRecommendations[collectionIds.domain] || state.collectionRecommendations[collectionIds.verifiedUseCase])?
            <KadaBtn text="SEE MORE" variant={kButtonVariants.primaryOutlined} data-test-id="discover-collection-button" style={{marginTop:4}} onClick={()=>{history.push('/my_ecosystem?tabName=DISCOVER')}}></KadaBtn>
            :
            <div>
              <div  data-test-id="discover-report-button" className="flex justify-between items-center overflow-hidden p-4 border-b border-(--color-base-border) cursor-pointer hover:bg-(--color-base-200)" onClick={()=>history.push('/my_ecosystem?tabName=DISCOVER&discoverTabName=REPORTS')}>
                <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:'content',size:24,colour:'var(--color-primary)'})}
                </div>
                <p className="text-primaryText truncate" style={{textDecoration:'underline',marginRight:12,flexGrow:1}}>Discover Reports</p>
              </div>
              <div  data-test-id="discover-term-button" className="flex justify-between items-center overflow-hidden p-4 border-b border-(--color-base-border) cursor-pointer hover:bg-(--color-base-200)" onClick={()=>history.push('/my_ecosystem?tabName=DISCOVER&discoverTabName=TERMS')}>
                <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:'term',size:24,colour:'var(--color-primary)'})}
                </div>
                <p className="text-primaryText truncate" style={{textDecoration:'underline',marginRight:12,flexGrow:1}}>Discover Terms</p>
              </div>
              <div  data-test-id="discover-collection-button" className="flex justify-between items-center overflow-hidden p-4 border-b border-(--color-base-border) cursor-pointer hover:bg-(--color-base-200)" onClick={()=>history.push('/my_ecosystem?tabName=DISCOVER&discoverTabName=COLLECTIONS')}>
                <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:'collection',size:24,colour:'var(--color-primary)'})}
                </div>
                <p className="text-primaryText truncate" style={{textDecoration:'underline',marginRight:12,flexGrow:1}}>Discover Collections</p>
              </div>
              <div  data-test-id="discover-list-button" className="flex justify-between items-center overflow-hidden p-4 border-b border-(--color-base-border) cursor-pointer hover:bg-(--color-base-200)" onClick={()=>history.push('/my_ecosystem?tabName=DISCOVER&discoverTabName=LISTS')}>
                <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:'list',size:24,colour:'var(--color-primary)'})}
                </div>
                <p className="text-primaryText truncate" style={{textDecoration:'underline',marginRight:12,flexGrow:1}}>Discover Lists</p>
              </div>
              <div  data-test-id="discover-tag-button" className="flex justify-between items-center overflow-hidden p-4 border-b border-(--color-base-border) cursor-pointer hover:bg-(--color-base-200)" onClick={()=>history.push('/my_ecosystem?tabName=DISCOVER&discoverTabName=TAGS')}>
                <div style={{width:24,height:24,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:'tag',size:24,colour:'var(--color-primary)'})}
                </div>
                <p className="text-primaryText truncate" style={{textDecoration:'underline',marginRight:12,flexGrow:1}}>Discover Tags</p>
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}

export default RightSideBar;
