import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Popper, Typography, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Background,
  MiniMap,
  ReactFlowProvider,
  useReactFlow
} from 'react-flow-renderer';
import CustomNode from './CustomNode';
import GroupNode from './GroupNode';
import './generalStyle.css'
import LineageNode from './LineageNode';
import LineageEdge from './LineageEdge';
import InsightsNode from './InsightsNode'
import KButtonNode from './KButtonNode';
import KIconLabelNode from './KIconLabelNode';
import CollectionMapNode from './CollectionMapNode';
import LineageNodeV3 from './LineageNodeV3';
import LineageGroupNodeV3 from './LineageGroupNodeV3';
import KSwimLane from './KSwimLane';
import KSwimLaneControl from './KSwimLaneControl';
import { globalListenerRef } from '../../../GlobalListenerRef';
import { getIconComponent, sendMessage } from '../../../utilities';
import { nodeHorizontalGap } from '../Lineage/LineageV3/layoutUtils';
import { isV3Lineage } from '../Lineage/utils';

const styles = theme => ({
  tooltipRoot:{
    // background:theme.palette.contextMenuBackground.main,
    borderRadius:4,
    minWidth:300,
    padding:'8px 0',
  }
})

const nodeTypes = {
  KCustomNode: CustomNode,
  KGroup: GroupNode,
  KLineageGroupNodeV3: LineageGroupNodeV3,
  KLineageNode: LineageNode,
  KLineageNodeV3: LineageNodeV3,
  KSwimLane: KSwimLane,
  KSwimLaneControl: KSwimLaneControl,
  KInsightsNode: InsightsNode,
  KButtonNode: KButtonNode,
  KIconLabelNode: KIconLabelNode,
  KCollectionMapNode: CollectionMapNode
};

const edgeTypes = {
  KLineageEdge: LineageEdge
}

function ReactFlowWrapper(props) {

  const {
    theme,
    classes,
    initialNodes,
    initialLinks,
    presetCenter,
    centerOffset={x:0,y:0},
    onFocusOffset={x:0,y:0},
    hideMiniMap,
    fitView,
    draggable = true,
    onSelectionChange,
    onNodeDragStop,
    onNodeDragStart,
    initialZoom,
    showEdgeBelowNode,
    alwaysShowAllNodes,
    removeAutoCenterOnClick,
    disableKeyDownAction,
    removeSwimlaneAutoFocus
  } = props;

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [visible, setVisible] = useState(false);
  const [disableScroll, setDisableScroll] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState(false)
  const [isKeyDown, setIsKeyDown] = useState(false)
  const [isNodeHovered, setIsNodeHovered] = useState(false)
  const [isMouseHovering, setIsMouseHovering] = useState(false)

  const mouseMoveTimeoutRef = useRef()
  const reactFlowInstance = useReactFlow();

  useEffect(()=>{
    if(!initialNodes || !initialLinks)return;
    setNodes([...initialNodes])
    setEdges([...initialLinks])
  // eslint-disable-next-line
  },[initialNodes, initialLinks])

  const onMoveViewport = (direction) => {
    let el = document.getElementsByClassName('react-flow__viewport')[0]
    el.style.transition = 'transform .15s linear'
    if(['left','right'].includes(direction)){
      let currentX = reactFlowInstance.getViewport().x
      let newX = parseInt(currentX)+ (direction==='left'?1:-1)*nodeHorizontalGap*reactFlowInstance.getZoom();
      reactFlowInstance.setViewport({x:newX,y:reactFlowInstance.getViewport().y,zoom:reactFlowInstance.getZoom()})
    }

    if(['up','down'].includes(direction)){
      let currentY = reactFlowInstance.getViewport().y
      let newY = parseInt(currentY)+ (direction==='up'?1:-1)*nodeHorizontalGap*reactFlowInstance.getZoom();
      reactFlowInstance.setViewport({x:reactFlowInstance.getViewport().x,y:newY,zoom:reactFlowInstance.getZoom()})
    }

    setTimeout(()=>{
      el.style.transition = ''
    },150)
  }

  const addKeyDownListener = () => {
    globalListenerRef.reactFlowKeyDownListener = (e) => {
      if(disableKeyDownAction)return;
      if (e.key === 'Alt' || e.key === 'Option') {
        toggleSwimlaneClickable('clickable',true)
        setIsKeyDown(true)
      }
      if(e.key==='ArrowLeft')onMoveViewport('left')
      if(e.key==='ArrowRight')onMoveViewport('right')
      if(e.key==='ArrowUp')onMoveViewport('up')
      if(e.key==='ArrowDown')onMoveViewport('down')
    };

    globalListenerRef.reactFlowKeyUpListener = (e) => {
      if(disableKeyDownAction)return;
      if (e.key === 'Alt' || e.key === 'Option') {
        toggleSwimlaneClickable('clickable',false)
        setIsKeyDown(false)
      }
    }

    window.addEventListener('keydown', globalListenerRef.reactFlowKeyDownListener)
    window.addEventListener('keyup', globalListenerRef.reactFlowKeyUpListener)
  }

  const removeKeyDownListener = () => {
    window.removeEventListener('keydown', globalListenerRef.reactFlowKeyDownListener)
    globalListenerRef.reactFlowKeyDownListener = null
    window.removeEventListener('keyup', globalListenerRef.reactFlowKeyUpListener)
    globalListenerRef.reactFlowKeyUpListener = null
  }


  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.reactFlowMsgListner)
    window.removeEventListener('mousemove',globalListenerRef.reactFlowMouseListner)

    if(!isV3Lineage())return;

    addKeyDownListener()

    globalListenerRef.reactFlowMsgListner = (msg) => {
      if(!msg.data)return;
      if(msg.data['react-flow-recenter']){
        let els = document.getElementsByClassName('react-flow__viewport')
        Array.prototype.forEach.call(els, function(el) {
          el.style.transition = 'transform .15s linear'
        });
        reactFlowInstance.setCenter(presetCenter?presetCenter.x:0,presetCenter?presetCenter.y:0)
        reactFlowInstance.zoomTo(initialZoom || 0.75)
        setTimeout(()=>{
          Array.prototype.forEach.call(els, function(el) {
            el.style.transition = ''
          });
        },150)
      }
      if(msg.data.react_flow_move_viewport){
        onMoveViewport(msg.data.react_flow_move_viewport)
      }
    }

    globalListenerRef.reactFlowMouseListner = (e) => {
      if(disableKeyDownAction)return;
      if(e.movementX!==0 || e.movementY!==0){
        clearTimeout(mouseMoveTimeoutRef.current)
        document.getElementById('react-flow-popper-anchor')?.remove()
        setPopperAnchor()
        mouseMoveTimeoutRef.current = setTimeout(()=>{
          // create a ref element based on mouse position, set the anchor to the element
          const ref = document.createElement('div')
          ref.style.position = 'fixed'
          ref.style.left = e.clientX+'px'
          ref.style.top = e.clientY+16+'px'
          ref.id = 'react-flow-popper-anchor'
          document.body.appendChild(ref)
          setPopperAnchor(ref)
        },800)
      }
    }

    window.addEventListener('message',globalListenerRef.reactFlowMsgListner)
    window.addEventListener('mousemove',globalListenerRef.reactFlowMouseListner)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.reactFlowMsgListner)
      window.removeEventListener('mousemove',globalListenerRef.reactFlowMouseListner)
      removeKeyDownListener()
      globalListenerRef.reactFlowMsgListner = null
      globalListenerRef.reactFlowMouseListner = null
      globalListenerRef.reactFlowMouseClickListner = null
    }
  // eslint-disable-next-line
  },[reactFlowInstance])

  const toggleSwimlaneClickable = (type, value) => {
    let els = document.getElementsByClassName('react-flow__node-KSwimLane')
    let className;
    if(type==='clickable'){
      className = 'k-swim-lane-clickable'
    }
    if(value){
      for (let i = 0; i < els.length; i++) {
        els[i].classList.add(className)
      }
      setDisableScroll(true)
      sendMessage({swimlane_show_label:true})
    }else{
      for (let i = 0; i < els.length; i++) {
        els[i].classList.remove(className)
      }
      setDisableScroll(false)
      sendMessage({swimlane_hide_label:true})
    }
  }


  let miniMapStyle;
  if(localStorage.hasOwnProperty('dark')){
    miniMapStyle = {
      nodeColor:'#eee',
      maskColor:'#eeeeee20',
    }
  }else{
    miniMapStyle = {
      nodeColor:'#D8D8D8',
      maskColor:'#aaaaaa20',
    }
  }

  return (
    <>
      <ReactFlow
        className={showEdgeBelowNode?'react-flow-show-edge-below-node':''}
        onInit={reactFlowInstance=>{
          reactFlowInstance.setCenter(presetCenter?presetCenter.x:0,presetCenter?presetCenter.y:0)
          reactFlowInstance.zoomTo(initialZoom || 0.75)
          setVisible(true)
        }}
        onEdgeClick={(event, edge, node)=>{
          if(removeAutoCenterOnClick)return;
          let els = document.getElementsByClassName('react-flow__viewport')
          Array.prototype.forEach.call(els, function(el) {
            el.style.transition = 'transform .15s linear'
          });
          const target = reactFlowInstance.getNode(edge.target)
          reactFlowInstance.setCenter(target.positionAbsolute.x+centerOffset.x+onFocusOffset.x, target.positionAbsolute.y+centerOffset.y+onFocusOffset.y, {zoom:reactFlowInstance.getZoom()})
          setTimeout(()=>{
            Array.prototype.forEach.call(els, function(el) {
              el.style.transition = ''
            });
          }
          ,150)
        }}
        onNodeClick={(event, node)=>{
          event.stopPropagation();
          if(removeAutoCenterOnClick)return;
          if(node.type==='KSwimLane' && removeSwimlaneAutoFocus)return;
          if(node.type==='KSwimLaneControl')return;

          let els = document.getElementsByClassName('react-flow__viewport')
          Array.prototype.forEach.call(els, function(el) {
            el.style.transition = 'transform .15s linear'
          });
          let newX, newY, newZoom;
          if (node.type==='KSwimLane'){
            newX = node.positionAbsolute.x+node.width+centerOffset.x+onFocusOffset.x;
            newY = 0;
            newZoom = 0.5
          }else{
            newX = node.positionAbsolute.x+centerOffset.x+onFocusOffset.x;
            newY = node.positionAbsolute.y+centerOffset.y+onFocusOffset.y;
            newZoom = reactFlowInstance.getZoom();
          }
          reactFlowInstance.setCenter(newX, newY, {zoom:newZoom})
          setTimeout(()=>{
            Array.prototype.forEach.call(els, function(el) {
              el.style.transition = ''
            });
          },150)
        }}
        zoomOnScroll={!disableScroll}
        minZoom={0.2}
        nodes={visible?nodes:[]}
        edges={visible?edges:[]}
        onlyRenderVisibleElements={alwaysShowAllNodes?false:true}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        onNodeDragStart={onNodeDragStart}
        onNodeDragStop={onNodeDragStop}
        fitView={fitView}
        onSelectionChange={onSelectionChange}
        nodesDraggable={draggable}
        attributionPosition="top-right"
        onMouseLeave={()=>{
          setPopperAnchor()
          setIsMouseHovering(false)
          clearTimeout(mouseMoveTimeoutRef.current)
        }}
        onMouseEnter={()=>{
          setIsMouseHovering(true)
        }}
        onNodeMouseEnter={()=>{
          setIsNodeHovered(true)
        }}
        onNodeMouseLeave={()=>{
          setIsNodeHovered(false)
        }}
        onEdgeMouseEnter={()=>{
          setIsNodeHovered(true)
        }}
        onEdgeMouseLeave={()=>{
          setIsNodeHovered(false)
        }}
      >
        {
          !hideMiniMap &&
          <MiniMap
            nodeColor={miniMapStyle.nodeColor}
            maskColor={miniMapStyle.maskColor}
            style={{
              border:`1px solid ${theme.palette.header.main}`,
              borderRadius:4,
              background:theme.palette.background.main
            }}
          />
        }
        {/* <Controls /> */}
        <Background />
        {/* <svg style={{
        background:`repeating-linear-gradient(
            90deg,
            #fff,
            #fff 300px,
            #222 300px,
            #222 600px,
            #fff 600px,
            #fff 900px
          )`,
          x:0,
          y:0,
          width:'100%',
          height:'100%'
        }}>

        </svg> */}
      </ReactFlow>
      <Popper anchorEl={popperAnchor} open={Boolean(popperAnchor) && !isKeyDown && isMouseHovering && !isNodeHovered} placement='bottom'>
        <Paper className={classes.tooltipRoot}>
          <div style={{display:'flex',alignItems:'center'}}>
            <div style={{width:20,height:20,marginRight:16,marginLeft:16}}>
              {getIconComponent({label:"info",size:20,colour:theme.palette.primaryText.light})}
            </div>
            <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light}}>
              Hold ALT / OPTION and click to edit
            </Typography>
          </div>
        </Paper>
      </Popper>
    </>
  )
}

ReactFlowWrapper.propTypes = {
  initialNodes: PropTypes.array.isRequired,
  initialLinks: PropTypes.array.isRequired,
  initialZoom:PropTypes.number,
  presetCenter: PropTypes.shape({x:PropTypes.number.isRequired, y: PropTypes.number.isRequired}),
  centerOffset: PropTypes.shape({x:PropTypes.number.isRequired, y: PropTypes.number.isRequired}),
  hideMiniMap: PropTypes.bool,
  fitView: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  onNodeDragStop: PropTypes.func,
  onNodeDragStart: PropTypes.func,
  showEdgeBelowNode: PropTypes.bool,
  alwaysShowAllNodes: PropTypes.bool,
  disableKeyDownAction: PropTypes.bool
}

function FlowWithProvider(props) {
  return (
    <ReactFlowProvider>
      <ReactFlowWrapper {...props} />
    </ReactFlowProvider>
  );
}

FlowWithProvider.propTypes = {
  initialNodes: PropTypes.array.isRequired,
  initialLinks: PropTypes.array.isRequired,
  initialZoom:PropTypes.number,
  presetCenter: PropTypes.shape({x:PropTypes.number.isRequired, y: PropTypes.number.isRequired}),
  centerOffset: PropTypes.shape({x:PropTypes.number.isRequired, y: PropTypes.number.isRequired}),
  hideMiniMap: PropTypes.bool,
  fitView: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  onNodeDragStop: PropTypes.func,
  onNodeDragStart: PropTypes.func,
  showEdgeBelowNode: PropTypes.bool,
  alwaysShowAllNodes: PropTypes.bool,
  removeAutoCenterOnClick: PropTypes.bool,
  draggable: PropTypes.bool,
  disableKeyDownAction: PropTypes.bool,
  removeSwimlaneAutoFocus: PropTypes.bool
}

export default withTheme()(withStyles(styles)(FlowWithProvider));
