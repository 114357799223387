import React from 'react';
// import axiosSolr from '../../../axios-solr'
import { createRoot } from 'react-dom/client'
// import InlineChip from './InlineChip';
import axiosCerebrum from '../../../axios-cerebrum'
import DetailPopperEL from './DetailPopperEL';
import { getCollectionColour, scrollIntoElemenet } from '../../../utilities';
import {palette} from '../../../theme';
import removeMD from 'remove-markdown'
import GlossaryDetailPopper from '../GlossaryAdder/GlossaryDetailPopper';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

const details = {};

const generateKTermLinkedEl = ({id,name,el,linked,isMultiple,colour}) => {

  const container = el;
  const root = createRoot(container);
  root.render(
    <DetailPopperEL
      termId={id}
      name={name}
      isSuggestion={!linked}
      buttonText={linked?undefined:'SEE LINK OPTION(S)'}
      buttonAction={linked?undefined:()=>{scrollIntoElemenet("profile-glossary-adder",0)}}
      colour={colour}
      isMultiple={isMultiple}
    />
  );

  let tempRoot = document.getElementById(`template-detail-popper-${id}`);
  if(!tempRoot){
    tempRoot = document.createElement('div');
    tempRoot.id = `template-detail-popper-${id}`
    document.body.appendChild(tempRoot);
  }
  // create an empty store
  let store = createStore(()=>({}),{});
  createRoot(tempRoot).render(
    <Provider store={store}>
      <GlossaryDetailPopper termId={id} isSuggestion={!linked} />
    </Provider>
  )
}

export const cleanUpMountedEls = () => {
  document.querySelectorAll('[id^="template-detail-popper-"]').forEach(el=>{
    el.remove()
  })
}


const linkCIRegex = /kobject@(.*?)@(.+?\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12}))@/
const linkCIReplacementRegex = /\$\$widget[^$$]+\skobject@(.*?)@(.+?\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12}))@\$\$/g
const suggestionCIRegex = /(kTermSuggestionMultiple|kTermSuggestion|kTermLinked)@(.*?)@(.+?\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12}))@/
const suggestionCIReplacementRegex = /\$\$widget[^$$]+\s(kTermSuggestionMultiple|kTermSuggestion|kTermLinked)@(.*?)@(.+?\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12}))@\$\$/g

export const addDetail = item => {
  details[item.id] = item;
}

export const clearDetail = () => {
  Object.keys(details).forEach(k=>{
    delete details[k]
  })
}

export const templates = {
  linkCI:{
    regex: linkCIRegex,
    triggerChar:'/',
    formatString: ({name,link}) => {
      return `kobject@${name}@${link}@`
    },
    renderer: text => {
      let span = document.createElement('span');
      try{
        const [,name, , id] = text.match(linkCIRegex);
        span = document.createElement('span');
        span.className = `markdown-ci-tag-${id}`;
        // let label;
        // try{
        //   label = url.split('profile/')[1].split('/')[0];
        // }catch{}

        let colour = palette.inlineCIBackground.main
        span.style.background = colour+'60'
        span.style.padding = '2px'
        span.style.marginRight = '2px'

        span.innerHTML = name;
        generateKTermLinkedEl({id, name, el:span, linked:true, colour:getCollectionColour(undefined,'manual',undefined).colour})

      }catch(error){
        // console.log(error)
        span.innerText = text;
      }
      return span;
    }
  },
  termSuggestion:{
    regex: suggestionCIRegex,
    triggerChar:'/',
    getReplacementRegex: name => {
      return new RegExp(`(?<!(kTermSuggestion|kTermSuggestionMultiple|kTermLinked|kobject)@[^@]*?)(s|(?<![a-zA-Z0-9]))${name}(s|(?![a-zA-Z0-9]))(?![^@]*?@(.+?/(w{8}-w{4}-w{4}-w{4}-w{12}))@)`,'ig')
    },
    formatString: ({name,link,isLinked, isMultiple}) => {
      return `${isLinked?'kTermLinked':'kTermSuggestion'+(isMultiple?'Multiple':'')}@${name}@${link}@`
    },
    renderer: text => {
      let span = document.createElement('span');
      try{
        const [,type,name,, id] = text.match(suggestionCIRegex);
        span = document.createElement('span');
        span.className = `term-suggestion-tag-${id}`;
        let colour = palette.inlineCIBackground.main
        let linked = type==='kTermLinked'
        if(linked){
          span.style.background = colour+'60'
          span.style.padding = '2px'
        }else{
          span.style.textDecoration = `underline ${colour} 2px dashed`;
          span.style.textUnderlineOffset = '3px'
        }
        span.style.marginRight = '2px'
        span.innerHTML = name;
        generateKTermLinkedEl({id, name, el:span, linked, isMultiple:type==='kTermSuggestionMultiple', colour:getCollectionColour(undefined,'glossary',undefined).colour})
      }catch(error){
        // console.log(error)
        span.innerText = text;
      }
      return span;
    }
  }
}

export const getAllRules = () => {
  return Object.keys(templates).map(el=>({
    rule: templates[el].regex,
    toDOM: templates[el].renderer
  }))
}

export const postSaveAction = async ({item, initialValue, newValue, onUpdate}) => {

  const onLinkCI = async (newIds, isTerm) => {
    let toBeAdded = newIds;
    if(toBeAdded.length===0)return;
    for(let i=0; i<toBeAdded.length; i++){
      let id = toBeAdded[i]
      let relationship = 'MEMBERS';
      if(isTerm)relationship = 'RELATES_TO'
      await axiosCerebrum.put(
        `/api/collectioninstances/${id}/related?relationship=${relationship}&object_id=${item.id}`
      )
      .catch(error=>{
        console.log(error)
      })
    }
  }

  let newIds = [];
  let oldIds = [];
  let newTermIds = [];
  let oldTermIds = [];
  let regex = new RegExp(templates.linkCI.regex,'g');

  let match = initialValue.match(regex)
  match && match.forEach(m=>{
    let id = m.match(templates.linkCI.regex)[3]
    if(!oldIds.includes(id))oldIds.push(id)
  })

  match = newValue.match(regex)
  match && match.forEach(m=>{
    let id = m.match(templates.linkCI.regex)[3]
    if(!newIds.includes(id) && !oldIds.includes(id))newIds.push(id)
  })

  regex = new RegExp(templates.termSuggestion.regex,'g');

  match = initialValue.match(regex)
  match && match.forEach(m=>{
    let id = m.match(templates.termSuggestion.regex)[4]
    if(!oldTermIds.includes(id))oldTermIds.push(id)
  })

  match = newValue.match(regex)
  match && match.forEach(m=>{
    let id = m.match(templates.termSuggestion.regex)[4]
    if(!newTermIds.includes(id) && !oldTermIds.includes(id))newTermIds.push(id)
  })

  await onLinkCI(newIds.filter(el=>el!==item.id))
  await onLinkCI(newTermIds.filter(el=>el!==item.id),true)

  onUpdate && onUpdate();
  window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
  // window.postMessage({reload_related_terms:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
}

export const mockTagTermReponse = ({
  collection_id,
  term,
  collection_name,
  id,
  description,
  name,
  collection_txt,
  parent_name
}) => {
  return {
    collection_id,
    term,
    collection_name,
    id,
    description,
    name,
    collection_txt,
    parent_name
  }
}

export const clearAllPatterns = (txt) => {
  let regex = new RegExp(/\$\$widget[^$$]+\$\$/,'g');
  txt = txt.replace(regex,'')
  return txt;
}

export const removeMarkDown = (txt) => {
  if(!txt)return '';
  return removeMD(txt.replace(linkCIReplacementRegex,'$1').replace(suggestionCIReplacementRegex,'$2'))
}
