import React, { useEffect, useReducer, useRef, useState } from 'react';
import { withStyles, Button } from '@material-ui/core';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/Cart/Body/Body';
import 'url-search-params-polyfill';
import axiosCerebrum from '../../axios-cerebrum';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import { LinearProgress, Typography } from '@material-ui/core';
import { sendMessage, tabGroup } from '../../utilities';
import { addHistory } from '../../HistoryManager';
import { globalListenerRef } from '../../GlobalListenerRef';
import SnackBar from '../../components/UI/SnackBar/SnackBar';
import useAlert from '../../hooks/useAlert';

const styles = theme => ({
});

const initialState = {
  selectedType:undefined,
  cartData:{}
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_selected_type':
      return {
        ...state,
        selectedType:action.selectedType
      }
    case 'set_tabs':
      return {
        ...state,
        tabs:action.tabs,
        tabsLoading:action.tabsLoading,
        tabsError:action.tabsError
      }
    case 'set_cart_data':
      return {
        ...state,
        cartData:action.cartData
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const Cart = props => {

  const {
    history,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarButtons, setSnackbarButtons] = useState([]);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  const loadCount = () => {
    dispatch({type:'set_tabs',tabsLoading:true})
    axiosCerebrum
      .get(
        `/api/me/cart/stats`
      )
      .then(response=>{
        let tabs = response.data.counts.map(el=>({
          objectType:Object.keys(el)[0],
          count:el[Object.keys(el)[0]]
        }))

        if(!state.selectedType || !tabs.map(el=>el.objectType).includes(state.selectedType)){
          let type;
          tabGroup.forEach(g=>{
            g.tabs.forEach(t=>{
              if(tabs.map(el=>el.objectType).includes(t) && !type){
                type = t
              }
            })
          })
          dispatch({type:'set_selected_type',selectedType:type})
        }
        dispatch({type:'set_cart_data',cartData:{}})
        dispatch({type:'set_tabs',tabs})
      })
      .catch(error=>{
        dispatch({type:'set_tabs',tabsError:true})
        console.log(error)
      })
  }

  useEffect(()=>{
    if(!state.tabs){
      loadCount()
    }
    addHistory({url:window.location.pathname, iconLabel:'cart', title: 'Data Cart', subTitle:'Data Cart',type:'application'})

    const onMsgReceived = (msg) => {
      if(msg.data.clear_cart_items ){
        let ids = msg.data.ids;
        setSnackbarMessage(`Action completed for ${ids.length} item(s)`);

        const removeItems = ()=>{
          // delete cart items
          // divide ids into chunks of 100
          let chunks = [];
          let chunkSize = 100;
          for (let i=0; i<ids.length; i+=chunkSize) {
            chunks.push(ids.slice(i,i+chunkSize));
          }
          let promises = [];
          chunks.forEach(chunk=>{
            promises.push(
              axiosCerebrum
                .delete(
                  `/api/me/cart?object_ids=${chunk.join(',')}`
                )
            )
          })
          Promise.all(promises)
            .then(response=>{
              sendAlert({message:"Items successfully removed from cart",type:'info'})
              loadCount()
              sendMessage({reloadCart:true})
            })
            .catch(error=>{
              console.log(error)
              sendAlert({message:"Error occurred removing items",type:'error'})
            })
        }

        setSnackbarButtons([
          <Button style={{color: 'var(--color-base-100)'}} onClick={()=>{setSnackbarOpen(false);removeItems()}}>CLEAR FROM CART</Button>
        ]);

        setSnackbarOpen(true);
      }
    }

    window.removeEventListener('message',globalListenerRef.clearCartListener);
    globalListenerRef.clearCartListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.clearCartListener);
    // return (()=>{window.removeEventListener('message',globalListenerRef.clearCartListener);})
     // eslint-disable-next-line
  },[])



  if (state.tabsLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.tabsError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.tabs)return <div></div>

  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              label='cart'
              title='Data Cart'
              subtitle=' '
            />
          </div>)}
        body={
          <Body
            state={state}
            history={history}
            dispatch={dispatch}
            sessionData={sessionData}
          />
        }
      />
      <SnackBar
        snackBarOpen={snackbarOpen}
        setSnackBarOpen={setSnackbarOpen}
        message={snackbarMessage}
        buttons={snackbarButtons}
      />
    </div>
  )
}

export default withStyles(styles)(Cart);
