import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Modal, Paper, CircularProgress, Typography, Stepper, Step, StepLabel, Button, InputBase} from '@material-ui/core';
import PropTypes from 'prop-types';
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import Scheduler from '../../UI/Scheduler/Scheduler';
import AddContactInput from '../../UI/AddContactInput/AddContactInput';
import axiosCerebrum from '../../../axios-cerebrum';
import { generateCronByConfig } from '../../UI/Scheduler/utils';
import moment from 'moment';
import SnackBar from '../../UI/SnackBar/SnackBar';

const styles = theme => ({
  title: {
    fontSize: 20,
    fontWeight: '500',
    color:theme.palette.header.main,
    marginBottom:24,
  },
  stepper:{
    paddingTop:0,
    paddingLeft:0,
    paddingRight:24,
    backgroundColor:theme.palette.background.main,
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    marginBottom:8,
    letterSpacing:1
  },
  inputBase:{
    ...theme.components.inputBase,
    height:56,
    width:"100%"
  },
  selector:{
    ...theme.components.selector,
  },
  block:{
    marginBottom:24
  }
})

function AddAlertModal(props) {

  const {
    theme,
    classes,
    modalOpen,
    setModalOpen,
    history,
  } = props;

  const [step, setStep] = useState(0)
  const [alertOpen, setAlertOpen] = useState();
  const [alertType, setAlertType] = useState('error')
  const [alertMessage, setAlertMessage] = useState('')
  const [saving, setSaving] = useState(false)

  const [frequency, setFrequency] = useState('none')
  const [loadDay, setLoadDay] = useState(-1)
  const [loadTime, setLoadTime] = useState(moment())
  const [customCron, setCustomCron] = useState('')

  const [alertName, setAlertName] = useState('')

  const [contactType, setContactType] = useState('user')
  const [contacts, setContacts] = useState([])


  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarButton, setSnackBarButton] = useState([]);


  const isCancelledRef = useRef(false);

  let selectedFilter = modalOpen?.filter;

  useEffect(()=>{
    if(!modalOpen){
      setStep(0)
      setFrequency('none')
      setLoadDay(-1)
      setLoadTime(moment())
      setCustomCron('')
      setAlertName('')
      setContacts([])
    }
  },[modalOpen])

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])

  const checkClickable = step => {
    if(step===0){
      if(alertName.trim()==='')return false;
      if(frequency==='none')return false;
      if(frequency==='daily')return true;
      if(frequency==='weekly' && loadDay===-1)return false;
      if(frequency==='monthly' && loadDay===-1)return false;
      if(frequency==='custom' && !customCron)return false;
      return true;
    }
    if(step===1){
      return contacts.length>0 && checkClickable(0)
    }
  }

  const onSave = () => {
    setSaving(true)
    let payload = {
      "name": alertName,
      "description": "",
      "filtered_list_id": selectedFilter.id,
      "alert_channel": "EMAIL",
      "alert_channel_args": {
          "recipients": contacts.map(contact=>contact.email_srt)
      },
      "schedule": generateCronByConfig({frequency, customCron, loadTime, loadDay})
    }
    axiosCerebrum
      .post(
        `/api/alerts`,payload
      )
      .then(response=>{
        // sendAlert({type:"info",message:"Alert created successfully"})

        // setKeepSnackBarOpen(false)
        let msg = `Alert created successfully`
        setSnackBarMessage(msg)
        setSnackBarButton(<Button style={{color: 'var(--color-base-100)'}} onClick={()=>history.push('/my_alerts')}>GO TO MY ALERT</Button>)
        setSnackBarOpen(true)
        setModalOpen(false)
        setSaving(false)
      })
      .catch(error=>{
        console.log(error)
        setAlertMessage('Error occurred creating alert')
        setAlertOpen(true)
        setAlertType('error')
        setSaving(false)
      })
  }

  const onAddEmail = email => {
    let emailObj = {
      id:email,
      name_srt:email,
      email_srt:email,
      object_type_srt:'email'
    }
    if(contacts.find(item=>item.id===email)){
      setAlertType('info')
      setAlertMessage('Recipient already added')
      setAlertOpen(true)
      return;
    }
    setContacts([...contacts,emailObj])
  }

  const onAddUser = user => {
    if(contacts.find(item=>item.id===user.id))return;
    axiosCerebrum
      .get(
        `/api/users/${user.id}`
      )
      .then(response=>{
        if(!response.data || !response.data.email){
          setAlertType('error')
          setAlertMessage('User email not found')
          setAlertOpen(true)
        }else{
          if(contacts.find(c=>c.email_srt===response.data.email)){
            setAlertType('info')
            setAlertMessage('Recipient already added')
            setAlertOpen(true)
          }else{
            setContacts([...contacts,{...user, email_srt:response.data.email}])
          }
        }
      })
      .catch(error=>{
        console.log(error)
        setAlertType('error')
        setAlertMessage('User email not found')
        setAlertOpen(true)
      })
  }

  const onRemoveUser = item => {
    setContacts(contacts.filter(user=>user.id!==item.id))
  }

  const getStepContent = (index) => {
    if(index===0){
      return (
        <div>
          <div className={classes.block}>
            <Typography className={classes.sectionHeader}>ALERT NAME</Typography>
            <InputBase
              className={classes.inputBase}
              placeholder='Enter alert name'
              data-test-id="add-alert-name-input"
              value={alertName}
              autoFocus
              onChange={(event)=>setAlertName(event.target.value)}
            />
          </div>
          <Scheduler
            frequency={frequency}
            setFrequency={setFrequency}
            loadDay={loadDay}
            setLoadDay={setLoadDay}
            loadTime={loadTime}
            setLoadTime={setLoadTime}
            customCron={customCron}
            setCustomCron={setCustomCron}
            customInputTitle={{
              'load_day':'ALERT DAY',
              'load_time':'ALERT TRIGGER TIME'
            }}
          />
        </div>
      )
    }
    if(index===1){
      return (
        <div>
          <AddContactInput
            type={contactType}
            setType={setContactType}
            onAddUser={onAddUser}
            onAddEmail={onAddEmail}
            onRemoveUser={onRemoveUser}
            users={contacts}
          />
        </div>
      )
    }
  }

  return (
    <>
      {
        selectedFilter &&
        <Modal
          open={modalOpen}
          onClose={()=>setModalOpen(false)}
          disableBackdropClick={true}
          style={{display:'flex',alignItems:'center',justifyContent:'center'}}
        >
        <div style={{outline:'none',width:'max-content'}}>
          <Paper style={{width:720,padding:24,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
            <div style={{display:'flex',alignItems:'center'}}>
              <Typography className={classes.title}>Create alert from {selectedFilter.name} filter</Typography>
            </div>
            <Stepper activeStep={step}  className={classes.stepper}  alternativeLabel>
              {['Add alert details','Add alert recipients'].map((label, index) => (
                <Step key={index} className={classes.step}>
                  <StepLabel>
                    <Typography color='primary' style={{color:step<index?theme.palette.primaryText.light:theme.palette.primary.main,fontSize:16,marginLeft:6}}>
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {
              getStepContent(step)
            }
            <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:-12,marginTop:24}}>
              {
                step===1 &&
                <Button color='primary' data-test-id="add-alert-back-button" style={{marginRight:8}} disabled={saving} onClick={()=>{setStep(0)}}>
                  BACK
                </Button>
              }
              {
                saving &&
                <div style={{width:64,marginRight:8,textAlign:'center'}}>
                  <CircularProgress color='secondary' style={{width:24,height:24}}/>
                </div>
              }
              {
                !saving &&
                <Button
                  color='primary'
                  data-test-id="add-alert-next-button"
                  disabled={!checkClickable(step)}
                  style={{marginRight:8}}
                  onClick={()=>{
                    if(step===0)setStep(1)
                    else{onSave()}
                  }}
                >
                  {step===0?'NEXT':'SAVE'}
                </Button>
              }
              <Button
                color='secondary'
                onClick={()=>{
                  setModalOpen(false)
                }}
              >
                CANCEL
              </Button>
            </div>
          </Paper>
          <ModalAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            message={alertMessage}
            type={alertType}
          />
        </div>
      </Modal>
    }
    <SnackBar
      snackBarOpen={snackBarOpen}
      // keepOpen={keepSnackbarOpen}
      setSnackBarOpen={setSnackBarOpen}
      message={snackBarMessage}
      buttons={snackBarButton}
    />
  </>
  )
}

AddAlertModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
}

export default withTheme()(withStyles(styles)(AddAlertModal));
