import React, { } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles} from '@material-ui/core';
import GroupNode from '../../ReactFlowWrapper/GroupNode';
import theme from '../../../../theme';
import LineageNodeV3 from '../../ReactFlowWrapper/LineageNodeV3';


const styles = theme => ({
})

function Diagram(props) {
  const {
    source,
    sourceParent,
    target,
    targetParent,
    paddingRight
  } = props;

  if(!source || !sourceParent || !target || !targetParent)return <></>

  return (
    <div style={{paddingRight}}>
      <div style={{height:175,marginTop:16,width:'100%',background:'transparent',position:'relative',zIndex:30,}}></div>

      <div style={{display:'flex',overflow:'hiddne',alignItems:'flex-start',justifyContent:'center',height:175,marginTop:-175,transform:'scale(0.75)'}}>
        <div style={{flex:'1 1 30%',display:'flex',justifyContent:'flex-end'}}>
          <div>
            <GroupNode data={{...sourceParent.data,height:100,width:264}} viewOnly={true}/>
            <div style={{zIndex:1,position:'relative', top:-45,right:-2}}>
              <LineageNodeV3 data={{...source.data,tags:[],width:260}} viewOnly={true}/>
            </div>
          </div>
        </div>
        <svg width={200} height={100} style={{marginLeft:8,marginRight:8}}>
          <line x1="10" y1="50" x2="180" y2="50" stroke={theme.palette.primary.main} stroke-width="2"/>
          <polygon points="180,45 190,50 180,55" fill={theme.palette.primary.main}/>
        </svg>
        <div style={{flex:'1 1 30%',marginLeft:5}}>
          <GroupNode data={{...targetParent.data,height:100,width:264}} viewOnly={true}/>
          <div style={{zIndex:1,position:'relative', top:-45,right:-2}}>
            <LineageNodeV3 data={{...target.data,tags:[],width:260,bodyActions:[]}} viewOnly={true}/>
          </div>
        </div>
      </div>
    </div>
  )
}

Diagram.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(Diagram));
