import React, { useState,  useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core';
import {withRouter} from 'react-router'
import '@toast-ui/editor/dist/toastui-editor-viewer';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
import { Editor as MDEditor } from '@toast-ui/react-editor';
import { cleanUpMountedEls, clearAllPatterns, getAllRules } from './Templates';
import { isInViewport } from '../../../utilities';
import { globalListenerRef } from '../../../GlobalListenerRef';
import TextMenu from '../ContextMenu/TextMenu';


const styles = theme => ({
  mdViewerWrapper:{
    overflow:'auto',
    // marginTop:-10,
    ...theme.components.customScroll,
    ...theme.components.MDViewer
  }
})

function InteractiveViewer(props) {

  const {
    classes,
    initialValue,
    object,
    id,
    scrollElID,
    alwaysShown,
    maxHeight,
    topOffset = 0,
    enableCustomContextMenu
  } = props;

  const containerRef = useRef();
  const MDRef = useRef()
  const [shouldRender, setShouldRender] = useState(false);
  const [elHeight, setElHeight] = useState(0)

  const [contextMenuOpen, setContextMenuOpen] = useState(false)

  let elementID = `md-input-viewer-${`${id}`.trim().toLowerCase().replace(/\s/g,'-')}`

  useEffect(()=>{
    bindActions()
    if(alwaysShown)return;
    if(isInViewport(containerRef)){
      setShouldRender(true)
    }
  // eslint-disable-next-line
  },[containerRef.current])

  useEffect(()=>{
    if(alwaysShown)return;
    if(shouldRender)setElHeight(containerRef.current.getBoundingClientRect().height)
  // eslint-disable-next-line
  },[shouldRender])

  const bindActions = () => {
    let root = document.getElementById(elementID);
    if(!root)return;
    let links = root.getElementsByTagName('a')
    for(let i=0; i<links.length; i++){
      links[i].onclick = event => {
        window.open(event.currentTarget.href,'_blank')
      }
    }
  }

  useEffect(()=>{
    if(alwaysShown)return;
    let el;
    if(scrollElID)el = document.getElementById(scrollElID);
    if(!el)el = window;
    el.removeEventListener('scroll',globalListenerRef[`viewerRenderListener_${id}`])
    globalListenerRef[`viewerRenderListener_${id}`] = () => {
      const bottom = containerRef.current.getBoundingClientRect().bottom;
      const top = containerRef.current.getBoundingClientRect().top;
      if((bottom <= window.innerHeight && bottom>=0) || (top>=0 && top<=window.innerHeight) || (top>=window.innerHeight && bottom<=0)){
        setShouldRender(true)
      }else{
        setShouldRender(false)
      }
    }
    el.addEventListener('scroll',globalListenerRef[`viewerRenderListener_${id}`])

    return ()=>{
      cleanUpMountedEls()
      if(alwaysShown)return;
      el.removeEventListener('scroll',globalListenerRef[`viewerRenderListener_${id}`])
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!contextMenuOpen && MDRef.current && MDRef.current.getSelectedText()){
      MDRef.current.setSelection(0,0)
    }
  },[contextMenuOpen])

  return (
    <div
      onMouseUp={(event)=>{
        if(!enableCustomContextMenu)return;
        event.preventDefault();
        if(!MDRef.current || !MDRef.current.getSelectedText() || contextMenuOpen)return;
        setContextMenuOpen({x:event.clientX - 20, y:event.clientY+20})
      }}
      data-test-id={elementID}
      id={elementID}
      className={classes.mdViewerWrapper}
      style={{minHeight:elHeight, maxHeight, marginTop:topOffset, width:'100%',flexGrow:0}}
      ref={containerRef}
    >
      {
        (shouldRender || alwaysShown) &&
        <MDEditor
          autofocus={false}
          onLoad={instance => {MDRef.current = instance}}
          initialValue={initialValue}
          theme={localStorage.hasOwnProperty('dark')?"dark":"white"}
          hideModeSwitch
          initialEditType="wysiwyg"
          usageStatistics={false}
          toolbarItems={[]}
          height={'auto'}
          widgetRules={getAllRules()}
          onKeydown={(type, event)=>{
            event.preventDefault()
            event.stopPropagation()
          }}
        />
      }
      {
        contextMenuOpen &&
        <TextMenu
          object={object}
          open={contextMenuOpen}
          setOpen={setContextMenuOpen}
          anchor={contextMenuOpen}
          text={MDRef.current?clearAllPatterns(MDRef.current.getSelectedText().trim()):''}
        />
      }
    </div>
  )
}


InteractiveViewer.propTypes = {
  initialValue:PropTypes.string.isRequired,
  id:PropTypes.any.isRequired,
  scrollElID:PropTypes.string,
  alwaysShown:PropTypes.bool,
  maxHeight:PropTypes.number,
  topOffset:PropTypes.number,
  enableCustomContextMenu:PropTypes.bool
}

export default withTheme()(withStyles(styles)(withRouter(InteractiveViewer)));
