import React from 'react';
import { KadaBtn, kButtonVariants } from 'kada-component-library';

const DataTile = props => {

  const {
    onClick,
    selected,
    clickable,
    title,
    tooltip,
  } = props;

  return (
    <div className={tooltip?"tooltip":''} data-tip={tooltip||''}>
      <KadaBtn
        text={title}
        onClick={clickable ? onClick : undefined}
        disabled={!clickable}
        size="s"
        variant={ selected ? kButtonVariants.primarySelected : kButtonVariants.primaryOutlined }
      />
    </div>
  )
}

export default DataTile;
