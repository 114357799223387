import React, { useEffect } from 'react';
import { withTheme, withStyles, CircularProgress, Typography } from '@material-ui/core';
import DataTile from './DataTile';
import axiosSolr from '../../../axios-solr';
import { contentItems, dataItems, userItems } from '../utils';
import axiosCerebrum from '../../../axios-cerebrum';
import { formatNumber, mapObjectName, tabGroup, titleCaseObjectName, toTitleCase } from '../../../utilities';

const styles = theme => ({
  dataTile:{
    width:240,
    marginRight:40,

  },
  selectedDataTile:{

  },
  tileTitle:{
    color:theme.palette.header.main,
    fontSize:12,
    letterSpacing:2,
    marginBottom:16
  },
  tileNumber:{
    margin:'0 auto',
    fontSize:24,
    width:'max-content',
    color:theme.palette.primary.main
  },
  tileFooter:{
    margin:'8px auto 0',
    width:'max-content',
    color:theme.palette.primaryText.main,
    fontSize:13.75,
  }
})

const ImpactSummary = props => {

  const {
    theme,
    state,
    dispatch,
  } = props;

  const loadSummaryData = async ({extractId, showReference=state.showReference}) => {
    if(!state.summaryData)dispatch({type:'set_summary_data',summaryLoading:true})
    try{
      let dataCount, dataTypes;
      await axiosSolr.get(
        `/solr/impact/select`,
        {params:{
          q:'*:*',
          rows:0,
          fq:`object_type_srt:(${dataItems.join(' OR ')})
              AND extract_id:${extractId} ${showReference?'':'AND -reference_srt:YES'}` +
              (state.historyObject?` AND starting_object_id:${state.historyObject.id}`:' AND -starting_object_id:*'),
          'json.facet':{
            'object_types':{
              type:'terms',
              field:'object_type_srt',
              limit:20,
            }
          }
        }}
      ).then(response=>{
        dataCount = response.data.response.numFound;
        dataTypes = response.data.facets?.object_types?.buckets || []
        let order = [...tabGroup.find(el=>el.name==='DATA').tabs,...tabGroup.find(el=>el.name==='CODE').tabs]
        dataTypes.sort((a,b)=>order.indexOf(a.val.toUpperCase())-order.indexOf(b.val.toUpperCase()))
        // dataCount = dataRes.data.grouped.object_id.ngroups;
      })

      let contentCount, contentTypes;
      await axiosSolr.get(
        `/solr/impact/select`,
        {params:{
          q:'*:*',
          rows:0,
          fq:`object_type_srt:(${contentItems.join(' OR ')}) AND
              extract_id:${extractId} ${showReference?'':'AND -reference_srt:YES'}` +
              (state.historyObject?` AND starting_object_id:${state.historyObject.id}`:' AND -starting_object_id:*'),
          'json.facet':{
            'object_types':{
              type:'terms',
              field:'object_type_srt',
              limit:20,
            }
          }
        }}
      ).then(response=>{
        contentCount = response.data.response.numFound
        contentTypes = response.data.facets?.object_types?.buckets || []
        let order = tabGroup.find(el=>el.name==='CONTENT').tabs
        contentTypes.sort((a,b)=>order.indexOf(a.val.toUpperCase())-order.indexOf(b.val.toUpperCase()))
      })


      let userCount, userTypes;
      await axiosSolr.get(
        `/solr/impact/select`,
        {params:{
          q:'*:*',
          rows:0,
          fq:`object_type_srt:(${userItems.join(' OR ')}) AND extract_id:${extractId}` +
          (state.historyObject?` AND starting_object_id:${state.historyObject.id}`:' AND -starting_object_id:*'),
          'json.facet':{
            'user_types':{
              type:'terms',
              field:'user_type_srt',
              limit:20,
            }
          }
        }}
      ).then(response=>{
        userCount = response.data.response.numFound;
        userTypes = response.data.facets?.user_types?.buckets || []
      })

      let summaryData = {
        all:{count:dataCount+contentCount, name:'All'},
        data:{count:dataCount, name:'Data', objectTypes: dataTypes},
        content:{count:contentCount, name:'Content', objectTypes: contentTypes},
        people:{count:userCount, name:'User', objectTypes: userTypes},
      }

      dispatch({type:'set_result_data_object_type',resultDataObjectType:dataTypes[0]?.val})
      dispatch({type:'set_result_content_object_type',resultContentObjectType:contentTypes[0]?.val})
      dispatch({type:'set_result_user_type',resultUserType:userTypes[0]?.val})

      let collectionIDs = state.extractData.args.filters.collection_ids;

      if(!collectionIDs || collectionIDs.length===0){
        dispatch({
          type:'set_summary_data',
          summaryData:summaryData
        })
        return;
      }

      let collectionData = [];

      for(let i=0; i<collectionIDs.length; i++){
        let id = collectionIDs[i];
        await axiosCerebrum
          .get(
            `/api/collections/${id}`
          )
          .then(response=>{
            collectionData.push(response.data)
          })
      }

      await axiosSolr.get(
        `/solr/search/select`,
        {params:{
          q:'*:*',
          rows:collectionIDs.length,
          fq:`id:(${collectionIDs.join(' OR ')})`,
        }}
      ).then(response=>{

        for(let i=0; i<collectionData.length; i++){
          collectionData[i] = {
            ...collectionData[i],
            ...(response.data.response.docs.find(r=>r.id.toLowerCase()===collectionData[i].id.toLowerCase())||{})
          }
        }

        collectionData.forEach(c=>{
          c = {
            ...c,
            ...(response.data.response.docs.find(r=>r.id===c.id)||{})
          }
        })
      })

      dispatch({
        type:'set_extract_collection_details',
        extractCollectionDetails:collectionData
      })

      for(let i=0; i<collectionData.length; i++){
        let c = collectionData[i]
        await axiosSolr.get(
          `/solr/impact/select`,
          {params:{
            q:'*:*',
            rows:0,
            fq:`object_type_srt:(COLLECTION_INSTANCE) AND -object_id:("other ${c.name.toLowerCase()}" OR "no ${c.name.toLowerCase()}") AND extract_id:${extractId} AND parent_id_srt:${c.id}` +
            (state.historyObject?` AND starting_object_id:${state.historyObject.id}`:' AND -starting_object_id:*'),
          }}
        // eslint-disable-next-line
        ).then(response=>{
          summaryData[collectionData[i].id] = {
            count:response.data.response.numFound,
            name:collectionData[i].name,
          }
        })
      }

      // set the first tile that has value > 0 as default
      let firstTile = Object.keys(summaryData).find(k=>summaryData[k].count>0 && (state.multiHistoryObjects || k!=='all'))
      if(firstTile)dispatch({type:'set_selected_dashboard',selectedDashboard:firstTile})

      dispatch({
        type:'set_summary_data',
        summaryData:summaryData
      })

    }catch(error){
      console.log(error)
      dispatch({
        type:'set_summary_data',
        summaryError:true
      })
    }

  }

  useEffect(()=>{
    if(!state.summaryData && !state.summaryLoading)loadSummaryData({extractId:state.extractData.id})
     // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(state.summaryData){
      loadSummaryData({extractId:state.extractData.id,showReference:state.showReference})
      dispatch({type:'set_detail_data',detailData:{}})
    }
    // eslint-disable-next-line
  },[state.showReference])

  if(state.summaryLoading)return (
    <div style={{display:'flex',justifyContent:'center'}}>
      <CircularProgress color='secondary'/>
    </div>
  )

  if(state.summaryError)return <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading summary</Typography>

  if(!state.summaryData)return <div></div>

  const getCollectionTileData = () => {
    let keys = Object.keys(state.summaryData).filter(k=>!['data','content','people','all'].includes(k))
    return keys
  }

  return (
    <div>
      <div className={'flex items-center gap-4'}>
        {
          state.multiHistoryObjects && !state.historyObject &&
          <DataTile
            clickable={true}
            onClick={()=>{dispatch({type:'set_selected_dashboard',selectedDashboard:'all'})}}
            selected={state.selectedDashboard==='all'}
            title={'All'}
          />
        }
        <DataTile
          clickable={state.summaryData.data.count>0}
          onClick={()=>{dispatch({type:'set_selected_dashboard',selectedDashboard:'data'})}}
          selected={state.selectedDashboard==='data'}
          title={'Impacted Data'}
        />
        <DataTile
          clickable={state.summaryData.content.count>0}
          onClick={()=>{dispatch({type:'set_selected_dashboard',selectedDashboard:'content'})}}
          selected={state.selectedDashboard==='content'}
          title={'Impacted Content'}
        />
        <DataTile
          clickable={state.summaryData.people.count>0}
          onClick={()=>{dispatch({type:'set_selected_dashboard',selectedDashboard:'people'})}}
          selected={state.selectedDashboard==='people'}
          title='Impacted People'
        />
        {
          getCollectionTileData(state.summaryData).map(k=>(
            <DataTile
              clickable={state.summaryData[k].count>0}
              onClick={()=>{dispatch({type:'set_selected_dashboard',selectedDashboard:k})}}
              selected={state.selectedDashboard===k}
              title={state.summaryData[k].name}
            />
          ))
        }
      </div>
      {
        ['content','data','people'].includes(state.selectedDashboard) &&
        state.summaryData[state.selectedDashboard].objectTypes?.length>0 &&
        <div
          className={'flex items-center gap-2'}
          style={{marginTop:12, padding:'6px 0', background:'var(--color-base-200',border:`1px solid var(--color-base-300)`,borderLeft:'none',borderRight:'none'}}
        >
          {/* <p>IMPACT BY:</p> */}
          {
            state.summaryData[state.selectedDashboard].objectTypes.map(d=>{
              let selected;
              if(['content','data'].includes(state.selectedDashboard)){
                selected = state[`result${toTitleCase(state.selectedDashboard)}ObjectType`]===d.val
              }else{
                selected = state.resultUserType===d.val
              }
              return (
                <DataTile
                  clickable={d.count>0}
                  onClick={()=>{
                    if(['content','data'].includes(state.selectedDashboard)){
                      dispatch({type:`set_result_${state.selectedDashboard}_object_type`,[`result${toTitleCase(state.selectedDashboard)}ObjectType`]:d.val})
                    }else{
                      dispatch({type:'set_result_user_type',resultUserType:d.val})
                    }
                  }}
                  selected={selected}
                  title={titleCaseObjectName(mapObjectName(d.val)) + `s (${formatNumber(d.count)})`}
                />
              )
            })
          }
          {/* <KadaFilter
            options={state.summaryData[state.selectedDashboard].objectTypes.map(d=>({
              text:titleCaseObjectName(mapObjectName(d.val)) + `s (${formatNumber(d.count)})`,
              value:d.val
            }))}
            valueDisplayPrefix='Object Type: '
            isNativeSearch
            isHidePlaceholderMenuItem
            value={
              state.selectedDashboard==='data'
                ?state.resultDataObjectType
                :state.selectedDashboard==='content'
                  ?state.resultContentObjectType
                  :state.resultUserType
            }
            onChange={event=>{
              if(['content','data'].includes(state.selectedDashboard)){
                dispatch({type:`set_result_${state.selectedDashboard}_object_type`,[`result${toTitleCase(state.selectedDashboard)}ObjectType`]:event.detail.value})
              }else{
                dispatch({type:'set_result_user_type',resultUserType:event.detail.value})
              }
            }}
          /> */}
        </div>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(ImpactSummary));
