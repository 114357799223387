import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum';
import { KadaBadge, KadaBtn, KadaIconBtn, KadaLoadingSpinner, kBadgeVariants, kButtonVariants, kLoadingSpinnerVariants } from 'kada-component-library';
import { onClickResultItem, toTitleCase, trimText } from '../../../utilities';
import useGetWorkflowStatus from '../../../hooks/useGetWorkflowStatus';
import { globalListenerRef } from '../../../GlobalListenerRef';
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import { onLinkTermSuggestion } from './utils';
import { getIconLabel } from '../SearchResults/utils';
import axiosSolr from '../../../axios-solr';
import KTooltip from '../KTooltip/KTooltip';
import UserChip from '../Chips/UserChip';

function GlossaryDetailPopper(props) {
  const {
    termId,
    isSuggestion,
    forceOpen,
    forcePos,
    disabledListener
  } = props;

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const holderRef = useRef(null);
  const [data, setData] = useState({})
  const hideTimeoutRef = useRef(null);

  const {
    data: statusData
  } = useGetWorkflowStatus({ objectType: 'COLLECTION_INSTANCE' });

  const loadData = (id) => {
    setData({loading:true})
    axiosCerebrum
      .get(
        `/api/collectioninstances/${id}`
      )
      .then(response=>{
        let obj = response.data;
        axiosSolr
          .get(
            `/solr/search/select`,{params:{
              q:"*",
              fq:`name_srt:"${obj.name}" AND collection_type_srt:GLOSSARY AND object_type_srt:COLLECTION_INSTANCE`,
              fl:'*',
              rows: 10
            }}
          )
          .then(response=>{
            obj = {
              ...obj,
              ...(response.data.response.docs.find(s=>s.id===obj.id)||{})
            }
            setData({data:[obj, ...response.data.response.docs.filter(s=>s.id!==obj.id)]})
          })
          .catch(error=>{
            console.log(error)
            setData({data:[obj]})
          })
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })
  }

  useEffect(()=>{
    if((visible || forceOpen) && termId){
      loadData(termId)
      setVisible(true)
      clearTimeout(hideTimeoutRef.current);
    }
  // eslint-disable-next-line
  },[termId])

  useEffect(()=>{
    if(forceOpen){
      setVisible(true)
    }else{
      hideTimeoutRef.current = setTimeout(() => {
        setVisible(false);
      }, 600);
    }
  },[forceOpen])

  useEffect(()=>{
    if(forcePos){
      setPosition(forcePos)
    }
  },[forcePos])

  useEffect(() => {
    if(disabledListener)return;
    window.removeEventListener('glossaryDetailPop', globalListenerRef[`glossaryDetailPop_${termId}`]);
    globalListenerRef[`glossaryDetailPop_${termId}`] = (event) => {
      const { detail } = event;
      if (detail.id === termId) {
        if(visible)return;
        let y = detail.y - 24;
        setPosition({ x: detail.x - 24, y });
        setVisible(true);
        loadData(termId)
        hideTimeoutRef.current = setTimeout(() => {
          setVisible(false);
        }, 1000);
      } else {
        setVisible(false);
      }
    };

    window.addEventListener('glossaryDetailPop', globalListenerRef[`glossaryDetailPop_${termId}`]);

    return () => {
      window.removeEventListener('glossaryDetailPop', globalListenerRef[`glossaryDetailPop_${termId}`]);
    };
  // eslint-disable-next-line
  }, [termId, visible, disabledListener]);

  const getPropertyContent = ({data, property}) => {

    if(!data.properties[property.id]){
      return property.data_type === 'BOOLEAN' ? 'false' : 'N/A';
    }

    if(['USER_LOOKUP_FILTERED','USER_LOOKUP'].includes(property.data_type)){
      return (
        <UserChip
          userId={data.properties[property.id]}
          onClick={()=>onClickResultItem({label:'user',id:data.properties[property.id],newWindow:true})}
        />
      )
    }
    if(['USER_LOOKUP_FILTERED_MULTI','USER_LOOKUP_MULTI'].includes(property.data_type)){
      return (
        <div className='flex flex-wrap items-center gap-2'>
          {
            data.properties[property.id].map(el=>(
              <UserChip
                userId={el}
                onClick={()=>onClickResultItem({label:'user',id:el,newWindow:true})}
              />
            ))
          }
        </div>
      )
    }
    return data.properties[property.id] + ''
  }

  return (
    <div
      id={disabledListener?null:`glossaryDetailPop_${termId}`}
      ref={holderRef}
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        display: visible ? 'block' : 'none',
        zIndex: 1000,
      }}
      onMouseOver={()=>{
        clearTimeout(hideTimeoutRef.current);
      }}
      onMouseLeave={() => {
        hideTimeoutRef.current = setTimeout(() => {
          setVisible(false);
        },300);
      }}
    >
      <div className="card w-180 max-w-[50vw] bg-base-100 border border-(--color-base-border) p-4">
        {
          data.loading &&
          <KadaLoadingSpinner variant={kLoadingSpinnerVariants.secondary} />
        }
        {
          data.error &&
          <p>Error occurred loading term detail</p>
        }
        {
          data.data?.[0] && !isSuggestion &&
          <>
            <div
              className="flex items-center mb-4"
            >
              <h3 className='flex-grow'>
                {data.data[0].name}
              </h3>
              {
                data.data[0].workflow_status_txt &&
                <KTooltip title={`Status is ${toTitleCase(data.data[0].workflow_status_txt)}`}>
                  <KadaBadge
                    text={toTitleCase(data.data[0].workflow_status_txt)}
                    variant={kBadgeVariants.outlined}
                    color={data.data[0].workflow_status_txt ? statusData.find(s => s.name.toUpperCase() === data.data[0].workflow_status_txt.toUpperCase())?.colour : undefined}
                  />
                </KTooltip>
              }
              <KadaIconBtn
                className='ml-2'
                iconName='open'
                iconColor='var(--color-light-text)'
                size={30}
                onClick={()=>{
                  onClickResultItem({
                    item: data.data[0],
                    id: data.data[0].id,
                    label: 'COLLECTION_INSTANCE',
                    newWindow: true
                  })
                }}
              />
            </div>
            <p className="mb-6 text-(--color-light-text)">
              {trimText(data.data[0].description || 'No description provided', 300)}
            </p>
            {
              data.data[0].parent?.properties?.slice(0,3).map(o=>(
                <>
                  <h4 className='tracking-widest mb-4 uppercase'>
                    {o.name}
                  </h4>
                  <p className="mb-6 text-(--color-light-text)" style={{lineHeight: 'normal'}}>
                    {
                      getPropertyContent({data:data.data[0], property:o})
                    }
                  </p>
                </>
              ))
            }
            {
              data.data[0].parent &&
              <>
                <h4 className='tracking-widest mb-4 uppercase'>
                  {data.data[0].parent.category}
                </h4>
                <KadaBtn
                  text={data.data[0].parent.name}
                  variant={kButtonVariants.primarySelected}
                  onClick={()=>{
                    onClickResultItem({
                      item: data.data[0].parent,
                      id: data.data[0].parent.id,
                      label: 'COLLECTION',
                      newWindow: true
                    })
                  }}
                />
              </>
            }
          </>
        }
        {
          data.data && isSuggestion &&
          <>
            <h4
              className='mb-4 tracking-widest'
            >
              {data.data.length} SUGGESTED TERM(S). CLICK TO LINK
            </h4>
            {
              data.data.map((el, index) => (
                <SimpleResultItem
                  item={el}
                  title={`${el.name_txt} (${el.collection_txt || el.parent_name_txt || 'Unknown Glossary'})`}
                  titleColour='var(--color-base-content)'
                  subTitle={el.description}
                  iconLabel={getIconLabel({ label: 'collection_instance', item: el})}
                  onClick={()=>{
                    let targetID = window.location.pathname.split('?')[0].split('/').pop()
                    onLinkTermSuggestion({term:el, targetID})
                    setVisible(false)
                  }}
                  showUnderline
                  tailObject={
                    el.workflow_status_txt &&
                    <KadaBadge
                      text={toTitleCase(el.workflow_status_txt)}
                      variant={kBadgeVariants.outlined}
                      color={el.workflow_status_txt ? statusData.find(s => s.name.toUpperCase() === el.workflow_status_txt.toUpperCase())?.colour : undefined}
                    />
                  }
                />
              ))
            }
          </>
        }
      </div>
    </div>
  );
}

GlossaryDetailPopper.propTypes = {
  popperId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default GlossaryDetailPopper;
