import React,{useEffect,useState, useRef} from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem,InputBase, Paper, Popper, MenuList,IconButton, ClickAwayListener,Checkbox   } from '@material-ui/core';
import VerticalTabBar from "../../UI/VerticalTabBar/VerticalTabBar";
import axiosSolr from '../../../axios-solr';
import SolrList from '../../UI/SolrList/SolrList';
import {getIconComponent, isInViewport,  mapObjectName, formatNumber, getNameSearchQuery, titleCaseObjectName} from '../../../utilities';
import * as actions from '../../../store/actions/index';
import { connect, useStore } from 'react-redux';
import { getCollectionObjectName } from '../../BasicSearch/MainSearch/Utils/Utils';
import CartAdder, { addToCartLimit } from '../../UI/CartAdder/CartAdder';
import { checkCanAddToCart, checkCanBulkUpdate } from '../../../permissionChecker';
import useAlert from '../../../hooks/useAlert';


const styles = theme => ({
  root:{
    display:'flex'
  },
  normaltext:{
    color:theme.palette.primaryText.main
  },
  title:{
    flexGrow:1,
    fontSize:20,
    color:theme.palette.header.main
  },
  selector:{
    ...theme.components.selector,
    width:160,
    marginRight:16,
  },
  nameFilter:{
    ...theme.components.inputBase,
    marginRight:24,
		width:160,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },

  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  checkbox:{
    paddingLeft:0
  }
})

const Content = props => {

  const {
    classes,
    history,
    theme,
    state,
    dispatch,
		setBulkEditPrams
  } = props;

  const loadingRef = useRef()
  const searchTimeoutRef = useRef();
	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);

  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])

	const [addToCartIds, setAddToCartIds] = useState()

  const loadData = ({tab=state.contentTabState,filter=state.contentFilter?.[state.contentTabState]||'trust_srt desc',search=state.searchFilter?.[state.contentTabState]||'',reload,reloadAll,showDeleted=state.showDeleted,showReference=state.showReference,addToCart}) => {
    let isCollection = state.contentTabData[tab].isCollection;
    let label = state.contentTabData[tab].name;
    let fq;
    if(isCollection){
      fq = `object_type_srt:COLLECTION_INSTANCE AND collection_srt:"${label}" AND source_id_srt:${state.basicData.source_id}` + (showDeleted?"":' AND active_srt:YES')
    }else{
      fq = `object_type_srt:${label} AND source_id_srt:${state.basicData.source_id} ${showDeleted?'':'AND active_srt:YES'} ${showReference?'':'AND reference_srt:NO'}`;
    }

    let url = `/solr/search/select`;
    let params = {
      rows:addToCart?addToCartLimit:10,
      start: addToCart?0:(reload||!state.contentData[tab]?0:state.contentData[tab].docs.length),
      q:getNameSearchQuery(search),
      fl:'*',
      fq,
      sort:search?undefined:filter,
      mm: '90%'
    }
    if(!addToCart){
      if(reload){
        dispatch({
          type:'set_content_data',
          contentData:{
            ...(reloadAll?{}:state.contentData),
            [tab]:undefined
          }
        })
      }
      dispatch({type:'set_content_error',contentError:{...state.contentError,[tab]:false}})
      dispatch({type:'set_content_loading',contentLoading:{...state.contentLoading,[tab]:true}})
    }
    axiosSolr
      .get(url, {params})
      .then(response=>{
        if(addToCart){
          setAddToCartIds(response.data.response.docs.map(d=>d.id))
          return;
        }
        dispatch({
          type:'set_content_data',
          contentData:{
            ...(reloadAll?{}:state.contentData),
            [tab]:
              reload||!state.contentData[tab]?
                {...response.data.response}:
                {...response.data.response,docs:[...state.contentData[tab].docs,...response.data.response.docs]}
          }
        })
        dispatch({type:'set_content_error',contentError:{...state.contentError,[tab]:false}})
        dispatch({type:'set_content_loading',contentLoading:{...state.contentLoading,[tab]:false}})
      })
      .catch(error=>{
        if(addToCart){
          sendAlert({message:'Error occurred adding items to Cart',type:'error'})
					return;
				}
        console.log(error)
        dispatch({type:'set_content_error',contentError:{...state.contentError,[tab]:true}})
        dispatch({type:'set_content_loading',contentLoading:{...state.contentLoading,[tab]:false}})
      })
  }

  useEffect(()=>{
    if(state.contentTabData && state.contentTabData.length>0 && !state.contentData[state.contentTabState] && state.contentTabData.length>0){
      loadData({tab:state.contentTabState,filter:state.contentFilter[state.contentTabState],search:state.searchFilter[state.contentTabState]})
    }
  // eslint-disable-next-line
  },[state.contentTabState, state.contentTabData])

  const shouldLoadMore = () => {
    return ((!state.contentLoading[state.contentTabState]) && isInViewport(loadingRef) && state.contentData[state.contentTabState] && state.contentData[state.contentTabState].docs.length<state.contentData[state.contentTabState].numFound)
  }

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadData({tab:state.contentTabState,filter:state.contentFilter[state.contentTabState],search:state.searchFilter[state.contentTabState]})
    }
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadData({tab:state.contentTabState,filter:state.contentFilter[state.contentTabState],search:state.searchFilter[state.contentTabState]})
    }
  // eslint-disable-next-line
  },[state.contentData, state.contentLoading])

  const onChangeFilter = value => {
    dispatch({
      type:'set_content_filter',
      contentFilter:{
        ...state.contentFilter,
        [state.contentTabState]:value
      }
    })
    loadData({tab:state.contentTabState,filter:value,search:state.searchFilter[state.contentTabState], reload:true})
  }

  const onClickShowReference = value => {
		setTimeout(()=>{
      dispatch({
        type:'set_show_reference',
        showReference:value
      })
      loadData({tab:state.contentTabState,filter:state.contentFilter[state.contentTabState], search:state.searchFilter[state.contentTabState],showReference:value, reload:true, reloadAll:true})
    })
	}

  const onClickShowDeleted = value => {
    setTimeout(()=>{
      dispatch({
        type:'set_show_deleted',
        showDeleted:value
      })
      loadData({tab:state.contentTabState,filter:state.contentFilter[state.contentTabState],showDeleted:value, search:state.searchFilter[state.contentTabState], reload:true, reloadAll:true})
    })

  }

  const onNameFilterChange = value => {
    dispatch({type:'set_search_filter',searchFilter:{...state.searchFilter,[state.contentTabState]:value}})
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      loadData({tab:state.contentTabState,reload:true,search:value,filter:state.contentFilter[state.contentTabState]})
    },250)
  }

  if(state.contentTabError)return <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading content</Typography>
  if(state.contentTabLoading)return <div style={{display:'flex',justifyContent:'center',marginTop:20}}><CircularProgress color='secondary'/></div>
  if(!state.contentTabData)return <Typography style={{color:theme.palette.primaryText.main}}>No content to be displayed</Typography>
  if(state.contentTabData.length===0)return <Typography style={{color:theme.palette.primaryText.main}}>No content to be displayed</Typography>


  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

	const onClickViewInSearch = () => {
    let isCollection = state.contentTabData[state.contentTabState].isCollection;
    let label = state.contentTabData[state.contentTabState].name;
    let fq;
    if(isCollection){
      fq = `object_type_srt:COLLECTION_INSTANCE AND collection_srt:"${label}" AND source_id_srt:${state.basicData.source_id}` + (state.showDeleted?"":' AND active_srt:YES')
      label = getCollectionObjectName({collectionName:label,collectionType:state.contentTabData[state.contentTabState].collection_type});
    }else{
      fq = `object_type_srt:${label} AND source_id_srt:${state.basicData.source_id} ${state.showDeleted?'':'AND active_srt:YES'} ${state.showReference?'':"AND reference_srt:NO "}`;
    }

    let presetFilter = fq;
    history.push(`/basic_search?query=*&object=${label}&presetFilter=${presetFilter}`)
	}

  const onClickBulkEdit = () => {
    let isCollection = state.contentTabData[state.contentTabState].isCollection;
    let label = state.contentTabData[state.contentTabState].name;
    let fq;
    if(isCollection){
      fq = `object_type_srt:COLLECTION_INSTANCE AND collection_srt:"${label}" AND source_id_srt:${state.basicData.source_id}` + (state.showDeleted?"":' AND active_srt:YES')
    }else{
      fq = `object_type_srt:${label} AND source_id_srt:${state.basicData.source_id} ${state.showDeleted?'':'AND active_srt:YES'} ${state.showReference?'':"AND reference_srt:NO "}`;
    }

		setBulkEditPrams({
			url:'/solr/search/select',
      type:'solr',
			params:{
				q:'*',
				fq,
				fl:'*',
				rows:1000,
			},
			redirectUrl:window.location.pathname.split('?')[0],
			fileName:`${state.contentData[state.contentTabState]?formatNumber(state.contentData[state.contentTabState].numFound):'0'} ${titleCaseObjectName(state.contentTabData[state.contentTabState].name).toLowerCase()}(s) from ${state.basicData.name} tool`.trim(),
		})
		history.push('/bulk_update')
	}

	const onAddToCart = () => {
		loadData({addToCart:true})
		setListActionOpen(false)
	}


  return (
    <div className={classes.root}>
      <VerticalTabBar
        tabOptions={state.contentTabData.map(el=>`${mapObjectName(el.name).replace(/_/g,' ')} (${el.count})`)}
        tabState={state.contentTabState}
        setTabState={value => dispatch({ type: 'set_content_tab_state', contentTabState: value })}
      />
      <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
        <div style={{display:'flex', alignItems:'center',flexWrap:'wrap'}}>
          <Typography data-test-id="child-list-title" style={{marginBottom:10}} className={classes.title} color='primary'>{`${state.contentData[state.contentTabState]?formatNumber(state.contentData[state.contentTabState].numFound):'0'} ${mapObjectName(state.contentTabData[state.contentTabState].name).toUpperCase().replace(/_/g,' ')}(S)`}</Typography>
          <div style={{display:'flex',marginBottom:10,alignItems:'center'}}>
            <InputBase
              value={state.searchFilter[state.contentTabState]}
              onChange={event => onNameFilterChange(event.target.value)}
              variant={'filled'}
              placeholder={'Search'}
              className={classes.nameFilter}
              endAdornment={
                <IconButton
                  disabled={!state.searchFilter[state.contentTabState] || state.searchFilter[state.contentTabState]===''}
                  onClick={()=>onNameFilterChange('')}
                  style={{width:32,height:32,marginRight:6}}
                >
                  {getIconComponent({label:!state.searchFilter[state.contentTabState] || state.searchFilter[state.contentTabState]===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              }
            />
            <Select
              className={classes.selector}
              value={state.contentFilter[state.contentTabState] || 'trust_srt desc'}
              onChange={event=>onChangeFilter(event.target.value)}
              MenuProps={{
                classes:{
                  paper:classes.selectPaper
                }
              }}
              disabled={state.searchFilter[state.contentTabState]}
            >
              {
                [
                  {displayName:'Trust',value:'trust_srt desc'},
                  {displayName:'Recently used',value:'last_used_srt desc'},
                  {displayName:'A-Z',value:'name_srt asc'},
                  {displayName:'Z-A',value:'name_srt desc'}
                ].map(el=>(
                  <MenuItem  className={classes.menuItem} value={el.value}>
                    {el.displayName}
                  </MenuItem>
                ))
              }
            </Select>
            <div style={{marginLeft:8,width:24,height:24,cursor:'pointer',flexGrow:0,flexShrink:0}} onClick={onListActionClick}>
              {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
            </div>
            <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
              <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
                <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                  <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
                  <MenuList className={classes.listContainer}>
                    <MenuItem onClick={()=>{onClickViewInSearch()}} className={classes.menuItem} >
                      <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
                    </MenuItem>
                    {
                      checkCanBulkUpdate({sessionData}) &&
                      <MenuItem onClick={()=>{onClickBulkEdit()}} className={classes.menuItem} >
                        <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Bulk action</Typography>
                      </MenuItem>
                    }
                    {
                      checkCanAddToCart({sessionData, objectType: state.contentTabData?.[state.contentTabState]?.name}) &&
                      <MenuItem data-test-id="child-list-add-cart" onClick={()=>{onAddToCart()}} className={classes.menuItem} >
                        <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Add to Cart</Typography>
                      </MenuItem>
                    }
                  </MenuList>
                  <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
                  <MenuItem onClick={()=>{onClickShowReference(!state.showReference)}} className={classes.menuItem} >
                    <Checkbox key={state.showReference} className={classes.checkbox} color='primary' checked={state.showReference}/>
                    <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show reference</Typography>
                  </MenuItem>
                  <MenuItem onClick={()=>{onClickShowDeleted(!state.showDeleted)}} className={classes.menuItem} >
                    <Checkbox key={state.showDeleted} className={classes.checkbox} color='primary' checked={!state.showDeleted}/>
                    <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                  </MenuItem>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
        </div>
        <SolrList
          history={history}
          list={state.contentData[state.contentTabState]?state.contentData[state.contentTabState].docs:undefined}
          enableEdit={true}
          onDescriptionUpdated={({item,description})=>{
            dispatch({
              type:'set_content_data',
              contentData:{
                ...state.contentData,
                [state.contentTabState]:{
                  ...state.contentData[state.contentTabState],
                  docs:state.contentData[state.contentTabState].docs.map(el=>{
                    if(el.id!==item.id)return el;
                    return {...el,description}
                  })
                }
              }
            })
          }}
        />
        {
          state.contentError[state.contentTabState]  &&
          <Typography className={classes.normaltext}>Error occurred loading items</Typography>
        }
        <div ref={loadingRef} style={{width:'100%',display:'flex',justifyContent:'center',marginBottom:30}}>
          {
            state.contentLoading[state.contentTabState] &&
            <CircularProgress color='secondary'/>
          }
        </div>
      </div>
      {
        checkCanAddToCart({sessionData, objectType: state.contentTabData?.[state.contentTabState]?.name}) &&
        <CartAdder
					objectIds={addToCartIds}
					onFinish={()=>{setAddToCartIds()}}
					history={history}
				/>
      }
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
		setBulkEditPrams:(data) => dispatch(actions.setBulkEditParam(data))
  }
}
const mapStateToProps = state => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(Content)));
