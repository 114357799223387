import React, { useEffect, useRef } from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import { generateChartGroup, generateDataTiles, generateRecommendationList, generateSummaryChart } from './componentGenerator'
import { loadGraphData , loadTileData} from './dataLoader'
import { mapObjectName, toTitleCase } from '../../../../utilities';
import UsageSelector from '../UsageSelector';
import { allUsage } from '../loadUtils';

const styles = theme => ({
  chartContainer:{
    width:'48%',
    flexGrow:1,
    boxSizing:'border-box',
    padding:16,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
  },
  chartTitle:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  chartSubtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light
  },
  selector:{
    ...theme.components.selector,
    width:160,
    height:48
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  subTitle:{
    color:theme.palette.primaryText.light,
    fontSize:13.75
  },
  menuItem:{
    fontSize:13.75,
    paddingLeft:0,
    color:theme.palette.primaryText.main,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  basicMenuItem:{
    color:theme.palette.primaryText.main,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  dropdownCaption:{
    color:theme.palette.primary.main,
    fontSize:12,
    letterSpacing:2,
    marginLeft:16,
    marginTop:16,
    marginBottom:8
  },
  tooltip:{
    fontSize:13.75,
    whiteSpace:'pre-wrap'
  },
  selectorHeader:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.overline.main,
    marginBottom:8
  },
  suggestionChip:{
    ...theme.components.suggestionChip
  },
  suggestionHeader:{
    fontSize:12,
    letterSpacing:2,
    marginTop:16,
    marginBottom:8,
    color:theme.palette.primaryText.light
  },


  header:{
    fontSize:20,
    color:theme.palette.primaryText.main,
  },
  subHeader:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:8
  },
  recommendationContainer:{
    height:48,
    display:'flex',
    alignItems:'center',
    padding:'0px 16px',
    overflow:'hidden',
    maxWidth:800,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:"pointer",
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  },
  recommendationTitle:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    flexGrow:1,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:16
  },
})

const Source = props => {

  const {
    classes,
    // theme,
    state,
    dispatch
  } = props;

  const data = state.governanceData['source'];
  const setData =  d => dispatch({type:'set_governance_data',governanceData:{...state.governanceData,source:d}})
  const source = state.dbList.find(el=>el.id===state.governanceSource)
  const isActive = useRef(false)
  const apiSeq = useRef(1)

  useEffect(()=>{
    isActive.current = state.governanceTabState===2
    if(isActive.current && !data.tileData && !data.tileDataLoading){
      apiSeq.current+=1
      loadTileData({data, setData, source, usageCountByObjectBySource:state.usageCountByObjectBySource, apiSeq:apiSeq.current, apiSeqRef:apiSeq});
    }
    // eslint-disable-next-line
  },[state.governanceTabState])

  useEffect(()=>{
    // setFilterValues({sourceId: state.governanceSource})
    // if(!state.governanceSource)return;
    if(!isActive.current || !data.tileData)return;
    apiSeq.current+=1
    loadTileData({data, setData, source, usageCountByObjectBySource:state.usageCountByObjectBySource, apiSeq:apiSeq.current, apiSeqRef:apiSeq});
    // eslint-disable-next-line
  },[source])

  useEffect(()=>{
    if(!isActive.current)return;
    if(!data || !data.tileData || !data.selectedTile || data[`${data.selectedTile}_graphDataError`] || data[`${data.selectedTile}_graphData`] || data[`${data.selectedTile}_graphDataLoading`] )return;
    if(data[`${data.selectedTile}_recommendationData`] || data[`${data.selectedTile}_recommendationLoading`]  || data[`${data.selectedTile}_recommendationError`])return;
    apiSeq.current+=1
    loadGraphData({data,setData,usage:data.usage,source, apiSeq:apiSeq.current, apiSeqRef:apiSeq})
    // eslint-disable-next-line
  },[data])

  if(!isActive.current)return <></>;

  return (
    <div className={classes.root}>
      <UsageSelector
        key={state.governanceSource}
        labelText="FILTER BY"
        selectedUsages={data.usage || allUsage}
        onChangeUsage={usages=>{
          apiSeq.current+=1
          loadTileData({data, setData, usage:usages,  source, usageCountByObjectBySource:state.usageCountByObjectBySource, apiSeq:apiSeq.current, apiSeqRef:apiSeq});
        }}
        availableUsages={allUsage.map(el=>({val:el, count:state.usageCountByObjectBySource?.[state.governanceSource]?.[source.source_template.type]?.[el]?.count || 0 })).filter(el=>el.count!==0 || !state.usageCountByObjectBySource)}
        removeCount={!state.usageCountByObjectBySource}
      />
      {
        data.usage?.length===0 &&
        <Typography>Select at least one usage type to view insights</Typography>
      }
      {
        data.selectedTile && data.usage?.length>0 &&
        <>
          {generateDataTiles({classes, data, setData})}
          {
            data.selectedTile!=='DATABASE' &&
            <>
              <Typography className={classes.header} id="summary-section">SUMMARY</Typography>
              <Typography className={classes.subHeader}>Overall progress of governance actions across {toTitleCase(mapObjectName(data.selectedTile).replace(/_/g,' '))}s</Typography>
              {generateSummaryChart({classes,data})}
            </>
          }
          <Typography className={classes.header} id="insights-section">GOVERNANCE PROPERTIES INSIGHTS</Typography>
          <Typography className={classes.subHeader}>Select a governance property to drill through and view all data assets that meet the criteria via the Search page</Typography>
          {generateChartGroup({classes,data,sourceColourMap:state.sourceColourMap})}
          {
            data.selectedTile!=='DATABASE' &&
            <>
              <Typography className={classes.header} id="recommendation-section">RECOMMENDATIONS</Typography>
              <Typography className={classes.subHeader}>Select a recommendation to see more details</Typography>
              {generateRecommendationList({classes,data,setData})}
            </>
          }
        </>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(Source));
