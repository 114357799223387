import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import { getLabelPlural, getNameSearchQuery } from '../../../../utilities';
import SearchSelector from '../../SearchSelector/SearchSelector';
import { tileStyles } from './utils/styles';
import { getPlatformSettings } from '../../../../permissionChecker';
import { checkIsClickOnSuggestion } from './utils/utils';
import UserChip from '../../Chips/UserChip';
import useAlert from '../../../../hooks/useAlert';
import { getDefaultSearchParams } from '../../../BasicSearch/MainSearch/Utils/Utils';
import { globalListenerRef } from '../../../../GlobalListenerRef';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
})

function OwnerManager(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    isEditable,
    alwaysOpenNewTab
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  let userFilter = getPlatformSettings(4008)?.value;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])

  useEffect(() => {
    window.removeEventListener('message', globalListenerRef.ownerTileListener)
    globalListenerRef.ownerTileListener = (msg) => {
      if (msg.data.sidebar_action === 'reload_sidebar') {
        loadOwner()
      }
    }
    window.addEventListener('message', globalListenerRef.ownerTileListener)

    return () => {
      window.removeEventListener('message', globalListenerRef.ownerTileListener)
    }
  // eslint-disable-next-line
  }, [object])

  const loadOwner = () => {
    setLoading(true)
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related`,{
          params:{
            object_name:'USER',
            // relationship:'OWNED_BY,OWNED_BY_SRC',
            relationship:'OWNED_BY',
            per_page:200
          }
        }
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":response.data.items.map(t=>({
              name:t.name,
              relationship:t.relationship,
              labels:'user',
              id:t.id,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/user/${t.id}`,'_blank'):
                history.push(`/profile/user/${t.id}`)
              }
            })),
            "disp_title":"OWNER",
            "id":"owner",
          }
        )
        setLoading(false)
      })
      .catch(error=>{
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(!tileData){
      loadOwner()
    }
  // eslint-disable-next-line
  },[])

  const onAddOwner = async (item) => {
    setSearchValue('')
    if(item.id===tileData.disp_body[0]?.id)return;

    await axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=OWNED_BY&object_id=${item.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[
              ...tileData.disp_body,
              {
                name:item.name_txt,
                relationship:'OWNED_BY',
                labels:'user',
                id:item.id,
                onClick:()=>{
                  alwaysOpenNewTab?
                  window.open(`/profile/user/${item.id}`,'_blank'):
                  history.push(`/profile/user/${item.id}`)
                }
              }
            ],
            "disp_title":tileData.disp_title,
            "id":tileData.id,
          }
        )
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred updating owner, please try again', type: 'error'})
      })
  }

  const onDelete = item => {
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=OWNED_BY&object_id=${item.id}`
      ).then(response=>{
        onAddTileData(
          {
            "disp_body":tileData.disp_body.filter(t=>t.id!==item.id || t.relationship!=='OWNED_BY'),
            "disp_title":tileData.disp_title,
            "id":tileData.id,
          }
        )
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred deleting owner, please try again', type: 'error' })
      })
  }

  if(!tileData)return <></>

  const linkedOwners = tileData.disp_body?.filter(t=>t.relationship==='OWNED_BY') || []
  const srouceOwners = tileData.disp_body?.filter(t=>t.relationship==='OWNED_BY_SRC') || []

  return (
    <>
      <div data-test-id="owner-data-tile" className={classes.root}>
        <div className={classes.editableHeaderContainer} >
          <Typography className={classes.title}>{tileData.disp_title.toUpperCase()}</Typography>
        </div>
        <ClickAwayListener
          onClickAway={(event)=>{
            if(checkIsClickOnSuggestion(event.target))return;
            setSearchValue('')
            setEditing(false)
          }}
        >
          <div className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && setEditing(true)}>
            {
              loading && <CircularProgress color='secondary' size={16}/>
            }
            {
              !loading && tileData && linkedOwners.length===0 &&
              <div data-test-classname="owner-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
                <span className={classes.tagText} style={{color:theme.palette.primaryText.main}}>None</span>
              </div>
            }
            {
              !loading && tileData && linkedOwners.map(t=>(
                <UserChip
                  user={t}
                  testID={"owner-data-tile-chip"}
                  onClick={t.onClick}
                  onRemove={isEditable?event=>{event.stopPropagation();onDelete(t)}:undefined}
                  onlyShowDeleteOnHover={!editing}
                  bottomMargin={8}
                />
              ))
            }
            {
              editing &&
              <div style={{marginTop:8,marginBottom:6,width:'100%'}}>
                <SearchSelector
                  url={'/solr/search/select'}
                  params={{
                    ...getDefaultSearchParams({indexName:'search'}),
                    q:getNameSearchQuery(searchValue),
                    fq:'object_type_srt:USER AND -merge_type_srt:CANDIDATE AND account_type_srt:"USER_ACCOUNT"' +
                        (userFilter==='data_roles'?' AND data_role_kc_msrt:"DATA OWNER"':'')+
                        (linkedOwners.length>0?` AND -id:(${linkedOwners.map(b=>b.id).join(' OR ')})`:''),
                    fl:'*',
                    qf:'name_srt',
                    sort:'name_srt asc',
                    rows:20,
                  }}
                  testID="user-manager-input"
                  clearable
                  autoFocus
                  height={32}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  placeholder={`Search for a user`}
                  autoSuggestion={userFilter==='data_roles'}
                  suggestionListWidth={290}
                  removeSuggestionHeader
                  scrollable={true}
                  isSimplifiedSuggestion={true}
                  onResultClick={onAddOwner}
                />
              </div>
            }
          </div>
        </ClickAwayListener>
      </div>

      {
        !loading && tileData && srouceOwners.length>0 &&
        <div data-test-id="source-owner-data-tile" className={classes.root}>
          <div className={classes.editableHeaderContainer} >
            <Typography className={classes.title}>OWNER (IN SOURCE)</Typography>
          </div>
          <div className={classes.chipContainer}>
            {
              srouceOwners.map(t=>(
                <UserChip
                  user={t}
                  testID={"source-owner-data-tile-chip"}
                  onClick={t.onClick}
                  bottomMargin={8}
                />
              ))
            }
          </div>
        </div>
      }
    </>
  )
}

OwnerManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(OwnerManager));
