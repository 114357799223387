import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography, Select, MenuItem } from '@material-ui/core';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import { getIconComponent, titleCaseObjectName, toTitleCase } from '../../../utilities';
import { graphLoader } from './loadUtils';
import { generateChartGroup } from './componentGenerator';
import RangeEditor from './RangeEditor';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  headerSelector: {
    ...theme.components.titleSelector,
    marginRight: 12,
    maxWidth: 500,
  },
  subSelector: {
    ...theme.components.titleSelector,
    marginRight: 12,
    maxWidth: 500,
    height: 24,
    borderRadius: 12,
    '& div div': {
      padding: '3px 24px 3px 8px',
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1,
      fontSize: 12
    }
  },
  header: {
    fontSize: 20,
    color: theme.palette.primaryText.main,
  },
  subHeader: {
    fontSize: 12,
    color: theme.palette.primaryText.light,
    marginBottom: 8
  },
  /////
  chartContainer: {
    width: '50%',
    flexGrow: 1,
    boxSizing: 'border-box',
    padding: 16,
    border: `1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius: 4,
    marginBottom: 24
  },
  chartTitle: {
    fontSize: 16,
    color: theme.palette.header.main,
  },
  chartSubtitle: {
    fontSize: 12,
    color: theme.palette.primaryText.light
  },
  recommendationContainer: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    overflow: 'hidden',
    maxWidth: 800,
    borderBottom: `1px solid ${theme.palette.listItemDivider.main}`,
    cursor: "pointer",
    '&:hover': {
      backgroundColor: theme.palette.hovered.main
    }
  },
  recommendationTitle: {
    fontSize: 16,
    color: theme.palette.primaryText.main,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: 16
  },
})

const BySource = props => {

  const {
    classes,
    theme,
    state,
    dispatch
  } = props;

  const tabOptions = ['LINEAGE COMPLEXITY', 'UPSTREAM LINEAGE', 'DOWNSTREAM LINEAGE']

  const onChangeFilters = ({
    type = tabOptions[state.bySourceTabState],
    source = state.bySourceSelectedSource,
    usage = state.bySourceSelectedUsage,
    object = state.bySourceSelectedObject
  }) => {
    if (source !== state.bySourceSelectedSource) {
      usage = Object.keys(state.objectUsageBySourceMap[source])[0];
      dispatch({ type: 'set_by_source_selected_usage', bySourceSelectedUsage: usage });
      object = Object.keys(state.objectUsageBySourceMap[source][usage])[0];
      dispatch({ type: 'set_by_source_selected_object', bySourceSelectedObject: object });
    }
    else if (usage !== state.bySourceSelectedUsage) {
      object = Object.keys(state.objectUsageBySourceMap[source][usage])[0];
      dispatch({ type: 'set_by_source_selected_object', bySourceSelectedObject: object });
    }

    graphLoader({
      state,
      dispatch,
      type,
      source,
      usage,
      object
    })
  }

  useEffect(() => {
    if (!state.graphDataLoading && !state.graphData) {
      onChangeFilters({})
    }
    // eslint-disable-next-line
  }, [])

  const getSourceIcon = s => {
    let source = state.dbList.find(db => db.name.toUpperCase() === s.toUpperCase());
    if (!source) return 'source';
    return getIconComponent({ label: source.source_template.name, size: 24, colour: theme.palette.primaryText.light, defaultLabel: 'source' })
  }

  return (
    <div className={classes.root}>
      <VerticalTabBar
        tabOptions={tabOptions}
        tabState={state.bySourceTabState}
        setTabState={value => {
          dispatch({ type: 'set_by_source_tab_state', bySourceTabState: value })
          onChangeFilters({ type: tabOptions[value] })
        }}
      />
      <div style={{ flexGrow: 1, marginLeft: 80, overflow: 'hidden' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
          <Select
            className={classes.headerSelector}
            value={state.bySourceSelectedSource}
            disabled={state.graphDataLoading}
            disableUnderline
            onChange={event => {
              if (state.graphDataLoading) return;
              onChangeFilters({ source: event.target.value })
              dispatch({ type: 'set_by_source_selected_source', bySourceSelectedSource: event.target.value })
            }}
            renderValue={value => {
              let source = state.dbList.find(db => db.name.toUpperCase() === value.toUpperCase());
              if (!source) return value;
              return (
                <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', paddingTop: 0, paddingBottom: 0, paddingRight: 12, paddingLeft: 8 }}>
                  {
                    getIconComponent({ label: source.source_template.name, size: 24, colour: theme.palette.primaryText.light, defaultLabel: 'source' })
                  }
                  <Typography style={{ flexGrow: 1, marginLeft: 16, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textTransform: 'uppercase', color: theme.palette.header.main, fontSize: 20 }}>{source.name}</Typography>
                </div>
              )
            }}
          >
            {
              Object.keys(state.objectUsageBySourceMap).map(s => {
                return <MenuItem key={s} value={s}>{getSourceIcon(s)}<span style={{marginLeft: 16}}>{s}</span></MenuItem>
              })
            }
          </Select>
          <Typography className={classes.header} style={{ color: theme.palette.header.main }}>{tabOptions[state.bySourceTabState]}</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
          <Typography style={{ fontSize: 12, marginRight: 8, letterSpacing: 1 }}>
            FILTER BY:
          </Typography>
          <Select
            className={classes.subSelector}
            value={state.bySourceSelectedUsage}
            disabled={state.graphDataLoading}
            disableUnderline
            renderValue={value => {
              return 'Usage: ' + toTitleCase(value)
            }}
            onChange={event => {
              if (state.graphDataLoading) return;
              onChangeFilters({ usage: event.target.value })
              dispatch({ type: 'set_by_source_selected_usage', bySourceSelectedUsage: event.target.value })
            }}
          >
            {
              ['DAILY', 'WEEKLY', 'MONTHLY', 'INFREQUENT', 'UNKNOWN'].filter(el => Object.keys(state.objectUsageBySourceMap[state.bySourceSelectedSource]).includes(el)).map(s => {
                return <MenuItem key={s} value={s}>{toTitleCase(s)}</MenuItem>
              })
            }
          </Select>
          <Select
            className={classes.subSelector}
            value={state.bySourceSelectedObject}
            disabled={state.graphDataLoading}
            disableUnderline
            renderValue={value => {
              return 'Type: ' + titleCaseObjectName(value)
            }}
            onChange={event => {
              if (state.graphDataLoading) return;
              onChangeFilters({ object: event.target.value })
              dispatch({ type: 'set_by_source_selected_object', bySourceSelectedObject: event.target.value })
            }}
          >
            {
              Object.keys(state.objectUsageBySourceMap[state.bySourceSelectedSource][state.bySourceSelectedUsage]).map(s => {
                return <MenuItem key={s} value={s}>{titleCaseObjectName(s)}</MenuItem>
              })
            }
          </Select>
        </div>

        {generateChartGroup({ classes, state, dispatch, type: tabOptions[state.bySourceTabState], object: state.bySourceSelectedObject })}
        {/* {generateRecommendationList({ classes, state })} */}
      </div>
      <RangeEditor
        modalOpen={state.bySourceRangeEditorOpen}
        setModalOpen={value => dispatch({ type: 'set_by_source_range_editor_open', bySourceRangeEditorOpen: value })}
        onRangeChange={() => onChangeFilters({})}
      />
    </div>
  )
}

export default withTheme()(withStyles(styles)(BySource));
