import React, {useEffect, useReducer} from 'react';
import { withTheme, withStyles, Typography,LinearProgress } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import PropTypes from 'prop-types';
import DeadEnd from  '../../components/Generic/Page/DeadEnd';
import {setInitialState, handleShareClick, getDispFields, formatNumber} from '../../utilities'
import useGetCerebrum from '../../hooks/useGetCerebrum';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import axiosCerebrum from '../../axios-cerebrum'
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import {addHistory} from '../../HistoryManager'
import Body from '../../components/MyEcosystem/Body/Body';
import UserBody from '../../components/UserProfile/Body/Body'
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import { assignedObjects, contributedObjects, createdObjects, runObjects, usedObjects } from '../../components/MyEcosystem/utils';

const styles = theme => ({
  normalText:{
    color: theme.palette.primaryText.main,
}
});

const initialState = {
  viewType:'TIMELINE',
  tabState:0,
  relatedTabState:0,
  listListSort:'LINKAGE_END_DESC',
  contributedListObjects:contributedObjects,
  contributedListSort:'LINKAGE_END_DESC',
  usedListObjects:usedObjects,
  usedListSort:'LAST_USED_DESC',
  runListObjects:runObjects,
  runListSort:'LINKAGE_END_DESC',
  createdListObjects:createdObjects,
  createdListSort:'LINKAGE_END_DESC',
  assignedListObjects:assignedObjects,
  assignedListSort:'LINKAGE_END_DESC',
  followedListSort:'END_DESC',
  accessRolesSort:'LINKAGE_END_DESC',
  accessRolesSearch:'',
  linkedUsersSearch:'',
  usedTabState:0,
  assignedTabState:0,
  contributedTabState:0,
  runTabState:0,
  createdTabState:0,
}

const reducer = (state, action) => {
  switch (action.type){
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case "set_user_data":
      return {
        ...state,
        userData: action.userData,
        userLoading:action.userLoading,
        userError:action.userError
      }
    case "set_collection_data":
      return {
        ...state,
        collectionData:action.collectionData
      }
    case 'set_merged_to_user_data':
      return {
        ...state,
        mergedToUserData:action.mergedToUserData
      }
    case 'set_metrics_data':
      return {
        ...state,
        metricsData:action.metricsData
      }
    case "set_group_data":
      return {
        ...state,
        groupData:action.groupData
      }
    case 'set_related_tab_state':
      return {
        ...state,
        relatedTabState:action.relatedTabState
      }
    case 'set_collection_instances':
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    //////////////////////////////////
    /////////
    case 'set_view_type':
      return {
        ...state,
        viewType:action.viewType
      }
    case 'set_list_timeline':
      return {
        ...state,
        listTimeLineData:action.listTimeLineData,
        listTimeLineLoading:action.listTimeLineLoading,
        listTimeLineError:action.listTimeLineError
      }
    case 'set_list_list':
      return {
        ...state,
        listListData:action.listListData,
        listListLoading:action.listListLoading,
        listListError:action.listListError
      }
    case 'set_list_list_sort':
      return {
        ...state,
        listListSort:action.listListSort
      }
    case 'set_used_tab_state':
      return {
        ...state,
        usedTabState:action.usedTabState
      }
    case 'set_used_timeline':
      return {
        ...state,
        usedTimeLineData:action.usedTimeLineData,
        usedTimeLineLoading:action.usedTimeLineLoading,
        usedTimeLineError:action.usedTimeLineError
      }
    case 'set_used_list':
      return {
        ...state,
        usedListData:action.usedListData,
        usedListLoading:action.usedListLoading,
        usedListError:action.usedListError
      }
    case 'set_used_list_objects':
      return {
        ...state,
        usedListObjects:action.usedListObjects
      }
    case 'set_used_list_sort':
      return {
        ...state,
        usedListSort:action.usedListSort
      }
    case "set_contributed_tab_state":
      return {
        ...state,
        contributedTabState:action.contributedTabState
      }
    case 'set_contributed_timeline':
      return {
        ...state,
        contributedTimeLineData:action.contributedTimeLineData,
        contributedTimeLineLoading:action.contributedTimeLineLoading,
        contributedTimeLineError:action.contributedTimeLineError
      }
    case 'set_contributed_list':
      return {
        ...state,
        contributedListData:action.contributedListData,
        contributedListLoading:action.contributedListLoading,
        contributedListError:action.contributedListError
      }
    case 'set_contributed_list_sort':
      return {
        ...state,
        contributedListSort:action.contributedListSort
      }
    case 'set_created_tab_state':
      return {
        ...state,
        createdTabState:action.createdTabState
      }
    case 'set_created_timeline':
      return {
        ...state,
        createdTimeLineData:action.createdTimeLineData,
        createdTimeLineLoading:action.createdTimeLineLoading,
        createdTimeLineError:action.createdTimeLineError
      }
    case 'set_created_list':
      return {
        ...state,
        createdListData:action.createdListData,
        createdListLoading:action.createdListLoading,
        createdListError:action.createdListError
      }
    case 'set_created_list_objects':
      return {
        ...state,
        createdListObjects:action.createdListObjects
      }
    case 'set_created_list_sort':
      return {
        ...state,
        createdListSort:action.createdListSort
      }
    case 'set_run_tab_state':
      return {
        ...state,
        runTabState:action.runTabState
      }
    case 'set_run_timeline':
      return {
        ...state,
        runTimeLineData:action.runTimeLineData,
        runTimeLineLoading:action.runTimeLineLoading,
        runTimeLineError:action.runTimeLineError
      }
    case 'set_run_list':
      return {
        ...state,
        runListData:action.runListData,
        runListLoading:action.runListLoading,
        runListError:action.runListError
      }
    case 'set_run_list_objects':
      return {
        ...state,
        runListObjects:action.runListObjects
      }
    case 'set_run_list_sort':
      return {
        ...state,
        runListSort:action.runListSort
      }
    case 'set_assigned_tab_state':
      return {
        ...state,
        assignedTabState:action.assignedTabState
      }
    case 'set_assigned_timeline':
      return {
        ...state,
        assignedTimeLineData:action.assignedTimeLineData,
        assignedTimeLineLoading:action.assignedTimeLineLoading,
        assignedTimeLineError:action.assignedTimeLineError
      }
    case 'set_assigned_list':
      return {
        ...state,
        assignedListData:action.assignedListData,
        assignedListLoading:action.assignedListLoading,
        assignedListError:action.assignedListError
      }
    case 'set_assigned_list_objects':
      return {
        ...state,
        assignedListObjects:action.assignedListObjects
      }
    case 'set_assigned_list_sort':
      return {
        ...state,
        assignedListSort:action.assignedListSort
      }
    case 'set_followed_timeline':
      return {
        ...state,
        followedTimeLineData:action.followedTimeLineData,
        followedTimeLineLoading:action.followedTimeLineLoading,
        followedTimeLineError:action.followedTimeLineError
      }
    case 'set_followed_list':
      return {
        ...state,
        followedListData:action.followedListData,
        followedListLoading:action.followedListLoading,
        followedListError:action.followedListError
      }
    case 'set_followed_list_sort':
      return {
        ...state,
        followedListSort:action.followedListSort
      }
    //////
    case 'set_access_roles':
      return {
        ...state,
        accessRolesData:action.accessRolesData,
        accessRolesLoading:action.accessRolesLoading,
        accessRolesError:action.accessRolesError
      }
    case 'set_access_roles_sort':
      return {
        ...state,
        accessRolesSort:action.accessRolesSort
      }
    case 'set_access_roles_search':
      return {
        ...state,
        accessRolesSearch:action.accessRolesSearch
      }
    case 'set_linked_users':
      return {
        ...state,
        linkedUsersData:action.linkedUsersData,
        linkedUsersLoading:action.linkedUsersLoading,
        linkedUsersError:action.linkedUsersError
      }
    case 'set_linked_users_search':
      return {
        ...state,
        linkedUsersSearch:action.linkedUsersSearch
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData:action.insightsData
      }
    default:
      throw new Error(`Action type:${action.type} not supported`);
  }
}

const UserProfile = props => {

  const {
    match,
    history,
    theme,
    classes,
    pageCache,
    storePageCache,
  } = props;

  const tabOptions = ['USED','CONTRIBUTED','RUN','CREATED','ASSIGNED','LIST','FOLLOWING','RELATED']
  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  // eslint-disable-next-line
  },[state])


  // get user data
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    fetchList:userFetch
  } = useGetCerebrum({
    url:`/api/users/${match.params.id.toLowerCase()}`,
    preventAuto:true
  })


  useEffect(()=>{
    dispatch({type: "set_user_data", userData:userData || state.userData, userLoading, userError});
    if(!userData)return;
    addHistory({url:window.location.pathname, title: getDispFields(userData,'dispTitle'), subTitle:'User',iconLabel:'user',object:userData,type:'profile'})
  // eslint-disable-next-line
  }, [userData, userError,userLoading])

  const {
    data:groupData,
    fetchList:groupFetch
  } = useGetCerebrum({
    url:`/api/users/${match.params.id.toLowerCase()}/related`,
    params:{
      object_name:'GROUP',
      relationship:'MEMBER_OF'
    },
    preventAuto:true
  })

  useEffect(()=>{
    dispatch({type: "set_group_data", groupData:groupData || state.groupData});
  // eslint-disable-next-line
  }, [groupData])

  const {
    data:collectionData,
    fetchList:collectionFetch
  } = useGetCerebrum({
    url:`/api/users/${match.params.id.toLowerCase()}/related/collections`,
    params:{
      category:'PLATFORM,AUTOMATED',
      parent_name:'data role,user usage type',
      relationship:'MEMBER_OF',
      per_page:50
    },
    preventAuto:true
  })

  useEffect(()=>{
    dispatch({type: "set_collection_data", collectionData:collectionData || state.collectionData});
  // eslint-disable-next-line
  }, [collectionData])

  const {
    data: mergedToUserData,
    fetchList:mergedToUserFetch
  } = useGetCerebrum({
    url:`/api/merges`,
    params:{
      type:'CANDIDATE',
      object_type:'USER',
      object_id:match.params.id,
      per_page:1
    },
    preventAuto:true
  })


  useEffect(()=>{
    dispatch({type: "set_merged_to_user_data", mergedToUserData: mergedToUserData?mergedToUserData.items:state.mergedToUserData});
  // eslint-disable-next-line
  }, [mergedToUserData])

  const fetchMetrics = async () => {
    let consumer = await axiosCerebrum.get(`/api/users/${match.params.id.toLowerCase()}/related`,{params:{relationship:'VIEWS',per_page:0}})
    let owner = await axiosCerebrum.get(`/api/users/${match.params.id.toLowerCase()}/related`,{params:{relationship:'OWNER_OF',per_page:0}})
    let producer = await axiosCerebrum.get(`/api/users/${match.params.id.toLowerCase()}/related`,{params:{relationship:'CREATOR_OF',per_page:0}})
    let steward = await axiosCerebrum.get(`/api/users/${match.params.id.toLowerCase()}/related`,{params:{relationship:'STEWARD_OF',per_page:0}})
    dispatch({
      type:'set_metrics_data',
      metricsData:[
        {name:'CONSUMER',value:consumer.data.total},
        {name:'OWNER',value:owner.data.total},
        {name:'PRODUCER',value:producer.data.total},
        {name:'STEWARD',value:steward.data.total}
      ]
    })
  }


  useEffect(()=>{
    if(!state.userData)userFetch();
    if(!state.groupData)groupFetch();
    if(!state.metricsData)fetchMetrics();
    if(!state.collectionData)collectionFetch();
    if(!state.mergedToUserData)mergedToUserFetch();
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!state.userData || !state.metricsData || !state.groupData)return;
    let email=state.userData.email;
    let metricsData=state.metricsData;
    let teams = state.groupData.items;
    let databoxes = []

    if(teams && teams.length>0){
      databoxes.push(
        {
          "disp_body":teams.map(el=>({
            labels:'group',
            name:el.name,
            id:el.id
          })),
          "disp_title":"TEAM",
          "id":"team"
        }
      )
    }
    if(email && email.trim()!==''){
      databoxes.push(
        {
          "disp_body":{name:email},
          "disp_title":"CONTACT",
          "onClick":()=>window.open(`mailto:${email}`),
          "id":"contact"
        }
      )
    }
    if(metricsData){
      ['OWNER','STEWARD','CONSUMER','PRODUCER'].map(el=>(
        databoxes.push(
          {
            "disp_body":{name:el,value:metricsData.find(m=>m.name===el)?formatNumber(metricsData.find(m=>m.name===el).value):0},
            "disp_title":el,
            "onClick":()=>window.open(`mailto:${email}`),
            "id":`${el.toLowerCase()}_m`
          }
        )
      ))
    }
    dispatch({
      type:'set_insights_data',
      insightsData:databoxes
    })
  },[state.userData, state.metricsData, state.groupData])



  if (state.userLoading ) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.userError) {
    return (
      <DeadEnd />
    )
  }

  if(!state.userData){
    return <div></div>
  }

  const onTagClick = () => {
    let presetFilter = `reference_srt:YES`;
    history.push(`/basic_search?query=*&object=USER&presetFilter=${presetFilter}`)
  }

  let userTags = [];

  if(state.userData.reference===true){
    userTags.push(
      {name:'Reference',onClick:()=>onTagClick('reference'),colour:'rgb(230, 240, 242)',fontColour:'#000'}
    )
  }
  if(state.collectionData && state.collectionData.items.find(el=>el.parent_name==='Data Role')){
    state.collectionData.items.filter(el=>el.parent_name==='Data Role').forEach(instance=>{
      userTags.push(
        {name:instance.name,onClick:()=>history.push(`/profile/collection_instance/${instance.id}`),fontColour:'#fff',colour:'rgb(252, 100, 45)'}
      )
    })
  }

  let buttons = []

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  buttons.push(
    <ProfileButton
      text={`SWITCH TO ${state.viewType==='TIMELINE'?'LIST':'TIMELINE'} VIEW`}
      onClick={()=>{dispatch({type:'set_view_type',viewType:state.viewType==='TIMELINE'?'LIST':'TIMELINE'})}}
    />
  )

  let bannerdisplayText, bannerCause, bannerVisibility, bannerData;
  if(state.mergedToUserData && state.mergedToUserData[0]){
    bannerdisplayText = 'This account has been merged into another account';
    bannerCause = 'merge';
    bannerVisibility = 'visible';
    bannerData = {targetUrl: `/profile/user/${state.mergedToUserData[0].target_node.id}`}
  }

  if (state.userData && state.userData.reference){
    let data = state.userData
    if(data.reference_sources){
      if(data.reference_sources.length===0){
        bannerdisplayText=`This User is referenced in unknown source. Limited metadata available.`
      }
      else if(data.reference_sources.length>0){
        bannerdisplayText=`This User is referenced in ${data.reference_sources.map(el=>el.reference_source.name).join(', ')}. Limited metadata available.`
      }
    }
    else{
      bannerdisplayText= 'This Column is referenced but not integrated as a source. Limited metadata available.';
    }
    bannerCause = 'shell';
    bannerVisibility = 'visible'
  }

  return (
    <ProfileLayout
      header={
        <ProfileHeader
          type='tool'
          title={state.userData.name}
          customTags={userTags}
          subtitle={''}
          label={'user'}
          buttons={buttons}
          data={state.userData}
          history={history}
          avatarIcon={true}
          bannerdisplayText={bannerdisplayText}
          bannerCause={bannerCause}
          bannerVisibility={bannerVisibility}
          bannerData={bannerData}
          shouldLoadLinkedInstance={true}
          state={state}
          dispatch={dispatch}
        />
      }
      tabBar={
        <div style={{marginLeft:52}}>
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={state=>dispatch({type: "set_tab_state", tabState: state})}
            disableScroll={true}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        </div>
      }
      body={
        state.tabState<=6?
        <Body
          sessionData={{id:match.params.id}}
          dispatch={dispatch}
          state={state}
          history={history}
          tabOptions={tabOptions}
          variant="user_profile"
        />
        :
        <UserBody
          dispatch={dispatch}
          state={state}
          history={history}
          sessionData={{id:match.params.id}}
        />
      }
      sideBar={
        state.insightsData &&
        <ProfileSideBar
          tabOptions={tabOptions}
          history={history}
          state={state}
          dispatch={dispatch}
          mapping={
            ['team','contact','consumer_m','producer_m','owner_m','steward_m']
          }
          cerebrumLabel={'users'}
          data={{...state.userData,object:{name:'USER'}}}
        />
      }
    />
  )
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,

  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(UserProfile)));
