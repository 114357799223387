import React, { useReducer, useEffect, useState } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { LinearProgress, Typography,} from '@material-ui/core';
import Body from '../../components/IssueProfile/Body/Body';
import { copyToClipboard, handleShareClick, sendMessage} from '../../utilities';
import { setInitialState, getDispFields} from '../../utilities';
import axiosCerebrum from '../../axios-cerebrum';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import DeleteModal from '../../components/IssueProfile/DeleteModal/DeleteModal'
import TabBar from '../../components/UI/TabBar/TabBar';
import {addHistory} from '../../HistoryManager'
import axiosSolr from '../../axios-solr'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';
import { checkIsFollowed } from '../../permissionChecker';
import { globalListenerRef } from '../../GlobalListenerRef';
import { KadaSnackBar } from 'kada-component-library';

const styles = theme => ({

  underlineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
});

const initialState = {
  linkSearchValue:'',
  linkFilter:'all',
  tabState:0,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_basic_data':
      return {
        ...state,
        basicData:action.basicData,
        loading:action.loading,
        error:action.error
      }
    case 'set_tab_state':
      return {
        ...state,
        tabState:action.tabState
      }
    case 'set_external_ticket_data':
      return {
        ...state,
        externalTicketData:action.externalTicketData,
        externalTicketLoading:action.externalTicketLoading,
        externalTicketError:action.externalTicketError
      }
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_delete_modal_open':
      return {
        ...state,
        deleteModalOpen:action.deleteModalOpen
      }
    case 'set_link_data':
      return {
        ...state,
        linkData:action.linkData,
        linkError:action.linkError,
        linkLoading:action.linkLoading
      }
    case 'set_link_alert':
      return {
        ...state,
        alertMessage:action.alertMessage,
        alertType:action.alertType,
        alertOpen:action.alertOpen
      }
    case 'set_alert_open':
      return {
        ...state,
        alertOpen:action.alertOpen
      }
    case 'set_db_data':
      return {
        ...state,
        dbData:action.dbData,
        dbError:action.dbError,
        dbLoading:action.dbLoading
      }
    case 'set_tool_data':
      return {
        ...state,
        toolData:action.toolData,
        toolError:action.toolError,
        toolLoading:action.toolLoading
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData:action.insightsData
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    // change
    case 'set_changes':
      return {
        ...state,
        changes: action.changes,
        changesLoading: action.changesLoading,
        changesError: action.changesError
      }
    case 'set_change_date':
      return {
        ...state,
        changeDate: action.changeDate
      }
    case 'set_change_type':
      return {
        ...state,
        changeType: action.changeType
      }
    case 'set_selected_change':
      return {
        ...state,
        selectedChange: action.selectedChange
      }
    default:
      console.log(action)
      throw new Error("Reducer action not supported.", action);
  }
}

const IssueProfile = props => {
  const {
    match,
    theme,
    history,
    classes,
    pageCache,
    storePageCache,
    sessionData,
  } = props;

  const [state, dispatch] = useReducer(reducer,setInitialState(pageCache,initialState));
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [alertDetails, setAlertDetails] = useState({});


  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  // eslint-disable-next-line
  },[state])

  const fetchExternalTicketData = ( issueID ) => {
    dispatch({
      type:'set_external_ticket_data',
      externalTicketLoading:true
    })
    axiosCerebrum
      .get(
        `/api/integrations/issues/${issueID}`
      )
      .then(response=>{
        dispatch({type:'set_external_ticket_data',externalTicketData:response.data})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_external_ticket_data',externalTicketError:true})
      })
  }

  const fetchList = () => {
    dispatch({type:'set_basic_data',basicData:state.basicData,loading:state.basicData?false:true})
    axiosCerebrum.get(
      `/api/issues/${match.params.id.toLowerCase()}`
    ).then(response=>{
      if(response.data.external_url){
        fetchExternalTicketData(response.data.id)
      }
      dispatch({type:'set_basic_data',basicData:response.data})
      addHistory({url:window.location.pathname, title: getDispFields(response.data,'dispTitle'), subTitle:getDispFields(response.data,'dispSubtitle'),object:response.data,type:'profile'})
      setTimeout(()=>{
        sendMessage({sidebar_action: 'reload_sidebar'})
      }, 100)
    }).catch(error=>{
      console.log(error)
      dispatch({type:'set_basic_data',error:true})
    })
  }

  const followDataFetch = () => {
    checkIsFollowed({objectId:match.params.id.toLowerCase()})
      .then(followed=>{
        dispatch({type:'set_following',following:followed})
      })
  }

  useEffect(() => {
    window.removeEventListener('message', globalListenerRef.issueProfileListener)
    globalListenerRef.issueProfileListener = (msg) => {
      if (msg.data.reload_issue_profile) {
        fetchList()
      }
      if(msg.data.issue_profile_external_created?.url){
        setAlertDetails({
          message: 'JIRA ticket created',
          type: 'info',
          actions: [
            {
              text: 'OPEN',
              onClick: ()=>window.open(msg.data.issue_profile_external_created.url, '_blank')
            },
            {
              text: 'COPY LINK',
              onClick: () => {
                copyToClipboard(msg.data.issue_profile_external_created.url)
                setAlertDetails({
                  type: 'info',
                  message: 'Link copied to clipboard'
                })
              }
            }
          ]
        })
      }
    }
    window.addEventListener('message', globalListenerRef.issueProfileListener)

    return () => {
      window.removeEventListener('message', globalListenerRef.issueProfileListener)
    }
    // eslint-disable-next-line
  }, [state.basicData])


  useEffect(()=>{
    if(!state.basicData)fetchList();
    if(state.followData===undefined){
      followDataFetch()
    }
    // if(!state.linkData)linkFetch();
   // eslint-disable-next-line
  },[])


  useEffect(()=>{
    if(state.linkData){
      dispatch({type:'set_link_object_data',linkObjectData:{linkedItems:{'IMPACTS':{items:state.linkData.items.filter(el=>el.relationship==='IMPACTS')}}}})
    }
  },[state.linkData])

  const onClickDelete = () => {
    dispatch({type:'set_delete_modal_open',deleteModalOpen:true})
  }



  if (state.loading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.error ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const data = state.basicData;


  const loadLinkData = ({page=1}) => {
    dispatch({type:'set_link_data',linkData:state.linkData,linkLoading:true})
    axiosCerebrum
      .get(`/api/issues/${state.basicData.id}/related`,{params:{relationship:"IMPACTS,IMPACTS_SRC,RELATED",page,per_page:10}})
      .then(response=>{
        if(response.data.total===0){
          dispatch({type:'set_link_data',linkData:response.data})
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,
            {
              params:{
                q:"*",
                fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
                fl:'*',
                rows:response.data.items.length
              }
            }
          )
          .then(solrRes=>{
            let processedItem = response.data.items;
            processedItem.forEach((el,index)=>{
              processedItem[index] = {...el,...solrRes.data.response.docs.find(t=>t.id===el.id)}
            })
            dispatch({type:'set_link_data',linkData:page===1?{...response.data,items:processedItem}:{...response.data,items:[...state.linkData.items,...processedItem]}})
          })
          .catch(error=>{
            dispatch({type:'set_link_data',linkData:state.linkData})
            console.log(error);
          })
      })
      .catch(error=>{
        dispatch({type:'set_link_data',linkData:state.linkData})
        console.log(error);
      })
  }

  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() =>onClickDelete()}
      iconLabel='delete'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
    />
  )

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )


  const onOpenLinkModal = () => {
    setLinkModalOpen({
      relations:['IMPACTS'],
      linkableObjects:['TABLE','COLUMN','SCHEMA','DATABASE','MACRO','PROCEDURE','CONTENT','CONTENT_APP','CONTENT_CHILD','DATA_PIPELINE','QUERY','FILE'],
      onLinkUpdated:()=>loadLinkData({})
    })
  }

  buttons.push(
    <ProfileButton
      iconLabel='link'
      text='LINK'
      onClick={onOpenLinkModal}
    />
  )

  buttons.push(
    <FollowButton
      following={state.following}
      object={state.basicData}
      dispatch={dispatch}
    />
  )


  let bannerdisplayText, bannerCause, bannerVisibility;
  if (state.basicData.external_url) {
    bannerdisplayText = 'This issue is synchronised to an external ticket. Certain properties such as the title and description are locked.';
    bannerCause="external_issue"
    bannerVisibility = 'visible'
  }

  const tabOptions = ['DETAILS','RELATED','CHANGES']

  return (
    <div>

      <DeleteModal
        state={state}
        dispatch={dispatch}
        history={history}
      />

      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['link']}
        history={history}
      />

      <ProfileLayout
        header={
          <div>
            <ProfileHeader
              title={data.name}
              history={history}
              subtitle={
                <span>
                  <span className={classes.underlineOnHover} onClick={()=>history.push('/issue_board')}>ISSUE BOARD</span>
                  <span style={{marginLeft:6,marginRight:6}}>/</span>
                  <span>{'ISSUE-'+data.display_id}</span>
                </span>

              }
              state={state}
              dispatch={dispatch}
              nodeKey={match.params.id.toLowerCase()}
              label='issue'
              buttons={buttons}
              data={data}
              bannerVisibility={bannerVisibility}
              bannerCause={bannerCause}
              bannerdisplayText={bannerdisplayText}
            />
          </div>}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({type:'set_tab_state',tabState:value})}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        disableMinContentHeight={['CHANGES'].includes(tabOptions[state.tabState])}
        hideSideBar={['CHANGES', 'RELATED'].includes(tabOptions[state.tabState])}
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
            fetchList={fetchList}
            loadLinkData={loadLinkData}
            onOpenLinkModal={onOpenLinkModal}
            tabOptions={tabOptions}
          />
        }
        sideBar={
          // <SideBar
          //   history={history}
          //   state={state}
          //   fetchList={fetchList}
          //   dispatch={dispatch}
          // />
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            cerebrumLabel={'issues'}
            data={state.basicData}
            mapping={['issueSeverity','topDomain','assignedTo','createdBy','createdOn','sourceType','lastUpdated','dq_dimension','lastUpdatedBy']}
          />
        }
      />
      <KadaSnackBar
        alertDetails={alertDetails}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(IssueProfile)));
