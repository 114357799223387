import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import axiosCerebrum from '../axios-cerebrum';

function useGetIssueProject({ preventAuto = false }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const source = useRef(axios.CancelToken.source());

  const fetchIssueProject = useCallback(async () => {
    source.current.cancel();
    source.current = axios.CancelToken.source();

    if (localStorage.getItem('issueIntegration')) {
      let data;
      try{
        data = JSON.parse(localStorage.getItem('issueIntegration'));
      }
      catch(e){
        console.log(e);
      }
      // If data is not expired (1 hour), use it
      if(data && data.timestamp && new Date().getTime() - data.timestamp < 3600000 && data.data){
        setData(data.data);
        setLoading(false);
        setError(false);
        return;
      }
    }

    setLoading(true);
    setError(false);
    try {
      const response = await axiosCerebrum.get(
        "/api/integrations/project",
        {
          cancelToken: source.current.token
        }
      );
      setData(response.data);
      localStorage.setItem('issueIntegration', JSON.stringify({data: response.data, timestamp: new Date().getTime()}));
    } catch (error) {
      setLoading(false);
      if (error.message) setError(error);
      console.log(error);
    }
    setLoading(false);
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (preventAuto) return;
    fetchIssueProject();
  // eslint-disable-next-line
  }, [preventAuto, fetchIssueProject, source]);

  return {
    data,
    loading,
    error,
    fetchIssueProject
  }
}

export default useGetIssueProject;
