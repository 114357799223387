import React, { useEffect } from 'react';
import { withStyles, Typography, Button, InputBase} from '@material-ui/core';
import theme from '../../../theme';
import axiosCerebrum from '../../../axios-cerebrum'
import { getIconComponent } from '../../../utilities'
import KTooltip from '../../UI/KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { KadaLoadingSpinner, kLoadingSpinnerVariants } from 'kada-component-library';

const styles = theme => ({
  detailInputBase:{
    ...theme.components.inputBase,
    width:618,
    '& input':{
      padding:'16px 16px'
    }
  },
  checkbox:{
    color:theme.palette.primary.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  inputContainer:{
    marginBottom:16
  },
  tooltip:{
    fontSize:13.75,
  },
  focusBorder:{
    border: `1px solid ${theme.palette.error.main}`
  },
  helperText:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    paddingLeft:16,
    marginTop:3
  },
})

const ConnectionManager = props => {

  const {
    classes,
    state,
    dispatch,
    // integration,
    createdIntegration,
    directConnectionSettings,
    setDirectConnectionSettings,
    connectionInitialised,
    setConnectionInitialised,
    // connectionTested,
    setConnectionTested,
  } = props;

  const {
    sendAlert
  } = useAlert({
    id:`create-integration-connection-manager`,
  })

  useEffect(()=>{

    if(state.createIntegrationStep>=2  && createdIntegration && !connectionInitialised){
      axiosCerebrum
        .get(`/api/connections?source_id=${createdIntegration.id}`)
        .then(async response=>{
          if(response.data.total===0){
            setConnectionInitialised(true)
            return;
          }
          let connectionSetting = Object.create(null)
          Object.keys(directConnectionSettings).forEach(k=>{
            connectionSetting[k] = directConnectionSettings[k]
          })
          let allConnected = true;
          for(let i = 0; i<response.data.items.length; i++){
            let connection = response.data.items[i];
            let connectionName = connection.connection_template.name
            if(connectionSetting[connectionName]){
              connectionSetting[connectionName].saved = true
            }
            if(!connection.active_flag){
              allConnected = false;
            }
          }

          if(!allConnected){
            for(let i = 0; i<response.data.items.length; i++){
              let connectionName = response.data.items[i].connection_template.name;
              connectionSetting[connectionName]['_password'] = '';
              connectionSetting[connectionName].saved = false;
            }
          }
          setConnectionInitialised(true)
          setDirectConnectionSettings(connectionSetting)
        })
        .catch(error=>{
          console.log(error)
          setConnectionInitialised(true)
        })
      if(!['TOOL','IDENTITY_STORE'].includes(createdIntegration.source_template.type)){
        let connectionSetting = {}
        let connectionTemplates = createdIntegration.connections;
        connectionTemplates.forEach(el=>{
          let template = el.connection_template;
          connectionSetting[template.name] = {
            id:el.id,
            '_username':el._username,
            '_password':'',
            'connection_string':'',
            saved:false,
          }
        })
        setDirectConnectionSettings(connectionSetting)
      }
    }
    // eslint-disable-next-line
  },[state.createIntegrationStep])

  const checkIsValidJson = text => {
    let isValid = true;
    try{
      JSON.parse(text)
    }catch(error){
      isValid = false
    }
    return isValid;
  }

  const directConnecitonSettingChanged = (name, field, val) => {
    setConnectionTested({})
    setDirectConnectionSettings({
      ...directConnectionSettings,
      [name]:{
        ...directConnectionSettings[name],
        [field]:val,
        saved:false
      }
    });
  }

  const connectionBlockFilled = name => {
    if(!directConnectionSettings[name])return false;
    let allFilled = true;
    let selectedTemplate = createdIntegration.connections.find(el=>el.connection_template.name===name)
    if(!selectedTemplate)return false;
    selectedTemplate.connection_template.args.forEach(arg=>{
      if(arg.optional===false && (!directConnectionSettings[name][arg.populates] || (directConnectionSettings[name][arg.populates].trim()+'')==='' ) ){
        allFilled = false;
      }
      if(arg.type==='JSON' && directConnectionSettings[name][arg.populates] && directConnectionSettings[name][arg.populates].trim()!==''){
        if(!checkIsValidJson(directConnectionSettings[name][arg.populates]))allFilled = false
      }
    })
    return allFilled;
  }

  const onSaveConnection = async names => {
    let newObj = {...directConnectionSettings}
    for await (let name of names){
      let payload = {
        "_password": directConnectionSettings[name]['_password'] || '',
        "_username": directConnectionSettings[name]['_username'] || '',
        "connection_string": directConnectionSettings[name]['connection_string'] || ''
      }
      await axiosCerebrum.put(
        `/api/connections/${createdIntegration.connections.find(el=>el.connection_template.name===name).id}`,
        payload
      // eslint-disable-next-line
      ).then(async response=>{
        newObj = {
          ...newObj,
          [name]:{
            ...newObj[name],
            saved:true
          }
        }
      // eslint-disable-next-line
      }).catch(error=>{
        console.log(error);
        newObj = {
          ...newObj,
          [name]:{
            ...newObj[name],
            saved:false,
          }
        }
        sendAlert({message:`Error occurred updating connection ${name}`,type:'error'})
      })
    }
    let allSaved = true;
    console.log(newObj)
    Object.keys(newObj).forEach(key=>{
      if(!newObj[key].saved){
        allSaved = false;
      }
    })
    if(allSaved){
      dispatch({type:'set_create_integration_step',createIntegrationStep:3})
    }
    setDirectConnectionSettings(newObj)
  }

  const generateConnectionInput = () => {
    // let template = state.integrationTemplates.find(el=>el.id===integration)
    let connectionTemplates = [
      {
        connection_template:{
          "id": 15,
          "name": "JIRA_BASIC_AUTH",
          "description": "Basic Authentication for JIRA using Email and API Token.",
          "args": [
              {
                  "name": "Email",
                  "description": "The Atlassian account email address associated with the API token.",
                  "type": "STRING",
                  "default": "username",
                  "secret": false,
                  "optional": false,
                  "populates": "_username"
              },
              {
                  "name": "API Token",
                  "description": "API token generated from Atlassian for authentication.",
                  "type": "STRING",
                  "default": "token",
                  "secret": true,
                  "optional": false,
                  "populates": "_password"
              }
          ]
        }
      }
    ];
    const connectionInputsArr = [];
    connectionTemplates.forEach((t,index)=>{
      const template = t.connection_template;
      const name = template.name;

      const getValue = (arg) => {
        let value = directConnectionSettings[name]?directConnectionSettings[name][arg.populates]:''
        // if(arg.secret && isEdit && !value)return '********'
        return value
      }

      const isValueEntered = (value, isPassword, type) => {
        if(isPassword && directConnectionSettings[name] && directConnectionSettings[name].saved){
          return true
        }
        else if(type==='JSON' && value.trim()!==''){
          return checkIsValidJson(value)
        }
        else{
          return value && value.trim()!==''
        }
      }

      connectionInputsArr.push(
        <div style={{marginTop:index===0?0:24}}>
          <div style={{display:'flex',alignItems:'center',marginBottom:16}}>
            <Typography color='primary' style={{fontSize:13.75}}>{name}</Typography>
            <KTooltip title={template.description} classes={{tooltip:classes.tooltip}}  interactive={true}>
              <div style={{width:20,height:20,marginLeft:12}}>
                {getIconComponent({label:'info',size:20,colour:theme.palette.primary.main})}
              </div>
            </KTooltip>
          </div>
          {
            t.connection_template.args.map(arg=>(
              <div className={classes.inputContainer}>
                <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
                  <Typography color='primary' style={{fontSize:13.75}}>{arg.name}</Typography>
                  <KTooltip title={arg.description} classes={{tooltip:classes.tooltip}}  interactive={true}>
                    <div style={{width:20,height:20,marginLeft:12,flexGrow:1}}>
                      {getIconComponent({label:'info',size:20,colour:theme.palette.primary.main})}
                    </div>
                  </KTooltip>
                  {
                    arg.type==='JSON' &&
                    <Button
                      color='primary'
                      variant="outlined"
                      onClick={()=>{
                        try{
                          let value = JSON.stringify(JSON.parse(getValue(arg),null,2), null, 2)
                          directConnecitonSettingChanged(name,arg.populates,value)
                        }catch(error){
                          console.log(error)
                          sendAlert({message:'Not a valid JSON',type:'error'})
                        }

                      }}
                    >
                      FORMAT
                    </Button>
                  }
                </div>
                <InputBase
                  className={classes.detailInputBase +  (!isValueEntered(getValue(arg), arg.secret, arg.type) && arg.optional===false?' '+classes.focusBorder:'')}
                  variant="filled"
                  type={arg.secret?"password":undefined}
                  placeholder={arg.name}
                  value={getValue(arg)}
                  onChange={(event)=>directConnecitonSettingChanged(name,arg.populates,event.target.value)}
                  InputProps={{
                    startAdornment: <HelpIcon style={{color:'#888',width:24,height:24}}/>
                  }}
                  multiline={arg.type==='JSON'}
                  rowsMax={arg.type==='JSON'?4:undefined}
                  rows={4}
                />
                <Typography className={classes.helperText} style={!isValueEntered(getValue(arg), arg.secret, arg.type) && arg.optional===false?{color:theme.palette.error.main}:undefined}>
                  {arg.optional===false?'Required':'Optional'}{arg.type==='JSON'?'. Must be valid JSON':''}
                </Typography>
              </div>
            ))
          }
        </div>
      )
    })

    connectionInputsArr.push(
      <div style={{display:'flex',alignItems:'center'}}>
        <Button
          disabled={connectionTemplates.map(el=>el.connection_template.name).some(name=>!connectionBlockFilled(name))}
          variant='contained'
          color='primary'
          onClick={()=>onSaveConnection(connectionTemplates.map(el=>el.connection_template.name))}
        >
          SAVE
        </Button>
      </div>
    )

    return connectionInputsArr;
  }

  const connectionInput = generateConnectionInput();

  if(!connectionInitialised){
    return <KadaLoadingSpinner variant={kLoadingSpinnerVariants.secondary} />
  }

  return (
    connectionInput
  )
}


export default withStyles(styles)(ConnectionManager);
