import React,{useEffect, useRef, useState} from 'react';
import {withTheme, Typography, withStyles, Button, Modal, Paper, CircularProgress, InputBase, LinearProgress } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import { useStore } from 'react-redux'
import { formatNumber, getIconComponent, getInitials } from '../../../utilities';
import KTooltip from '../KTooltip/KTooltip';
import { checkProfileEditable } from '../../../permissionChecker';
import { withRouter } from 'react-router-dom'
import useAlert from '../../../hooks/useAlert';
import { KadaAvatar, KadaBtn, KadaIcon, KadaIconBtn, KadaLoadingSpinner, kButtonVariants, kLoadingSpinnerVariants } from 'kada-component-library';

const styles = theme => ({
  modalContainer:{
    width:880,
    maxWidth:'85vw',
    margin:'auto',
    marginTop:'15vh',
    outline:'none'
  },
  formBody:{
    // minHeight:'30vh',
    maxHeight:'65vh',
    padding:24,
    paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    overflowY:'auto',
    overflowX:'hidden',
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.light
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: theme.palette.primaryText.light,
      borderRadius:4,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{

    },
    '&::-webkit-scrollbar': {
      width: 5
    },
  },
  deleteFormBody:{
    padding:24,
    paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
  },
  inputContainer:{
    display:'flex',
    marginBottom:30
  },
  inputTitle:{
    width:160,
    paddingTop:8,
    flexShrink:0,
    flexGrow:0,
    marginRight:8,
    color:theme.palette.primaryText.main,
    fontSize:16
  },
  textFieldContainer:{
    flexGrow:1,
    flexShrink:1
  },
  inputBase:{
    ...theme.components.inputBase,
    '& textarea':{
      marginBottom:6,
      marginLeft:16,
      marginTop:6
    },
    '& input':{
      margin:6,
      marginLeft:16
    },
    width:'100%'
  },
  helperText:{
    fontSize:12,
    marginLeft:16,
    color:theme.palette.primaryText.light
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  focusBorder:{
    border:`1px solid ${theme.palette.error.main} !important`,
  },
  focusFont:{
    color:theme.palette.error.main
  },
  columnHeader:{
    color:theme.palette.primaryText.light,
    letterSpacing:2,
    fontSize:12,
    paddingRight:16
  },
  listText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    padding:'12px 16px 12px 0'
  },
  tooltip:{
    fontSize:13.75,
    whiteSpace:"pre-wrap"
  },
  clickableItem:{
    cursor:'pointer',
    "&:hover":{
      background:theme.palette.hovered.main
    }
  },

  avatar:{
    width:24,
    height:24,
    borderRadius:12,
    flexShrink:0,
    background:theme.palette.avatar.main,
    color:'#fff',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:12,
    marginRight:8
  },
  scrollableContent:{
    maxHeight:158,
    height:'max-content',
    whiteSpace:'pre-wrap',
    marginTop:8,
    color:theme.palette.primaryText.light,
    overflow:'auto',
    fontSize:13.75,
    width:'100%',
    paddingRight:'40%',
    boxSizing:'border-box',
    ...theme.components.customScroll,
  },
  detailBodyText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    marginBottom:24,
    whiteSpace:"pre-wrap"
  },
})

function AddtionalPropertyAdder(props) {

  const {
    theme,
    classes,
    history,
    object,
    state,
    dispatch,
    modalOnly,
    modalOpen,
    setModalOpen,
    disableEditing,
    title,
    subTitle
  } = props;


  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  const [seeMore, setSeeMore] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [creating, setCreating] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const [editing, setEditing] = useState(false);
  const [modalType, setModalType] = useState('create'); // create, delete, edit

  const [internalModalOpen, setInternalModalOpen] = useState(false);

  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [description, setDescription] = useState('')

  const [expandedItems, setExpandedItems] = useState([])

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  const loadProperties = () => {
    dispatch({
      type:'set_additional_properties',
      additionalProperties:state.additionalProperties,
      additionalPropertiesLoading:true
    })
    axiosCerebrum
      .get(
        `/api/additionalproperties/${object.id}`,
      )
      .then(response=>{
          dispatch({
            type:'set_additional_properties',
            additionalProperties:response.data
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_additional_properties',
          additionalPropertiesError:true
        })
      })
  }

  useEffect(()=>{
    if(!state.additionalProperties && !modalOnly){
      loadProperties({})
    }
  // eslint-disable-next-line
  },[])


  const onCancel = () => {
    setModalOpen(false);
    setInternalModalOpen(false)
    setName('');
    setValue('');
    setDescription('');
  }

  const onSave = async () => {
    setCreating(true);
    let operation;
    if(['create','edit'].includes(modalType)){
      operation = 'ADD_OR_UPDATE'
    }
    if(modalType==='delete'){
      operation = 'CLEAR'
    }
    axiosCerebrum
      .put(
        `/api/additionalproperties/${object.id}`,
        [{
          operation,
          name,
          value,
          description,
          type:'STRING'
        }]
      )
      .then(response=>{
        if(modalType==='create'){
          sendAlert({message:`Property "${name}" successfully created`,type:'info'})
        }
        if(modalType==='edit'){
          sendAlert({message:`Property "${name}" successfully updated`,type:'info'})
        }
        setCreating(false);
        loadProperties();
        onCancel()
      })
      .catch(error=>{
        console.log(error);
        setCreating(false)
        setAlertOpen(true);
        let verb = '';
        switch(modalType){
          case 'create':
            verb = 'creating';
            break;
          case 'edit':
            verb = 'editing';
            break
          case 'delete':
            verb = 'deleting';
            break;
          default:
        }
        setAlertMessage(`Error occurred ${verb} the property, please try again.`)
      })
  }

  const onEditProperty = property => {
    setName(property.name);
    setDescription(property.description);
    setValue(property.value);
    setModalType('edit');
    setInternalModalOpen(true)
  }

  const onDeleteProperty = property => {
    setName(property.name)
    setDescription("")
    setValue("")
    setModalType("delete");
    setInternalModalOpen(true)
  }


  const isRequiredFilled = () => {
    if(name.trim()==='')return false;
    if(value.trim()==='')return false;
    return true;
  }

  let list;;
  if(state.additionalProperties){
    if(seeMore)list = state.additionalProperties.additional_properties;
    else{list = state.additionalProperties.additional_properties.slice(0,3)}
  }

  const onExpand = (event, id) => {
    let el = event.currentTarget.parentElement;
    if(!el)return;
    if(!expandedItems.includes(id)){
      expandedItems.forEach(i=>{
        onExpand({currentTarget:{parentElemenet:document.getElementById(`additional_property_${i}`)}}, i)
      })
      el.style["max-height"] = '335px';
      setExpandedItems([id])
    }else{
      el.style["max-height"] = '47px';
      setTimeout(()=>setExpandedItems(expandedItems.filter(e=>e!==id)),100)
    }

  }

  const isValueOverflow = value => {
    return value.length > 75
  }

  const formatValue = el => {
    let newV = el.value;
    if(el.type==='STRING')return newV;
    if(el.type==='JSON'){
      try{
        newV = JSON.stringify(JSON.parse(value),null,2)
      }catch(error){
        console.log(error)
      }
    }
    if(el.type==='INTEGER'){
      try{
        newV = formatNumber(Number(value).valueOf())
      }catch(error){
        console.log(error)
      }
    }
    return newV
  }

  return (
    <div className={classes.root}>
      {
        !modalOnly &&
        <div id="profile-property-section" tabIndex={0} className="collapse collapse-arrow overflow-visible">
          {
            expanded &&
            <div className="flex justify-end absolute right-12 top-4.5 z-10">
              {
                checkProfileEditable({sessionData, isStewardOrOwner:state.isStewardOrOwner}) &&  !disableEditing &&
                <KadaIconBtn
                  iconName='add'
                  size={26}
                  onClick={()=>{setModalType('create');setInternalModalOpen(true)}}
                  color='var(--color-light-text)'
                  tooltip='Add a new property'
                />
              }
              {
                checkProfileEditable({sessionData, isStewardOrOwner:state.isStewardOrOwner}) && !disableEditing && list && list.length>0 &&
                <KadaIconBtn
                  iconName={editing?'clear':'edit'}
                  size={26}
                  onClick={()=>{setEditing(!editing)}}
                  color='var(--color-light-text)'
                  className='ml-2'
                  tooltip={editing?'Cancel edit':'Edit'}
                />
              }
            </div>
          }
          <input
            type="checkbox"
            onChange={()=>setExpanded(!expanded)}
          />
          <div className="collapse-title flex items-center">
            <div>
              <span className="text-sm text-(--color-header-text)">
                {title || 'PROPERTIES'} ({state.additionalProperties?state.additionalProperties.additional_properties.length:0})
              </span>
              <p className='!text-xs text-(--color-light-text)'>
                {subTitle || 'Additional properties added by external applications'}
              </p>
            </div>
          </div>
          <div className="collapse-content overflow-hidden">
            {
              state.additionalProperties && list.length===0 &&
              <p>No property added</p>
            }
            {
              list && list.length>0 &&
              <div style={{display:'flex',width:'100%',height:24,alignItems:'center'}}>
                <p className="!text-xs pr-4 text-(--color-light-text) tracking-widest" style={{marginLeft:56,flex:'0 0 25%'}}>NAME</p>
                <p className="!text-xs pr-4 text-(--color-light-text) tracking-widest" style={{flex:'0 1 75%'}}>VALUE</p>
              </div>
            }
            {
              list && list.map((el, index)=>(
                <div key={el.name} id={`additional_property_${index}`} style={{transition: "max-height .1s ease",maxHeight:47,display:'flex',flexDirection:'column', overflow:'hidden',border:`1px solid var(--color-base-border)`, borderStyle:expandedItems.includes(index)?'solid':'none none solid none'}}>
                  <div onClick={!editing && isValueOverflow(formatValue(el)) ? event=>onExpand(event, index): undefined} className={isValueOverflow(formatValue(el))?classes.clickableItem:undefined}  style={{display:'flex',width:'100%',alignItems:'flex-start',height:47}}>
                    <KTooltip title={el.description && el.description.trim()!==''?el.description:'No description'}>
                      <div style={{width:24,height:24,margin:'12px 16px',flexShrink:0,flexGrow:0}}>
                        {getIconComponent({label:'info',size:20,colour:theme.palette.primary.main})}
                      </div>
                    </KTooltip>
                    <KTooltip placement='bottom-start' title={el.name}>
                      <Typography className={classes.listText} style={{flex:expandedItems.includes(index)?'1 1 100%':'0 0 25%',fontWeight:expandedItems.includes(index)?700:400}}>{el.name}</Typography>
                    </KTooltip>
                    {
                      !expandedItems.includes(index) &&
                      <KTooltip placement='bottom-start' title={el.value}>
                        <Typography className={classes.listText} style={{flex:'0 1 75%',paddingRight:0}}>{isValueOverflow(formatValue(el))?formatValue(el).slice(0,75)+'...':formatValue(el)}</Typography>
                      </KTooltip>
                    }
                    {
                      editing &&
                      <KadaIconBtn
                        iconName='edit'
                        className='mt-3'
                        size={24}
                        iconColor='var(--color-light-text)'
                        onClick={()=>{onEditProperty(el)}}
                      ></KadaIconBtn>
                    }
                    {
                      editing &&
                      <KadaIconBtn
                        iconName='close'
                        className='mt-3 ml-1'
                        size={24}
                        iconColor='var(--color-light-text)'
                        onClick={()=>{onDeleteProperty(el)}}
                      ></KadaIconBtn>
                    }
                    {
                      isValueOverflow(formatValue(el)) && !editing &&
                      <div className="mt-3 mr-1">
                        {
                          expandedItems.includes(index)?
                          <KadaIcon
                            iconName='keyboard_arrow_up'
                            size={24}
                            color="var(--color-light-text)"
                          ></KadaIcon>:
                          <KadaIcon
                            iconName='keyboard_arrow_down'
                            size={24}
                            color="var(--color-light-text)"
                          ></KadaIcon>
                        }
                      </div>
                    }
                  </div>
                  {
                    expandedItems.includes(index) &&
                    <div style={{flex:'1 1', height:'100%', display:'flex', flexDirection:'column',paddingLeft:56,paddingTop:8,paddingBottom:16,marginBottom:8}}>
                      <div style={{flex:'1 1' }}>
                        <div style={{display:'flex',alignItems:'center',marginBottom:24}}>
                          {
                            el.user?.name &&
                            <div style={{flex:"1 1 33%",marginRight:16}}>
                              <div className="flex items-center cursor-pointer" onClick={()=>history.push(`/profile/user/${el.user.id}`)}>
                                <KadaAvatar
                                  text={getInitials(el.user.name)}
                                  className="mr-2"
                                />
                                <div className="tooltip" data-tip={el.user.name}>
                                  <p data-test-classname="property-list-expanded-user">
                                    {el.user.name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                        <p className={'!text-xs text-primary mt-3 tracking-widest'} style={{marginTop:12}}>VALUE</p>
                        <div className={classes.scrollableContent}>
                          {formatValue(el)}
                        </div>
                      </div>
                    </div>
                  }
                </div>
              ))
            }
            {
              state.additionalPropertiesLoading && <KadaLoadingSpinner style={{marginTop:16}} variant={kLoadingSpinnerVariants.secondary}></KadaLoadingSpinner>
            }
            {
              state.additionalPropertiesError && <p style={{marginBottom:16}}>Error occurred loading details</p>
            }
            <div style={{display:'flex'}}>
              {
                state.additionalProperties && state.additionalProperties.additional_properties.length>3 && !seeMore && !state.additionalPropertiesLoading &&
                <KadaBtn text='SEE MORE' variant={kButtonVariants.primaryBorderless} style={{marginTop:8}} onClick={()=>{setSeeMore(true)}}></KadaBtn>
              }
              {
                state.additionalProperties && seeMore && !state.additionalPropertiesLoading &&
                <KadaBtn text="SEE LESS" variant={kButtonVariants.primaryBorderless} style={{marginTop:8,marginLeft:8}} onClick={()=>{setSeeMore(false)}}></KadaBtn>
              }
            </div>
          </div>
        </div>
      }
      {
        !disableEditing &&
        <Modal
          open={ (modalOnly && modalOpen) || internalModalOpen}
          onClose={()=>onCancel()}
          disableBackdropClick={true}
        >
          <div className={classes.modalContainer} style={{width:modalType==='delete'?450:undefined}}>
            {
              ['edit','create'].includes(modalType) &&
              <Paper className={classes.formBody}>
                <Typography style={{color:theme.palette.header.main,fontSize:20,marginBottom:24}}>
                  {
                    modalType==='edit'?
                    `Edit ${name} Property`:
                    `Add a new Property`
                  }
                </Typography>
                {
                  modalType==='create' &&
                  <div className={classes.inputContainer}>
                    <Typography className={classes.inputTitle}>Name</Typography>
                    <div className={classes.textFieldContainer}>
                      <InputBase
                        className={classes.inputBase + (name.trim()===''?` ${classes.focusBorder}`:'')}
                        value={name}
                        onChange={event=>{if(event.target.value.length<=50)setName(event.target.value)}}
                        placeholder={'Add a property name'}
                      />
                      <Typography className={classes.helperText + (name.trim()===''?` ${classes.focusFont}`:'')}>Required. Max 50 characters</Typography>
                    </div>
                  </div>
                }
                <div className={classes.inputContainer}>
                  <Typography className={classes.inputTitle}>Value</Typography>
                  <div className={classes.textFieldContainer}>
                    <InputBase
                      className={classes.inputBase + (value.trim()===''?` ${classes.focusBorder}`:'')}
                      value={value}
                      onChange={event=>setValue(event.target.value)}
                      placeholder={'Add a value'}
                      multiline={true}
                      rows={4}
                    />
                    <Typography className={classes.helperText + (value.trim()===''?` ${classes.focusFont}`:'')}>Required</Typography>
                  </div>
                </div>
                <div className={classes.inputContainer}>
                  <Typography className={classes.inputTitle}>Description</Typography>
                  <div className={classes.textFieldContainer}>
                    <InputBase
                      className={classes.inputBase}
                      value={description}
                      onChange={event=>setDescription(event.target.value.slice(0,250))}
                      placeholder={'Add a description'}
                      multiline={true}
                      rows={4}
                    />
                    <Typography className={classes.helperText}>Optional. Displayed as Tool tip. Max 250 characters</Typography>
                  </div>
                </div>
                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',marginTop:60}}>
                  {
                    !creating &&
                    <Button color='primary' style={{marginRight:8,width:96}} classes={{disabled:classes.disabledButton}} onClick={onSave} disabled={!isRequiredFilled()}>
                      {modalType==='edit'?'SAVE':'ADD'}
                    </Button>
                  }
                  {
                    creating && <div style={{width:96,height:36,display:'flex',marginRight:8,alignItems:'center',justifyContent:'center'}}><CircularProgress color='secondary' style={{width:24,height:24}}/></div>
                  }
                  <Button color='secondary' style={{width:96}} onClick={onCancel}>CANCEL</Button>
                </div>
              </Paper>
            }
            {
              modalType==='delete' &&
              <Paper className={classes.deleteFormBody}>
                <Typography style={{fontSize:20,color:theme.palette.primaryText.main,marginBottom:12}}>Delete {name} property</Typography>
                {
                  creating?
                  <div style={{textAlign:'center'}}>
                    <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                    <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Deletion in progress</Typography>
                  </div>
                  :
                  <Typography style={{fontSize:16,whiteSpace:'pre-wrap',color:theme.palette.primaryText.light}}>
                    {`Are you sure?\n\nThis will permanently remove the property`}
                  </Typography>
                }

                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',marginTop:60}}>
                  <Button color='primary' disabled={creating} style={{marginRight:8,width:96}} classes={{disabled:classes.disabledButton}} onClick={onSave}>
                    DELETE
                  </Button>
                  <Button color='secondary' style={{width:96}} onClick={onCancel}>CANCEL</Button>
                </div>
              </Paper>
            }

            <ModalAlert
              setOpen={setAlertOpen}
              open={alertOpen}
              message={alertMessage}
              type={'error'}
            />
          </div>
        </Modal>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(withRouter(AddtionalPropertyAdder)));
