import React  from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import AdditionalPropertyAdder from '../../UI/AdditionalPropertyAdder/AdditionalPropertyAdder'
import AccessAdder from '../../UI/AccessAdder/AccessAdder'
import SourceDescription from '../../UI/SourceDescription/SourceDescription';

const styles = theme => ({

})

const Details = props => {

  const {
    state,
    fetchList,
    dispatch,
    isShell
  } = props;

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/databases/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        label="database"
        initialValue={state.basicData.description}
        data={state.basicData}
        property="description"
        placeholder="Add a description"
        multiline
        state={state}
        fetchList={fetchList}
        collection="data"
        textfieldRows={12}
        enableMarkDown={true}
        enableWidget={true}
      />

      <SourceDescription
        state={state}
        dispatch={dispatch}
      />

      {
        !isShell &&
        <div style={{ marginTop: 60 }} >
          <AdditionalPropertyAdder
            object={state.basicData}
            state={state}
            dispatch={dispatch}
            modalOpen={state.additionalPropertiesModalOpen}
            setModalOpen={value=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
          />
        </div>
      }

      {
        !isShell &&
        <div style={{ marginTop: 24 }} >
          <AccessAdder
            object={state.basicData}
            state={state}
            dispatch={dispatch}
            modalOpen={state.accessModalOpen}
            setModalOpen={value=>dispatch({type:'set_access_modal_open',accessModalOpen:value})}
          />
        </div>
      }

	  </div>
  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  userName: PropTypes.string
}

export default withStyles(styles)(Details);
