import React, { useState } from 'react';
import { withStyles, Divider, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  root: {
    display:'flex',
    alignItems:'center',
    width:'100%',
    cursor:'pointer',
  },
  hovered:{
    background:theme.palette.hovered.main
  },
  divider:{
    background:theme.palette.listItemDivider.main
  }
});

function ChipWrapper(props) {

  const {
    classes,
    wrappedComponent,
    icon,
    onIconClick,
    iconDisabled,
    iconTooltip,
    actionSet,
    showUnderline,
    onClick
  } = props;

  const [hovered, setHovered] = useState(false)
  const [actionHovered, setActionHovered] = useState(false)

  return (
    <div>
      <div onClick={onClick} className={classes.root + (hovered && !actionHovered?(' '+classes.hovered):'')} onMouseEnter={()=>setHovered(true)} onMouseLeave={()=>setHovered(false)}>
        <div style={{flex:'1 1 75%',overflow:'hidden'}}>{wrappedComponent}</div>
        {
          actionSet &&
          <div style={{flex:'0 1 30px',  marginLeft:10,marginRight:10}} onMouseEnter={()=>setActionHovered(true)} onMouseLeave={()=>setActionHovered(false)}>
            {actionSet}
          </div>
        }
        {
          !actionSet && icon?
          <KTooltip title={iconTooltip}>
            <div style={{flex:'0 1 48px', marginLeft:10,marginRight:10}} onClick={iconDisabled?undefined:onIconClick}>
              <IconButton data-test-classname="item-wrapper-icon-btn" disabled={iconDisabled} onMouseEnter={()=>setActionHovered(true)} onMouseLeave={()=>setActionHovered(false)}>{icon}</IconButton>
            </div>
          </KTooltip>
          :undefined
        }
      </div>
      {
        showUnderline && <Divider className={classes.divider}/>
      }
    </div>
  )
}

ChipWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  wrappedComponent: PropTypes.any.isRequired,
  icon: PropTypes.any.isRequired,
  onIconClick: PropTypes.func.isRequired,
  iconDisabled: PropTypes.bool,
  iconTooltip: PropTypes.string,
  showUnderline: PropTypes.bool,
  actionSet: PropTypes.any
}

export default withStyles(styles)(ChipWrapper);
