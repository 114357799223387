import React,{ } from 'react';
import PropTypes from 'prop-types';
import GenericLinkModal from '../GenericLinkModal/GenericLinkModal';

function GlossaryModalAdder(props) {

  const {
    history,
    object, // in cerebrum api format
    modalOpen,
    setModalOpen,
  } = props;


  return (
    <GenericLinkModal
      object={object}
      history={history}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      linkableObjects={['TERM']}
      relations={['RELATED']}
      // onLinkUpdated={linkModalOpen.onLinkUpdated}
    />
  )

}

GlossaryModalAdder.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
}

export default GlossaryModalAdder;
