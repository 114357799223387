import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem, Popper, Paper, ClickAwayListener,MenuList, Checkbox, InputBase, IconButton} from '@material-ui/core';
import { onClickResultItem,getDispFields,transformLogScale, getIconComponent, isInViewport, getSearchMode, formatNumber, getNameSearchQuery } from '../../../utilities';
import UsageListItem from '../../UI/SearchResults/UsageListItem';
import * as actions from '../../../store/actions/index';
import { connect, useStore } from 'react-redux';
import axiosSolr from '../../../axios-solr';
import CartAdder, { addToCartLimit } from '../../UI/CartAdder/CartAdder';
import { checkCanAddToCart, checkCanBulkUpdate } from '../../../permissionChecker';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
	root: {
		width: '100%'
	},
	title: {
		color: theme.palette.header.main,
		fontSize: 20,
		fontWeight: '400'
	},
	subtitle: {
		fontSize: '0.75rem',
		fontWeight: '400',
		color: theme.palette.primaryText.light,
		// width: '15rem'
	},
	selector: {
		...theme.components.selector,
		width: 180,
	},
	loadingUsage:{
		color:theme.palette.primaryText.main
	},
	listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
	menuItem:{
		padding:'10px 10px 10px 16px',
		color:theme.palette.primaryText.main,
		'&:hover':{
      background: theme.palette.hovered.main
    }
	},
  checkbox:{
    paddingLeft:0,
  },
  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
	searchFilter:{
    ...theme.components.inputBase,
    marginRight:24,
		width:160,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
	emptyMsg:{
		fontSize:13.75,
		colour:theme.palette.primaryText.main,
		'& span':{
			textDecoration:'underline',
			cursor:'pointer',
		}
	}
})

const ChildList = props => {

	const {
		history,
		theme,
		classes,
		state,
		dispatch,
		setBulkEditPrams,
		isShell
	} = props;

	const scrollRef = useRef();
	const [maxFrequency, setMaxFrequency] = useState(0);
	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
	const searchTimeoutRef = useRef()

  const store = useStore();
  const sessionData = store.getState().auth.session_user;

	const [addToCartIds, setAddToCartIds] = useState()

	const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


	const sortByItems = [
		{ dispName: 'Frequently used', value: 'total_usage_srt desc' },
		{ dispName: 'Recently used', value: 'last_used_srt desc' },
		{ dispName: 'A-Z', value: 'name_srt asc' },
		{ dispName: 'Z-A', value: 'name_srt desc' }
	]

	const onLoading = ({sort=state.childSort,showDeleted=state.showDeletedChild,showReference=state.showReferenceChild,start=0,search=state.childSearch, addToCart}) => {
		if(!addToCart){
			dispatch({
				type:'set_child_data',
				childListLoading:true,
				childListData:start===0?undefined:state.childListData
			})
		}
		if(!sort.includes('name_srt')){sort+=', name_srt asc'}
		const queryParams = {
			q: getNameSearchQuery(search),
			fl:'*',
			fq: `object_type_srt:REPORT AND hierarchy_parent_id_srt:${state.basicData.id} ${showDeleted?'':'AND -active_srt:NO'} ${showReference?'':'AND reference_srt:NO'} `,
			sort: search?undefined:sort,
			rows: addToCart?addToCartLimit:20,
			start: addToCart?0:start,
      mm: '90%'
		};
		axiosSolr.get('/solr/search/select', { params: queryParams }, { withCredentials: true })
			.then(response => {
				if(addToCart){
					setAddToCartIds(response.data.response.docs.map(d=>d.id))
					return;
				}
				let newData = {...response.data.response}
				if(start===0){
					dispatch({ type: 'set_child_data', childListData: newData})
					return;
				}
				newData.docs = [...state.childListData.docs,...response.data.response.docs]
				dispatch({ type: 'set_child_data', childListData: newData})
			})
			.catch(error => {
				if(addToCart){
					sendAlert({message:'Error occurred adding items to Cart',type:'error'})
					return;
				}
				console.log('Error retrieving data from cerebrum', error);
				dispatch({type:'set_child_data',childListError:true})
			})
	}

	useEffect(()=>{
		if(state.childListData)return;
		onLoading({});
	// eslint-disable-next-line
	},[])


	// eslint-disable-next-line
	const loadMore = () => {
		if (isInViewport(scrollRef) && state.childListData && state.childListData.docs.length < state.childListData.numFound && !state.childListLoading) {
			onLoading({start:state.childListData.docs.length})
		}
	}


	useEffect(()=>{
		loadMore()
	},[state.childListData,loadMore,state.childListLoading])

	window.onscroll = () => loadMore()

	if(state.childListError ){
		return <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading dataset fields</Typography>
	}

	const mapDataToRows = data => {
		if (!data) return [];
		let maxFre = maxFrequency;

		let frequencyArr = data.map(col=>col.total_usage_srt)
		let {logValue} = transformLogScale(frequencyArr,Math.E)
		maxFre=Math.max(...logValue)

		let rowArray = data.map((col,index) => {
			return {
				name: col.name_txt || 'untitled',
				id:col.id,
				definition: col.description,
				frequency: Math.max(logValue[index],0),
				labels: 'content',
				item: col
			}
		})
		if (maxFre > maxFrequency) setMaxFrequency(maxFre)
		return rowArray;
	}

	const onSortChange  = value => {
		dispatch({ type: 'set_child_sort', childSort: value })
		onLoading({sort:value})
	}

	const onClickShowReference = value => {
		dispatch({type:'set_show_reference_child',showReferenceChild:value})
		onLoading({showReference:value})
	}

	const onClickShowDeleted = value => {
		dispatch({type:'set_show_deleted_child',showDeletedChild:value})
		onLoading({showDeleted:value})
	}


	const onAddToCart = () => {
		onLoading({addToCart:true})
		setListActionOpen(false)
	}

	const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

	const onClickViewInSearch = () => {
		if(getSearchMode()==='basic_search'){
      let query = `*`;
      let presetFilter = `hierarchy_parent_id_srt:${state.basicData.id} ${state.showDeletedChild?'':'AND active_srt:YES'} ${state.showReferenceChild?'':"AND reference_srt:NO "}`;
			history.push(`/basic_search?query=${query}&object=REPORT&presetFilter=${presetFilter}`)
    }
	}

	const onClickBulkEdit = () => {
		setBulkEditPrams({
			url:'/solr/search/select',
			type:'solr',
			params:{
				q: getNameSearchQuery(state.childSearch),
				fl:'*',
				fq: `object_type_srt:REPORT AND hierarchy_parent_id_srt:${state.basicData.id}  ${state.showDeletedChild?'':'AND active_srt:YES'}  ${state.showReferenceChild?'':"AND reference_srt:NO "}`,
				rows:1000,
			},
			fileName:`${state.childListData?formatNumber(state.childListData.numFound):''} reports from ${state.basicData.name} content app`.trim(),
			redirectUrl:window.location.pathname.split('?')[0]
		})
		history.push('/bulk_update')
	}

	const onSearchChange = value => {
    dispatch({type:'set_child_search',childSearch:value})
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      onLoading({search:value})
    },250)
  }

	return (
		<div className={classes.root}>
			<div style={{ display: 'flex', alignItems: 'flex-end',justifyContent:'space-between',flexWrap:'wrap' }}>
				<div style={{ display: 'flex', flexDirection: 'column',marginBottom:24}}>
					<Typography data-test-id="child-list-title" className={classes.title}>{state.childListData?formatNumber(state.childListData.numFound):0} REPORT(S)</Typography>
					<Typography className={classes.subtitle}>
						{
							state.basicData.reference===false?
							'Select a report to see more details' :
							'Fields referenced in content. May not be a complete view of this Content App at its source'
						}
					</Typography>
				</div>
				<div style={{display:'flex',alignItems:'center',marginBottom:24,flexShrink:0}}>
					<InputBase
						value={state.childSearch}
						onChange={event => onSearchChange(event.target.value)}
						variant={'filled'}
						placeholder={'Search'}
						className={classes.searchFilter}
						endAdornment={
							<IconButton
								disabled={state.childSearch===''}
								onClick={()=>onSearchChange('')}
								style={{width:32,height:32,marginRight:6}}
							>
								{getIconComponent({label:state.childSearch===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
							</IconButton>
						}
					/>
					<Select
						className={classes.selector}
						value={state.childSort}
						MenuProps={{
							classes:{
								paper:classes.selectPaper
							}
						}}
						disabled={state.childSearch}
						onChange={event => onSortChange(event.target.value) }
					>
						{
							sortByItems.map(el => (
								<MenuItem  className={classes.menuItem} value={el.value}>
									<span>{el.dispName}</span>
								</MenuItem>
							))
						}
					</Select>
					<div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
						{getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
					</div>
					<Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
						<ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
							<Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
								<Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
								<MenuList className={classes.listContainer}>
									<MenuItem onClick={()=>{onClickViewInSearch()}} className={classes.menuItem} >
										<Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
									</MenuItem>
									{
									  checkCanBulkUpdate({sessionData}) &&
										<MenuItem onClick={()=>{onClickBulkEdit()}} className={classes.menuItem} >
											<Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Bulk action</Typography>
										</MenuItem>
									}
									{
										checkCanAddToCart({sessionData, objectType:'REPORT'}) &&
										<MenuItem data-test-id="child-list-add-cart" onClick={()=>{onAddToCart()}} className={classes.menuItem} >
											<Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Add to Cart</Typography>
										</MenuItem>
									}
								</MenuList>
								<Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
								<MenuItem onClick={()=>{onClickShowReference(!state.showReferenceChild)}} className={classes.menuItem} >
                  <Checkbox key={state.showReferenceChild} className={classes.checkbox} color='primary' checked={state.showReferenceChild}/>
                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show reference</Typography>
                </MenuItem>
								<MenuItem onClick={()=>{onClickShowDeleted(!state.showDeletedChild)}} className={classes.menuItem} >
                  <Checkbox key={state.showDeletedChild} className={classes.checkbox} color='primary' checked={!state.showDeletedChild}/>
                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                </MenuItem>
							</Paper>
						</ClickAwayListener>
					</Popper>
				</div>
			</div>

			<div className={classes.body}>
				{
					state.childListData && state.childListData.numFound===0 &&
					(
						(!state.searchFilter || state.searchFilter.trim()==='') && state.childCount && !state.showDeletedChild ?
						<Typography className={classes.emptyMsg}>No active reports found. <span onClick={()=>onClickShowDeleted(true)}>Click to see inactive reports</span></Typography>
						:
						<Typography className={classes.emptyMsg}>No reports found</Typography>
					)
				}
				{
					state.childListData && state.childListData.numFound > 0 && !isShell &&
					<div style={{display:'flex',justifyContent:'flex-end'}}><Typography style={{fontSize:12,letterSpacing:2, color:theme.palette.primaryText.main,marginRight:132,marginBottom:8}}>USAGE</Typography></div>
				}
				{
					state.childListData && mapDataToRows(state.childListData.docs).map(c => (
						<UsageListItem
							key={c.id}
							item={c.item}
							dispTitle={getDispFields(c.item, 'dispTitle')}
							frequency={c.frequency}
							maxFrequency={maxFrequency}
							onDescriptionUpdated={({item,description})=>{
								dispatch({
									type:'set_child_data',
									childListData:{
										...state.childListData,
										docs:state.childListData.docs.map(el=>{
											if(el.id!==item.id)return el;
											return {...el,description}
										})
									}
								})
							}}
							onClick={() => onClickResultItem({id:c.id, label: 'content', history: history, item: c.item })}
							enableEdit
						/>
					)
				)}
				<div
					style={{ marginBottom: 10, display:'flex',justifyContent:'center' }}
					ref={scrollRef}
				>
					{
						state.childListLoading && <CircularProgress color='secondary'/>
					}
				</div>
			</div>
			{
				checkCanAddToCart({sessionData, objectType:'REPORT'}) &&
				<CartAdder
					objectIds={addToCartIds}
					onFinish={()=>{setAddToCartIds()}}
					history={history}
				/>
			}
		</div>
	)
}

ChildList.propTypes = {
	classes: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired
}

const mapDispatchToProps = dispatch => {
  return {
		setBulkEditPrams:(data) => dispatch(actions.setBulkEditParam(data))
  }
}
const mapStateToProps = state => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(ChildList)));
