import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { tileStyles } from './utils/styles';
import { measureText } from './utils/utils';
import axiosSolr from '../../../../axios-solr';
import { KadaIcon } from 'kada-component-library';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  clickableText:{
    ...tileStyles.clickableText
  },
})

function DomainManager(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    alwaysOpenNewTab
  } = props;

  const [loading, setLoading] = useState(false)

  const isCancelledRef = useRef(false)

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  const loadDomain = () => {
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq:`id:(${object.id})`,
          rows:1,
          fl:"top_domains_msrt",
        }}
      )
      .then(response=>{
        let domains = response.data.response.docs[0].top_domains_msrt || [];

        if(domains.length===0){
          onAddTileData(
            {
              "disp_body":[],
              "disp_title": "LINKED DOMAIN",
              "id": 'topDomain',
            }
          )
          return;
        }

        axiosSolr
          .get(
            `/solr/search/select`,{params:{
              q:"*",
              fq:`collection_srt:DOMAIN AND name_srt:(${domains.map(el=>`"${el}"`).join(' OR ')})`,
              sort:"name_srt asc",
              rows:domains.length,
            }}
          )
          .then(response=>{
            domains = response.data.response.docs;
            onAddTileData(
              {
                "disp_body":domains.map(el=>({
                  labels:'collection_instance',
                  name:el.name_txt,
                  onClick:()=>{
                    alwaysOpenNewTab?
                    window.open(`/profile/collection_instance/${el.id}`, '_blank'):
                    history.push(`/profile/collection_instance/${el.id}`)
                  }
                })),
                "disp_title": "LINKED DOMAIN",
                "id": 'topDomain',
              }
            )
          })
          .catch(error=>{
            console.log(error)
            setLoading(false)
          })
      })
      .catch(error=>{
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(!tileData){
      loadDomain()
    }
  // eslint-disable-next-line
  },[])

  if(!tileData)return <></>

  return (
    <div data-test-id="top-domain-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer}>
        <Typography className={classes.title}>LINKED DOMAIN</Typography>
        <div
          className="tooltip tooltip-top ml-1 before:!w-[200px] relative -bottom-[2px]"
          data-tip="Top domains of the data and content linked to this issue. Not editable."
        >
          <KadaIcon
            iconName='lock'
            color='var(--color-light-text)'
            size={16}
          />
        </div>
      </div>
      <div className={classes.chipContainer}>
        {
          loading && <CircularProgress color='secondary' size={16}/>
        }
        {
          !loading && tileData && tileData.disp_body.length===0 &&
          <div data-test-classname="top-domain-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
            <span className={classes.tagText} style={{color:'var(--color-base-content)'}}>None</span>
          </div>
        }
        {
          !loading && tileData && tileData.disp_body.sort((a,b)=>a.name<b.name?-1:1).map(t=>(
            <div data-test-classname="top-domain-data-tile-chip" style={{background:'var(--color-primary-dark)',border:'none',width:measureText(t.name)+24}} className={classes.tag} onClick={t.onClick}>
              <span className={classes.tagText + ' ' + classes.clickableText} style={{color:'var(--color-base-100)'}}>{t.name}</span>
            </div>
          ))
        }
      </div>
    </div>
  )
}

DomainManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(DomainManager));
