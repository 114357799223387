import React, { useEffect, useRef, useState, } from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles, withTheme, Typography, ClickAwayListener, InputBase } from '@material-ui/core';
import KTooltip from '../KTooltip/KTooltip';
import { getIconComponent, getLabelPlural, handleShareClick } from '../../../utilities';
import { checkIsAdmin, checkProfileEditable } from '../../../permissionChecker';
import { useStore } from 'react-redux';
import axiosCerebrum from '../../../axios-cerebrum';
import { getPutPayload } from '../UpdateInput/utils';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  editable:{
    // boxSizing:'border-box',
    border:`1px solid ${theme.palette.background.main}`,
    '&:hover':{
      background:`${theme.palette.editHovered.main}`,
      border:`1px solid ${theme.palette.listItemDivider.main}`,
      marginLeft:-6,
      paddingLeft:6,
    }
  },
  editing:{
    background:`${theme.palette.background.main} !important`,
    border:`1px solid ${theme.palette.background.main} !important`,
    marginLeft:-6,
    paddingLeft:6,
  },
  titleReadOnly: {
    maxWidth:'100%',
    width:'max-content',
    color:theme.palette.header.main,
    overflow:"hidden",
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  subHeader:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    marginTop:4,
    wordBreak:'break-word'
  },
  primaryNameEdit:{
    fontSize:16,
    color:theme.palette.primaryText.light,
    marginRight:8,
    // maxWidth:200,
    overflow:'hidden',
    // textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  inputBase:{
    border:'none',
    fontSize:16,
    flexGrow:1,
    color:theme.palette.primary.main
  },
  button:{
    padding:2
  },
  inputSection:{
    display:'flex',
    alignItems:'center',
    flexGrow:1,
    maxWidth:830,
    borderRadius:3,
    paddingLeft:6,
    marginLeft:-6,
    border:`1px solid ${theme.palette.primary.main}`
  }
})


const TitleSection = props => {

  const {
    classes,
    data,
    state,
    dispatch,
    title,
    copiedTitle,
    label,
    description,
    isShell,
    titleFontSize
  } = props;

  const [editing, setEditing] = useState(false)
  const [mouseDownPos, setMouseDownPos] = useState()

  let initialName = (['table','column','host','data_quality_test','query'].includes(label)?data?.alternate_name:data?.name) || '';
  // if(label==='query' && !data?.alternate_name)initialName = '';

  const [name, setName] = useState(initialName)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  let sessionData = useStore().getState().auth.session_user;

  let editable = false;

  if(state?.basicData){
    if(['table','column','query','data_quality_test'].includes(label))editable = checkProfileEditable({sessionData, isStewardOrOwner: state.isStewardOrOwner})
    if(label==='issue' && !state.basicData.external_url)editable = true;
    if(label==='host' && checkIsAdmin({sessionData}))editable = true;
    if(label==='collection_instance'){
      if(sessionData.user_role && sessionData.user_role.length>0 && data?.parent?.instance_roles){
        let userRoles = sessionData.user_role.map(el=>el.toUpperCase());
        let instance_roles = data.parent.instance_roles;
        userRoles.forEach(el=>{
          if(instance_roles[el] && instance_roles[el].includes('EDIT')){
            editable = true;
          }
        })
      }
    }
  }

  if(isShell && label!=='host')editable = false;

  const processTitle = title => {
    let fontSize = 28;
    let text = title || '';
    if(text.length>100){
      fontSize = 20;
    }
    if(text.length>150){
      fontSize = 18;
    }
    return {fontSize, text}
  }

  const onSaveBusinessName = () => {
    if(name===data.alternate_name){
      setEditing(false)
      return;
    }
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(data.object?.name||label)}/${data.id}`,
        getPutPayload({property:'alternate_name',value:name,data})
      )
      .then(()=>{
        dispatch({type:'set_basic_data',basicData:{...state.basicData,alternate_name:name}})
        setEditing(false)
      })
      .catch(error=>{
        console.log(error);
        sendAlert({
          message: 'Error occurred updating business name, please try again',
          type: 'error'
        })
      })
  }

  const onSaveObjectName = (forceName) => {
    if(label==='issue'){
      if(name===data.name && !forceName){
        setEditing(false)
        return;
      }
      let finalName = name;
      if(finalName.trim()==='')finalName = data.signature;
      if(forceName)finalName = forceName;
      axiosCerebrum
        .patch(
          `/api/issues/${data.id}`,
          {name:finalName}
        )
        .then(()=>{
          dispatch({type:'set_basic_data',basicData:{...state.basicData,name:finalName}})
          if(forceName===data.signature)setName('')
          setEditing(false)
        })
        .catch(error=>{
          console.log(error);
          sendAlert({
            message: 'Error occurred updating name, please try again',
            type: 'error'
          })
        })
    }
    if(label==='collection_instance'){
      if(name===data.name){
        setEditing(false)
        return;
      }
      let payload = getPutPayload({property:'name',value:name, data});
      axiosCerebrum
        .put(
          `/api/${getLabelPlural(data.object?.name||label)}/${data.id}`,
          payload
        )
        .then(()=>{
          dispatch({type:'set_basic_data',basicData:{...state.basicData,name:name,properties:{...(data.properties||{})}}})
          setEditing(false)
        })
        .catch(error=>{
          console.log(error);
          sendAlert({
            message: 'Error occurred updating name, please try again',
            type: 'error'
          })
        })
    }
  }

  let editComponent;
  if(editable && ['table','column','host','data_quality_test','query'].includes(label)){
    editComponent = (
      <div style={{display:'flex',width:'100%',alignItems:'center'}}>
        <div className={classes.inputSection}>
          <Typography className={classes.primaryNameEdit}>{data.name.slice(0,30)}{data.name.length>30?'...':''}</Typography>
          <Typography className={classes.primaryNameEdit}>-</Typography>
          <InputBase
            className={classes.inputBase}
            value={name}
            onChange={event=>setName(event.target.value.slice(0,60))}
            autoFocus
            inputProps={{
              'data-test-id':"title-edit-input"
            }}
            onKeyDown={event=>{
              if(event.key==='Enter'){
                onSaveBusinessName()
              }
            }}
            placeholder={'Add a business name'}
          />
        </div>
        <Button
          className={classes.button}
          onClick={(event)=>{
            event.stopPropagation();
            onSaveBusinessName()
          }}
          color="primary"
          variant='contained'
          style={{marginLeft:12,marginRight:12}}
          data-test-id="title-save-button"
        >
          SAVE
        </Button>
        <Button
          className={classes.button}
          onClick={(event)=>{
            event.stopPropagation();
            setEditing(false);
            setName(initialName);
          }}
          color="secondary"
          style={{marginRight:8}}
          data-test-id="title-cancel-button"
        >
          CANCEL
        </Button>
      </div>
    )
  }

  if(editable && ['collection_instance','issue'].includes(label)){
    editComponent = (
      <div style={{display:'flex',width:'100%',alignItems:'center'}}>
        <div className={classes.inputSection}>
          <InputBase
            className={classes.inputBase}
            value={name}
            onChange={event=>setName(event.target.value.slice(0,label==='query'?60:120))}
            autoFocus
            inputProps={{
              'data-test-id':"title-edit-input"
            }}
            placeholder={'Edit the name'}
            onKeyDown={event=>{
              if(event.key==='Enter' && name.trim()!==''){
                onSaveObjectName()
              }
            }}
          />
        </div>
        <Button
          className={classes.button}
          onClick={(event)=>{
            event.stopPropagation();
            onSaveObjectName()
          }}
          disabled={name.trim()===''}
          color="primary"
          variant='contained'
          data-test-id="title-save-button"
          style={{marginLeft:12,marginRight:12}}
        >
          SAVE
        </Button>
        {
          initialName!=='' && label==='query' &&
          <Button
            className={classes.button}
            onClick={(event)=>{
              event.stopPropagation();
              onSaveObjectName(data.signature);
            }}
            disabled={name.trim()===''}
            color="primary"
            variant='contained'
            data-test-id="title-reset-button"
            style={{marginRight:12}}
          >
            RESET
          </Button>
        }
        <Button
          className={classes.button}
          onClick={(event)=>{
            event.stopPropagation();
            setEditing(false);
            setName(initialName);
          }}
          data-test-id="title-cancel-button"
          color="secondary"
        >
          CANCEL
        </Button>
      </div>
    )
  }

  const handleEditClick = (event) => {
    if(!mouseDownPos)return;
    if(event.clientX!==mouseDownPos.x || event.clientY!==mouseDownPos.y)return;
    setEditing(true);
    setMouseDownPos()
  }

  return (
    <div style={{marginTop:10}}>
      <ClickAwayListener onClickAway={()=>{setEditing(false);setName(initialName)}}>
        <div
          onMouseDown={(event)=>editable && setMouseDownPos({x:event.clientX, y:event.clientY})}
          onMouseUp={editable && handleEditClick}
          // onClick={()=>editable && setEditing(true)}
          style={{height:36,display:'flex',alignItems:'center',overflow:'hidden'}}
          className={ (editable?classes.editable:'') + (editing?' '+classes.editing:'')}
        >
          {
            editing ?
            editComponent
            :
            <KTooltip  placement="bottom-end"  leaveDelay={200} interactive={true}
              title={
                <div>
                  <div style={{display:'flex',alignItems:'center'}}>
                    {title}
                    <div onMouseDown={event=>{event.stopPropagation();event.preventDefault()}} onClick={(event)=>{event.stopPropagation();handleShareClick(copiedTitle|| title,'Name copied to Clipboard')}} style={{cursor:'pointer',marginLeft:8}}>
                      {getIconComponent({label:'copy',size:20,colour:'#fff'})}
                    </div>
                  </div>
                  {
                    ['column','table'].includes(label) && data && data.location && data.location.split("://")[1] &&
                    <div style={{display:'flex',alignItems:'center',marginTop:8}}>
                      {data.location.split("://")[1].split('/').map(el=>`"${el.toUpperCase()}"`).join('.')}
                      <div onMouseDown={event=>{event.stopPropagation();event.preventDefault()}} onClick={(event)=>{event.stopPropagation();handleShareClick(data.location.split("://")[1].split('/').map(el=>`"${el.toUpperCase()}"`).join('.'),'Location copied to Clipboard')}} style={{cursor:'pointer',marginLeft:8}}>
                        {getIconComponent({label:'copy',size:20,colour:'#fff'})}
                      </div>
                    </div>
                  }
                </div>

              }
            >
                <Typography variant='h4' data-test-id="profile-title" className={classes.titleReadOnly} style={{fontSize:titleFontSize || processTitle(title).fontSize}}>
                  {processTitle(title).text}
                </Typography>
            </KTooltip>
          }
        </div>
      </ClickAwayListener>
      {
        description &&
        <Typography className={classes.subHeader} id='profile-header-sub-header'>
          {description}
        </Typography>
      }
    </div>
  )
}


TitleSection.propTypes = {
  classes: PropTypes.object.isRequired,
}



export default withTheme()(withStyles(styles)(TitleSection));
