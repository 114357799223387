import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum'
import { KadaBtn, KadaFilter, KadaInput, KadaLoadingBar, KadaModal, KadaTextArea, kButtonVariants, kFilterVariants, kLoadingBarVariants, KadaSnackBar, KadaIconBtn } from 'kada-component-library';
import { closeKadaModal, collectionIds, copyToClipboard, getDispFields, getPartialMatchSearchString, onClickResultItem, openKadaModal, sendMessage } from '../../../utilities';
import useGetWorkflowStatus from '../../../hooks/useGetWorkflowStatus';
import useGetPriorities from '../../../hooks/useGetPriorities';
import axiosSolr from '../../../axios-solr';
import { CerebrumLongListLoader } from '../../../LongListLoader';
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import ExternalLinker from './ExternalLinker';
import ObjectSelector from './ObjectSelector';
import useAlert from '../../../hooks/useAlert';


function DQModal(props) {

  const {
    dqModalOpen,
    setDqModalOpen,
    object,
    isExternal
  } = props;

  const [externalIssueData, setExternalIssueData] = useState({data: null, loading: false})
  const [externalURL, setExternalURL] = useState('')

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [status, setStatus] = useState()
  const [priority, setPriority] = useState()
  const [assignee, setAssignee] = useState()
  const [assigneeSearch, setAssigneeSearch] = useState('')
  const [assigneeSuggestions, setAssigneeSuggestions] = useState()
  const [dimensionSuggestions, setDimensionSuggestions] = useState()
  const [dimension, setDimension] = useState()
  const [linkedObjects, setLinkedObjects] = useState([])

  const [alertDetails, setAlertDetails] = useState()
  const [externalAlertDetails, setExternalAlertDetails] = useState()

  const [creating, setCreating] = useState(false)

  const { data: statusData } = useGetWorkflowStatus({
    objectType: 'ISSUE'
  });

  const { data: priorityData } = useGetPriorities({
    objectType: 'ISSUE'
  });

  const { sendAlert } = useAlert({});

  const isLinkingIssueToExternal = isExternal && object.object?.name==='ISSUE'

  const onLinkExternalIssue = (url) => {
    setAlertDetails({})
    setExternalIssueData({data: null, loading: true})
    axiosCerebrum
      .get(
        `/api/integrations/issues`, {params:{
          external_url: url
        }}
      )
      .then(response=>{
        setExternalIssueData({data: response.data, loading: false})
        setTitle(response.data.name)
        setDescription(response.data.description)
        setAssignee(response.data.assignee?.account_id)
        if(statusData.map(s=>s.name).includes(response.data.workflow_status)){
          setStatus(response.data.workflow_status)
        }else{
          setStatus(statusData[0]?.name)
        }
        if(priorityData.map(p=>p.name).includes(response.data.priority)){
          setPriority(response.data.priority)
        }else{
          setPriority(priorityData[0]?.name)
        }
        setTimeout(()=>{
          openKadaModal('issue-creation-modal')
        }, 100)
      })
      .catch(error=>{
        console.log(error)
        setExternalIssueData({data: null, loading: false})
        let msg = 'Error occurred fetching external issue, please try again'
        let actions;
        if(error.response?.data?.errors){
          msg = JSON.stringify(error.response.data.errors)
        }
        if(error.response?.status===409){
          msg = 'This external issue is already linked to an issue'
          let link = error.response?.data?.errors;
          if(link.startsWith?.('http')){
            actions = [
              {
                text: 'OPEN LINKED ISSUE',
                onClick: ()=>window.open(error.response.data.errors, '_blank')
              }
            ]
          }
        }
        setAlertDetails({
          type: 'error',
          message: msg,
          actions: actions
        })
      })
  };

  useEffect(()=>{
    if(dqModalOpen){
      setTimeout(()=>{
        openKadaModal('issue-creation-modal')
      }, 100)
    }else{
      setTimeout(()=>{
        closeKadaModal('issue-creation-modal')
      }, 100)
      resetData()
    }
   // eslint-disable-next-line
  },[dqModalOpen])

  useEffect(()=>{
    if(!status && statusData)setStatus(statusData[0]?.name)
    // eslint-disable-next-line
  },[statusData])

  useEffect(()=>{
    if(!priority && priorityData)setPriority(priorityData[0]?.name)
    // eslint-disable-next-line
  }, [priorityData])

  useEffect(()=>{
    if(object?.id){
      setLinkedObjects([object])
    }
    // eslint-disable-next-line
  },[object])

  const resetData = () => {
    setTitle('')
    setDescription('')
    setStatus(statusData[0]?.name)
    setPriority(priorityData[0]?.name)
    setAssignee()
    setAssigneeSearch('')
    setAssigneeSuggestions()
    setDimensionSuggestions()
    setDimension()
    setExternalIssueData({data: null, loading: false})
    setExternalURL('')
    setAlertDetails({})
  }

  const loadAssignee = ({search = assigneeSearch, start = 0}) => {
    let url = '/solr/search/select';
    let params = {
      q: getPartialMatchSearchString(search, true, true),
      fq:'object_type_srt:user AND account_type_srt:"USER_ACCOUNT" AND -merge_type_srt:CANDIDATE',
      rows:10,
      start
    }
    axiosSolr
      .get(url,{params})
      .then(response=>{
        setAssigneeSuggestions([
          ...( start===0 ? [] : (assigneeSuggestions || [])),
          ...response.data.response.docs
        ])
      })
      .catch(error=>{
        console.log(error)
      })
  }

  const loadDimensions = () => {
    CerebrumLongListLoader({
      url: '/api/collectioninstances',
      params: {
        collection_id: collectionIds.dqDimensions,
      },
      per_page: 50,
      onError: error => {
        console.log(error)
      },
      onFinishLoad: ({data}) => {
        setDimensionSuggestions(data)
      }
    })
  }

  const linkDimension = async (issueId, dimensionId) => {
    await axiosCerebrum
      .put(
        '/api/issues/'+issueId+'/related',undefined,{params:{
          object_id: dimensionId,
          relationship: "MEMBER_OF"
        }}
      )
      .catch(error=>{
        console.log(error)
      })
  }

  const linkObject = (issueId, objectId) => {
    axiosCerebrum
      .put(`/api/issues/${issueId}/related`,undefined,{params:{
        object_id: objectId,
        relationship: 'IMPACTS'
      }})
      .then(()=>{
        sendMessage({reload_issue: true})
      })
      .catch(error=>{
        console.log(error);
      })
  }

  const linkIssueToExternal = () => {
    setCreating(true)
    axiosCerebrum
      .patch(`/api/integrations/issues/${object.id}`,{
        external_url: externalURL
      })
      .then(async response=>{
        if(dimension){
          await linkDimension(object.id, dimension)
        }
        sendAlert({ message: 'JIRA ticket linked successfully', type: 'info' });
        sendMessage({ reload_issue_profile: true });
        sendMessage({ reload_issue: true });
        setDqModalOpen(false)
        setCreating(false)
      })
      .catch(error=>{
        let msg = error.response?.data?.errors || 'Failed to refresh JIRA ticket';
        console.log(msg);
        setCreating(false)
        sendAlert({ message: msg, type: 'error' });
      })
  }

  const createIssue = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setAlertDetails({})
    let requestBody = {
      name: title,
      description,
      workflow_status: status,
      priority,
    }
    if(assignee){
      requestBody.assignee = assignee
    }
    let url = '/api/issues'
    if(isExternal){
      let externalIssue = externalIssueData.data
      requestBody.external_url = externalIssue.external_url;
      requestBody.assignee = externalIssue.assignee;
      requestBody.reporter = externalIssue.reporter;
      requestBody.source_id = externalIssue.source_id;
      url = '/api/integrations/issues'
    }

    if(isLinkingIssueToExternal){
      linkIssueToExternal()
      return
    }

    setCreating(true)

    axiosCerebrum
      .post(
        url,
        requestBody
      )
      .then(async response=>{
        if(dimension){
          await linkDimension(response.data.id, dimension)
        }
        setExternalAlertDetails({
          type: 'info',
          message: 'Issue created successfully.',
          actions: [
            {
              text: 'OPEN',
              onClick: ()=>onClickResultItem({id: response.data.id, label: 'ISSUE', newWindow: true, item: response.data})
            },
            {
              text: 'COPY LINK',
              onClick: ()=>{
                setExternalAlertDetails({
                  type: 'info',
                  message: 'Link copied to clipboard'
                })
                copyToClipboard(window.location.origin+'/profile/issue/'+response.data.id)
              }
            },
            {
              text: 'VIEW ISSUE BOARD',
              onClick: ()=>window.open('/issue_board', '_blank')
            }
          ]
        })
        if(linkedObjects.length>0){
          linkedObjects.forEach(object=>linkObject(response.data.id, object.id))
        }
        sendMessage({ reload_issue: true });
        setCreating(false)
        closeKadaModal('issue-creation-modal')
        setDqModalOpen(false)
      })
      .catch(error=>{
        console.log(error)
        setCreating(false)
        let actions;
        let message = 'Error occurred creating issue, please try again'
        if(error.response && error.response.status && error.response.status===409){
          if(isExternal){
            message = 'This extenal issue is already linked to an issue'
          }else{
            message = 'An issue with this title already exists'
          }
        }
        if(error.response.data && error.response.data.errors){
          message = error.response.data.errors;
          if(message.startsWith?.('http')){
            actions = [
              {
                text: 'OPEN LINKED ISSUE',
                onClick: ()=>window.open(message, '_blank')
              }
            ]
            message = 'This extenal issue is already linked to an issue'
          }
        }
        setAlertDetails({ type: 'error', message, actions })
      })
  }

  if(isExternal && !externalIssueData.data){
    return (
      <ExternalLinker
        modalOpen={dqModalOpen}
        setModalOpen={setDqModalOpen}
        externalURL={externalURL}
        setExternalURL={setExternalURL}
        onLinkExternalIssue={onLinkExternalIssue}
        externalIssueData={externalIssueData}
        alertDetails={alertDetails}
        isLinkingIssueToExternal={isLinkingIssueToExternal}
      />
    )
  }

  return (
    <>
      <KadaModal id="issue-creation-modal" width="640px" maxWidth="90vw">
        <div slot="header">
          <h4>{isLinkingIssueToExternal ? "CONFIRM EXTERNAL TICKET DETAILS" : "CREATE ISSUE"}</h4>
        </div>
        <div slot="content">
          {
            creating &&
            <div style={{textAlign:'center',marginTop:24}}>
              <KadaLoadingBar variant={kLoadingBarVariants.primary} text="Creating issue"/>
            </div>
          }
          {
            !creating &&
            <>
              <KadaInput
                placeholder='Enter issue title'
                value={title}
                onInput={e=>setTitle(e.detail.value)}
                maxLength={255}
                helperText='Required. Max 255 characters'
                required
                className="mb-4"
                readOnly={isExternal}
              />
              <KadaTextArea
                placeholder='Enter issue description'
                value={description}
                onInput={e=>setDescription(e.detail.value)}
                maxLength={1000}
                helperText='Optional. Max 1000 characters'
                className="mb-4"
                readOnly={isExternal}
              />
              <div className="flex flex-wrap gap-2">
                {
                  status &&
                  <KadaFilter
                    options={statusData.map(s=>({
                      value: s.name,
                      text: s.name,
                      color: s.colour
                    }))}
                    placeholder='Status'
                    isHidePlaceholderMenuItem
                    variant={kFilterVariants.selectionChip}
                    value={status}
                    onChange={e=>setStatus(e.detail.value)}
                    isHideSearch
                    readOnly={isExternal}
                  />
                }
                {
                  priority &&
                  <KadaFilter
                    options={
                      isExternal ?
                      [{value: priority, text: externalIssueData.data?.priority, color: 'var(--color-base-300)'}]
                      :
                      priorityData.map(s=>({
                        value: s.name,
                        text: s.name,
                        color: s.colour
                      }))
                    }
                    placeholder='Priority'
                    isHidePlaceholderMenuItem
                    variant={kFilterVariants.selectionChip}
                    value={priority}
                    onChange={e=>setPriority(e.detail.value)}
                    isHideSearch
                    readOnly={isExternal}
                  />
                }
                <KadaFilter
                  options={
                    isExternal ?
                      assignee ? [{value: assignee, text: externalIssueData.data?.assignee?.display_name, icon: 'person', color: 'var(--color-primary)'}] : []
                    :
                    assigneeSuggestions?.map(s=>({
                      value: s.id,
                      text: s.name_txt,
                      icon: 'person',
                      color: 'var(--color-primary)'
                    }))
                  }
                  onClick={()=>{
                    if(!assigneeSuggestions && !isExternal)loadAssignee({})
                  }}
                  onInput={e=>{
                    setAssigneeSearch(e.detail.value)
                    setAssigneeSuggestions([])
                    loadAssignee({search:e.detail.value})
                  }}
                  onScroll={e=>{
                    loadAssignee({start:assigneeSuggestions.length})
                  }}
                  readOnly={isExternal}
                  onlyTriggerScrollOnBottom
                  autoInputDebounce
                  placeholder='No Assignee'
                  defaultIcon='unknown_circle'
                  variant={kFilterVariants.selectionChip}
                  value={assignee}
                  onChange={e=>setAssignee(e.detail.value)}
                  searchPlaceholder='Search assignee'
                />
                <KadaFilter
                  options={dimensionSuggestions?.map(s=>({
                    value: s.id,
                    text: s.name,
                    icon: 'collection_instance',
                    color: 'var(--color-primary)'
                  }))}
                  isNativeSearch
                  placeholder='No Dimension'
                  defaultIcon='unknown_circle'
                  // isHidePlaceholderMenuItem
                  variant={kFilterVariants.selectionChip}
                  value={dimension}
                  onChange={e=>{
                    setDimension(e.detail.value)
                  }}
                  searchPlaceholder='Search dimension'
                  onClick={()=>{
                    if(!dimensionSuggestions)loadDimensions()
                  }}
                />
                {
                  !isLinkingIssueToExternal &&
                  <div className={`dropdown dropdown-center ${linkedObjects.length > 3 ? 'dropdown-bottom' : 'dropdown-top'}`}>
                    <KadaBtn
                      text={linkedObjects.length === 0 ? "No linked assest" :  "Add linked assest"}
                      variant={kButtonVariants.baseContentOutlined}
                      size="s"
                      iconName='add'
                      role="button"
                      tabIndex={0}
                    />
                    <div tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-1 mb-2 mt-2 w-90 !p-4 shadow-sm">
                      <ObjectSelector
                        onSelectObject={object=>{
                          setLinkedObjects([...linkedObjects, object])
                          document.querySelector('.dropdown-content').classList.remove('show')
                        }}
                        addedObjects={linkedObjects}
                      />
                    </div>
                  </div>
                }
              </div>
              {
                linkedObjects.length>0 && !isLinkingIssueToExternal &&
                <div className="collapse collapse-arrow mt-4">
                  <input type="checkbox" style={{paddingTop: 8, paddingBottom: 8}} />
                  <div className="collapse-title flex items-center">
                    <span className="text-sm">
                      Linked Data / Content ({linkedObjects.length})
                    </span>
                  </div>
                  <div className="collapse-content text-sm overflow-hidden">
                    {
                      linkedObjects.map(el=>(
                        <SimpleResultItem
                          key={el.id}
                          title={getDispFields(el, 'dispTitle')}
                          label={el.object_type_txt}
                          item={el}
                          subTitle={getDispFields(el, 'dispSubtitle')}
                          hideRight={true}
                          tailObject={
                            <KadaIconBtn
                              iconName='close'
                              onClick={(e)=>{
                                e.stopPropagation()
                                setLinkedObjects(linkedObjects.filter(o=>o.id!==el.id))
                              }}
                            />
                          }
                          showUnderline
                          onClick={() => {
                            onClickResultItem({ id: el.id, item: el, label: el.object_type_txt, newWindow: true})
                          }}
                        />
                      ))
                    }
                  </div>
                </div>
              }
              {
                externalIssueData.data &&
                <div className="collapse collapse-arrow mt-4">
                  <input type="checkbox" style={{paddingTop: 8, paddingBottom: 8}} />
                  <div className="collapse-title flex items-center">
                    <span className="text-sm">
                    Linked external source
                    </span>
                  </div>
                  <div className="collapse-content text-sm overflow-hidden">
                    <SimpleResultItem
                      title={externalIssueData.data.name}
                      label={'issue'}
                      iconLabel="link"
                      item={externalIssueData.data}
                      subTitle={externalIssueData.data.external_url}
                      onClick={() => {
                        window.open(externalIssueData.data.external_url, '_blank')
                      }}
                      showUnderline
                    />
                  </div>
                </div>
              }
            </>
          }
        </div>
        <div slot="actions">
          <KadaBtn
            text={isLinkingIssueToExternal ? "LINK" : "CREATE"}
            style={{ marginRight: 16 }}
            onClick={creating || !title?.trim() ? null : createIssue}
            disabled={creating || !title?.trim()}
          />
          <KadaBtn
            text="CANCEL"
            daisyClass="btn-primary btn-outline"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              closeKadaModal('issue-creation-modal')
              setDqModalOpen(false)
            }}
            variant={kButtonVariants.secondaryBorderless}
          />
        </div>
        <div slot="message">
          <KadaSnackBar
            alertDetails={alertDetails}
            isChipOnly
          />
        </div>
      </KadaModal>
      <KadaSnackBar
        alertDetails={externalAlertDetails}
      />
    </>
  )
}

DQModal.propTypes = {
  history: PropTypes.object,
  dqModalOpen: PropTypes.bool.isRequired,
  setDqModalOpen: PropTypes.func.isRequired,
  object: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default DQModal;
