import React, { useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Button } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import AddProperties from './AddProperties';
import ReviewTemplate from './ReviewTemplate';
import axiosCerebrum from '../../../axios-cerebrum';
import { useStore } from 'react-redux';
import { mapObjectName, sendMessage, toTitleCase } from '../../../utilities';
import 'url-search-params-polyfill';
import ChooseObjectType from './ChooseObjectType';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
    width: '100%',
  },
  labelText: {
    color:theme.palette.primaryText.light
  },
  selectedLabelText:{
    color:`${theme.palette.primary.main} !important`,
    fontWeight:'400 !important'
  },
  stepIcon:{
    cursor:'pointer'
  },
  button:{
    minWidth:96,
    height:36,
    marginLeft:8
  },
  buttons:{
    position:'fixed',
    backgroundColor:theme.palette.background.main,
    height:80,
    bottom:0,
    right:0,
    left:40,
    //width:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    borderTop:`1px solid ${theme.palette.listItemDivider.main}`
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  stepper:{
    backgroundColor:theme.palette.background.main
  },
})

function CreateTemplate(props) {

  const {
    theme,
    classes,
    history,
    state,
    dispatch,
    setSnackBarOpen,
    setSnackbarMessage,
    setSnackbarButtons
  } = props;


  const urlSearch = new URLSearchParams(window.location.search);
  const cancelRedirectUrl = urlSearch.get('cancel_redirect_url');

  const [activeStep, setActiveStep] = useState(0);
  const [selectedTypes, setSelectedTypes] = useState([])
  const [properties, setProperties] = useState([
    {name_txt:'Business name',id:'default_prop_business_name'},
    {name_txt:'Description',id:'default_prop_description'},
    {name_txt:'Tags',id:'default_prop_tag'},
    {name_txt:'Classification',id:'default_prop_classification'},
    {name_txt:'Domains',id:'default_prop_domain'},
    {name_txt:'Verified for',id:'default_prop_use_case'},
    {name_txt:'Do not use for',id:'default_prop_not_verified_use_case'},
    {name_txt:'Owner',id:'default_prop_owner'},
    {name_txt:'Stewards',id:'default_prop_steward'},
  ])
  const steps = ['Select items to edit','Add properties to edit','Review & Create']


  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  window.onpopstate = () => {
    dispatch({type:'set_view',view:'history'})
  }

  const removeItems = () => {
    axiosCerebrum
      .delete(
        '/api/me/cart'
      )
      .then(response=>{
        sendMessage({reloadCart:true})
      })
      .catch(error=>{
        console.log(error)
      })
  }

  const onSuccess = () => {
    setSnackbarMessage('Template is being generated')
    setSnackbarButtons([
      <Button style={{color: 'var(--color-base-100)'}} onClick={()=>{setSnackBarOpen(false);removeItems()}}>CLEAR CART</Button>
    ]);
    setSnackBarOpen(true)
    setProperties([])
    dispatch({type:'set_tab_state',tabState:1})
    history.push(`/my_tasks?tabName=EXTRACTS&jobName=BULK EDIT FILE`)
  }

  const onClickCreate = () => {
    let objects = state.objectCount?.filter(o=>selectedTypes.includes(o.objectType)).map(el=>toTitleCase(mapObjectName(el.objectType).replace(/_/g,' '))+'s');
    let name;
    if(objects && objects.length<3)name = 'Edit ' +  objects.join(' & ')
    if(objects && objects.length>=3)name = 'Edit ' + objects.slice(0,-1).join(', ') + ' & ' + objects[objects.length-1]
    const payload = {
      "domain": document.location.protocol + "//" + document.location.hostname,
      "filters": {
        "name":name,
        "user_id":sessionData.id,
        "object_types": selectedTypes,
        "collection_ids": properties.filter(p=>!p.id.includes('default_prop_')).map(el=>el.id)
      },
      // "ignore_cache":state.isRerun || Boolean(changeId && targetObjectId),
      "ignore_cache":true,
      "lookup_code": "bulk_edit_excel",
      "type": "BULK_EDIT_EXCEL"
    }
    axiosCerebrum
      .post('/api/extracts',payload)
      .then(extractResponse=>{
        dispatch({type:'set_view',view:'history'})
        onSuccess();
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:"Error occurred submitting the request, please try again",type:'error'})
      })
  }

  let bodyContent;

  switch (activeStep) {
    case 0:
      bodyContent = <ChooseObjectType setSelectedTypes={setSelectedTypes} selectedTypes={selectedTypes} state={state} />
      break;
    case 1:
      bodyContent = <AddProperties properties={properties} setProperties={setProperties} />
      break;
    case 2:
      bodyContent = <ReviewTemplate selectedTypes={selectedTypes} properties={properties} state={state} onClickCreate={onClickCreate}/>
      break;
    default:
  }


  const onNextClick = () => {
    setActiveStep(activeStep+1)
  }

  const onBackClick = () => {
    setActiveStep(activeStep-1)
  }

  const onCancelClick = () => {
    if(cancelRedirectUrl)history.push(cancelRedirectUrl)
    dispatch({type:'set_view',view:'history'})
  }

  return (
    <div className={classes.root}>
      <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
        {steps.map((label,index) => (
          <Step className={classes.step}  key={label} completed={false}>
            <StepLabel
              onClick={()=>{
                if(index===1 && selectedTypes.length===0)return;
                if(index===2 && properties.length===0)return;
                setActiveStep(index)
              }}
              data-test-id={`create-template-step-${index+1}`}
              classes={{
                active:classes.selectedLabelText,
                iconContainer:classes.stepIcon,
                label:classes.labelText
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{marginBottom:100,marginTop:32}}>
        {bodyContent}
      </div>
      <div className={classes.buttons}>
        <Button disabled={activeStep===0} style={{width:120,height:48, color:theme.palette.primary.main}} onClick={onBackClick}>
          BACK
        </Button>
        {
          activeStep < steps.length - 1 &&
          <Button
            variant='contained'
            color='primary'
            disabled={(activeStep===0 && selectedTypes.length===0) || (activeStep===1 && properties.length===0)}
            style={{marginLeft:30,width:120,height:48}}
            data-test-id="next-button"
            onClick={onNextClick}
          >
            NEXT
          </Button>
        }
        {
          activeStep===2 &&
          <Button variant='contained' color='primary' style={{marginLeft:30,width:120,height:48}} data-test-id="create-button" onClick={onClickCreate} >
            CREATE
          </Button>
        }
        <Button color='primary' style={{marginLeft:30,width:120,height:48,marginRight:80}} onClick={onCancelClick}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

CreateTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(CreateTemplate));
