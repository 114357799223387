import React,{useState,useEffect,useRef} from 'react';
import { withTheme, withStyles, Typography, Select,MenuItem, CircularProgress,Button,  Paper, Popper, MenuList, ClickAwayListener, Checkbox, InputBase,IconButton } from '@material-ui/core';
import ResultItem2 from '../../UI/SearchResults/ResultItem2';
import {getIconComponent,  onClickResultItem, getDispFields,isInViewport,toTitleCase,getSearchMode, mapObjectName, formatNumber} from '../../../utilities'
import axiosSolr from '../../../axios-solr'
import axiosCerebrum from '../../../axios-cerebrum'
import ChipWrapper from '../../UI/ChipWrapper/ChipWrapper'
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import { getCollectionObjectName } from '../../BasicSearch/MainSearch/Utils/Utils';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root:{
    display:'flex'
  },
  selector: {
    ...theme.components.selector,
		width: 160,
  },
  divButton:{
    height:36,
    width:36,
    display:'flex',
    alignItems:'center',
    justifyContent:'space-around',
    borderRadius:18,
    cursor:'pointer',
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  },
  title: {
    display: 'inline-block',
    // fontSize: '1.1rem',
    position: 'relative',
    top: '0.1rem',
    color: theme.palette.header.main,
    fontWeight: '400',
    fontSize: '1.25rem'
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },

  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  checkbox:{
    paddingLeft:0,
  },
  chip:{
    ...theme.components.suggestionChip,
    width:'max-content',
    height:28,
    borderRadius:14,
    padding:'0 12px'
  },
	searchFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    width:160,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  emptyMsg:{
		fontSize:13.75,
		colour:theme.palette.primaryText.main,
		'& span':{
			textDecoration:'underline',
			cursor:'pointer',
		}
	}
})

function Linked(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    history,
    setModifiedUser,
    isList,
    // linkedFetch
  } = props;

  let scrollRef = useRef();
  const [editing, setEditing] = useState(false);
	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
	const searchTimeoutRef = useRef()

  const {
    sendAlert
  } = useAlert({})

  let label, data, isCollection, loading;
  let sort = 'TRUST_SCORE_DESC'
  let search = ''
  if(state.linkedTabs && state.linkedTabs.length>0){
    label = state.linkedTabs[state.linkedTabState].label;
    isCollection =  state.linkedTabs[state.linkedTabState].isCollection;
    data = state.linkedData[label];
    loading = data?data.loading:false;
    sort  = data?(data.sort||'TRUST_SCORE_DESC'):'TRUST_SCORE_DESC'
    search = data?(data.search||''):''
  }

  const loadData = async ({page=1,sort,search,showDeleted=state.showDeletedItems}) => {
    dispatch({
      type:'set_linked_data',
      linkedData:{
        ...state.linkedData,
        [label]:{
          ...(data||{}),
          items:page===1?[]:data.items,
          sort,
          search,
          loading:true,
          error:false
        }
      }
    });
    let url, params;
    if(isCollection){
      url = `/api/collectioninstances/${state.basicData.id}/related/collections`;
      params = {
        page:page,
        per_page:10,
        relationship:'MEMBERS,MEMBERS_AUTO,RELATES_TO,RELATED,MEMBER_OF,CLASSIFIES',
        parent_name:label,
        'search.name':search && search.trim()!==''?search:undefined,
        sort,
      }
    }else{
      url = `/api/collectioninstances/${state.basicData.id}/related`;
      params = {
        object_name:label,
        page:page,
        object_active_flag:showDeleted?undefined:true,
        'search.name':search && search.trim()!==''?search:undefined,
        sort,
        per_page:10
      }
    }

    let allTotal;
    if(page===1 && !isCollection){
      await axiosCerebrum
        .get(
          url,
          {
            ...params,
            object_active_flag:undefined
          }
        )
        .then(response=>{
          allTotal = response.data.total
        })
        .catch(error=>{
          console.log(error)
        })
    }

    axiosCerebrum
      .get(
        url,
        {params}
      )
      .then(response=>{
        if(response.data.total===0){
          dispatch({
            type:'set_linked_data',
            linkedData:{
              ...state.linkedData,
              [label]:{
                ...response.data,
                sort,
                search,
                allTotal
              }
            }
          })
          return;
        }
        axiosSolr.get(
          '/solr/search/select',
          {params:{
            q:"*",
            fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
            fl:'*',
            rows:10
          }}
        ).then(solrRes=>{
          let arr = [];
          response.data.items.forEach(el=>{
            arr.push({...el,...solrRes.data.response.docs.find(s=>s.id===el.id)||{}})
          })
          dispatch({
            type:'set_linked_data',
            linkedData:{
              ...state.linkedData,
              [label]:{
                ...response.data,
                items:page===1?arr:[...data.items,...arr],
                sort,
                search,
                loading:false,
                error:false,
                allTotal:allTotal || (data?data.allTotal:undefined)
              }
            }
          })
        }).catch(error=>{
          console.log(error)
          dispatch({
            type:'set_linked_data',
            linkedData:{
              ...state.linkedData,
              [label]:{
                error:true
              }
            }
          })
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_linked_data',
          linkedData:{
            ...state.linkedData,
            [label]:{
              error:true
            }
          }
        })
      })
  }


  const shoudLoadMore = () => {
    return data && !loading && data.page<data.pages && isInViewport(scrollRef)
  }

  useEffect(()=>{
    if(!state.linkedTabs||state.linkedTabs.length===0)return;
    if(!data && !loading)loadData({page:1,sort,search});
    if(shoudLoadMore())loadData({page:data.page+1,sort:sort,search})
  // eslint-disable-next-line
  },[state.linkedTabs,state.linkedTabState,state.linkedData])

  window.onscroll=()=>{
    if(!shoudLoadMore())return;
    loadData({page:data.page+1,sort,search})
  }


  const onFilterChange = value => {
    dispatch({
      type:'set_linked_data',
      linkedData:{
        ...state.linkedData,
        [label]:{
          ...data,
          sort:value
        }
      }
    })
    loadData({
      page:1, sort:value, search
    })
  }

  const onDelete = item => {
    axiosCerebrum.delete(
      `/api/collectioninstances/${state.basicData.id}/related?relationship=${item.relationship}&object_id=${item.id}`,
    ).then(()=>{
      if(
        (item.collection_txt==='DOMAIN' && item.relationship==='MEMBER_OF')
      ){
        window.postMessage({reload_sidebar_domain:'true'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
      }
      if(
        (item.collection_txt==='CLASSIFICATION' && item.relationship==='CLASSIFIED_BY')
      ){
        window.postMessage({reload_sidebar_classification:'true'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
      }
      if(isList)setModifiedUser()
      dispatch({type:'set_deleted_items',deletedItems:[...state.deletedItems,item]})
      let object = {...data,total:data.total-1,items:data.items.filter(el=>el.id!==item.id || el.relationship!==item.relationship)}
      dispatch({type:'set_linked_data',linkedData:{...state.linkedData,[label]:object}})
      if(state.linkObjectData && state.linkObjectData.linkedItems){
        dispatch({
          type:'set_link_object_data',
          linkObjectData:{
            ...state.linkObjectData,
            linkedItems:{
              ...state.linkObjectData.linkedItems,
              [item.relationship]:{
                ...state.linkObjectData.linkedItems[item.relationship],
                items:state.linkObjectData.linkedItems[item.relationship].items.filter(el=>el.id!==item.id),
                total:state.linkObjectData.linkedItems[item.relationship].total-1
              }
            }
          }
        })
      }
    }).catch(error=>{
      sendAlert({type:'error',message:'Error occurred removing link'})
      console.log(error);
    })
  }

  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}


	const onClickViewInSearch = () => {
    if(getSearchMode()==='basic_search'){
      if(!state.basicData.parent.short_name)return;
      let presetFilter = `${state.basicData.parent.short_name.toLowerCase().replace(/\s+/g,'_')}_kc_msrt:${state.basicData.name}`;
      let objectName = mapObjectName(label);
      if(isCollection)objectName = getCollectionObjectName({collectionType:data.items[0].collection_type_txt,collectionName:data.items[0].collection_txt})
      if(label==='DATA_QUALITY_TEST')objectName = label;
      history.push(`/basic_search?query=*&object=${objectName}&presetFilter=${presetFilter}`)
    }
	}

  const onClickShowDeleted = value => {
    loadData({page:1,showDeleted:value, sort, search})
		dispatch({type:'set_show_deleted_items',showDeletedItems:value})
		// onLoading({showDeleted:value})
	}

  const onSearchChange = value => {
    dispatch({
      type:'set_linked_data',
      linkedData:{
        ...state.linkedData,
        [label]:{
          ...data,
          search:value
        }
      }
    })
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      loadData({page:1,sort,search:value})
    },250)
  }

  if(state.linkedTabsLoading){
    return (
      <div style={{width:'100%',marginTop:80,display:'flex',justifyContent:'space-around'}}>
        <CircularProgress color='secondary' />
      </div>
    )
  }
  if(state.linkedTabsError){
    return <Typography className={classes.normalText}>Error occurred loading linked items</Typography>
  }
  if(!state.linkedTabs){
    return <div></div>
  }

  if(state.linkedTabs.length===0){
    return <Typography className={classes.normalText}>No item linked to this instance</Typography>
  }

  return (
    <div className={classes.root}>
      <VerticalTabBar
        tabOptions={state.linkedTabs.map(el=>el.dispName)}
        tabState={state.linkedTabState}
        setTabState={value => dispatch({ type: 'set_linked_tab_state', linkedTabState: value })}
      />
      <div style={{marginLeft:80,flexGrow:1,overflow:'hidden'}}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start',flexWrap:'wrap'}}>
          <div style={{marginBottom:24}}>
            <div style={{display:'flex',alignItems:'center',minHeight:42}}>
              <Typography data-test-id="child-list-title" className={classes.title}>
                {`${data && data.total?formatNumber(data.total):'0'} ${mapObjectName(label,true).replace(/_/g,' ').toUpperCase()}(S) LINKED`}
              </Typography>
              <Button color='primary' data-test-id="related-term-edit-button" style={{marginLeft:16,marginTop:6}} onClick={()=>setEditing(!editing)}>
                {editing?"CLOSE":"EDIT"}
              </Button>
            </div>
            <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>{toTitleCase(mapObjectName(label,true).replace(/_/g,' '))}(s) linked to this instance. Click EDIT to remove links</Typography>
          </div>
          <div style={{display:'flex',marginBottom:24}}>
            {
              !editing &&
              <div style={{display:'flex',flexGrow:0,alignItems:'center'}}>
                {
                  state.linkedTabs[state.linkedTabState].dispName!=='QUERY' &&
                  <InputBase
                    value={search}
                    onChange={event => onSearchChange(event.target.value)}
                    variant={'filled'}
                    placeholder={'Search'}
                    className={classes.searchFilter}
                    endAdornment={
                      <IconButton
                        disabled={search===''}
                        onClick={()=>onSearchChange('')}
                        style={{width:32,height:32,marginRight:6}}
                      >
                        {getIconComponent({label:search===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                      </IconButton>
                    }
                  />
                }
                <Select
                  className={classes.selector}
                  value={sort}
                  onChange={event => onFilterChange(event.target.value)}
                  MenuProps={{
                    classes:{
                      paper:classes.selectPaper
                    }
                  }}
                >
                  {
                    [{disp:'Trust',value:'TRUST_SCORE_DESC'},{disp:'A-Z',value:'ALPHABETICAL'},{disp:'Z-A',value:'REVERSE_ALPHABETICAL'}].map(el=>(
                      <MenuItem  className={classes.menuItem} value={el.value}>
                        <span>{el.disp}</span>
                      </MenuItem>
                    ))
                  }
                </Select>
                {
                  <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
                    {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
                  </div>
                }

                <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
                  <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
                    <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                      {
                        !['CHANGE','KNOWLEDGE'].includes(state.basicData.parent.category) && !(getSearchMode()==='basic_search' && !state.basicData.parent.short_name) && data && data.items && data.items.length!==0 &&
                        <div>
                          <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
                          <MenuList className={classes.listContainer}>
                            <MenuItem onClick={()=>{onClickViewInSearch()}} className={classes.menuItem} >
                              <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
                            </MenuItem>
                          </MenuList>
                        </div>
                      }
                      <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
                      <MenuItem onClick={()=>{onClickShowDeleted(!state.showDeletedItems)}} className={classes.menuItem} >
                        <Checkbox key={state.showDeletedItems} className={classes.checkbox} color='primary' checked={!state.showDeletedItems}/>
                        <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                      </MenuItem>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
            }
          </div>

        </div>
        <div>
          {
            data && data.error &&
            <Typography className={classes.normalText}>Error occurred loading items</Typography>
          }
          {
            data && data.items && data.items.length===0 && !loading &&
            (
              (!state.searchFilter || state.searchFilter.trim()==='') && !isNaN(data.allTotal) && data.allTotal>data.total ?
              <Typography className={classes.emptyMsg}>No active {mapObjectName(label,true).replace(/_/g,' ').toLowerCase()} found. <span onClick={()=>onClickShowDeleted(true)}>Click to see inactive {mapObjectName(label,true).replace(/_/g,' ').toLowerCase()}(s)</span></Typography>
              :
              <Typography className={classes.normalText}>No item found</Typography>
            )

          }
          {
            data && data.items && data.items.map(item=>(
              <ChipWrapper
                showUnderline={true}
                wrappedComponent={
                  <ResultItem2
                    key={item.id+state.editing}
                    item={item}
                    dispBody={getDispFields(item,'dispBody')}
                    dispSubtitle={getDispFields(item,'dispSubtitle')}
                    dispTitle={getDispFields(item,'dispTitle')}
                    showIcon
                    label={item.object_type_txt||item.type}
                    tagsWidthOffset={state.editing?48:0}
                    showDetailedTestScore
                    onClick={() => onClickResultItem({id:item.id, label:item.object_type_txt||item.type,history:history,item:item})}
                    tailObject={
                      item.object_type_txt==='COLLECTION_INSTANCE' && !['KNOWLEDGE'].includes(item.collection_type_txt) ?
                      <div className={classes.chip}>
                        {item.relationship==='RELATED' && 'related instance'}
                        {item.relationship==='RELATES_TO' && 'related instance'}
                        {(item.relationship==='MEMBERS' || item.relationship==='MEMBERS_AUTO') && 'member of this instance'}
                        {item.relationship==='MEMBER_OF' && 'is a member of'}
                        {item.relationship==='CLASSIFIES' && 'classified'}
                      </div>
                      :
                      undefined
                    }
                  />
                }
                iconDisabled={item.relationship.includes('_AUTO')}
                iconTooltip={item.relationship.includes('_AUTO')?'You cannot remove auto linked items':''}
                icon={editing?getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light}):undefined}
                onIconClick={()=>onDelete(item)}
              />
            ))
          }
        </div>
        <div ref={scrollRef} style={{width:'100%',display:'flex',justifyContent:'center',marginBottom:10}}>
          {loading && <CircularProgress style={{marginTop:80}} color='secondary'/>}
        </div>
      </div>
    </div>
  )
}


export default withTheme()(withStyles(styles)(Linked));
