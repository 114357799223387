import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { onClickResultItem, isInViewport, getDispFields } from '../../../utilities';
import { withTheme, withStyles, Typography, CircularProgress, Button} from '@material-ui/core';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import ChipWrapper from '../../UI/ChipWrapper/ChipWrapper'


const styles = theme => ({
  root: {
  },
  block: {
    marginBottom: 40
  },
  title:{
    display: 'inline-block',
    fontSize: '1.25rem',
    fontWeight:400,
    position: 'relative',
    //top: '0.1rem',
    paddingTop:'6px',
    paddingBottom:'6px',
    color:theme.palette.header.main
  },
  subtitle:{
    color:theme.palette.primaryText.light
  },
})

const Related = props => {

  const {
    classes,
    theme,
    history,
    state,
    loadLinkData,
    onOpenLinkModal
  } = props;

  const linkRef = useRef()

  const linkData = state.linkData;

  window.onscroll = () => {
    if(state.linkData && isInViewport(linkRef) && !state.linkLoading && state.linkData.page<state.linkData.pages){
      loadLinkData({page:state.linkData.page+1});
    }
  }

  useEffect(()=>{
    if(state.linkData && isInViewport(linkRef) && !state.linkLoading && state.linkData.page<state.linkData.pages){
      loadLinkData({page:state.linkData.page+1});
    }
  // eslint-disable-next-line
  },[state.linkData,state.linkLoading])

  useEffect(()=>{
    if(!state.linkData){
      loadLinkData({page:1})
    }
  // eslint-disable-next-line
  },[])

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <div style={{display:'block'}}>
          <div style={{display:'flex', alignItems:'flex-end'}}>
            <Typography  className = {classes.title}>LINKED TO</Typography>
            <Button color='primary' style={{marginLeft:16,marginBottom:2}} onClick={onOpenLinkModal}>EDIT</Button>
          </div>
          <Typography variant='caption' className={classes.subtitle}>Where this issue is found</Typography>
        </div>
        {
          linkData && linkData.total===0 &&
          <Typography style={{color:theme.palette.primaryText.main,marginTop:16}}>No item linked to the issue</Typography>
        }
        {
          linkData &&
          <div style={{width: '100%',marginTop:'1rem'}}>
          {
            linkData.items.map(item =>{
              return (
                <ChipWrapper
                  wrappedComponent={
                    <SimpleResultItem
                      item={item}
                      key={item.id}
                      title={getDispFields(item,'dispTitle').length>47?(getDispFields(item,'dispTitle').slice(0,47)+'...'):getDispFields(item,'dispTitle')}
                      subTitle={getDispFields(item,'dispSubtitle')}
                      label={item.object_type_txt || item.type.toLowerCase()}
                      rightText={item.trust_srt}
                      onClick={() => onClickResultItem({ id:item.id, label: item.type.toLowerCase(), history: history, item: item })}
                    />
                  }
                  showUnderline
                />

            )})
          }
          </div>
        }
        <div ref={linkRef} style={{ textAlign: 'center', marginTop: 20,marginBottom:10 }}>
          {
            state.linkLoading && <CircularProgress color="secondary" />
          }
        </div>
        {
          state.linkError && <Typography>Error occurred loading linnked items</Typography>
        }
      </div>
    </div>
  )
}

Related.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  loadLinkData: PropTypes.func.isRequired,
  onOpenLinkModal: PropTypes.func.isRequired
}

export default withTheme()(withStyles(styles)(Related));
