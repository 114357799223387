import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, LinearProgress } from '@material-ui/core';
import { getDispFields, sendMessage  } from '../../../utilities';
import PropTypes from 'prop-types';
import ReactFlowWrapper from '../../UI/ReactFlowWrapper/ReactFlowWrapper';
import { globalListenerRef } from '../../../GlobalListenerRef';
import { getIconLabel } from '../../UI/SearchResults/utils';
import RelatedListDrawer from './RelatedListDrawer';
import { checkLineageLinkable } from '../../../permissionChecker';
import LineageV3 from '../../UI/Lineage/LineageV3/LineageV3';

const styles = theme => ({

  chartWrapper:{
    width:'100%',
    marginTop:12,
    // '& div':{
    //   zIndex:16
    // }
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    textTransform:'uppercase',
  },
  button:{
    width:20,
    height:20,
    borderRadius:16,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  emotRoot: {
    marginTop:80,
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'center'
  },
  emot:{
    fontSize:40,
    color:theme.palette.primaryText.main
  },
  text:{
      fontSize:16,
      color:theme.palette.primaryText.light,
      marginTop:24
  },
})

function Map(props) {

  const {
    classes,
    state,
    dispatch,
    history,
    theme,
    sessionData,
  } = props;

  const [graphHeight, setGraphHeight] = useState(700)
  const [graphWidth, setGraphWidth] = useState(1000)

  const chartWrapperRef = useRef()
  const nodeWidth = 270;

  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(()=>{
    window.removeEventListener('resize',globalListenerRef.instanceMapResizeListener)
    globalListenerRef.instanceMapResizeListener = () => {
      if(!chartWrapperRef.current)return;
      setGraphWidth(document.getElementById('profile-content').getBoundingClientRect().width)
      setGraphHeight(window.outerHeight - chartWrapperRef.current.getBoundingClientRect().top  )
    }
    window.addEventListener('resize',globalListenerRef.instanceMapResizeListener)
    return ()=>window.removeEventListener('resize',globalListenerRef.instanceMapResizeListener)
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.instanceMapListener)
    globalListenerRef.instanceMapListener = (event) => {
        if(event.data.isLinkSearchModal){
          if(event.data.selectedObjectType==='COLLECTION_INSTANCE'){
            sendMessage({linkSearchSuggestionObject:JSON.stringify(
              {...state.basicData,collection_txt:state.basicData.parent.name}
            )})
          }
        }
    }
    window.addEventListener('message',globalListenerRef.instanceMapListener)
    return ()=>{
        window.removeEventListener('message',globalListenerRef.instanceMapListener)
    }
  // eslint-disable-next-line
  },[])



  useEffect(()=>{
    if(chartWrapperRef.current){
      setGraphWidth(document.getElementById('profile-content').getBoundingClientRect().width)
      setGraphHeight(window.outerHeight - chartWrapperRef.current.getBoundingClientRect().top  )
    }
  // eslint-disable-next-line
  },[ chartWrapperRef.current])

  useEffect(()=>{
    dispatch({
      type:'set_lineage_data',
      lineageData:{}
    })
  // eslint-disable-next-line
  },[state.lineageSelectedItem])

  useEffect(()=>{
    if(!state.linkedTabs)return;

    let nodes = []
    let links = []

    let root = {
      id:state.basicData.id,
      type:'KLineageNodeV3',
      extent:'parent',
      parentNode:state.basicData.parent?.id,
      position:{
        x:0, y:60
      },
      width:nodeWidth,
      height:42,
      data:{
        width:nodeWidth,
        height:42,
        // isConnectedToCentre:true,
        leftHandleOffset:8,
        rightHandleOffset:4,
        obj:state.basicData,
        label:getDispFields(state.basicData,'dispTitle'),
        icon:getIconLabel({label:'collection_instance',item:state.basicData}),
        object_type:'COLLECTION_INSTANCE',
        onClick:()=>{
          setDrawerOpen(false)
        }
      }
    }

    nodes.push(root)

    if(state.basicData.parent){
      let parent = {
        id:state.basicData.parent.id,
        position:{
          x:0, y:0
        },
        type:'KLineageGroupNodeV3',
        width:nodeWidth,
        height:102,
        data:{
          width:nodeWidth,
          height:102,
          label: getDispFields(state.basicData.parent,'dispTitle'),
          subTitle: state.basicData.parent.category,
          obj:state.basicData.parent,
          icon:getIconLabel({label:'collection',item:state.basicData.parent}),
          object_type:'COLLECTION',
        },
      }
      nodes.push(parent)
    }

    if(state.linkedTabs.length>0){
      state.linkedTabs.forEach((t,index)=>{
        let isCollection = t.isCollection;
        let countPerSide = state.linkedTabs.length/2;
        let sideHeight = 600;
        // spread out the nodes
        let order = index % countPerSide;
        let centreY = root.position.y;
        let y = centreY + (order - (countPerSide-1)/2) * sideHeight/countPerSide;
        let width = 200
        let height = 46
        let node = {
          id:t.label,
          type:'KIconLabelNode',
          position:{
            x:root.position.x + 500*(index>=countPerSide?1:-1),
            y
          },
          width,
          height,
          data:{
            width,
            height,
            text:t.dispName,
            iconLabel:isCollection?'collection':t.label,
            onClick:()=>{
              setDrawerOpen({type:t.label, isCollection})
            }
          }
        }

        let link = {
          id:index,
          source:node.position.x<root.position.x?root.id:node.id,
          target:node.position.x<root.position.x?node.id:root.id,
          type:'KLineageEdge',
          data:{
            lineType:'step',
            noArrow:true,
            disableHover:true
          }
        }

        nodes.push(node)
        links.push(link)
      })
    }

    dispatch({
      type:'set_map_data',
      mapData:{
        nodes, links
      }
    })
// eslint-disable-next-line
  },[state.linkedTabs])

  return (
    <div className={classes.root} >
      {
        !state.lineageSelectedItem &&
        <div style={{display:'flex',zIndex:24,position:'relative',marginRight:300}}>
          <Typography className={classes.title}>
            MAP
          </Typography>
        </div>
      }
      {
        !state.lineageSelectedItem &&
        <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>Click on an object type to see more details</Typography>
      }
      {
        state.linkedTabsLoading &&
        <div style={{textAlign:'center',marginTop:168}}>
          <LinearProgress color='secondary' style={{width:200,margin:'auto',marginBottom:24}}/>
          <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>Generating map</Typography>
        </div>
      }
      {
        state.linkedTabsError &&
        <div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',justifyContent:'center'}}>
          <div className={classes.emotRoot}>
            <Typography className={classes.emot}>¯\_(ツ)_/¯</Typography>
            <Typography className={classes.text}>Unfortunately I am not sure what went wrong.</Typography>
            <Typography className={classes.text}>Let your administrator know so they can fix the problem.</Typography>
          </div>
        </div>
      }

      {
        state.lineageSelectedItem &&
        <div className='mt-4'>
          <LineageV3
            history={history}
            root={state.lineageSelectedItem}
            state={state}
            dispatch={dispatch}
            isLinkable={checkLineageLinkable({sessionData})}
            onExit={()=>dispatch({type:'set_lineage_selected_item'})}
          />
        </div>
      }
      <div ref={chartWrapperRef} className={classes.chartWrapper} style={{height:graphHeight,width:graphWidth}}>
        {
          state.linkedTabs && state.mapData && !state.lineageSelectedItem &&
          <ReactFlowWrapper
            initialNodes={state.mapData.nodes.map(n=>({...n,selected:drawerOpen.type===n.id, draggable:false}))}
            initialLinks={state.mapData.links}
            presetCenter={{x:0,y:200}}
            hideMiniMap={true}
            onFocusOffset={{x:360,y:100}}
          />
        }
      </div>
      <RelatedListDrawer
        history={history}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        object={state.basicData}
        state={state}
        dispatch={dispatch}
      />

    </div>
  )
}

Map.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  cerberumLabel: PropTypes.string.isRequired,
  relations: PropTypes.array.isRequired,
  generateHeader: PropTypes.func,
}

export default withTheme()(withStyles(styles)(Map));
