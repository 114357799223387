export const getAlertTheme = palette => {
  return `.s-alert-wrapper {
    z-index: 99999
  }

  .s-alert-top {
    width: 400px !important;
    max-width: 400px !important;
    margin: 24px auto !important;
  }


  .s-alert-box {
    width: 400px;
    max-width: 400px;
  }

  .s-alert-info, .s-alert-warning {
    margin: 20px;
    margin-left: 60px;
    background-color:var(--color-info) !important;
    min-width: 400px;
    max-width: 568px;
    border-radius: 4px;
  }


  .s-alert-info div{
    color:var(--color-info-content) !important;
  }

  .s-alert-info span::before{
    background:var(--color-info-content) !important;
  }

  .s-alert-info span::after{
    background:var(--color-info-content) !important;
  }

  .s-alert-error {
    margin: 20px;
    margin-left: 60px;
    background-color:var(--color-error) !important;
    min-width: 400px;
    max-width: 568px;
    border-radius: 4px;
  }

  .s-alert-box-inner {
    color: #fff;
    display: flex;
    white-space:pre-wrap;
    flex-wrap: wrap !important;
    align-items: center !important;
    word-break: break-word;
  }`
}
