import React, {  useState } from 'react';
import
{ withTheme, withStyles, Modal, Paper, Typography, Button, LinearProgress}
from '@material-ui/core';
import ModalAlert from '../../UI/ModalAlert/ModalAlert'
import axiosCerebrum from '../../../axios-cerebrum'
import { getIconComponent } from '../../../utilities';
import {useDispatch} from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root:{
    width:'100vw',
    height:'100vh',
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center',
  },
  title: {
    fontSize: 20,
    fontWeight: '500',
    color:theme.palette.header.main,
    marginBottom: 16,
  },
  subTitle: {
    fontSize:13.75,
    marginBottom: 8,
    whiteSpace:'pre-wrap',
    color:theme.palette.primaryText.main
  },
  buttons: {
    flexGrow:0,
    backgroundColor: theme.palette.background.main,
    paddingTop: 12,
    marginRight:-16,
    marginTop:5,
    width:'100%',
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
})

function DeleteModal(props) {

  const {
    classes,
    theme,
    history,
    modalOpen,
    setModalOpen,
    state
  } = props;

  const reduxDispatch = useDispatch()
  const [disableButton, setDisableButton] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const {
    sendAlert
  } = useAlert({
    id:`ci-delete`,
  })

  const onCancel = () => {setModalOpen(false)}

  const onDelete = () => {
    setDisableButton(true)
    axiosCerebrum
      .delete(`/api/collectioninstances/${state.basicData.id}`)
      .then(response=>{
        reduxDispatch({
          type:actions.REMOVE_PAGE_CACHE,
          data:`${document.location.protocol + "//" + document.location.host}/profile/collection/${state.basicData.parent.id}`
        })
        reduxDispatch({
          type:actions.REMOVE_PAGE_CACHE,
          data:window.location.href
        })
        sendAlert({type:'info',message:`Instance "${state.basicData.name}" successfully deleted`})
        if(history.length>1){
          history.goBack()
        }else{
          history.push('/home')
        }
      })
      .catch(error=>{
        console.log(error);
        setDisableButton(false)
        let msg = `Error occurred deleting instance, please try again`;
        if(error.response && error.response.status && error.response.status===403){
          msg = `You are not allowed to delete this instance`
        }
        setAlertMessage(msg)
        setAlertOpen(true)
      })
  }

  let instanceName = 'instance'
  if(state.basicData.parent.category==='GLOSSARY')instanceName = 'term'

  return (
    <div >
      <Modal
        className={classes.root}
        open={modalOpen}
        onClose={()=>onCancel()}
        disableBackdropClick={true}
      >
        <div style={{width:550,marginTop:'30vh',outline:'none'}}>
          <Paper style={{width:500,padding:24,paddingBottom:8,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
            <Typography className={classes.title}>DELETE {instanceName.toUpperCase()}</Typography>
            {
              disableButton?
              <div style={{textAlign:'center'}}>
                <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Deletion in progress</Typography>
              </div>
              :
              <div>
                <Typography className={classes.subTitle}>
                  Are you sure? This action cannot be reversed
                </Typography>
                {
                  !isNaN(state.childCount) && state.childCount>0 &&
                  <div className={classes.subTitle} >
                    <div style={{display:'inline-block',position:'relative',bottom:-8,marginRight:12,lineHeight:1.5}}>
                      {getIconComponent({label:'warning',size:24,colour:theme.palette.primaryText.light})}
                    </div>
                    {state.childCount} child {instanceName}(s) of {state.basicData.name} will become child of {state.parentInstanceData && state.parentInstanceData.items[0]?state.parentInstanceData.items[0].name:state.basicData.parent.name}
                  </div>
                }
              </div>
            }
            <div className={classes.buttons} align='right'>
              <Button data-test-id="delete-modal-delete-button"  color="primary" classes={{disabled:classes.disabledButton}} style={{width:80}} disabled={disableButton} onClick={onDelete}>
                {'DELETE'}
              </Button>
              <Button data-test-id="delete-modal-close-button"  color="secondary" classes={{disabled:classes.disabledButton}} style={{width:80, marginLeft: 8}}  onClick={onCancel}>
                CLOSE
              </Button>
            </div>
          </Paper>

          <ModalAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            message={alertMessage}
            type='error'
          />
        </div>
      </Modal>
    </div>
  )
}
export default withTheme()(withStyles(styles)(DeleteModal));
