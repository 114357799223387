import React, { useEffect, useState } from 'react';
import {withTheme, withStyles, Typography, Select, MenuItem, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getDispFields, getIconComponent, getLabelPlural, getPartialMatchSearchString, mapObjectName, sendMessage } from '../../../utilities';
import SearchSelector from '../SearchSelector/SearchSelector';
import axiosCerebrum from '../../../axios-cerebrum'
import { mapRelationshipName } from './utils';
import { checkFilterApplied, getFilterValues } from './filterHistoryManager';
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import UserSuggestionItem from '../SearchResults/UserSuggestionItem';
import KTooltip from '../KTooltip/KTooltip';
import { globalListenerRef } from '../../../GlobalListenerRef';
import UserChip from '../Chips/UserChip';
import { getIconLabel } from '../SearchResults/utils';
import { getDefaultSearchParams } from '../../BasicSearch/MainSearch/Utils/Utils';
import { onAddFilterHistory } from './filterHistoryManager';

const styles = theme => ({
  container:{
    height:'100vh',
    width:'100vw',
    display:'flex',
    justifyContent:'center',
    outline:'none'
  },
  paperContainer:{
    width:782,
    marginTop:'8vh',
  },
  selector:{
    ...theme.components.selector,
    height:48,
    flex:'0 0 145px'
  },
  selectoContainer:{
    flex:'0 1',
    width:162,
    minWidth:162,
  },
  filterLabel:{
    marginBottom:-8,
    marginLeft:28,
    position:'relative',
    zIndex:99,
    width:'max-content',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    maxWidth:136,
    background:theme.palette.background.main,
    color:theme.palette.primaryText.light,
    padding:'0 6px'
  },
  DatePicker:{
    border:`1px solid ${theme.palette.primaryText.light}`,
    marginTop:0,
    '& input':{
      padding:0,
      paddingLeft:16,
      color:theme.palette.primaryText.main,
      height:32,
      paddingTop:6
    },
    marginLeft:16,
    height:46,
    borderRadius:4,
    marginBottom:1,
    background:`${theme.palette.background.main} !important`,

  },
  errorInput:{
    border:`1px solid ${theme.palette.error.main}`,
    // '& input':{
    //   color:theme.palette.error.main,
    // }
  }
})


const LinkBody = props => {

  const {
    theme,
    // history,
    classes,
    object,
    selectedObjectType,
    selectedRelation,
    setSelectedRelation,
    relations,
    state,
    dispatch,
    profileDispatch,
    setAlertOpen,
    setAlertMessage,
    setAlertType,
    getObjectName,
    enableFilterHistory
  } = props;

  const [searchValue, setSearchValue] = useState('')
  const [suggestionObjects, setSuggestionObjects] = useState(null)

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.linkModalSearchListener)
    globalListenerRef.linkModalSearchListener = (event) => {
        if(event.data.linkSearchSuggestionObject){
          try{
            setSuggestionObjects([JSON.parse(event.data.linkSearchSuggestionObject)])
          }
          catch(e){
            console.log(e)
          }
        }
    }
    window.addEventListener('message',globalListenerRef.linkModalSearchListener)
    return ()=>{
        window.removeEventListener('message',globalListenerRef.linkModalSearchListener)
    }
  },[])

  useEffect(()=>{
    sendMessage({
      isLinkSearchModal:true,
      selectedObjectType
    })
  },[selectedObjectType])

  const shouldSuggestExactMatch = (
      (object.object_type_txt==='TABLE' && selectedObjectType==='TABLE') ||
      (object.object_type_txt==='COLUMN' && selectedObjectType==='COLUMN')
    ) &&
    (selectedRelation==='SOURCE_OF' || selectedRelation==='SOURCE_FROM') &&
    searchValue.trim()===''


  const getParams = ({removeNameMatch}) => {
    let fqs = [];
    let q="*";
    fqs.push(`active_srt:YES`)

    let linkedObjects = state.linkedItemData?.filter(el=>el.relationship===selectedRelation) || []

    fqs.push(`-id:(${[...linkedObjects.map(el=>el.id),object.id].map(el=>el).join(' OR ')})`)

    // apply exact match for suggestion
    if(shouldSuggestExactMatch){
      if(!removeNameMatch)fqs.push(`name_srt:"${object.name_txt||object.name}"`)
      fqs.push(`object_type_srt:${object.object_type_txt}`)
    }

    if(selectedObjectType==='TERM'){
      fqs.push(`object_type_srt:COLLECTION_INSTANCE`)
      fqs.push(`collection_type_srt:GLOSSARY`)
    }else{
      fqs.push(`object_type_srt:${mapObjectName(selectedObjectType,undefined,true)}`)
    }
    if(['MACRO','PROCEDURE'].includes(selectedObjectType)){
      fqs.push(`code_type_srt:${selectedObjectType}`)
    }
    if(searchValue.trim()!=='')q = getPartialMatchSearchString(searchValue)
    if(selectedObjectType==='COLLECTION_INSTANCE'){
      fqs.push(`collection_type_srt:(DATA_GOVERNANCE OR DATA_MANAGEMENT)`)
      if(state.collection!=='all'){
        fqs.push(`collection_srt:"${state.filterCollections.find(c=>c.id===state.collection)?.name_txt}"`)
      }
      if(state.collectionType!=='all'){
        fqs.push(`collection_type_srt:"${state.collectionType}"`)
      }
    }
    if(selectedObjectType==='TERM'){
      if(state.collection!=='all'){
        fqs.push(`collection_srt:"${state.filterCollections.find(c=>c.id===state.collection)?.name_txt}"`)
      }
      if(state.collectionParent!=='all'){
        fqs.push(`hierarchy_parent_srt:"${state.collectionParent}"`)
      }
    }
    if(state.userType!=='all'){
      fqs.push(`account_type_srt:"${state.userType}"`)
    }
    if(state.team!=='all'){
      fqs.push(`teams_msrt:"${state.filterTeams.find(t=>t.id===state.team)?.name_txt}"`)
    }
    if(state.source!=='all'){
      fqs.push(`source_id_srt:${state.source}`)
    }
    if(state.issueSource!=='all'){
      fqs.push(`source_srt:"${state.issueSource}"`)
    }
    if(state.database!=='all'){
      fqs.push(`database_srt:"${state.filterDatabases.find(s=>s.id===state.database)?.name_txt}"`)
    }
    if(state.schema!=='all'){
      fqs.push(`schema_srt:"${state.filterSchemas.find(s=>s.id===state.schema)?.name_txt}"`)
    }
    if(state.table!=='all'){
      fqs.push(`table_srt:"${state.filterTables.find(s=>s.id===state.table)?.name_txt}"`)
    }
    if(state.location!=='all'){
      fqs.push(`location_srt:"${state.location}"`)
    }
    if(['HOST','TOOL'].includes(selectedObjectType) && state.reference!=='all'){
      fqs.push(`reference_srt:${state.reference}`)
    }
    if(selectedRelation==='REPLACES' && state.linkedItemData){
      if(state.linkedItemData.find(el=>el.relationship==='REPLACED_BY')){
        fqs.push(`-id:(${state.linkedItemData.filter(el=>el.relationship==='REPLACED_BY').map(el=>el.id).join(' OR ')})`)
      }
    }
    if(selectedRelation==='REPLACED_BY' && state.linkedItemData){
      if(state.linkedItemData.find(el=>el.relationship==='REPLACES')){
        fqs.push(`-id:(${state.linkedItemData.filter(el=>el.relationship==='REPLACES').map(el=>el.id).join(' OR ')})`)
      }
    }
    return {...getDefaultSearchParams({indexName:'search'}),q:q,fq:fqs.join(' AND '),fl:"*",rows:30,sort:searchValue.trim()===''?'name_srt asc':undefined}
  }


  const updateReplacedByDate = item => {
    let date = moment(state.replaceDate).format('YYYY-MM-DD')
    profileDispatch && profileDispatch({type:'set_basic_data',basicData:{...object,replace_date:date}})
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${selectedRelation==='REPLACES'?item.id:object.id}`,
        {
          replace_date:date,
          description:item.description||''
        }
      )
      .then(response=>{
      })
      .catch(error=>{
        console.log(error);
        profileDispatch && profileDispatch({type:'set_basic_data',basicData:{...object,replace_date:null}})
        setAlertMessage("Error occurred changing replaced date, please try again.");
        setAlertOpen(true)
        setAlertType('error')
      })
  }

  const onAddToFilterHistory = () => {
    if(!enableFilterHistory)return;
    if(!checkFilterApplied(state))return;
    onAddFilterHistory({
      filters: getFilterValues(state),
      objectType: selectedObjectType,
      state
    })
  }

  const onClickResult = el => {
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=${selectedRelation}&object_id=${el.id}`,
      )
      .then(response=>{
        dispatch({type:'set_link_updated',linkUpdated:true})
        setAlertMessage("Item successfully linked")
        setAlertOpen(true)
        setAlertType('info')
        if(['REPLACED_BY','REPLACES'].includes(selectedRelation)){
          updateReplacedByDate(el)
        }
        if(state.linkedItemData){
          dispatch({
            type:'set_linked_item',
            linkedItemData:[...state.linkedItemData,{...el,relationship:response.data.relationship}],
            linkedRelations:state.linkedRelations,
            linkedObjects:state.linkedObjects
          })
        }
        onAddToFilterHistory()
      })
      .catch(error=>{
        console.log(error)
        if(error.response && error.response.status && error.response.status===409){
          setAlertMessage("The link already exists")
        }else{
          setAlertMessage("Error occurred linking to the item, please try again")
        }
        setAlertOpen(true)
        setAlertType('error')
      })
  }

  let filterApplied = (shouldSuggestExactMatch && searchValue.trim()==='') || checkFilterApplied(state)

  return (
    <div style={{marginBottom:24}}>
      <Typography style={{fontSize:16,marginBottom:16}}>
        Select a {getObjectName(selectedObjectType)} to link
        {
          selectedObjectType==='COLLECTION_INSTANCE' &&
          <KTooltip title={"The item’s profile page will be updated immediately. Search results / filters will update overnight."}>
            <span style={{position:'relative',top:4,marginLeft:8}}>
              {getIconComponent({label:'info',size:20,colour:theme.palette.secondary.main})}
            </span>
          </KTooltip>
        }
      </Typography>

      <div style={{display:'flex',alignItems:'flex-start'}}>
        {
          relations.length>1 &&
          <Select
            value={selectedRelation}
            className={classes.selector}
            style={{marginRight:16}}
            disableUnderline
            data-test-id="link-modal-relation-selector"
            onChange={event=>{
              setSelectedRelation(event.target.value)
            }}
          >
            {
              relations
                .map(el=>(
                <MenuItem disabled={selectedObjectType!==object.object.name && ['REPLACED_BY','REPLACES'].includes(el)} value={el}>
                  {mapRelationshipName(el)}
                </MenuItem>
              ))
            }
          </Select>
        }
        {
          ['REPLACED_BY','REPLACES'].includes(selectedRelation) &&
          <div className={classes.selectoContainer} style={{marginTop:-12,marginLeft:-16,marginRight:16}}>
            {/* <Typography className={classes.overlineText}>REPLACE DATE</Typography> */}
            <Typography className={classes.filterLabel} style={{color:!state.replaceDate?theme.palette.error.main:undefined}}>
              Replace Date
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                margin="normal"
                // disableUnderline
                InputProps={{disableUnderline:true}}
                className={classes.DatePicker + (!state.replaceDate?` ${classes.errorInput}`:``)}
                value={state.replaceDate || null}
                emptyLabel='Select Date'
                onChange={value=>dispatch({type:'set_replace_date',replaceDate:value})}
                format='DD/MM/YY'
              />
            </MuiPickersUtilsProvider>
            <Typography
              style={{
                fontSize:12,
                color:theme.palette.primaryText.light,
                cursor:'pointer',
                marginLeft:33,
                marginTop:2
              }}
              onClick={()=>{dispatch({type:'set_replace_date',replaceDate:moment().format('YYYY-MM-DD')})}}
            >
              Set to today
            </Typography>
          </div>
        }
        {
          selectedObjectType &&
          <SearchSelector
            url={'/solr/search/select'}
            params={getParams({})}
            firstLevelSuggestionHeader={shouldSuggestExactMatch && `EXACT MATCHES TO THIS ${mapObjectName(object.object_type_txt).replace(/_/g,' ')}`}
            secondarySuggestionHeader={shouldSuggestExactMatch && (searchValue.trim()===''?'SUGGESTIONS':'SEARCH RESULTS')}
            secondarySuggestionParams={shouldSuggestExactMatch && getParams({removeNameMatch:true})}
            searchValue={searchValue}
            clearable
            disabled={
              ['REPLACED_BY','REPLACES'].includes(selectedRelation) && !state.replaceDate
            }
            autoFocus
            setSearchValue={setSearchValue}
            testID="link-modal-search"
            placeholder={`Search for a ${getObjectName(selectedObjectType)}`}
            suggestionMinWidth={550}
            keepPopperOnClick={true}
            forceSuggestionParam={filterApplied || shouldSuggestExactMatch}
            filterHistoryItem={el=>{
              if(selectedRelation==='REPLACES' && state.linkedItemData){
                if(state.linkedItemData.find(l=>l.relationship==='REPLACED_BY' && l.id===el.object.id)){
                  return false
                }
              }
              if(selectedRelation==='REPLACED_BY' && state.linkedItemData){
                if(state.linkedItemData.find(l=>l.relationship==='REPLACES' && l.id===el.object.id)){
                  return false
                }
              }
              if(state.linkedItemData.find(l=>l.relationship===selectedRelation && l.id===el.object.id))return false
              if(el.object && el.object.object.name==='ISSUE' && !state.linkedItemData.find(d=>d.id===el.object.id))return true;
              if(el.object && el.object.object.name.toLowerCase()!=='collection_instance')return true;
              if(el.object && el.object.parent && ['DATA_GOVERNANCE','DATA_MANAGEMENT'].includes(el.object.parent.category))return true;
              return false
            }}
            postProcessDataList = {list => {
              return list.filter(el=>
                !state.linkedItemData ||
                (
                  (selectedRelation!=='REPLACES' || !state.linkedItemData.find(l=>l.relationship==='REPLACED_BY' && l.id===el.id)) &&
                  (selectedRelation!=='REPLACED_BY' || !state.linkedItemData.find(l=>l.relationship==='REPLACES' && l.id===el.id))
                )
              )
            }}
            defaultSuggestionType={selectedObjectType}
            autoSuggestion={true}
            ignoreAutoCollections={true}
            renderResults={list => {
              if(list.length===0)return [<Typography style={{marginLeft:16,marginBottom:16}}>No suggestion found</Typography>]
              return list.map((item,index)=>(
                item.object_type_txt==='USER'?
                <UserSuggestionItem
                  item={item}
                  itemSource="solr"
                  tailObject={
                    <Button
                      data-test-classname="link-modal-list-item-add"
                      data-test-id={`link-modal-list-item-add-${getDispFields(item,'dispTitle').toLowerCase().replace(/\s/g,'-')}`}
                      onClick={(event) =>{event.stopPropagation();onClickResult(item)}}
                      color='primary'
                      variant="outlined"
                    >
                      ADD
                    </Button>
                  }
                />
                :
                <SimpleResultItem
                  title={getDispFields(item,'dispTitle')}
                  titleColour={theme.palette.primaryText.main}
                  label={item.object?item.object.name:item.object_type_txt}
                  item={item}
                  subTitle={getDispFields(item,'dispSubtitle')}
                  hideRight={true}
                  // onClick={event=>event.stopPropagation()}
                  // noPadding={true}
                  tailObject={
                    <Button
                      data-test-classname="link-modal-list-item-add"
                      data-test-id={`link-modal-list-item-add-${getDispFields(item,'dispTitle').toLowerCase().replace(/\s/g,'-')}`}
                      onClick={(event) =>{
                        event.stopPropagation();
                        onClickResult(item)
                      }}
                      color='primary'
                      variant="outlined"
                    >
                      ADD
                    </Button>
                  }
                  showUnderline={index!==list.length-1?true:false}
                />
              ))
            }}
          />
        }
      </div>
      {
        suggestionObjects?.filter(el=>!state.linkedItemData?.find(l=>l.id===el.id)).length>0 &&
        <div style={{marginTop:8}}>
          <Typography  style={{fontSize: 12, color: theme.palette.primaryText.light}}>Suggestions</Typography>
          <div style={{display:'flex',flexWrap:'wrap',overflow:'hidden',marginTop:8}}>
            {
              suggestionObjects?.filter(el=>!state.linkedItemData?.find(l=>l.id===el.id)).map(el=>(
                <UserChip
                  user={el}
                  onClick={()=>onClickResult(el)}
                  variant={'light'}
                  bottomMargin={6}
                  iconLabel={getIconLabel({label:'COLLECTION_INSTANCE',item:el})}
                />
              ))
            }
          </div>
        </div>
      }
    </div>
  )

}

LinkBody.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history:PropTypes.object.isRequired,
  relations: PropTypes.array,
  selectedRelation: PropTypes.string.isRequired,
  setSelectedRelation: PropTypes.func.isRequired,
  selectedObjectType: PropTypes.string,
  setAlertOpen: PropTypes.func,
  setAlertMessage: PropTypes.func,
  setAlertType: PropTypes.func
}


export default withTheme()(withStyles(styles)(LinkBody));
