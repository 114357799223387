import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { getInitials, toTitleCase, trimText } from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import GenericLinkModal from '../GenericLinkModal/GenericLinkModal';
import useAlert from '../../../hooks/useAlert';
import {
  KadaAvatar,
  KadaBadge,
  KadaDropdownBtn,
  KadaIconBtn,
  KadaLoadingBar,
  KadaTableHeader,
  kBadgeVariants,
  kDropdownBtnVariants,
  KadaInput,
  KadaHorizExpansion,
  KadaFilter,
  KadaIcon
} from 'kada-component-library'
import useGetPriorities from '../../../hooks/useGetPriorities';
import useGetWorkflowStatus from '../../../hooks/useGetWorkflowStatus';
import CustomMenu from '../ContextMenu/ContextMenu'
import ContextMenuHolder from '../ContextMenu/ContextMenuHolder';
import { onOpenContextHolder } from '../ContextMenu/utils';
import moment from 'moment';

const Issues = props => {

  const {
    history,
    dispatch,
    state
  } = props;

  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const [forceExpand, setForceExpand] = useState(false)

  const isCancelledRef = useRef(false)

  const { data: priorityData } = useGetPriorities({
    objectType: 'ISSUE'
  });

  const { data: statusData } = useGetWorkflowStatus({
    objectType: 'ISSUE'
  });

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])

  if (state.issueLoading ) return (
    <div className='mt-20'>
      <KadaLoadingBar
        text='Loading'
      />
    </div>
  );

  if (state.issueError) return <p>Fail to load issues</p>;

  if(!state.issueData)return <div></div>

  const selectedStatus = state.issueData.selectedStatus || 'All';
  const selectedPriority = state.issueData.selectedPriority || 'All';
  const sort = state.issueData.sort || 'Created,desc';

  const setSelectedStatus = value => {
    dispatch({
      type:'set_issue_data',
      issueData:{...state.issueData,selectedStatus:value,search:''}
    })
  }

  const setSelectedPriority = value => {
    dispatch({
      type:'set_issue_data',
      issueData:{...state.issueData,selectedPriority:value,search:''}
    })
  }

  const onSortChange = value => {
    dispatch({
      type:'set_issue_data',
      issueData:{...state.issueData,sort:value}
    })
  }

  const onSearchChange = value => {
    dispatch({
      type:'set_issue_data',
      issueData:{...state.issueData,search:value}
    })
  }


  const onRemoveIssue = (el) => {
    axiosCerebrum
      .delete(
        `/api/issues/${el.id}/related?relationship=IMPACTS&object_id=${state.basicData.id}`
      )
      .then(response=>{
        dispatch({
          type:'set_issue_data',
          issueData:{
            ...state.issueData,
            items:state.issueData.items.filter(i=>i.id!==el.id)
          }
        })
        sendAlert({
          message: `Link to ${el.name_txt} removed`,
          type: 'info'
        })
      })
      .catch(error=>{
        console.log(error);
        sendAlert({
          message: `Error occurred unlinking ${el.name_txt}`,
          type: 'error'
        })
      })
  }

  const getIssueName = fullName => {
    return fullName.split(':').slice(1).join(':')
  }

  const processList = list => {
    if(selectedStatus!=='All'){
      list = list.filter(l=>l.workflow_status_txt.toUpperCase()===selectedStatus.toUpperCase())
    }
    if(selectedPriority!=='All'){
      list = list.filter(l=>l.priority_txt.toUpperCase()===selectedPriority.toUpperCase())
    }

    list = list.filter(l=> !state.issueData.search || (l.name_txt||l.name_srt||'').split(":")?.[1]?.toLowerCase().includes(state.issueData.search.toLowerCase()))

    if(sort==='Name,asc'){
      list.sort((a,b)=>getIssueName(a.name_txt).localeCompare(getIssueName(b.name_txt)))
    }
    if(sort==='Name,desc'){
      list.sort((a,b)=>getIssueName(b.name_txt).localeCompare(getIssueName(a.name_txt)))
    }
    if(sort==='Priority,asc'){
      list.sort((a,b)=>priorityData.findIndex(p=>p.name.toUpperCase()===b.priority_txt.toUpperCase()) - priorityData.findIndex(p=>p.name.toUpperCase()===a.priority_txt.toUpperCase()))
    }
    if(sort==='Priority,desc'){
      list.sort((a,b)=>priorityData.findIndex(p=>p.name.toUpperCase()===a.priority_txt.toUpperCase()) - priorityData.findIndex(p=>p.name.toUpperCase()===b.priority_txt.toUpperCase()))
    }
    if(sort==='Status,desc'){
      list.sort((a,b)=>statusData.findIndex(s=>s.name.toUpperCase()===b.workflow_status_txt.toUpperCase()) - statusData.findIndex(s=>s.name.toUpperCase()===a.workflow_status_txt.toUpperCase()))
    }
    if(sort==='Status,asc'){
      list.sort((a,b)=>statusData.findIndex(s=>s.name.toUpperCase()===a.workflow_status_txt.toUpperCase()) - statusData.findIndex(s=>s.name.toUpperCase()===b.workflow_status_txt.toUpperCase()))
    }
    if(sort==='Created,asc'){
      list.sort((a,b)=>moment(a.created_at_srt).diff(b.created_at_srt))
    }
    if(sort==='Created,desc'){
      list.sort((a,b)=>moment(b.created_at_srt).diff(a.created_at_srt))
    }
    return list;
  }

  const validStatus = ['All', ...statusData.filter(s=>state.issueData.items.some(i=>i.workflow_status_txt===s.name.toUpperCase())).map(el=>el.name)]
  const validPriority = ['All', ...priorityData.filter(p=>state.issueData.items.some(i=>i.priority_txt===p.name.toUpperCase())).map(el=>el.name)]

  return (
    <div>
      <div
        className='flex items-center gap-4'
      >
        <KadaFilter
          placeholder='All status'
          value={selectedStatus}
          isHidePlaceholderMenuItem
          isHideSearch
          valueDisplayPrefix='Status: '
          options={validStatus.map(e=>{
            return {text:e,value:e}
          })}
          onChange={e=>{
            setSelectedStatus(e.detail.value)
          }}
        />
        <KadaFilter
          placeholder='All priority'
          value={selectedPriority}
          isHidePlaceholderMenuItem
          isHideSearch
          valueDisplayPrefix='Priority: '
          options={validPriority.map(e=>{
            return {text:e,value:e}
          })}
          onChange={e=>{
            setSelectedPriority(e.detail.value)
          }}
        />
        <div class="flex-grow"></div>
        <KadaHorizExpansion
          direction="left"
          bodyWidth="200px"
          forceExpand={forceExpand}
        >
					<div slot="root">
						<KadaIconBtn iconName="search" size={24} />
					</div>
					<div slot="body">
						<KadaInput
							placeholder="Search for issues"
              isSearch
							onInput={(e) => {
                onSearchChange(e.detail.value);
							}}
              onMouseEnter={()=>{
                setForceExpand(true)
              }}
              onMouseLeave={()=>{
                if(state.issueData.search)return;
                setForceExpand(false)
              }}
						/>
					</div>
				</KadaHorizExpansion>
        <KadaDropdownBtn
          iconName='add'
          iconOnly
          variant={kDropdownBtnVariants.roundedIconButton}
          options={[
            {text:'Add issue', onClick:()=>dispatch({type:'set_dqModal_open',dqModalOpen:true})},
            {text:'Link issue', onClick:()=>setLinkModalOpen(true)},
            {text:'Import an external issue and link', onClick:()=>dispatch({type:'set_dqModal_open',dqModalOpen:{isExternal:true}})},
          ]}
        />
        {/* <KadaDropdownBtn
          iconName='more_vert'
          iconOnly
          variant={kDropdownBtnVariants.roundedIconButton}
          options={[
            {text:'Link issue', onClick:()=>{}},
          ]}
        /> */}
      </div>
      <div>
        {
          processList(state.issueData.items).length===0
          ?
          (
            <p className="mt-4">
              No issues found
            </p>
          )
          :
          <table className="table table-wrap" id="issue-table">
            <KadaTableHeader
              tableId='issue-table'
              options={[
                {name:'ID',sortable:false},
                {name:'Name',sortable:true},
                {name:'Priority',sortable:true},
                {name:'Created',sortable:true},
                {name:'Status',sortable:true},
                {name:'Assignee',sortable:false},
                {name:'',sortable:false}
              ]}
              onChange={e=>{
                onSortChange(e.detail.value)
              }}
              value={sort}
            />
            <tbody>
              {
                processList(state.issueData.items).map((el, index) => (
                  <>
                    <tr
                      className="hover:bg-(--color-base-200)"
                      onContextMenu={e=>{
                        e.preventDefault()
                        onOpenContextHolder({
                          x: e.clientX,
                          y: e.clientY,
                          id: el.id
                        })
                      }}
                    >
                      <td className="w-25">
                        {el.name_txt.split(':')[0]}
                      </td>
                      <td>
                        <div
                          className="tooltip tooltip-right"
                          data-tip={el.name_txt}
                        >
                          <a
                            className={'!underline cursor-pointer'}
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(`/profile/issue/${el.id}`)
                            }}
                            href={`/profile/issue/${el.id}`}
                          >
                            {trimText(el.name_txt.split(':').slice(1).join(':'), 50)}
                          </a>
                        </div>
                      </td>
                      <td>
                        {
                          el.priority_txt ?
                          <KadaBadge
                            text={toTitleCase(el.priority_txt)}
                            variant={kBadgeVariants.outlined}
                            color={priorityData.find(p => p.name.toUpperCase() === el.priority_txt.toUpperCase())?.colour}
                          />
                          : 'N/A'
                        }
                      </td>
                      <td className="w-36">
                        {moment(el.created_at_srt).format('ll')}
                      </td>
                      <td>
                        {
                          el.workflow_status_txt ?
                          <KadaBadge
                            text={toTitleCase(el.workflow_status_txt)}
                            variant={kBadgeVariants.outlined}
                            color={el.workflow_status_txt ? statusData.find(s => s.name.toUpperCase() === el.workflow_status_txt.toUpperCase())?.colour : undefined}
                          />
                          : 'N/A'
                        }
                      </td>
                      <td className="w-22">
                        {
                          el.assignee_txts?.length > 0 ?
                          <KadaAvatar
                            text={getInitials(el.assignee_txts[0])}
                            tooltip={el.assignee_txts[0]}
                          />
                          :
                          <div
                            className="tooltip"
                            data-tip="None"
                          >
                            <KadaIcon
                              iconName="unknown_circle"
                              size={24}
                              color="var(--color-light-text)"
                            />
                          </div>
                        }
                      </td>
                      <td className="w-6">
                        <KadaDropdownBtn
                          iconName='more_horiz'
                          iconOnly
                          variant={kDropdownBtnVariants.roundedIconButton}
                          options={[
                            {text:'Remove issue', onClick:()=>onRemoveIssue(el)},
                          ]}
                        />
                      </td>
                    </tr>
                    <ContextMenuHolder
                      popperId={el.id}
                    >
                      <CustomMenu
                        item={el}
                        actions={[
                          'open_new_tab',
                          'bookmark',
                        ]}
                      />
                    </ContextMenuHolder>
                  </>
                ))
              }
            </tbody>
          </table>
        }
      </div>
      <GenericLinkModal
        object={state.basicData}
        history={history}
        modalOpen={linkModalOpen}
        setModalOpen={setLinkModalOpen}
        linkableObjects={['ISSUE']}
        relations={['IMPACTED_BY']}
        profileDispatch={dispatch}
        allowAddNewIssue={true}
        onLinkUpdated={()=>{
          window.postMessage({reload_issue:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
        }}
      />
    </div>
  )
}

Issues.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default Issues;
