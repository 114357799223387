import React, {useReducer, useEffect } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import {  LinearProgress, Typography } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/AutoCollectionInstanceProfile/Body/Body';
import useGetCerebrum from '../../hooks/useGetCerebrum';
import axiosSolr from '../../axios-solr';
import axiosCerebrum from '../../axios-cerebrum';
import { handleShareClick, toTitleCase, setInitialState, getDispFields, mapObjectName} from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import DeleteModal from '../../components/ManualCollectionInstanceProfile/DeleteModal/DeleteModal'
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { getIconLabel } from '../../components/UI/SearchResults/utils'

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});


const initialState = {
  tabState: 1,
  // basic table data
  basicData: null,
  linkedData:{},
  insightsData: null,
  linkedTabState:0,
  linkedTabs:[],

  relatedContentData:null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_terms':
      return {
        ...state,
        terms:action.terms,
        termsLoading:action.termsLoading,
        termsError:action.termsError
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData
      }
    case 'set_member_tables':
      return {
        ...state,
        memberTables:action.memberTables
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_linked_data':
      return {
        ...state,
        linkedData:action.linkedData
      }
    case 'set_linked_tabs':
      return {
        ...state,
        linkedTabs:action.linkedTabs,
        linkedTabsLoading:action.linkedTabsLoading,
        linkedTabsError:action.linkedTabsError
      }
    case 'set_linked_tab_state':
      return {
        ...state,
        linkedTabState:action.linkedTabState,
      }
    case 'set_related_data':
      return {
        ...state,
        relatedContentData:action.relatedContentData
      }
    case "set_clear_state":
      return {
        ...initialState,
      }
    case 'set_delete_modal_open':
      return {
        ...state,deleteModalOpen:action.deleteModalOpen
      }
    case "set_default_name":
      return {
        ...state,
        defaultName:action.defaultName
      }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_issue_data':
      return {
        ...state,
        issueData: action.issueData,
        issueError: action.issueError,
        issueLoading: action.issueLoading
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_user_map':{
      return {
        ...state,
        userMap:action.userMap
      }
    }
    case 'set_collection_map':{
      return {
        ...state,
        collectionMap:action.collectionMap
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const AutoCollectionInstanceProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
    cerebrumObject
  } = props;

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])


  const {
    data: fullResponse,
    loading,
    error,
    fetchList
  } = useGetCerebrum({
    url: `/api/collectioninstances/${match.params.id.toLowerCase()}`,
    preventAuto:true
  })

  useEffect(()=>{
    if(fullResponse){
      dispatch({type:'set_basic_data',basicData:fullResponse})
      addHistory({url:window.location.pathname, title: getDispFields(fullResponse,'dispTitle'), subTitle:getDispFields(fullResponse,'dispSubtitle'),object:fullResponse,type:'profile'})
    }
  },[fullResponse])

  useEffect(()=>{
    if(!state.basicData || !state.basicData.parent)return;
    let properties = state.basicData.parent.properties;
    let values = state.basicData.properties;
    let userIds = [];
    properties.forEach(el=>{
      if(['USER_LOOKUP_FILTERED','USER_LOOKUP_FILTERED_MULTI','USER_LOOKUP','USER_LOOKUP_MULTI'].includes(el.data_type)){
        if(values[el.id] && values[el.id]!==''){
          userIds = [...userIds,...(['USER_LOOKUP_FILTERED_MULTI','USER_LOOKUP_MULTI'].includes(el.data_type)?values[el.id]:[values[el.id]])];
        }
      }
    })
    if(userIds.length===0){
      dispatch({type:'set_user_map',userMap:{}});
    }else{
      axiosSolr.get(
        '/solr/search/select',
        {params:{
          q:"*",
          fq:`id:(${userIds.join(' OR ')})`,
          fl:'*',
          rows:userIds.length
        }}
      ).then(response=>{
        let map = {};
        response.data.response.docs.forEach(r=>{
          map[r.id]=r;
        })
        dispatch({type:'set_user_map',userMap:map});
      })
      .catch(error=>{
        console.log(error)
      })
    }
  },[state.basicData])

  const linkedFetch = (data,presetTabState) => {
    dispatch({
      type:'set_linked_data',linkedData:{}
    })
    dispatch({
      type:'set_linked_tabs',
      linkedTabsLoading:true
    });
    axiosCerebrum
      .get(`/api/collectioninstances/${match.params.id.toLowerCase()}/related/objects`,{params:{relationship:'MEMBERS,MEMBERS_AUTO,VERIFIES,CLASSIFIES',per_page:30}})
      .then(response=>{
        let tabs = response.data.items.filter(el=>el.name!=='COLLECTION_INSTANCE').map(el=>({label:el.name,dispName:mapObjectName(el.name,true).replace(/_/g,' ')}));
        axiosCerebrum
          .get(
            `/api/collectioninstances/${match.params.id.toLowerCase()}/related`,
            {params:{relationship:'MEMBERS,MEMBERS_AUTO,VERIFIES,CLASSIFIES',parent_flag:true,object_name:'COLLECTION_INSTANCE'}}
          )
          .then(collectionResp=>{
            tabs = [...tabs, ...collectionResp.data.items.map(el=>({label:el.name,isCollection:true,dispName:el.name.toUpperCase()}))];
            let defaultLinkedIndex = 0;
            let deaultLinkedLabel = '';
            switch(data.parent.name.toLowerCase()){
              case 'query cluster':
                deaultLinkedLabel= `query`;
                break;
              default:
                deaultLinkedLabel=`table`
            }
            if(!isNaN(presetTabState))dispatch({type:'set_linked_tab_state',linkedTabState:Math.max(0,Math.min(presetTabState,tabs.length-1))})
            else{
              tabs.forEach((el,index)=>{
                if(el.label.toLowerCase()===deaultLinkedLabel)defaultLinkedIndex=index;
              });
              dispatch({type:'set_linked_tab_state',linkedTabState: Math.max(0,Math.min(defaultLinkedIndex,tabs.length-1))})
            }
            dispatch({
              type:'set_linked_tabs',
              linkedTabs:tabs
            });

          })
          .catch(error=>{

          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_linked_tabs',
          linkedTabsError:true
        });
      })
  }

  useEffect(()=>{
    if(!state.basicData)return;
    let data = state.basicData;
    if(!data)return;
    linkedFetch(data);
    if(['Location','Query Cluster'].includes(state.basicData.parent.name)){
      dispatch({type:'set_tab_state',tabState:0})
    }
    if(data.parent.name.toLowerCase()==='table cluster'){
      axiosCerebrum
        .get(`/api/collectioninstances/${data.id}/related?object_name=TABLE&relationship=MEMBERS_AUTO&per_page=50`)
        .then(response=>{
          dispatch({
            type:'set_member_tables',
            memberTables:response.data.items
          })
          if(response.data.total>0){
            dispatch({type:'set_default_name',defaultName:response.data.items.map(el=>toTitleCase(el.name)).join(', ')+(response.data.total>10?'...':'')})
          }
        })
    }
  // eslint-disable-next-line
  },[state.basicData])

  const issueFetchList = () => {
    dispatch({
      type: 'set_issue_data',
      issueLoading: true
    })
    axiosCerebrum
      .get(
        `/api/collectioninstances/${match.params.id.toLowerCase()}/related`,
        {params: {
          relationship:'IMPACTED_BY,IMPACTED_BY_SRC',
          per_page:200,
          sort:'END_DESC'
        }}
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type: 'set_issue_data',
            issueData: response.data
          })
          return;
        }
        axiosSolr
          .post('/solr/search/select',{query:'*',limit:response.data.items.length,filter:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`})
          .then(solrRes=>{
            dispatch({
              type: 'set_issue_data',
              issueData: {...response.data,items:solrRes.data.response.docs},
            })
          })
          .catch(error=>{
            dispatch({
              type: 'set_issue_data',
              issueData: state.issueData,
              issueError: true,
            })
          })
      })
      .catch(error=>{
        dispatch({
          type: 'set_issue_data',
          issueData: state.issueData,
          issueError: true,
        })
      })
  }

  useEffect(()=>{
    if(state.basicData && state.basicData.parent && state.basicData.parent.name==='Table Cluster' && !state.issueData){
      issueFetchList();
    }
    // eslint-disable-next-line
  },[state.basicData])


  useEffect(()=>{
    if(!state.basicData){
      if(cerebrumObject){
        dispatch({type:'set_basic_data',basicData:cerebrumObject})
        addHistory({url:window.location.pathname, title: getDispFields(cerebrumObject,'dispTitle'), subTitle:getDispFields(cerebrumObject,'dispSubtitle'),object:cerebrumObject,type:'profile'})
        return;
      }
      fetchList()
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])


  if (loading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (error ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }


  const tabOptions = ['Location','Query Cluster'].includes(state.basicData.parent.name)?['RELATED']:['DETAILS', 'RELATED'];

  const onDeletInstance = () => {
    dispatch({type:'set_delete_modal_open',deleteModalOpen:true})
  }

  let editable = false;
  if(sessionData.user_role && sessionData.user_role.length>0){
    let userRoles = sessionData.user_role.map(el=>el.toUpperCase());
    let instance_roles = state.basicData.parent.instance_roles;
    userRoles.forEach(el=>{
      if(instance_roles[el] && instance_roles[el].includes('EDIT')){
        editable = true;
      }
    })
  }

  let buttons = [];

  if(editable){
    buttons.push(
      <ProfileButton
        onClick={() => onDeletInstance()}
        iconLabel='delete'
        iconColour={theme.palette.primaryText.light}
        iconOnly={true}
      />
    )
  }

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  buttons.push(
    <FollowButton
      following={state.following}
      object={state.basicData}
      dispatch={dispatch}
    />
  )

  let bannerdisplayText,bannerCause,bannerVisibility;

  let openIssueCount = state.basicData.number_of_issues_srt;
  if (openIssueCount > 0) {
    bannerdisplayText = `This instance has ${openIssueCount} open or in progress issue(s)`;
    bannerCause = 'issue';
    bannerVisibility = 'visible' ;
  }


  let title = state.basicData.name
  let description = state.basicData.description

  if(state.basicData.parent.name.toLowerCase()==='table cluster'){
    if(title===`${state.basicData.parent.name} ${state.basicData.signature}` && state.defaultName){
      title=state.defaultName;
      description = `Table Cluster ${state.basicData.display_id}`
    }
  }

  if(state.basicData.parent.name.toLowerCase()==='query cluster'){
    if(title!==`${state.basicData.parent.name} ${state.basicData.signature}`){
      description = `Query Cluster ${state.basicData.display_id}`
    }
  }


  let iconLabel = getIconLabel({label:'collection_instance',item:state.basicData})

  let mappingOption = ['collectionType','lastUpdated']

  return (
    <div>
      <DeleteModal
        state={state}
        history={history}
        modalOpen={state.deleteModalOpen}
        setModalOpen={open=>dispatch({type:'set_delete_modal_open',deleteModalOpen:open})}
      />
      <ProfileLayout
        header={(
          <ProfileHeader
            iconLabel={iconLabel}
            title={title}
            state={state}
            dispatch={dispatch}
            shouldLoadBreadCrumb
            nodeKey={match.params.id.toLowerCase()}
            label={'collection_instance'}
            description={description}
            buttons={buttons}
            data={state.basicData}
            history={history}
            shouldLoadLinkedInstance
            bannerdisplayText={bannerdisplayText}
            bannerVisibility={bannerVisibility}
            bannerCause={bannerCause}
          />
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
            fetchList={fetchList}
            tabOptions={tabOptions}
          />
        }
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            fetchList={fetchList}
            mapping={mappingOption}
            cerebrumLabel={'collectioninstances'}
            data={state.basicData}
          />
        }
        hideSideBar={['RELATED'].includes(tabOptions[state.tabState])}
        state={state}
        dispatch={dispatch}
      />
    </div>
  )

}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(AutoCollectionInstanceProfile)));
