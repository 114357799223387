import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ClickAwayListener,IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import {  getIconComponent,  getLabelPlural, getNameSearchQuery, validateUrl} from '../../../../utilities';
import { tileStyles } from './utils/styles';
import KTooltip from '../../KTooltip/KTooltip';
import SearchSelector from '../../SearchSelector/SearchSelector';
import { checkIsClickOnSuggestion, measureText } from './utils/utils';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  clickableText:{
    ...tileStyles.clickableText
  },
  iconButton:{
    ...tileStyles.deleteButton
  },
})

function ChannelManager(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    isEditable,
    alwaysOpenNewTab
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [deleting, setDeleting] = useState(false)
  const [adding, setAdding] = useState(false)
  const popperRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  const loadChannel = () => {
    setLoading(true)
    axiosCerebrum.get(
      `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
      {
        params:{
          category:'COLLABORATION',
          parent_name:'channel',
          per_page:200
        }
      }
    ).then(async response=>{
      if(response.data.total!==0){
        let details = {}
        let promises = []
        for(let i=0; i<response.data.items.length; i++){
          promises.push(axiosCerebrum
            .get(`/api/collectioninstances/${response.data.items[i].id}`)
            .then(instanceRes=>{
              if(instanceRes.data){
                details[response.data.items[i].id] = instanceRes.data
              }
            })
          )
        }
        await Promise.all(promises);
        onAddTileData(
          {
            "disp_body":Object.keys(details).map(k=>details[k]).map(el=>({
              name:el.name,
              id:el.id,
              onClick:()=>{
                if(el.properties[3]==='Email'){
                  window.open(`mailto:${el.properties[4]}`);
                  return;
                }
                let url = el.properties[4];
                if(!url)return;
                if (!url.match(/^https?:\/\//i) && !url.match(/^http?:\/\//i)){
                  url = 'https://' + url;
                }
                window.open(url,'_blank')
              },
              tooltip:`${el.description}\n\nType: ${el.properties[5]||'Unknown'}`,
              labels:!el.properties[3] || el.properties[3].toLowerCase()==='other'?'channel':el.properties[3],
            })),
            "disp_title":"LINKED CHANNELS",
            "id":"channels",
          }
        )
        setLoading(false)
      }else{
        onAddTileData(
          {
            "disp_body":[],
            "disp_title":"LINKED CHANNELS",
            "id":"channels",
          }
        )
        setLoading(false)
      }
    })
    .catch(error=>{
      setLoading(false)
      if(error.code==="ERR_CANCELED")return;
      console.log(error)
    })
  }

  useEffect(()=>{
    if(!tileData){
      loadChannel()
    }
  // eslint-disable-next-line
  },[])

  const onAdd = async item => {
    if(adding)return;
    setAdding(true)
    setSearchValue('')
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=MEMBER_OF&object_id=${item.id}`
      )
      .then(()=>{
        axiosCerebrum
          .get(
            `/api/collectioninstances/${item.id}`
          )
          .then(response=>{
            setAdding(false)
            onAddTileData(
              {
                "disp_body":[...tileData.disp_body,{
                  name:item.name_txt,
                  id:item.id,
                  onClick:()=>{
                    if(response.data.properties[3]==='Email'){
                      window.open(`mailto:${response.data.properties[4]}`);
                      return;
                    }
                    let url = response.data.properties[4];
                    if(!url || !validateUrl(url) ){
                      if(alwaysOpenNewTab){
                        window.open(`/profile/collection_instance/${item.id}`,'_blank')
                      }else{
                        history.push(`/profile/collection_instance/${item.id}`)
                      }
                      return;
                    }
                    window.open(url,'_blank')
                  },
                  tooltip:`${response.data.description}\n\nType: ${response.data.properties[5]||'Unknown'}`,
                  labels:!response.data.properties[3] || response.data.properties[3].toLowerCase()==='other'?'channel':response.data.properties[3],
                }],
                "disp_title":tileData.disp_title,
                "id":tileData.id,
              }
            )
          })
          .catch(error=>{
            setAdding(false)
            onAddTileData(
              {
                "disp_body":[...tileData.disp_body,{
                  name:item.name_txt,
                  id:item.id,
                  onClick:()=>{
                    alwaysOpenNewTab?
                    window.open(`/profile/collection_instance/${item.id}`,'_blank'):
                    history.push(`/profile/collection_instance/${item.id}`)
                  },
                  labels:'channel',
                }],
                "disp_title":tileData.disp_title,
                "id":tileData.id,
              }
            )
          })

      })
      .catch(error=>{
        setAdding(false)
        console.log(error)
        sendAlert({
          message: 'Error occurred adding channel, please try again',
          type: 'error'
        })
      })
  }

  const onDelete = (item) => {
    setDeleting(true)
    let showLoadingTimeout = setTimeout(()=>{
      setLoading(true)
    },800)
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=MEMBER_OF&object_id=${item.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":tileData.disp_body.filter(b=>b.id!==item.id),
            "disp_title":tileData.disp_title,
            "id":tileData.id,
          }
        )
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({
          message: 'Error occurred deleting channel, please try again',
          type: 'error'
        })
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
  }

  if(!tileData)return <></>

  return (
    <div data-test-id="channel-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer} >
        <Typography className={classes.title}>{tileData.disp_title.toUpperCase()}</Typography>
      </div>
      <ClickAwayListener
        onClickAway={(event)=>{
          if(checkIsClickOnSuggestion(event.target))return;
          setSearchValue('')
          setEditing(false)
        }}
      >
        <div ref={popperRef} className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          {
            !loading && tileData && tileData.disp_body.length===0 &&
            <div data-test-classname="channel-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
              <span className={classes.tagText} style={{color:theme.palette.primaryText.main}}>None</span>
            </div>
          }
          {
            !loading && tileData && tileData.disp_body.sort((a,b)=>a.name<b.name?-1:1).map(t=>(
              <div data-test-classname="channel-data-tile-chip" className={classes.tag} style={{width:editing||tileData.disp_body.length===1?undefined:measureText(t.name)+48}} onClick={t.onClick}>
                <div style={{width:16,height:16,marginLeft:8}}>
                  {getIconComponent({label:t.labels,size:16,colour:'#fff'})}
                </div>
                <KTooltip title={t.tooltip}>
                  <span className={classes.tagText + ' ' + classes.clickableText}>{t.name}</span>
                </KTooltip>
                {
                  isEditable &&
                  <IconButton className={classes.iconButton + ' chip-delete-button'} style={{display:editing?'block':undefined}}  onClick={event=>{event.stopPropagation();!deleting && onDelete(t)}}>
                    {getIconComponent({label:'clear',size:13.75,colour:'#fff'})}
                  </IconButton>
                }
              </div>
            ))
          }
          {
            editing &&
            <div style={{marginTop:8,marginBottom:6,width:'100%'}}>
              <SearchSelector
                url={'/solr/search/select'}
                params={{
                  q:getNameSearchQuery(searchValue),
                  fq:`object_type_srt:COLLECTION_INSTANCE AND collection_srt:CHANNEL` +
                      (tileData.disp_body.length>0?` AND -id:(${tileData.disp_body.map(b=>b.id).join(' OR ')})`:''),
                  fl:'*',
                  qf:'name_srt',
                  sort:'name_srt asc',
                  rows:20,
                }}
                testID="channel-manager-input"
                clearable
                autoFocus
                height={32}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder={`Search for a channel`}
                autoSuggestion={true}
                suggestionOnRender={true}
                suggestionListWidth={290}
                postProcessDataList={data=>{
                  if(!tileData.disp_body)return data;
                  return data.filter(d=>!tileData.disp_body.find(t=>t.id===d.id))
                }}
                keepPopperOnClick
                removeSuggestionHeader
                scrollable={true}
                isSimplifiedSuggestion={true}
                onResultClick={onAdd}
              />
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

ChannelManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ChannelManager));
