import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { KadaInput, KadaModal, KadaBtn, kButtonVariants, KadaFormEntryLayout, KadaSnackBar, KadaLoadingBar, kLoadingBarVariants } from 'kada-component-library';
import useGetIssueProject from '../../../hooks/useGetIssueProject';

function ExternalLinker(props) {
  const {
    setModalOpen,
    modalOpen,
    externalURL,
    setExternalURL,
    externalIssueData,
    onLinkExternalIssue,
    alertDetails,
    isLinkingIssueToExternal
  } = props;

  const {
    data: issueProjectData,
    fetchIssueProject
  } = useGetIssueProject({
    preventAuto: true
  });

  useEffect(()=>{
    if(modalOpen){
      fetchIssueProject();
    }
  // eslint-disable-next-line
  },[modalOpen])

  return (
    <KadaModal id="issue-creation-modal" width="640px" maxWidth="90vw">
      <div slot="header">
        <h4>
          {isLinkingIssueToExternal ? 'LINK EXTERNAL ISSUE' : 'IMPORT ISSUE FROM JIRA TICKET'}
        </h4>
      </div>
      <div slot="content">
        {
          externalIssueData?.loading ?
          <div className="mt-4">
            <KadaLoadingBar variant={kLoadingBarVariants.secondary} text="Loading data from external ticket"/>
          </div>
          :
          <>
            {
              isLinkingIssueToExternal &&
              <p className="text-(--color-light-text) mb-4">
                Linking an external ticket will <span className="font-bold">replace</span> this issue's title, description, and status with the details found in the external ticket.
              </p>
            }
            <KadaFormEntryLayout
              fieldName="TICKET URL"
            >
              <div slot="content">
                <KadaInput
                  placeholder='Enter external issue URL'
                  value={externalURL}
                  onInput={e => setExternalURL(e.detail.value)}
                  required
                  helperText={
                    issueProjectData ?
                    `Note: It is recommended that you link from ${issueProjectData.name} as this project has been integrated with K`:
                    ""
                  }
                />
              </div>
            </KadaFormEntryLayout>
          </>
        }
      </div>
      <div slot="actions">
        <KadaBtn
          text="LINK"
          style={{ marginRight: 16 }}
          onClick={externalURL?.trim() ? ()=>onLinkExternalIssue(externalURL) : null}
          disabled={!externalURL?.trim()}
        />
        <KadaBtn
          text="CANCEL"
          daisyClass="btn-primary btn-outline"
          onClick={() => {
            setModalOpen(false);
          }}
          variant={kButtonVariants.secondaryBorderless}
        />
      </div>
      <div slot="message">
        <KadaSnackBar
          alertDetails={alertDetails}
          isChipOnly
        />
      </div>
    </KadaModal>
  );
}

ExternalLinker.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  externalURL: PropTypes.string.isRequired,
  setExternalURL: PropTypes.func.isRequired,
  onLinkExternalIssue: PropTypes.func.isRequired,
};

export default ExternalLinker;
