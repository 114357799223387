import axiosCerebrum from "./axios-cerebrum";
import { getUserRoles } from "./utilities";

let platformSettings = [];

export const setPlatformSettings = settings => {
  platformSettings = settings;
}

export const updatePlatformSettings = setting => {
  if (!platformSettings) return;
  platformSettings = platformSettings.map(s => {
    if (s.id !== setting.id) return s;
    return setting
  })
  localStorage.setItem('platformSettings', JSON.stringify({ items: platformSettings }))
  return platformSettings;
}

export const getPlatformSettings = id => {
  return platformSettings.find(s => s.id === id)
}

const checkIfRoleIncluded = ({ sessionData, settingId }) => {
  let allowedRoles = [];
  try {
    allowedRoles = platformSettings.find(el => `${el.id}` === `${settingId}`).value;
  } catch { }
  return sessionData.user_role.some(el => allowedRoles.includes(el));
}

export const checkTermLinkable = ({ sessionData }) => {
  return checkIfRoleIncluded({ sessionData, settingId: 6010 })
}


export const checkLineageLinkable = ({ sessionData, isStewardOrOwner }) => {
  return checkIfRoleIncluded({ sessionData, settingId: 6030 }) || isStewardOrOwner
}


export const checkProfileEditable = ({ sessionData, isStewardOrOwner }) => {
  return checkIfRoleIncluded({ sessionData, settingId: 6000 }) || isStewardOrOwner
}

export const checkEmailConfigured = () => {
  if (!platformSettings.find(s => s.id === 5012)) return false;
  if (platformSettings.find(s => s.id === 5012).value === 'true') return true;
  return false;
}

export const checkCollectionEditable = ({ sessionData, collectionData }) => {
  let editable = false;
  if (sessionData.user_role && sessionData.user_role.length > 0) {
    let userRoles = sessionData.user_role.map(el => el.toUpperCase());
    let instance_roles = collectionData.instance_roles;
    userRoles.forEach(el => {
      if (instance_roles[el] && instance_roles[el].includes('EDIT')) {
        editable = true;
      }
    })
  }
  return editable
}


export const checkCollectionLinkable = ({ sessionData }) => {
  return checkIfRoleIncluded({ sessionData, settingId: 6020 })
}

export const checkIsAdmin = ({ sessionData }) => {
  return getUserRoles(sessionData.user_role).includes('00')
}

export const checkIsDataGov = ({ sessionData }) => {
  return getUserRoles(sessionData.user_role).includes('40')
}

export const checkIsDataManager = ({ sessionData }) => {
  return getUserRoles(sessionData.user_role).includes('10')
}

export const checkIsBusinessUserOnly = ({ sessionData }) => {
  return getUserRoles(sessionData.user_role).every(el => el === '90')
}

export const templateObjects = ['REPORT', 'CONTENT', 'SHEET', 'CONTENT_CHILD', 'TABLE', 'COLUMN', 'WORKSPACE', 'ML_MODEL', 'DATASET', 'DATASET_TABLE', 'DATASET_FIELD']
export const KUpdateOnlyObjects = ['SCHEMA', 'CONTENT_APP', 'DATASET', 'DATASET_TABLE', 'DATASET_FIELD', 'DATA_PIPELINE', 'ML_MODEL', 'DATA_QUALITY_TEST']
export const nonIAObjects = ['DATA_QUALITY_TEST']

export const checkCanAddToCart = ({ sessionData, objectType, ignoreObjectType }) => {
  return (
    (
      checkIsDataGov({ sessionData }) || checkIsDataManager({ sessionData }) ||
      (
        checkCollectionLinkable({ sessionData }) &&
        checkProfileEditable({ sessionData }) &&
        checkTermLinkable({ sessionData })
      )
    )
    &&
    ([...templateObjects, ...KUpdateOnlyObjects].includes(objectType) || ignoreObjectType)
  )
}

export const checkCanUploadBulkFile = ({ sessionData }) => {
  return checkIsAdmin({ sessionData }) || checkIsDataGov({ sessionData }) || checkIsDataManager({ sessionData })
}


export const checkCanBulkUpdate = ({ sessionData }) => {
  return checkIsAdmin({ sessionData }) || checkIsDataGov({ sessionData }) || checkIsDataManager({ sessionData })
}


export const checkCanAddKnowledge = (objectType) => {
  return ['COLUMN', 'TABLE', 'SCHEMA', 'DATABASE', 'CONTENT_APP', 'REPORT', 'SHEET', 'DATA_PIPELINE', 'DATASET_FIELD', 'DATASET_TABLE', 'DATASET', 'FILE', 'CODE'].includes(objectType)
}

export const checkSingleSingOnEnabled = () => {
  let settingID = 50;
  if (!platformSettings.find(s => s.id === settingID)) return false;
  if (platformSettings.find(s => s.id === settingID).value === 'true') return true;
  return false;
}

export const checkEmbedUrlEnabled = () => {
  let settingID = 10100;
  if (!platformSettings.find(s => s.id === settingID)) return false;
  if (platformSettings.find(s => s.id === settingID).value === 'true') return true;
  return false;
}

export const checkIsFollowed = ({ objectId }) => {
  return new Promise((resolve, reject) => {
    axiosCerebrum
      .get(`/api/me/follows`,{params:{
        object_id: objectId,
        type: 'OPT_IN'
      }})
      .then(res => {
        resolve(res.data.items.length > 0)
      })
      .catch(err => {
        // reject(err)
        console.log(err)
        resolve(false)
      })
  })
}
