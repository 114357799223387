import React from 'react';
import { withStyles} from '@material-ui/core';
import Reports from '../Reports/Reports';
import Glossary from '../Glossary/Glossary';
import Collection from '../Collection/Collection'
import Lists from '../Lists/Lists';
import Tags from '../Tags/Tags';

const styles = theme => ({
  root: {

  }
})

const Body = props => {

  const {
    classes,
    tabOptions,
    history,
    state,
    dispatch,
  } = props;

  let bodyContent;

  switch (tabOptions[state.tabState]) {
    case 'REPORTS':
      bodyContent = <Reports state={state} dispatch={dispatch} history={history} />
      break;
    case 'TERMS':
      bodyContent = <Glossary state={state} dispatch={dispatch} history={history} />
      break;
    case 'COLLECTIONS':
      bodyContent = <Collection state={state} dispatch={dispatch} history={history}/>
      break;
    case 'LISTS':
      bodyContent = <Lists state={state} dispatch={dispatch} history={history}/>
      break;
    case 'TAGS':
      bodyContent = <Tags state={state} dispatch={dispatch} history={history}/>
      break;
    default:
  }
  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

export default withStyles(styles)(Body);
