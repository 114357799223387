import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ClickAwayListener, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../../axios-cerebrum';
import { getFontColourByBackground, toTitleCase,} from '../../../../utilities';
import { tileStyles } from './utils/styles';
import { checkIsClickOnSuggestion } from './utils/utils';
import useAlert from '../../../../hooks/useAlert';
import useGetPriorities from '../../../../hooks/useGetPriorities';
import { globalListenerRef } from '../../../../GlobalListenerRef';
import { KadaIcon } from 'kada-component-library';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  iconButton:{
    ...tileStyles.deleteButton
  },
  selector:{
    ...tileStyles.selector
  }
})

function IssueSeverityManager(props) {

  const {
    classes,
    object,
    onAddTileData,
    tileData,
    isEditable,
    isExternalIssue
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const popperRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])

  const { data: priorityData } = useGetPriorities({
    objectType: 'ISSUE'
  });

  const loadSeverity = () => {
    onAddTileData(
      {
        "disp_body":[{
          name:object.priority || 'Unknown',
          labels:'issue_severity',
          id:object.priority || 'Unknown'
        }],
        "disp_title":"PRIORITY",
        "id":"issueSeverity",
      }
    )
  }

  useEffect(()=>{
    if(!tileData){
      loadSeverity()
    }
  // eslint-disable-next-line
  },[])

  useEffect(() => {
    window.removeEventListener('message', globalListenerRef.issueSeverityTileListener)
    globalListenerRef.issueSeverityTileListener = (msg) => {
      if (msg.data.sidebar_action==='reload_sidebar') {
        loadSeverity()
      }
    }
    window.addEventListener('message', globalListenerRef.issueSeverityTileListener)

    return () => {
      window.removeEventListener('message', globalListenerRef.issueSeverityTileListener)
    }
    // eslint-disable-next-line
  }, [object])

  const onAdd = async severity => {
    setEditing(false)
    if(severity===tileData.disp_body[0]?.id)return;
    setLoading(true)

    let requestBody = {
      "priority": severity
    }

    await axiosCerebrum
      .patch(`/api/issues/${object.id}`,requestBody)
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[{
              name:response.data.priority,
              labels:'issue_severity',
              id:response.data.priority,
            }],
            "disp_title":"PRIORITY",
            "id":"issueSeverity",
          }
        )
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:`Error occurred updating issue priority, please try again`,type:'error'})
      })

    setLoading(false)
  }

  if(!tileData)return <></>

  let tagColour = priorityData.find(el => el.name === tileData.disp_body[0].id)?.colour || 'var(--color-base-300)'
  let textColour = priorityData.find(el => el.name === tileData.disp_body[0].id)?.colour ? getFontColourByBackground(tagColour) : 'var(--color-base-content)'

  return (
    <div data-test-id="issue-severity-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer} >
        <Typography className={classes.title}>PRIORITY</Typography>
        {
          isExternalIssue &&
          <div
            className="tooltip tooltip-bottom ml-1 before:!w-[160px] relative"
            data-tip="Disabled. Set by external ticket"
          >
            <KadaIcon
              iconName='lock'
              color='var(--color-light-text)'
              size={16}
            />
          </div>
        }
      </div>
      <ClickAwayListener
        onClickAway={(event)=>{
          if(checkIsClickOnSuggestion(event.target))return;
          setEditing(false)
        }}
      >
        <div ref={popperRef} className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && !editing && setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          {
            tileData.disp_body.length>0 && !loading &&
            <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',overflow:"hidden",width:'100%'}}>
              <div data-test-classname="issue-severity-data-tile-chip" className={classes.tag} style={{background:tagColour,border:'none'}} onClick={tileData.disp_body[0].onClick}>
                <span
                  className={classes.tagText}
                  style={{color:textColour}}
                >
                  {toTitleCase(tileData.disp_body[0].name.replace(/_/g,' '))}
                </span>
              </div>
            </div>
          }
          {
            editing &&
            <div style={{width:'100%',height:0,overflow:'hidden'}}>
              <Select
                value={tileData.disp_body[0]?.id}
                style={{width:0,height:0,opacity:0}}
                onChange={event=>{
                  onAdd(event.target.value)
                }}
                MenuProps={{
                  id:'search_selector_popper_list',
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  style:{marginTop:8},
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl:null,
                }}
                open={editing}
                onClose={()=>setEditing(false)}
                autoFocus
                disableUnderline
                className={classes.selector}
              >
                {
                  priorityData.map(s=>{
                    return (
                      <MenuItem key={s.name} value={s.name} style={{fontSize:13}}>
                        <div
                          className="w-4 h-4 rounded-full mr-2"
                          style={{
                            background: s.colour
                          }}
                        >
                        </div>
                        {s.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

IssueSeverityManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(IssueSeverityManager));
