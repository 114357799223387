import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { getPartialMatchSearchString, removeUrlQueryArg } from '../../utilities';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import { addHistory } from '../../HistoryManager';
import 'url-search-params-polyfill';
import Body from '../../components/IssueBoard/Body/Body';
import { KadaBtn } from 'kada-component-library';
import axiosSolr from '../../axios-solr';
import useGetWorkflowStatus from '../../hooks/useGetWorkflowStatus';
import useGetPriorities from '../../hooks/useGetPriorities';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';
import { globalListenerRef } from '../../GlobalListenerRef';
import ProfileHeader3 from '../../components/UI/ProfileHeader/ProfileHeader3';
import useGetIssueIntegrations from '../../hooks/useGetIssueIntegration';

const initialState = {
  filters: [],
  page: 1,
  search: '',
  sort: 'created_at_srt,desc',
  basicData: {},
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_issue_data':
      return {
        ...state,
        issueData: action.issueData,
        issueDataLoading: action.issueDataLoading,
        issueDataError: action.issueDataError
      }
    case 'set_per_page':
      return {
        ...state,
        perPage: action.perPage
      }
    case 'set_page':
      return {
        ...state,
        page: action.page
      }
    case 'set_search':
      return {
        ...state,
        search: action.search
      }
    case 'set_filters':
      return {
        ...state,
        filters: action.filters
      }
    case 'set_sort':
      return {
        ...state,
        sort: action.sort
      }
    case 'set_dqModal_open':
      return {
        ...state,
        dqModalOpen: action.dqModalOpen
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


function IssueBoard(props) {

  const {
    history,
    sessionData,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);
  const urlSearch = new URLSearchParams(window.location.search);
  const presetFilter = urlSearch.get('presetFilter')

  const { data: statusData } = useGetWorkflowStatus({
    objectType: 'ISSUE'
  });

  const { data: priorityData } = useGetPriorities({
    objectType: 'ISSUE'
  });

  const { data: issueIntegrations } = useGetIssueIntegrations({})

  const loadIssues = ({ page = 1, perPage = state.perPage, search = state.search, filters = state.filters, sort = state.sort }) => {
    dispatch({
      type: 'set_issue_data',
      issueDataLoading: true,
    })

    let sortValue;
    let sortValuArr = sort?.split(',')
    if(sortValuArr?.length === 2 && ['asc','desc'].includes(sortValuArr[1])){
      sortValue = `${sortValuArr[0]} ${sortValuArr[1]}`
    }

    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:getPartialMatchSearchString(search),
          fq: `object_type_srt:ISSUE ${filters.map(f => `AND ${f}`).join('')}`,
          rows: perPage,
          start: (page - 1) * perPage,
          sort: sortValue
        }}
      )
      .then(response=>{
        dispatch({
          type: 'set_issue_data',
          issueData: response.data.response
        })
      })
      .catch(error=>{
        dispatch({
          type: 'set_issue_data',
          issueDataError: error
        })
        console.log(error)
      })
  }

  useEffect(() => {
    addHistory({ url: window.location.pathname, iconLabel: 'issue', title: 'Issue Board', subTitle: 'Issue Board', type: 'application' })
    window.removeEventListener('message',globalListenerRef.issueBoardListener)
    globalListenerRef.issueBoardListener = (msg) => {
      if(msg.data.reload_issue){
        setTimeout(()=>{
          loadIssues({})
        },500)
      }
    }
    window.addEventListener('message',globalListenerRef.issueBoardListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.issueBoardListener)
    }
    // eslint-disable-next-line
  }, [state.perPage, state.filters, state.sort, state.search])

  useEffect(()=>{
    if(state.issueData) return;
    let perPage = Math.max(3, Math.floor((window.innerHeight - 390) / 54));
    dispatch({type:'set_per_page', perPage})
    let filters = state.filters;
    if(presetFilter==='assignedToMe'){
      dispatch({type:'set_filters', filters: [`assignee_msrt:"${sessionData?.name}"`]})
      filters = [`assignee_msrt:"${sessionData?.name}"`]
    }
    if(presetFilter==='createdByMe'){
      dispatch({type:'set_filters', filters: [`created_by_msrt:"${sessionData?.name}"`]})
      filters = [`created_by_msrt:"${sessionData?.name}"`]
    }
    window.history.replaceState(null, null, removeUrlQueryArg({ url: window.location.toString(), keys: ['presetFilter'] }));
    loadIssues({perPage, filters})
    // eslint-disable-next-line
  },[])

  let buttons = [
    <KadaBtn
      iconName='upload'
      tooltip={!issueIntegrations || issueIntegrations.length === 0 ? 'Issue management integration needs to be configured to enable' : 'Import external ticket'}
      disabled={!issueIntegrations || issueIntegrations.length === 0}
      onClick={()=>{
        if(!issueIntegrations || issueIntegrations.length === 0)return;
        dispatch({
          type: 'set_dqModal_open',
          dqModalOpen: { isExternal: true }
        })
      }}
    />,
    <KadaBtn
      text="CREATE ISSUE"
      className="!ml-4"
      onClick={()=>{
        dispatch({
          type: 'set_dqModal_open',
          dqModalOpen: true
        })
      }}
    />
  ]

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        modalMapping={['issue']}
        history={history}
      />
      <ProfileLayout
        noPadding
        disableHeaderAutoHeight
        disableMinContentHeight
        header={
          <ProfileHeader3
            title='Issue Board'
            label="issue_board"
            iconLabel="issue"
            subtitle=' '
            buttons={buttons}
            description='View and create issues for Data & Content'
          />
        }
        body={
          <Body
            state={state}
            history={history}
            dispatch={dispatch}
            loadIssues={loadIssues}
            statusData={statusData}
            priorityData={priorityData}
            sessionData={sessionData}
          />
        }
      >

      </ProfileLayout>
    </div>
  )
}

IssueBoard.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(IssueBoard);
