import React, { useEffect, useState, useRef } from 'react';
import {  CircularProgress, Typography, withStyles, withTheme, InputBase, IconButton, Select, MenuItem} from '@material-ui/core';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import { getIconComponent, isInViewport, removeUrlQueryArg, toTitleCase } from '../../../utilities';
import SourceChip from './SourceChip';
import { globalListenerRef } from '../../../GlobalListenerRef';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr'
import 'url-search-params-polyfill';
import SourceStepperCard from './SourceStepperCard';
import ListColumn from './ListColumn';

const styles = theme => ({
  root: {
    // display:'flex',
    // overflow:'hidden',
    marginTop:6
  },
  inputBase:{
    ...theme.components.inputBase,
    width:260,
    height:42
  },
  selector:{
    ...theme.components.selector,
    minWidth:120,
    marginLeft:24
  }
})

const Discover = props => {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
  } = props;

  const perPage = 10;
  const scrollRef = useRef()

  const searchValue = state.sourceSearchValue;
  const setSearchValue = value => dispatch({type:"set_source_search_value",sourceSearchValue:value})
  const searchTimeoutRef = useRef()

  const tabOptions = ['DATABASE','CONTENT','REFERENCE']

  const urlSearch = new URLSearchParams(window.location.search);
  const sourceTabName = urlSearch.get('sourceTabName')

  useEffect(()=>{
    if(!sourceTabName)return;
    let index = tabOptions.indexOf(sourceTabName);
    if(index===-1)return;
    dispatch({type:'set_source_tab_state',sourceTabState:index})
    window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['sourceTabName']}));
  // eslint-disable-next-line
  },[sourceTabName])

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(()=>{
    window.removeEventListener('resize',globalListenerRef.sourceGridListener)
    globalListenerRef.sourceGridListener = () => {
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener('resize',globalListenerRef.sourceGridListener)

    return ()=>{
      window.removeEventListener('resize',globalListenerRef.sourceGridListener)
    }
  },[])

  const enrichSourceData = ({sourceData, type, page, isRef}) => {
    axiosCerebrum
      .get(
        '/api/stats',
        {params:{
          object_ids:sourceData.items.map(el=>isRef?el.id:el.host_ids[0]).join(','),
          per_page:sourceData.items.length,
        }}
      )
      .then(response=>{
        let list = sourceData.items.map(el=>({
          ...el,
          node_stats:(response.data.items.find(i=>i.node_id===(isRef?el.id:el.host_ids[0]))||{})
        }))

        if(isRef){
          let newObj = state.sourceList || {};
          newObj[type] = {
            ...sourceData,
            items:page===1?list:state.sourceList?.[type].items.concat(list)
          }
          dispatch({
            type:'set_source_list',
            sourceList:newObj
          })
          return;
        }

        let promises = [];
        let objectFacet;
        let descriptionArr;

        let objetcCountFq = `source_id_srt:(${sourceData.items.map(i=>i.id).join(' OR ')}) AND reference_srt:NO AND active_srt:YES`;
        promises.push(
          axiosSolr
            .get(
              '/solr/search/select',{
                params:{
                  q:"*",
                  fq:objetcCountFq,
                  rows:0,
                  'json.facet':{
                    "source_id": {
                      "type": "terms",
                      "field": type==='REFERENCE'?'parent_id_srt':"source_id_srt",
                      "mincount":1,
                      "limit":sourceData.items.length,
                      'facet':{
                        "object_type":{
                          "type":"terms",
                          "field": "object_type_srt",
                          "mincount":1,
                        },
                      }
                    }
                  }
                }
              }
            )
            .then(facet=>{
              objectFacet = facet.data.facets?.source_id?.buckets;
            })
        )

        let descriptionFq = `source_id_srt:(${sourceData.items.map(i=>i.id).join(' OR ')}) AND object_type_srt:SOURCE`;
        promises.push(
          axiosSolr
            .get(
              '/solr/search/select',{
                params:{
                  q:"*",
                  fq:descriptionFq,
                  rows:sourceData.items.length,
                  fl:"id,source_id_srt,description",
                }
              }
            )
            .then(response=>{
              descriptionArr = response.data.response.docs
            })
        )

        Promise
          .all(promises)
          .then(()=>{
            list = list.map(l=>({
              ...l,
              description:descriptionArr?.find(b=>b.source_id_srt===l.id)?.description || l.description,
              object_count: objectFacet?.find(b=>b.val===l.id)?.object_type?.buckets || []
            }))
            let newObj = state.sourceList || {};
            newObj[type] = {
              ...sourceData,
              items:page===1?list:state.sourceList?.[type].items.concat(list)
            }
            dispatch({
              type:'set_source_list',
              sourceList:newObj
            })
          })
          .catch(error=>{
            console.log(error)
            dispatch({
              type:'set_source_list',
              sourceListError:true
            })
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_source_list',
          sourceListError:true
        })
      })
  }

  const loadSourceDetails = async ({ type, page, sort}) => {

    dispatch({
      type:'set_source_list',
      sourceListLoading:true,
      sourceList:{
        ...(state.sourceList||{}),
        [type]:page===1?undefined:state.sourceList?.[type]
      }
    })

    let sourceData, sourceListError;
    await axiosCerebrum
      .get(
        `/api/sources`,{
          params:{
            per_page:perPage,
            page,
            types:type==='DATABASE'?'DATABASE':'TOOL',
            active_flag:true,
            internal_flag:false,
            sort
          }
        }
      )
      .then(response=>{
        sourceData = response.data;
      })
      .catch(error=>{
        console.log(error)
        sourceListError = true;
      })

    if(sourceListError){
      dispatch({
        type:'set_source_list',
        sourceListError
      })
      return;
    }

    if(sourceData?.total===0){
      dispatch({
        type:'set_source_list',
        sourceListLoading:false,
        sourceList:{
          ...(state.sourceList||{}),
          [type]:sourceData
        }
      })
      return;
    }

    enrichSourceData({sourceData, type, page})

  }

  const loadList = async ({
    type,
    isReload,
    page=1,
    search=searchValue[tabOptions[state.sourceTabState]]||'',
    sort=state.sourcesSort[tabOptions[state.sourceTabState]]||'ALPHABETICAL'
  }) => {
    if(type!=='REFERENCE'){
      loadSourceDetails({type, page, isReload, sort})
    }else{
      dispatch({
        type:'set_reference_sources',
        referenceSourcesLoading:true,
        referenceSources:page===1?undefined:state.referenceSources
      })
      dispatch({
        type:'set_source_list',
        sourceListLoading:true,
        sourceList:{
          ...(state.sourceList||{}),
          [type]:page===1?undefined:state.sourceList?.[type]
        }
      })
      axiosCerebrum
        .get(
          `/api/hosts`,{
            params:{
              per_page:perPage,
              page:page,
              'search.alternate_name':search,
              reference:true,
              sort:'ALPHABETICAL'
              // sort
            }
          }
        )
        .then(response=>{
          let data = page===1?response.data.items:state.referenceSources.items.concat(response.data.items)
          dispatch({
            type:'set_reference_sources',
            referenceSources:{...response.data,items:data}
          })
          if(response.data.total===0){
            dispatch({
              type:'set_source_list',
              sourceList:{
                ...(state.sourceList||{}),
                [type]:response.data
              }
            })
            return;
          }
          enrichSourceData({sourceData:response.data, type, page, isRef:true})
        })
        .catch(error=>{
          console.log(error)
          dispatch({
            type:'set_reference_sources',
            referenceSourcesError:true
          })
          dispatch({
            type:'set_source_list',
            sourceListError:true
          })
        })
    }
  }

  useEffect(()=>{
    if(tabOptions.length===0)return;
    let label = tabOptions[state.sourceTabState];
    if(!state.sourceList || !state.sourceList[label]){
      loadList({page:1, type:label})
    }
  // eslint-disable-next-line
  },[state.sourceTabState])

  const onSearchChange = value => {
    clearTimeout(searchTimeoutRef.current)
    setSearchValue({...searchValue,[tabOptions[state.sourceTabState]]:value})
    searchTimeoutRef.current = setTimeout(()=>{
      let label = tabOptions[state.sourceTabState];
      loadList({page:1, type:label, isReload:true, search:value})
    },300)
  }

  const shouldLoadMore = () => {
    let label = tabOptions[state.sourceTabState];
    if(label==='REFERENCE'){
      return isInViewport(scrollRef) && state.referenceSources?.page<state.referenceSources?.pages && !state.referenceSourcesError && !state.sourceListLoading && !state.referenceSourcesLoading
    }
    return isInViewport(scrollRef) && state.sourceList && state.sourceList[label] && state.sourceList[label].page<state.sourceList[label].pages && !state.sourceListError && !state.sourceListLoading
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      let label = tabOptions[state.sourceTabState];
      let page = label==='REFERENCE'?state.referenceSources?.page+1:state.sourceList?.[label]?.page+1;
      loadList({type:label, page})
    }
  // eslint-disable-next-line
  },[state.sourceList, state.sourceListLoading])

  window.onscroll = () => {
    if(shouldLoadMore()){
      let label = tabOptions[state.sourceTabState];
      let page = label==='REFERENCE'?state.referenceSources?.page+1:state.sourceList?.[label]?.page+1;
      loadList({type:label, page})
    }
  }

  let sourceData = state.sourceList?.[tabOptions[state.sourceTabState]]
  let sourceList =  sourceData?.items || []

  return (
    <div className={classes.root}>
      <div style={{float:'left'}}>
        <VerticalTabBar
          tabOptions={tabOptions.map(el=>{
            if(el==='DATABASE')return 'DATA SOURCES'
            if(el==='CONTENT')return 'CONTENT SOURCES'
            if(el==='REFERENCE')return 'REFERENCE SOURCES'
            return ''
          })}
          tabState={state.sourceTabState}
          setTabState={value=>dispatch({type:'set_source_tab_state',sourceTabState:value})}
        />
      </div>
      <div style={{marginLeft:280}}>
        <div style={{position:'sticky',zIndex:999,top:180,background:theme.palette.background.main,display:'flex',alignItems:'flex-start'}}>
          <div style={{flexGrow:1,paddingBottom:24,marginRight:16}}>
            <Typography style={{fontSize:20,color:theme.palette.header.main,marginBottom:2}}>
              {tabOptions[state.sourceTabState]==='REFERENCE'?(state.referenceSources?.total||''):(sourceData?.total||'')} {tabOptions[state.sourceTabState]} SOURCE(S)
            </Typography>
            <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>{toTitleCase(tabOptions[state.sourceTabState])} sources catalogued in K</Typography>
          </div>
          {
            tabOptions[state.sourceTabState]==='REFERENCE' &&
            <InputBase
              key={tabOptions[state.sourceTabState]}
              value={searchValue[tabOptions[state.sourceTabState]]}
              onChange={e=>onSearchChange(e.target.value)}
              placeholder={`Search ${toTitleCase(tabOptions[state.sourceTabState])} sources`}
              className={classes.inputBase}
              inputProps={{
                'data-tets-id':'search-source-input'
              }}
              endAdornment={
                <IconButton
                  disabled={!searchValue[tabOptions[state.sourceTabState]]}
                  onClick={()=>onSearchChange('')}
                  style={{width:32,height:32,marginRight:6}}
                >
                  {getIconComponent({label:searchValue[tabOptions[state.sourceTabState]]?'clear':'search',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              }
            />
          }
          {
            tabOptions[state.sourceTabState]!=='REFERENCE' &&
            <Select
              value={state.sourcesSort[tabOptions[state.sourceTabState]]||'ALPHABETICAL'}
              onChange={e=>{
                dispatch({type:'set_sources_sort',sourcesSort:{[tabOptions[state.sourceTabState]]:e.target.value}})
                loadList({page:1, type:tabOptions[state.sourceTabState], isReload:true, sort:e.target.value})
              }}
              className={classes.selector}
              disableUnderline
            >
              {
                [
                  {name:'A-Z',value:'ALPHABETICAL'},
                  {name:'Z-A',value:'REVERSE_ALPHABETICAL'},
                ].map(el=>(
                  <MenuItem key={el.value} value={el.value}>{el.name}</MenuItem>
                ))
              }
            </Select>
          }
          <Select
            value={state.sourceView}
            onChange={e=>dispatch({type:'set_source_view',sourceView:e.target.value})}
            className={classes.selector}
            disableUnderline
          >
            {
              ['cards','list'].map(el=>(
                <MenuItem key={el} value={el}>{toTitleCase(el)}</MenuItem>
              ))
            }
          </Select>
        </div>
        {
          state.sourceListError &&
          <Typography>Error occurred loading sources</Typography>
        }
        {
          state.sourceView==='cards' &&
          <div style={{display:'flex',flexWrap:'wrap',marginLeft:-16}}>
            {
              sourceList?.map(el=>(
                <SourceStepperCard
                  key={el.id}
                  history={history}
                  source={el}
                  windowHeight={windowHeight}
                />
              ))
            }
          </div>
        }
        {
          state.sourceView==='list' &&
          <div>
            <ListColumn
              isRef={tabOptions[state.sourceTabState]==='REFERENCE'}
            />
            {
              sourceList?.map(el=>(
                <SourceChip
                  key={el.id}
                  history={history}
                  sourceWithStats={el}
                  windowHeight={windowHeight}
                />
              ))
            }
          </div>
        }
        {
          sourceData && sourceList.length===0 && !state.sourceListLoading && !state.sourceListError &&
          <Typography style={{fontSize:13.75}}>No {toTitleCase(tabOptions[state.sourceTabState])} sources found.</Typography>
        }
        <div ref={scrollRef} style={{marginTop:8,display:'flex',justifyContent:'center',maxWidth:1500}}>
          {
            state.sourceListLoading &&
            <CircularProgress color='secondary'/>
          }
        </div>
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(Discover));
