import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Button } from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import { getDispFields, getIconComponent, onClickResultItem } from '../../../utilities';
import SnackBar from '../../UI/SnackBar/SnackBar'
import axiosCerebrum from '../../../axios-cerebrum'
import { useStore } from 'react-redux';
import { checkTermLinkable } from '../../../permissionChecker';

const styles = theme => ({
  title: {
    color: theme.palette.header.main,
    fontSize: 20,
    marginBottom:24
  },
  matchedChip:{
    border:`1px solid ${theme.palette.border.main}`,
    background:theme.palette.chip.main,
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    marginRight:16,
    marginLeft:8,
    height:24,
    padding:'0px 12px',
    borderRadius:16,
    display:'flex',
    alignItems:'center'
  }
})

function Suggested(props) {

  const {
    classes,
    history,
    theme,
    state,
    dispatch,
    linkedFetch
  } = props;


  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarType, setSnackBarType] = useState()
  const [snackBarButton, setSnackBarButton] = useState()

  let sessionData = useStore().getState().auth.session_user;
  let editable = checkTermLinkable({sessionData})


  const loadSuggestions = () => {
    dispatch({type:'set_suggested_link',suggestedLinkLoading:true});
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            "q": `"${state.basicData.name}"`,
            "qf": "description name_srt name_txt alternate_name_srt alternate_name_txt",
            "pf": "name_srt name_txt description alternate_name_srt alternate_name_txt",
            "fq": `object_type_srt:(CONTENT_APP OR REPORT OR SHEET OR DATASET OR DATASET_TABLE OR DATASET_FIELD OR ML_MODAL OR FILE OR TABLE OR COLUMN OR SCHEMA) AND active_srt:YES AND reference_srt:NO AND -${state.basicData.parent.short_name.toLowerCase().replace(/\s/g,'_')}_kc_msrt:"${state.basicData.name}"`,
            "fl": "*",
            "group": true,
            "group.field": "object_type_srt",
            "group.limit": 10,
            "hl": "on",
            "hl.fl": "description name_txt alternate_name_txt",
            "hl.fragsize": 0,
            "hl.snippets": 3,
            "hl.highlightMultiTerm": "false",
          }
        }
      )
      .then(response=>{
        dispatch({type:'set_suggested_link',suggestedLink:response.data.grouped.object_type_srt.groups, suggestedHighlight:response.data.highlighting})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_suggested_link',suggestedLinkError:true});
      })
  }

  useEffect(()=>{
    if(state.suggestedLink || state.suggestedLinkLoading)return;
    loadSuggestions()
  // eslint-disable-next-line
  },[])

  const onSuccess = (group, item) => {
    setSnackBarMessage(`Link to ${item.name_txt} added`)
    setSnackBarButton(<Button style={{color: 'var(--color-base-100)'}} onClick={()=>onClickResultItem({label:item.object_type_txt,item, id:item.id, history})}>OPEN</Button>)
    setSnackBarOpen(true)
    setSnackBarType()
    dispatch({
      type:'set_suggested_link',
      suggestedHighlight:state.suggestedHighlight,
      suggestedLink:state.suggestedLink.map(el=>{
        if(el.groupValue!==group)return el;
        return {
          ...el,
          doclist:{
            ...el.doclist,
            docs:el.doclist.docs.filter(d=>d.id!==item.id)
          }
        }
      })
    })
  }

  const onLink = (group, item) => {
    axiosCerebrum
      .put(
        `/api/collectioninstances/${state.basicData.id}/related?relationship=RELATES_TO&object_id=${item.id}`
      )
      .then(response=>{
        onSuccess(group, item)
        linkedFetch()
      })
      .catch(error=>{
        if(error.response && error.response.status && error.response.status===409){
          onSuccess(group, item)
          return;
        }
        console.log(error)
        setSnackBarMessage(`Link to ${item.name_txt} failed. Please try again`)
        setSnackBarButton()
        setSnackBarType('error')
      })
  }

  const onClickHeader = (event, objectType, isCollapsed) => {
    dispatch({type:'set_suggested_link',suggestedLink:state.suggestedLink.map(s=>{
      if(s.groupValue===objectType){
        return {...s, isCollapsed:!isCollapsed}
      }else{
        return s
      }
    })})
  }

  const getHeight = docs => {
    let height = 32;
    docs.forEach(d=>{
      if(getDispFields(d,'dispSubtitle')){
        height+=66
      }else{
        height+=48
      }
    });
    return height;
  }

  const generateHighlight = d => {
    if(!state.suggestedHighlight)return <div></div>
    if(!state.suggestedHighlight[d.id])return <div></div>
    let fields = Object.keys(state.suggestedHighlight[d.id]);
    if(fields.length===0)return <div></div>
    let order = ['name','business name','description']
    let finalFields = fields.map(el=>{
      switch(el){
        case 'name_srt':
        case 'name_txt':
          return 'name';
        case 'alternate_name_srt':
        case 'alternate_name_txt':
          return 'business name';
        default:
          return el
      }
    }).sort((a,b)=>
      order.indexOf(a)-order.indexOf(b)
    )
    return <div className={classes.matchedChip}>Matched on {finalFields.join(finalFields.length<3?' & ':', ')}</div>
  }

  return (
    <div>
      <Typography className={classes.title}>SUGGESTED LINKS</Typography>
      {
        state.suggestedLinkLoading && <CircularProgress color='secondary'/>
      }
      {
        state.suggestedLinkError &&
        <Typography style={{fontSize:13.75}}>Error occurred loading suggestions</Typography>
      }
      {
        state.suggestedLink && (state.suggestedLink.length===0 || state.suggestedLink.every(el=>el.doclist.docs.length===0)) &&
        <Typography style={{fontSize:13.75}}>No suggestions found</Typography>
      }
      {
        state.suggestedLink && state.suggestedLink.filter(el=>el.doclist.docs.length>0).map(el=>(
          <div id={`suggested_link_${el.groupValue}`} style={{marginBottom:16,transition:'height .1s linear',height:el.isCollapsed?24:getHeight(el.doclist.docs),overflow:'hidden'}}>
            <div onClick={event=>onClickHeader(event,el.groupValue, el.isCollapsed)} style={{display:'flex',height:24,paddingBottom:8,alignItems:'center',cursor:'pointer'}}>
              <div style={{width:24,height:24,marginRight:8,transition: "transform .3s ease",transform:`rotate(${el.isCollapsed?-90:0}deg)`}}>
                {getIconComponent({label:'triangle_down',size:24,colour:theme.palette.primaryText.light})}
              </div>
              <Typography style={{fontSize:13.75, color:theme.palette.header.main}}>{el.groupValue}(S)</Typography>
            </div>
            {
              el.doclist.docs.map(d=>(
                <SimpleResultItem
                  title={getDispFields(d,'dispTitle')}
                  label={d.object_type_txt}
                  item={d}
                  subTitle={getDispFields(d,'dispSubtitle')}
                  hideRight={true}
                  showUnderline={true}
                  onClick={()=>onClickResultItem({label:d.object_type_txt,id:d.id,item:d,history})}
                  tailObject={
                    <div style={{display:'flex',alignItems:'center',flexShrink:0}}>
                      {generateHighlight(d)}
                      {editable && <Button color='primary' variant='outlined' style={{height:24,minWidth:20,width:52}} onClick={(event)=>{event.stopPropagation();onLink(el.groupValue,d)}}>LINK</Button>}
                    </div>

                  }
                />
              ))
            }
          </div>
        ))
      }

      <SnackBar
        snackBarOpen={snackBarOpen}
        setSnackBarOpen={setSnackBarOpen}
        message={snackBarMessage}
        type={snackBarType}
        onClose={()=>{setSnackBarOpen(false);setSnackBarButton();}}
        buttons={snackBarButton}
      />
    </div>
  )
}

export default withTheme()(withStyles(styles)(Suggested));
