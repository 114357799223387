import React, { useEffect, useRef, useState }  from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import UserChip from '../Chips/UserChip';
import { getIconComponent, getLabelPlural, mapObjectName, onClickResultItem, toTitleCase } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import InteractiveInputBody from '../InteractiveInput/InteractiveInputBody';
import InteractiveViewer from '../InteractiveInput/InteractiveViewer';
import theme from '../../../theme';
import { diffWords } from 'diff';
import sqlFormatter from "sql-formatter";
import Editor from '../Editor/Editor';
import { getIconLabel } from '../SearchResults/utils';
import KTooltip from '../KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';
import { KadaBadge, KadaBtn, kButtonVariants } from 'kada-component-library';

const ChangeCard = props => {

  const {
    history,
    maxHeight,
    changeItem,
    onUpdateNote,
    onClose,
    object
  } = props;

  // const [noteEditing, setNoteEditing] = useState(false)
  const [note, setNote] = useState(changeItem.notes);
  const [expanded, setExpanded] = useState(-1);
  const [isShowAllDetail, setIsShowAllDetail] = useState(false);
  const [changeList, setChangeList] = useState([]);

  const isCancelledRef = useRef(false);

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  });

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true;
    }
  },[]);

  const onSaveNote = () => {
    axiosCerebrum
      .put(
        `/api/generalisedmetrics/${changeItem.id}`,
        {
          notes: note,
        }
      )
      .then(response=>{
        onUpdateNote(response.data);
        // setNoteEditing(false)
      })
      .catch(error=>{
        console.log(error);
        setNote(changeItem.notes);
        sendAlert({ message: "Error occurred saving note, please try again", type: 'error' });
      });
  };

  const getChangeIcon = (action, type) => {
    let actionIcon;
    switch(action){
      case 'ADDED':
      case 'CREATED':
        actionIcon =  (
          <svg width={12} height={12} viewBox="-6 -6 30 30" version="1.1">
            <title>icon_add</title>
            <g id="icon_add" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon id="Path" fill={theme.palette.primary.main} points="10,0 14,0 14,10 24,10 24,14 14,14 14,24 10,24 10,14 0,14 0,10 10,10"></polygon>
            </g>
          </svg>
        );
        break;
      case 'DELETED':
        actionIcon =  (
          <svg width={12} height={12} viewBox="-6 -6 30 30" style={{transform:'rotate(45deg)'}} version="1.1">
            <title>icon_add</title>
            <g id="icon_add" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon id="Path" fill={theme.palette.error.main} points="10,0 14,0 14,10 24,10 24,14 14,14 14,24 10,24 10,14 0,14 0,10 10,10"></polygon>
            </g>
          </svg>
        );
        break;
      case 'MODIFIED':
        actionIcon = (
          <svg width={12} height={12} viewBox="0 0 24 24" version="1.1" >
            <title>icon_change</title>
            <g id="icon_change" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon id="Path" fill={theme.palette.secondary.main} fillRule="nonzero" points="12,2 22,20 2,20"></polygon>
            </g>
          </svg>
        );
        break;
      default:
    }
    return (
      <div className="w-6 h-6 flex items-end">
        <div className="w-6 h-6">
          {
            getIconComponent({label:type,size:24,colour:theme.palette.primary.main})
          }
        </div>
        <div className="relative left-[-12px] bottom-[-2px] w-3.5 h-3.5 flex items-center justify-center rounded-full" style={{background:theme.palette.background.main}}>
          {actionIcon}
        </div>
      </div>
    );
  };

  const generateDiffText = (content, background) => {
    return (
      <span className={`px-1 ${background ? 'py-1' : 'py-0'} mr-1`} style={{background, whiteSpace:'pre-wrap', wordBreak:'break-word', fontSize:12, color:theme.palette.primaryText.light}}>
        {content}
      </span>
    );
  };

  const generateDiffCodeLine = (arr) => {

    const content = [];
    let currentStack = [];
    let currentLine = 1;
    arr.forEach((a,index)=>{
      currentStack.push(generateDiffText(a.value, a.background));
      if(a.value.includes('\n') || index===arr.length-1){
        content.push(
          <div className="flex items-start mb-0.5">
            <div className="min-w-[8px] mr-1.5 text-xs" style={{color:theme.palette.primaryText.main,marginTop:4}}>
              {currentLine}
            </div>
            <div>
              {currentStack}
            </div>
          </div>
        );
        currentLine += 1;
        currentStack = [];
      }
    });

    return content;
  };

  const getDiffText = (old, current, variant) => {
    let diffArray;
    diffArray = diffWords(old, current);
    let oldArr = [];
    let currentArr = [];
    diffArray.forEach((a,index)=>{
      if(!a.added && !a.removed){
        let hasEndNewLine = false;
        let allLines = a.value.split('\n').filter(l=>{
          if(l)return true;
          hasEndNewLine = true;
          return false;
        });
        allLines.forEach((l,index)=>{
          let value = l + (index<allLines.length-1 || hasEndNewLine ? '\n':'');
          oldArr.push({value, background:undefined});
          currentArr.push({value, background:undefined});
        });
      }else if(a.added){
        currentArr.push({value:a.value, background:'#00D46A25'});
      }else if(a.removed){
        oldArr.push({value:a.value, background:'#ff385c25'});
      }
    });

    if(variant==='code'){
      oldArr = generateDiffCodeLine(oldArr);
      currentArr = generateDiffCodeLine(currentArr);
    }
    if(variant==='plain'){
      oldArr = oldArr.map((a, index)=>(generateDiffText(a.value, a.background)));
      currentArr = currentArr.map((a, index)=>(generateDiffText(a.value, a.background)));
    }

    return {oldArr, currentArr};
  };

  const generateCIPropertyChangeText = (properties) => {
    let arr = [];
    Object.keys(properties).forEach(k=>{
      arr.push(
        <span className="font-bold text-xs" style={{color:theme.palette.primaryText.light, whiteSpace:'pre-wrap', wordBreak:'break-word'}}>{k}: </span>
      );
      arr.push(
        <span className="text-xs" style={{color:theme.palette.primaryText.light, whiteSpace:'pre-wrap', wordBreak:'break-word'}}>{properties[k]}{'\n\n'}</span>
      );
    });
    return arr;
  };

  const mapFieldName = (field, shouldFormat) => {
    let name;
    switch(field){
      case 'alternate_name':
        name = 'business name';
        break;
      default:
        name = field;
    }
    if(shouldFormat)return toTitleCase(name, false);
    return name;
  };

  const generateChangeDetailItem = async (change, index) => {
    let hasDetail = change.previous || change.current;
    let isExpanded = index===expanded;
    let diffText;

    let isTitleClickable = object.id!==change.object_id;
    let isDetectedChange = changeItem.metric_type.name==='DETECTED_CHANGE';
    let isMetaChange = changeItem.metric_type.name==='K_METADATA_CHANGE';
    let isLinkageChange = changeItem.metric_type.name==='K_LINKAGE_CHANGE';

    if(isExpanded){
      if(isMetaChange && change.object_type==='COLLECTION_INSTANCE' && change.modified_field==='properties'){
        diffText = {
          oldArr:generateCIPropertyChangeText(change.previous),
          currentArr:generateCIPropertyChangeText(change.current),
        };
      }else if(isMetaChange){
        change.previous = change.previous===undefined?'':change.previous + '';
        change.current = change.current===undefined?'':change.current + '';
        if(change.modified_field==='description'){
          diffText = {
            oldArr: (
              <InteractiveViewer
                initialValue={change.previous}
                id={'change-detail-prev-'+change.modified_field}
                alwaysShown={true}
                maxHeight={400}
              />
            ),
            currentArr: (
              <InteractiveViewer
                initialValue={change.current}
                id={'change-detail-current-'+change.modified_field}
                alwaysShown={true}
                maxHeight={400}
              />
            )
          };
        }else{
          diffText = {
            oldArr:generateDiffText(change.previous),
            currentArr:generateDiffText(change.current),
          };
        }
      }else if(isDetectedChange){
        change.previous = change.previous===undefined?'':change.previous + '';
        change.current = change.current===undefined?'':change.current + '';
        if(change.modified_field==='code'){
          diffText = getDiffText(sqlFormatter.format(change.previous,{language:'pl/sql'}), sqlFormatter.format(change.current,{language:'pl/sql'}),'code');
        }else{
          diffText = getDiffText(change.previous, change.current, 'plain');
        }
      }
    }

    let linkageComponent;
    if(isLinkageChange){
      linkageComponent = await getLinkageDetailComponent(change);
    }

    return (
      <div className={`rounded-md ${isExpanded ? 'border' : ''}`} style={{borderColor:theme.palette.listItemDivider.main}}>
        <div
          className={`h-12 flex px-4 items-center overflow-hidden ${hasDetail ? 'cursor-pointer hover:bg-(--color-base-200)' : ''}`}
          data-test-id={`change-detail-${index}`}
          onClick={()=>{
            if(!hasDetail)return;
            if(isExpanded)setExpanded(-1);
            else{setExpanded(index);}
          }}
        >
          {
            isDetectedChange &&
            <>
              <div className="w-6 h-6 mr-4 flex-grow-0 flex-shrink-0">
                {getChangeIcon(change.action, change.object_type)}
              </div>
              <KTooltip title={change.object_name}>
                <p className={`text-xs flex-grow-0 flex-shrink-1 overflow-hidden text-ellipsis whitespace-nowrap ${isTitleClickable ? 'cursor-pointer hover:underline' : ''}`} style={{marginRight:6,fontWeight:700}} onClick={isTitleClickable ? () => onClickResultItem({label:change.object_type,id:change.object_id,history,query:['tabName=CHANGES']}) : undefined}>
                  {change.object_name}
                </p>
              </KTooltip>
              <p className="text-xs flex-grow-0 flex-shrink-0" style={{marginRight:6}}>
                {mapObjectName(change.object_type).replace(/_/g,' ').toLowerCase()}
              </p>
              <p className="text-xs flex-grow-1 flex-shrink-0">
                {mapFieldName(change.modified_field, !isDetectedChange)} {change.action.toLowerCase()}
              </p>
            </>
          }
          {
            isMetaChange &&
            <>
              <p className="text-xs flex-grow-1">
                <span className="font-bold">{mapFieldName(change.modified_field, true)}</span> {change.action.toLowerCase()}
              </p>
            </>
          }
          {
            isLinkageChange &&
            <>
              <div className="w-6 h-6 mr-4 flex-grow-0 flex-shrink-0">
                {getIconComponent({label:getIconLabel({label:change.object_type,item:{...change,collection_name:change.parent_object_name}}),size:24,colour:theme.palette.primaryText.light}) }
              </div>
              {
                linkageComponent
              }
            </>
          }
          {
            hasDetail &&
            <div className="w-6 h-6 flex-grow-0 flex-shrink-0 transition-all" style={{transform:isExpanded?'rotate(180deg)':undefined}}>
              {
                getIconComponent({label:'triangle_down',size:24,colour:theme.palette.primaryText.main})
              }
            </div>
          }
        </div>
        {
          hasDetail &&
          <div className="flex overflow-hidden pl-4 transition-all" style={{maxHeight:isExpanded?undefined:0, paddingTop:isExpanded?12:0, paddingBottom:isExpanded?24:0}}>
            <div className="w-1/2 pr-2 box-border">
              <p className="text-xs tracking-wider mb-2" style={{color:theme.palette.primaryText.main}}>PREVIOUS</p>
              <div data-test-id='prev-value-block'>
                {diffText?.oldArr}
              </div>
            </div>
            <div className="w-1/2 pr-2 box-border">
              <p className="text-xs tracking-wider mb-2" style={{color:theme.palette.primaryText.main}}>LATEST</p>
              <div data-test-id='latest-value-block'>
                {diffText?.currentArr}
              </div>
            </div>
          </div>
        }
      </div>
    );
  };

  const isLinkedBy = change => {
    return !['CLASSIFIED_BY','NOT_VERIFIED_BY','VERIFIED_BY','MEMBER_OF','RELATED','RELATED_BY'].includes(change.relationship);
  };

  const getLinkageDetailComponent = async change => {
    let isObjectDeleted = false;
    await axiosCerebrum
      .get(`/api/${getLabelPlural(change.object_type)}/${change.object_id}`)
      .catch(error=>{
        isObjectDeleted = true;
      });
    if(!isLinkedBy(change)){
      return (
        <>
          <p className={`text-xs ${isObjectDeleted ? '' : 'cursor-pointer hover:underline'}`} style={{marginRight:6,fontWeight:700}} onClick={isObjectDeleted ? undefined : () => onClickResultItem({label:change.object_type,id:change.object_id,history})}>
            {change.object_name}
          </p>
          <p className="text-xs">
            {mapObjectName(change.object_type,true).replace(/_/g,' ').toLowerCase() + (change.action==='DELETED'?' unlinked':' linked')}
          </p>
        </>
      );
    }

    if(change.object_type==='COLLECTION_INSTANCE'){

      let changeText = change.action==='DELETED'?' removed from':' added to';
      if(change.relationship==='NOT_VERIFIED_FOR' && change.action==='ADDED')changeText = ' not verified for';
      if(change.relationship==='VERIFIES' && change.action==='ADDED')changeText = ' verified for';
      if(change.relationship==='CLASSIFIES' && change.action==='ADDED')changeText = ' classified as';
      return (
        <KTooltip title={toTitleCase(mapObjectName(object.object.name,true).replace(/_/g,' ').toLowerCase(),false) + (changeText)+' '+change.object_name + ` (${change.parent_object_name} collection)`}>
          <p className="text-xs">
            {toTitleCase(mapObjectName(object.object.name,true).replace(/_/g,' ').toLowerCase(),false) + (changeText)} <span className={isObjectDeleted ? '' : 'cursor-pointer hover:underline'} style={{fontWeight:700}} onClick={isObjectDeleted ? undefined : () => onClickResultItem({label:change.object_type,id:change.object_id,history})}>{change.object_name}</span> ({change.parent_object_name} collection)
          </p>
        </KTooltip>
      );
    }else{
      let fieldName;
      if(change.relationship==='OWNER_OF')fieldName = 'Owner';
      else if(change.relationship==='STEWARD_OF')fieldName =  'Steward';
      else if(change.relationship==='TAG_OF')fieldName =  'Tag';
      return (
        <KTooltip title={change.object_name+' '+(change.action==='DELETED'?'removed from ':'added as ') + fieldName + ' of this ' + mapObjectName(object.object.name,true).replace(/_/g,' ').toLowerCase() }>
          <p className="text-xs">
            <span className={isObjectDeleted ? '' : 'cursor-pointer hover:underline'} style={{marginRight:6,fontWeight:700}} onClick={isObjectDeleted ? undefined : () => onClickResultItem({label:change.object_type,id:change.object_id,history})}>{change.object_name}</span> {(change.action==='DELETED'?'removed from ':'added as ') + fieldName + ' of this ' + mapObjectName(object.object.name,true).replace(/_/g,' ').toLowerCase()}
          </p>
        </KTooltip>
      );
    }
  };

  useEffect(()=>{
    const loadChangeList = async () => {
      let list = [];
      for(let i=0;i<changeItem.change_context.length;i++){
        let item = await generateChangeDetailItem(changeItem.change_context[i],i);
        if(item)list.push(item);
      }
      setChangeList(list);
    };
    loadChangeList();
  // eslint-disable-next-line
  },[changeItem, expanded]);

  return (
    <div className="p-4 rounded-md border" style={{borderColor:theme.palette.listItemDivider.main}}>
      <div className="flex items-center justify-between mb-6 pr-6 pl-6">
        <h3 className='!font-bold'>
          {toTitleCase(changeItem.metric_type.name.replace(/_/g,' '))}
        </h3>
        <KadaBtn
          text='CLOSE'
          variant={kButtonVariants.primaryOutlined}
          // size="s"
          onClick={onClose}
          data-test-id={`change-close-button`}
        />
      </div>
      <div className="overflow-auto pr-6 pl-6" style={{maxHeight:maxHeight - 86}}>
        {
          changeItem.user && changeItem.metric_type.name!=='DETECTED_CHANGE' &&
          <div className="flex items-center mb-10" data-test-classname="change-by-section">
            <p className="mr-7 text-primary" style={{width:95}}>Change by</p>
            <UserChip user={changeItem.user} testID={'change-item-user'} onClick={()=>onClickResultItem({label:'user',id:changeItem.user.id,history,item:changeItem.user})}/>
          </div>
        }
        <div className="flex items-center mb-10" data-test-classname="change-on-section">
          <p className="mr-7 text-primary" style={{width:95}}>Detected on</p>
          <KadaBadge
            text={moment(changeItem.timestamp).format('LL HH:mm:ss')}
          />
          {/* <div className="flex items-center px-2 py-0.5 bg-gray-200 rounded-full border" style={{borderColor:theme.palette.border.main}}>
            <p >
              {moment(changeItem.timestamp).format('LL HH:mm:ss')}
            </p>
          </div> */}
        </div>
        {
          changeItem.change_context && changeItem.change_context.length>0 &&
          <div className="mb-10" data-test-id="change-detail-section">
            <p className="mb-2 text-primary">Change Details</p>
            {
              changeList.slice(0, isShowAllDetail?changeList.length:5)
            }
            {
              changeItem.change_context.length>5 &&
              <KadaBtn
                text={isShowAllDetail?'SEE LESS':`SEE ${changeItem.change_context.length-5} MORE CHANGES`}
                variant={kButtonVariants.primaryBorderless}
                className="ml-4 mt-1"
                onClick={()=>setIsShowAllDetail(!isShowAllDetail)}
              />
            }
          </div>
        }
        <div className="mb-3">
          <Editor
            title={
              <div className="flex items-center">
                <p className="mr-7 text-primary" style={{width:95}}>
                  {
                    changeItem.notes_last_updated_by_user?
                    'Note added by'
                    :
                    'Note'
                  }
                </p>
                {
                  changeItem.notes_last_updated_by_user &&
                  <UserChip user={changeItem.notes_last_updated_by_user} onClick={()=>onClickResultItem({label:'user',id:changeItem.notes_last_updated_by_user.id,history,item:changeItem.notes_last_updated_by_user})}/>
                }
              </div>
            }
            body={
              <InteractiveViewer
                key={changeItem.id}
                initialValue={note || 'Add details about this change'}
                id={`change-note`}
                // maxHeight={300}
                alwaysShown={true}
              />
            }
            onSave={()=>{
              onSaveNote();
            }}
            onClose={()=>{
              setNote(changeItem.notes);
            }}
            editWindow={
              <InteractiveInputBody
                placeholder="Add a note to this change"
                initialValue={changeItem.notes || ''}
                onChange={(value)=>setNote(value)}
                disableWidget={true}
                height={'160px'}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

ChangeCard.propTypes = {
  history: PropTypes.object.isRequired,
  maxHeight: PropTypes.number.isRequired,
  changeItem:PropTypes.object.isRequired,
  onUpdateNote:PropTypes.func.isRequired,
  onClose:PropTypes.func.isRequired,
};

export default ChangeCard;
