import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import axiosCerebrum from '../axios-cerebrum';

function useGetPriorities({ objectType, preventAuto = false }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const source = useRef(axios.CancelToken.source());

  const fetchPriorities = useCallback(async () => {
    if (!objectType) return;
    source.current.cancel();
    source.current = axios.CancelToken.source();

    if (localStorage.getItem('priorities')) {
      let data;
      try{
        data = JSON.parse(localStorage.getItem('priorities'));
      }
      catch(e){
        console.log(e);
      }
      // If data is not expired (1 hour), use it
      if(data && data.timestamp && new Date().getTime() - data.timestamp < 3600000 && data.items){
        setData(data.items);
        setLoading(false);
        setError(false);
        return;
      }
    }

    setLoading(true);
    setError(false);
    try {
      const response = await axiosCerebrum.get(
        "/api/priority",
        {
          params: {
            object_type: objectType,
            "sort.position": "ASC",
            per_page: 20
          },
          cancelToken: source.current.token
        }
      );
      setData(response.data.items);
      localStorage.setItem('priorities', JSON.stringify({items: response.data.items, timestamp: new Date().getTime()}));
    } catch (error) {
      setLoading(false);
      if (error.message) setError(error);
      console.log(error);
    }
    setLoading(false);
  // eslint-disable-next-line
  }, [objectType]);

  useEffect(() => {
    if (preventAuto) return;
    fetchPriorities();
  // eslint-disable-next-line
  }, [objectType, preventAuto, fetchPriorities, source]);

  return {
    data,
    loading,
    error,
    fetchPriorities
  }
}

export default useGetPriorities;
