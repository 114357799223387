import React from 'react';
import IssueList from '../IssueList/IssueList';

const Body = props => {

  const {
    state,
    dispatch,
    loadIssues,
    history,
    statusData,
    priorityData,
    sessionData
  } = props;

  return (
    <div>
      <IssueList
        state={state}
        history={history}
        loadIssues={loadIssues}
        statusData={statusData}
        priorityData={priorityData}
        dispatch={dispatch}
        sessionData={sessionData}
      />
    </div>
  )
}


export default Body;
