import React from 'react';
import { withStyles, Typography, Button, CircularProgress} from '@material-ui/core';
import theme from '../../../theme';
import axiosCerebrum from '../../../axios-cerebrum'
import { getIconComponent } from '../../../utilities'

const styles = theme => ({
  detailInputBase:{
    ...theme.components.inputBase,
    width:618,
    '& input':{
      padding:'16px 16px'
    }
  },
  checkbox:{
    color:theme.palette.primary.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  inputContainer:{
    marginBottom:16
  },
  tooltip:{
    fontSize:13.75,
  },
  focusBorder:{
    border: `1px solid ${theme.palette.error.main}`
  },
  helperText:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    paddingLeft:16,
    marginTop:3
  },
})

const ConnectionTester = props => {

  const {
    classes,
    dispatch,
    createdIntegration,
    connectionTested,
    setConnectionTested,
  } = props;

  const onTestConnection = () => {
    setConnectionTested({loading:true})
    axiosCerebrum.get(
      `/api/integrations/${createdIntegration.id}/test`,
    )
    .then(response=>{
      setConnectionTested({tested:true})
    })
    .catch(error=>{
      console.log(error)
      setConnectionTested({ error:error.response && error.response.data ? error.response.data.errors:'Failed to connect'})
    })
  }


  const generateTestConnectionComponent = () => {
    return (
      <div>
        <div style={{display:'flex',alignItems:'center',marginTop:16}}>
          {
            connectionTested.loading?
            <div style={{height:36,display:'flex',alignItems:'center',marginLeft:24}}>
              <CircularProgress color='secondary' style={{ width:24,height:24}}/>
            </div>
            :
            <Button className={classes.stepButton} style={{flexShrink:0}} variant='contained' color='primary' onClick={()=>onTestConnection()}>TEST CONNECTION</Button>
          }

          {
            connectionTested.tested===true &&
            <Typography style={{display:'flex', alignItems:'center',flexShrink:1,flexGrow:1,fontSize:13.75,marginLeft:32,whiteSpace:'pre-wrap',color:"#36ba4f"}}>
              {getIconComponent({label:'checked',size:24,colour:'#36ba4f'})}  Connection successful
            </Typography>
          }
          {
            connectionTested.error &&
            <Typography style={{display:'flex', alignItems:'center',flexShrink:1,flexGrow:1,fontSize:13.75,marginLeft:32,whiteSpace:'pre-wrap', wordBreak:'break-word',color:theme.palette.error.main}}>
              {getIconComponent({label:'info',size:24,colour:theme.palette.error.main})}<div style={{width:16}}></div> {connectionTested.error.trim()}
            </Typography>
          }
        </div>
        <Button
          disabled={connectionTested.tested!==true}
          className={classes.stepButton}
          style={{marginTop:16}}
          variant='contained'
          color='primary'
          onClick={()=>{
            dispatch({type:'set_create_integration_step',createIntegrationStep:4})
          }}
        >
          NEXT
        </Button>
      </div>
    )
  }

  const connectionInput = generateTestConnectionComponent();

  return (
    connectionInput
  )
}


export default withStyles(styles)(ConnectionTester);
