import React,{useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {onClickResultItem, getLabelPlural, toTitleCase,  getDispFields, mapObjectName} from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import {withRouter} from 'react-router'
import axiosSolr from '../../../axios-solr'
import { KadaBtn, KadaLoadingSpinner, kButtonVariants, kLoadingSpinnerVariants } from 'kada-component-library';


function AccessAdder(props) {

  const {
    object,
    state,
    dispatch,
    history,
  } = props;

  const [seeMore, setSeeMore] = useState(false)

  const isCancelledRef = useRef(false)


  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  const loadRoles = ({page=1,previousData=[]}) => {
    if(page===1){
      dispatch({
        type:'set_access_roles',
        accessRolesLoading:true
      })
    }
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
        {params:{
          category:'PLATFORM',
          parent_name:'Access Role',
          sort:'END_DESC',
          page:page,
          per_page:10,
        }}
      )
      .then(async response=>{
        let items = []
        if(response.data.total===0){
          dispatch({
            type:'set_access_roles',
            accessRoles:response.data
          })
          return;
        }
        if(response.data.items.length!==0){
          for(let i=0; i<response.data.items.length; i++){
            let detail = await axiosCerebrum
              .get(
                `/api/collectioninstances/${response.data.items[i].id}`
              )
            items.push({
              ...detail.data,
              collection_name:detail.data.parent.name.toLowerCase(),
              collection_category:'platform',
            })
          }
          await axiosSolr
            .get(
              `/solr/search/select`,{
                params:{
                  q:"*",
                  fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`
                }
              }
            )
            .then(solrRes=>{
              items = items.map(el=>({
                ...el,
                ...((solrRes.data.response.docs.find(s=>s.id===el.id))||{})
              }))
            })
            .catch(error=>{
              console.log(error)
            })
        }

        if(response.data.page<response.data.pages){
          loadRoles({page:response.data.page+1,previousData:[...previousData,...items]})
        }else{
          dispatch({
            type:'set_access_roles',
            accessRoles:{...response.data,items:[...previousData,...items]}
          })
        }

      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_access_roles',
          accessRolesError:true
        })
      })
  }

  useEffect(()=>{
    if(!state.accessRoles){
      loadRoles({})
    }
  // eslint-disable-next-line
  },[])

  if(!state.accessRoles || state.accessRoles.total===0)return null;

  return (
    <div id="profile-access-role-section" tabIndex={0} className="collapse collapse-arrow">
      <input
        type="checkbox"
      />
      <div className="collapse-title flex items-center">
        <div>
          <span className="text-sm text-(--color-header-text)">
            ACCESS ({state.accessRoles.total})
          </span>
          <p className='!text-xs text-(--color-light-text)'>
            Roles that give access to this {toTitleCase(mapObjectName(object.object.name).replace(/_/g,' '))}
          </p>
        </div>
      </div>
      <div className="collapse-content">
        {
          state.accessRoles && state.accessRoles.total===0 &&
          <p>No roles found or loaded from source</p>
        }
        {
          state.accessRoles && (seeMore?state.accessRoles.items:state.accessRoles.items.slice(0,3)).map((item)=>(
            <SimpleResultItem
              item={item}
              key={item.id}
              label={item.object_type_txt}
              titleColour={'var(--color-base-content)'}
              title={getDispFields(item,'dispTitle')}
              subTitle={getDispFields(item,'dispSubtitle')}
              hideRight
              showUnderline={true}
              onClick={(event)=>{onClickResultItem({item,id:item.id,label:'COLLECTION_INSTANCE',history,collection:'collection'})}}
            />
          ))
        }
        {
          state.accessRolesLoading && <KadaLoadingSpinner variant={kLoadingSpinnerVariants.secondary}/>
        }
        {
          state.accessRolesError && <p>Error occurred loading roles</p>
        }
        <div style={{display:'flex', marginTop: 8}}>
          {
            state.accessRoles && state.accessRoles.items.length>3 &&
            <KadaBtn
              variant={kButtonVariants.primaryBorderless}
              onClick={()=>{setSeeMore(!seeMore)}}
              text={`SEE ${seeMore?'LESS':'MORE'}`}
            >
            </KadaBtn>
          }
        </div>
      </div>
    </div>
  )
}

AccessAdder.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(AccessAdder);
