
import React from 'react';
import { Typography, Select, MenuItem, CircularProgress, Checkbox } from '@material-ui/core';
import BarChart from '../../Components/BarChart'
import DataTile from '../../Components/DataTile'
import LineChart from '../../Components/LineChart'
import StackedAreaChart from '../../Components/StackedAreaChart'
import KTooltip from '../../../UI/KTooltip/KTooltip';
import QuestionBoardChip from '../../../QuestionBoard/QuestionBoardChip/QuestionBoardChip';
import { processObjectName } from '../loadUtils';
import Alert from 'react-s-alert';
import { getIconComponent, mapSearchObjectName } from '../../../../utilities';
import { frequencyExplainText } from '../../Components/utils';
import theme from '../../../../theme';

export const generateDataTiles = ({classes, data, setData}) => {
  return (
    <div style={{display:'flex',width:'100%',marginBottom:24}}>
      {
        data.tileDataLoading && <CircularProgress color='secondary' style={{margin:'0px auto'}} />
      }
      {
        data.tileDataError && <Typography className={classes.normalText}>Error occurred loading details</Typography>
      }
      {
        data.tileData && data.tileData.map((el,index)=>(
          <DataTile tooltip={el.tooltip} clickable={el.clickable} tileId={el.id} onClick={()=>{if(el.clickable){setData({...data,selectedTile:el.id})}}} selected={data.selectedTile===el.id} title={el.title} value={el.value} footer={el.footer}/>
        ))
      }
      {
        data.tileData && data.tileData.length===0 && <Typography className={classes.normalText}>No data to display</Typography>
      }
    </div>
  )
}

export const generateChartGroup = ({classes,data,sourceColourMap}) => {
  if(data[`${data.selectedTile}_graphDataLoading`]){
    return <div style={{textAlign:'center',marginTop:36,marginBottom:24}}><CircularProgress color='secondary'/></div>
  }
  if(data[`${data.selectedTile}_graphDataError`]){
    return <Typography className={classes.normalText} style={{marginBottom:24}}>Error occurred loading graphs</Typography>
  }
  if(!data[`${data.selectedTile}_graphData`]){
    return <div></div>
  }
  let graphData = data[`${data.selectedTile}_graphData`];
  let chartData = [];

  let objectType = data.selectedTile;
  let displayObjectType = processObjectName(objectType)
  if(['REPORT','DATASET','DATA_PIPELINE','ML_MODEL'].includes(data.selectedTile)){
    let title = `${displayObjectType}s used by change status`
    let subTitle = `Count of ${displayObjectType}s by their change status`
    if(graphData[`${objectType}_BY_CHANGE`]){
      chartData.push({type:'bar',width:'80%',height:260,title,subTitle,series:graphData[`${objectType}_BY_CHANGE`].series,labels:graphData[`${objectType}_BY_CHANGE`].labels})
    }

    title = `${displayObjectType}s used by source`
    subTitle = `Count of ${displayObjectType}s by source`
    if(graphData[`${objectType}_BY_SOURCE`]){
      chartData.push({type:'bar',width:'80%',height:260,title,subTitle,series:graphData[`${objectType}_BY_SOURCE`].series,labels:graphData[`${objectType}_BY_SOURCE`].labels})
    }
    title = `${displayObjectType}s by usage frequency`
    subTitle = `Count of ${displayObjectType}s by usage frequency`
    let tooltip = frequencyExplainText;
    if(graphData[`${objectType}_BY_FREQUENCY`]){
      chartData.push({type:'bar',width:'80%',height:260,title,subTitle,series:graphData[`${objectType}_BY_FREQUENCY`].series,labels:graphData[`${objectType}_BY_FREQUENCY`].labels, tooltip, toggleLabel:graphData[`${objectType}_BY_FREQUENCY`].toggleLabel})
    }
    title = `${displayObjectType}s by trust score`
    subTitle = `Count of ${displayObjectType}s by trust score`
    if(graphData[`${objectType}_BY_TRUST`]){
      chartData.push({type:'bar',width:'80%',height:260,title,subTitle,series:graphData[`${objectType}_BY_TRUST`].series,labels:graphData[`${objectType}_BY_TRUST`].labels})
    }

  };

  if(chartData.length===0)return <div style={{marginBottom:24}}></div>

  return (
    <div style={{display:'flex',flexWrap:'wrap',gap:24,marginBottom:24}}>
      {
        chartData.map(el=>(
          <div className={classes.chartContainer}  data-test-id={`chart-${el.title.replace(`${displayObjectType}s `,'').toLowerCase()}`}>
            <div>
              <div style={{display:'flex',alignItems:'center',overflow:'hidden'}}>
                <Typography className={classes.chartTitle}>{el.title}</Typography>
                {
                  el.tooltip &&
                  <KTooltip title={el.tooltip}>
                    <div style={{width:16,height:16,marginLeft:6}}>
                      {getIconComponent({label:'info',size:16,colour:theme.palette.primary.main})}
                    </div>
                  </KTooltip>
                }
              </div>
              <Typography className={classes.chartSubtitle}>{el.subTitle}</Typography>
            </div>

            {
              el.type==='text' &&
              <div style={{width:el.width,height:el.height}}>
                <Typography style={{marginTop:16}} className={classes.subTitle}>{el.text}</Typography>
              </div>
            }
            {
              el.type==='bar' &&
              <BarChart
                id={el.title}
                key={el.title}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
                toggleLabel={el.toggleLabel}
                defaultHideToggleLabel
              />
            }

          </div>
        ))
      }

    </div>
  )
}

export const generateSwitchableLineGraph = ({classes, data, setData, dbList, sourceColourMap}) => {
  if(!data[`${data.selectedTile}_graphData`] || data[`${data.selectedTile}_graphDataError`] || data[`${data.selectedTile}_graphDataLoading`] ){
    return <div></div>
  }
  let graphData = data[`${data.selectedTile}_graphData`];
  let title, subTitle, chartData, tooltip;

  if(!['REPORT','DATA_PIPELINE','DATASET'].includes(data.selectedTile))return <div></div>
  // if(['REPORT'].includes(data.selectedTile)){
  let objectType = data.selectedTile;
  let displayObjectType = processObjectName(objectType)
  title = `${displayObjectType}s used last 30 days`;
  subTitle = `Count of ${displayObjectType}s used last 30 days`;
  chartData = graphData[`${objectType}_USED_PER_DAY`]
  // }

  if(!chartData)return <div></div>

  let series = chartData && chartData.series
                .filter(el=>data[`${data.selectedTile}_${data.lineStyle}_compared_sources`].includes(el.source_id))
                .sort((a,b)=>a.name<b.name?-1:1)

  if(series && series.find(el=>el.source_id===1000)){
    series = [
      series.find(el=>el.source_id===1000),
      ...series.filter(el=>el.source_id!==1000)
    ]
  }

  return (
    <div  className={classes.chartContainer} style={{width:'100%'}}>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start'}}>
        <KTooltip placement="top-start"  title={tooltip} classes={{tooltip:classes.tooltip}}>
          <div style={{flexGrow:1}}>
            <Typography className={classes.chartTitle}>{title}</Typography>
            <Typography className={classes.chartSubtitle} style={{marginBottom:12}}>{subTitle}</Typography>
          </div>
        </KTooltip>
        {
          chartData &&
          <Select
            className={classes.selector}
            value={data.lineStyle}
            MenuProps={{
              classes:{
                paper:classes.selectPaper
              }
            }}
            inputProps={{
              'data-test-id':'graph-type-selector'
            }}
            onChange={(event)=>{
              let newData = {...data,lineStyle:event.target.value};
              setData(newData)
            }}
          >
            <MenuItem className={classes.basicMenuItem} value={'line'}>
              Line
            </MenuItem>
            {
              chartData && chartData.series.length>1 &&
              <MenuItem className={classes.basicMenuItem} value={'stacked'}>
                Stacked
              </MenuItem>
            }
          </Select>
        }
        {
          chartData &&
          <div style={{width:'max-content',marginLeft:24}}>
            <Select
              style={{width:330}}
              className={classes.selector}
              MenuProps={{
                classes:{
                  paper:classes.selectPaper
                }
              }}
              inputProps={{
                'data-test-id':'graph-source-selector'
              }}
              multiple={true}
              value={data[`${data.selectedTile}_${data.lineStyle}_compared_sources`]}
              renderValue={values=>values.map(el=>el===1000?'All Sources':dbList.find(d=>d.id+''===el+'').name).join(', ')}
              onChange={(event)=>{
                if(event.target.value.length===0 || event.target.value.filter(el=>el!==1000).length>5){
                  Alert.error('Max 5 sources can be selected', {
                    position: 'top',
                    effect: 'stackslide',
                  });
                  return
                }
                setData({...data,[`${data.selectedTile}_${data.lineStyle}_compared_sources`]:event.target.value})
              }}
            >
              {
                // data.lineStyle==='line' &&
                // <MenuItem className={classes.menuItem} value={1000}>
                //   <Checkbox
                //     color='primary'
                //     checked={data[`${data.selectedTile}_${data.lineStyle}_compared_sources`].includes(1000)}
                //   />
                //   {'All Sources'}
                // </MenuItem>
              }
              {
                chartData.series.filter(el=>el.source_id+''!=='1000').length>0 &&
                <Typography className={classes.dropdownCaption} style={data.lineStyle==='stacked'?{marginTop:4}:undefined}>SELECT SOURCES</Typography>
              }

              {
                chartData.series.filter(el=>el.source_id+''!=='1000').map(el=>el.source_id).map(el=>(
                  <MenuItem className={classes.menuItem} value={el}>
                    <Checkbox
                      color='primary'
                      checked={data[`${data.selectedTile}_${data.lineStyle}_compared_sources`].includes(el)}
                    />
                    {dbList.find(d=>d.id+''===el+'').name}
                  </MenuItem>
                ))
              }
            </Select>
            <Typography className={classes.chartSubtitle} style={{marginLeft:16}}>Max 5 data sources to compare</Typography>
          </div>
        }
      </div>
      {
        (!chartData || (data.lineStyle==='stacked' && chartData.series.length===1))
        ?
        <div>
          <Typography style={{marginTop:16}} className={classes.subTitle}>No data to display</Typography>
        </div>
        :
        <div style={{height:300}}>
          {
            data.lineStyle==='line' &&
            <LineChart
              width={'100%'}
              height={'300px'}
              series={series}
              // colours={series.map(el=>sourceColourMap[el.source_id])}
            />
          }
          {
            data.lineStyle==='stacked' &&
            <StackedAreaChart
              width={'100%'}
              height={'300px'}
              series={series}
              // colours={series.map(el=>sourceColourMap[el.source_id])}
            />
          }
        </div>
      }

    </div>
  )
}


export const generateExtractDownloader = ({ state, dispatch, data,}) => {

  let objectName = mapSearchObjectName(data.selectedTile);
  let code = `0200`;

  let button = (
    <QuestionBoardChip
      key={objectName}
      buttonText={'USAGE REPORT'}
      buttonOnly
      state={state}
      dispatch={dispatch}
      code={code}
      defaultFilters={{
        object_type: objectName,
      }}
    />
  )

  return (
    <div style={{display:'flex',justifyContent:'flex-end',marginTop:-72,marginBottom:16}}>
      {button}
    </div>
  )
}
