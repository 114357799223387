import React, { useRef } from 'react';
import PropTypes from 'prop-types';

function DetailPopperEL(props) {
  const {
    termId,
    name,
  } = props;

  const hoverTimeoutRef = useRef();

  // let caption = isSuggestion
  //   ? isMultiple
  //     ? 'MULTIPLE SUGGESTED TERMS'
  //     : 'SUGGESTED TERM'
  //   : 'LINKED TERM';

  return (
    <span className="select-none">
      <span
        style={{ cursor: 'default' }}
        onMouseEnter={(e) => {
          clearTimeout(hoverTimeoutRef.current);
          hoverTimeoutRef.current = setTimeout(() => {
            const event = new CustomEvent('glossaryDetailPop', {
              detail: { x: e.clientX, y: e.clientY + window.scrollY, id: termId },
            });
            window.dispatchEvent(event);
          }, 100);
        }}
        onMouseLeave={() => {
          clearTimeout(hoverTimeoutRef.current);
        }}
      >
        {name}
      </span>
    </span>
  );
}

DetailPopperEL.propTypes = {
  item: PropTypes.object.isRequired,
  deleted: PropTypes.bool
};

export default DetailPopperEL;
