import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Select, MenuItem, Button } from '@material-ui/core';
import { titleCaseObjectName } from '../../../utilities';
import SourceSelector from '../../OnBoardSource/UIComponents/SourceSelector';
import SelectionChip from '../Components/SelectionChip';
import { getParentType } from '../utils';
import ScopeSelect from '../ScopeSelect/ScopeSelect';
import ObjectSelector from '../Components/ObjectSelector';

const styles = theme => ({
  header: {
    fontSize: 20,
    color: theme.palette.primaryText.main,
    marginBottom: 24
  },
  blockTitle: {
    fontSize: 13.75,
    color: theme.palette.primaryText.main,
  },
  blockSubtitle: {
    fontSize: 10,
    color: theme.palette.primaryText.light,
    marginBottom: 12
  },
  selectionText: {
    fontSize: 16,
  },
  block: {
    marginBottom: 32,
  },
  typeSelector: {
    ...theme.components.selector,
    border: `1px solid ${theme.palette.listItemDivider.main}`,
    background: theme.palette.background.main,
    height: 36,
    width: 160,
    '& div div': {
      paddingLeft: 16
    }
  },
  disabledSelector: {
    '& svg': {
      opacity: 0.6
    }
  },
  errorBorder: {
    border: `1px solid ${theme.palette.error.main}`,
  }
})

function ItemSelect(props) {

  const {
    classes,
    state,
    dispatch,
  } = props;

  const objectMapping = {
    DATASET_TABLE: ['DATASET_TABLE', 'TABLE'],
    TABLE: ['TABLE', 'DATASET_TABLE'],
    COLUMN: ['COLUMN', 'DATASET_FIELD'],
    DATASET_FIELD: ['DATASET_FIELD', 'COLUMN'],
  }

  const onReset = () => {
    dispatch({ type: 'set_upstream_child_type', upstreamChildType: null })
    dispatch({ type: 'set_upstream_parent_type', upstreamParentType: null })
    dispatch({ type: 'set_upstream_source', upstreamSource: null })
    dispatch({ type: 'set_downstream_child_type', downstreamChildType: null })
    dispatch({ type: 'set_downstream_parent_type', downstreamParentType: null })
    dispatch({ type: 'set_downstream_source', downstreamSource: null })
    dispatch({ type: 'set_is_map_child', isMapChild: null })
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        Select the asset type combination to link
        <Button color='secondary' style={{ marginLeft: 16 }} onClick={onReset}>RESET</Button>
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 48 }}>
        <Typography className={classes.selectionText} style={{ marginRight: 12 }}>I want to link</Typography>
        <ObjectSelector
          objectType={state.upstreamChildType}
          validObjectTypes={Object.keys(objectMapping)}
          onSetObjectType={type => {
            dispatch({ type: 'set_upstream_child_type', upstreamChildType: type })
            dispatch({ type: 'set_upstream_parent_type', upstreamParentType: getParentType(type) })
            dispatch({ type: 'set_upstream_source', upstreamSource: null })
            dispatch({ type: 'set_downstream_child_type', downstreamChildType: null })
            dispatch({ type: 'set_downstream_parent_type', downstreamParentType: null })
            dispatch({ type: 'set_downstream_source', downstreamSource: null })
            dispatch({ type: 'set_is_map_child', isMapChild: false })
          }}
          selectorClassname={classes.typeSelector + (!state.upstreamChildType ? ` ${classes.errorBorder}` : '')}
        />
        <Typography className={classes.selectionText} style={{ marginRight: 12, marginLeft: 12 }}>in</Typography>
        <SelectionChip
          object={{ name_txt: state.upstreamSource?.name || 'Select a Source', object_type_txt: state.upstreamSource?.source_template?.name || 'SOURCE' }}
          id="upstream-source"
          width={260}
          height={36}
          isErrorBorder={!state.upstreamSource}
          disabled={!state.upstreamChildType}
          interactiveHeader='Select a Source'
          interactiveBody={
            <SourceSelector
              sourceList={state.sources.filter(s => !s.reference_flag && (!state.sourceByObjectType || !state.upstreamChildType || state.sourceByObjectType[state.upstreamChildType].includes(s.id)))}
              isDatabaseLevel
              onChangeSource={(name, source) => {
                dispatch({ type: 'set_upstream_source', upstreamSource: source })
                document.getElementById(`modal-close-btn`).click()
              }}
              defaultOpen={true}
            />
          }
        />
        <Typography className={classes.selectionText} style={{ marginRight: 12, marginLeft: 12 }}>to</Typography>
        <Select
          value={state.downstreamChildType}
          className={classes.typeSelector + (!state.upstreamChildType ? ` ${classes.disabledSelector}` : '') + (!state.downstreamChildType ? ` ${classes.errorBorder}` : '')}
          renderValue={value => value ? titleCaseObjectName(value) + 's' : 'Select a type'}
          displayEmpty
          disabled={!state.upstreamChildType}
          disableUnderline
          onChange={e => {
            if (e.target.value === state.downstreamChildType) return;
            dispatch({ type: 'set_downstream_source', downstreamSource: null })
            dispatch({ type: 'set_downstream_child_type', downstreamChildType: e.target.value })
            dispatch({ type: 'set_downstream_parent_type', downstreamParentType: getParentType(e.target.value) })
          }}
        >
          {
            state.upstreamChildType && objectMapping[state.upstreamChildType].map(o => {
              return (
                <MenuItem key={o} value={o}>{titleCaseObjectName(o)}s</MenuItem>
              )
            })
          }
        </Select>
        <Typography className={classes.selectionText} style={{ marginRight: 12, marginLeft: 12 }}>in</Typography>
        <SelectionChip
          object={{ name_txt: state.downstreamSource?.name || 'Select a Source', object_type_txt: state.downstreamSource?.source_template?.name || 'SOURCE' }}
          id="downstream-source"
          width={260}
          height={36}
          isErrorBorder={!state.downstreamSource}
          disabled={!state.upstreamChildType || !state.downstreamChildType}
          interactiveHeader='Select a Source'
          interactiveBody={
            <SourceSelector
              sourceList={state.sources.filter(s => !s.reference_flag && (!state.sourceByObjectType || !state.downstreamChildType || state.sourceByObjectType[state.downstreamChildType].includes(s.id)))}
              isDatabaseLevel
              onChangeSource={(name, source) => {
                dispatch({ type: 'set_downstream_source', downstreamSource: source })
                document.getElementById(`modal-close-btn`).click()
              }}
              defaultOpen={true}
            />
          }
        />
      </div>

      {
        state.upstreamChildType && state.downstreamChildType &&
        <ScopeSelect state={state} dispatch={dispatch} />
      }

    </div>
  )
}

ItemSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ItemSelect));
