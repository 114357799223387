import theme from "../../../../../theme"

export const tileStyles = {
  chipRoot:{
    minHeight:53,
    width:'100%',
    display:"flex",
    alignItems:'center'
  },
  title: {
    fontSize: 12,
    letterSpacing:1,
    color:theme.palette.primaryText.main,
    flexGrow:0,
    flexShrink:1,
    width:'max-content',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  headerContainer:{
    display:"flex",
    alignItems:'center',
    width:132,
    marginRight:8,
    flexShrink:0
  },
  chipContainer:{
    display:'flex',
    flexWrap:'wrap',
    paddingTop:10,
    paddingBottom:4,
    flexGrow:1,
    marginTop:3,
    paddingLeft:8,
    paddingRight:8,
    borderRadius:3,
    // marginLeft:-1,
    overflow:'hidden',
    border:`1px solid transparent`,
  },
  clickableBox:{
    '&:hover':{
      border:`1px solid ${theme.palette.listItemDivider.main}`,
      background:`${theme.palette.editHovered.main}85`
    }
  },
  tag:{
    width:'max-content',
    lineHeight:'normal',
    maxWidth:'100%',
    overflow:'hidden',
    display:'flex',
    alignItems:'center',
    boxSizing:localStorage.hasOwnProperty('dark')?'border-box':undefined,
    height:32,
    borderRadius:16,
    marginBottom:6,
    background:theme.palette.avatar.main,
    border:`1px solid ${theme.palette.avatar.main}`,
    cursor:'pointer',
    marginRight:6,
    "& .chip-delete-button":{
      display:'none'
    },
    '&:hover':{
      "& .chip-delete-button":{
        display:'block'
      },
      // "& span":{
      //   textDecoration:'underline'
      // }
    }
  },
  tagText:{
    color:'#FFF',
    fontSize:13.75,
    marginLeft:8,
    marginRight:6,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  clickableText:{
    '&:hover':{
      textDecoration:'underline'
    },
    cursor:'pointer'
  },
  deleteButton:{
    padding:3,
    marginRight:2,
    "&:hover":{
      backgroundColor:`${theme.palette.hovered.main}40`
    }
  },
  selector:{
    ...theme.components.selector,
    height:24,
    borderRadius:12,
    overflow:'hiden',
    '& div div':{
      fontSize:13,
      paddingLeft:8,
      paddingRight:36
    }
  }
}
