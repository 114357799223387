import axiosSolr from "../../../axios-solr"
import { formatNumber, sendMessage } from "../../../utilities";


const getIssueFq = ({status, priority, domain}) => {
  let fqArr = ['object_type_srt:ISSUE'];
  if(status!=='all')fqArr.push(`workflow_status_srt:"${status}"`)
  if(priority!=='all')fqArr.push(`priority_srt:"${priority}"`)
  if(domain!=='all')fqArr.push(`top_domains_msrt:"${domain}"`)
  return fqArr.join(' AND ')
}

export const loadIssueSeverityChart = async ({status, priority, domain}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq: getIssueFq({status, priority, domain}),
          rows:0,
          'json.facet':{
            "issue_priority": {
              "type": "terms",
              "field": "priority_srt",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.issue_priority)return;
      let buckets = facet.data.facets.issue_priority.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val),
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:'ISSUE',
              modalFilter:`priority_srt:"${b.val}" AND ${getIssueFq({status, priority, domain})}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadIssueIsAssignedChart = async ({status, priority, domain}) => {
  let chartData;
  let facetQuery = {
    'assigned': {
      type: 'query',
      q: `assignee_msrt:*`
    },
    'not assigned': {
      type: 'query',
      q: `-assignee_msrt:[* TO *]`
    },
  }
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq: getIssueFq({status, priority, domain}),
          rows:0,
          'json.facet':facetQuery
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets)return;
      let names = Object.keys(facet.data.facets).filter(c=>c!=='count');
      if(names.length===0)return;
      chartData = {
        labels:names.map(el=>el.toUpperCase()),
        series:[{
          name:'count',
          data:names.map(n=>facet.data.facets[n].count),
          clickFns:names.map(n=>()=>{
            sendMessage({
              modalQuery:`${facetQuery[n].q}`,
              modalObject:'ISSUE',
              modalFilter:getIssueFq({status, priority, domain})
            })
          })
        }]
      }
    })
  return chartData
}

export const loadIssueDimension = async ({status, priority, domain}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq: getIssueFq({status, priority, domain}),
          rows:0,
          'json.facet':{
            "issue_dimension": {
              "type": "terms",
              "field": "data_quality_dimension_kc_msrt",
              "mincount":1,
              "limit":100,
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.issue_dimension)return;
      let buckets = facet.data.facets.issue_dimension.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val),
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:`*`,
              modalObject:'ISSUE',
              modalFilter:`data_quality_dimension_kc_msrt:"${b.val}" AND ${getIssueFq({status, priority, domain})}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadIssueCreatedChart = async ({status, priority, domain}) => {
  let chartData;
  let facetQuery = {
    'LAST 7 DAYS': {
      type: 'query',
      q: `created_at_srt:[NOW/DAY-7DAYS TO NOW/DAY+1DAY]`
    },
    'LAST 30 DAYS': {
      type: 'query',
      q: `created_at_srt:[NOW/DAY-30DAYS TO NOW/DAY+1DAY]`
    },
    'LAST 90 DAYS': {
      type: 'query',
      q: `created_at_srt:[NOW/DAY-90DAYS TO NOW/DAY+1DAY]`
    },
    'LAST 6 MONTHS': {
      type: 'query',
      q: `created_at_srt:[NOW/DAY-6MONTH TO NOW/DAY+1DAY]`
    },
    'LAST YEAR': {
      type: 'query',
      q: `created_at_srt:[NOW/DAY-1YEAR TO NOW/DAY+1DAY]`
    },
    'ALL TIME': {
      type: 'query',
      q: `created_at_srt:[* TO *]`
    }
  }

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq: getIssueFq({status, priority, domain}),
          rows:0,
          'json.facet':facetQuery
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets)return;
      let names = Object.keys(facet.data.facets).filter(c=>c!=='count');
      if(names.length===0)return;
      chartData = {
        labels:names.map(el=>el.toUpperCase()),
        series:[{
          name:'count',
          data:names.map(n=>facet.data.facets[n].count),
          clickFns:names.map(n=>()=>{
            sendMessage({
              modalQuery:`*`,
              modalObject:'ISSUE',
              modalFilter:`created_at_srt:from_today,${facetQuery[n].q.split(':')[1]} AND ${getIssueFq({status, priority, domain})}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadIssueOpenRateChart = async ({status, priority, domain}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq: getIssueFq({status, priority, domain}),
          rows:0,
          'json.facet':{
            "openRate":{
              "type": "range",
              "field": "created_at_srt",
              "start": 'NOW/DAY-30DAYS',
              "end": 'NOW',
              "gap": '+1DAY'
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.openRate)return;
      let buckets = facet.data.facets.openRate.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val),
        series:[{
          name:'new issues',
          data:buckets.map(b=>b.count),
        }]
      }
    })
  return chartData
}

export const loadIssueAssignedToChart = async ({status, priority, domain}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq: getIssueFq({status, priority, domain}),
          rows:0,
          'json.facet':{
            "assigned_to": {
              "type": "terms",
              "field": "assignee_msrt",
              "mincount":1,
              "limit":5,
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.assigned_to)return;
      let buckets = facet.data.facets.assigned_to.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val),
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:`*`,
              modalObject:'ISSUE',
              modalFilter:`assignee_msrt:${b.val} AND ${getIssueFq({status, priority, domain})}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadIssueCreatedByChart = async ({status, priority, domain}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq: getIssueFq({status, priority, domain}),
          rows:0,
          'json.facet':{
            "created_by": {
              "type": "terms",
              "field": "created_by_msrt",
              "mincount":1,
              "limit":5,
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.created_by)return;
      let buckets = facet.data.facets.created_by.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val),
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:`*`,
              modalObject:'ISSUE',
              modalFilter:`created_by_msrt:${b.val} AND ${getIssueFq({status, priority, domain})}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadRecommendations = async ({status, priority, domain}) => {
  let recommendations = [undefined,undefined,undefined];
  let promises = [];

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`${getIssueFq({status, priority, domain})} AND -assignee_msrt:[* TO *]`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;

        recommendations[0] = {
          title:`Allocate issues with no assignee`,
          countTxt:formatNumber(response.data.response.numFound) + ' issues',
          onClick:()=>{
            sendMessage({
              modalQuery:`*`,
              modalObject:'ISSUE',
              modalFilter:`${getIssueFq({status, priority, domain})} AND assignee_msrt:NO ASSIGNEDTO`
            })
          }
        }
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`${getIssueFq({status, priority:'all', domain})} AND priority_srt:HIGHEST AND created_at_srt:[* TO NOW/DAY-90DAY]`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;

        recommendations[1] = {
          title:`Review highest priority issues created more than 90 days ago`,
          countTxt:formatNumber(response.data.response.numFound) + ' issues',
          onClick:()=>{
            sendMessage({
              modalQuery:'*',
              modalObject:'ISSUE',
              modalFilter:`${getIssueFq({status, priority:'all', domain})} AND priority_srt:HIGHEST AND created_at_srt:between,*,NOW/DAY-90DAY`,
              // modalForceFq:'created_at_srt:[* TO NOW/DAY-90DAY]'
            })
          }
        }
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`${getIssueFq({status, priority:'all', domain})} AND priority_srt:(HIGH OR MEDIUM) AND created_at_srt:[* TO NOW/DAY-6MONTH]`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;

        recommendations[2] = {
          title:`Review high & medium priority issues created more than 6 months ago`,
          countTxt:formatNumber(response.data.response.numFound) + ' issues',
          onClick:()=>{
            sendMessage({
              modalQuery:'*',
              modalObject:'ISSUE',
              modalFilter:`${getIssueFq({status, priority:'all', domain})} AND priority_srt:HIGH,MEDIUM AND created_at_srt:from_today,[* TO NOW/DAY-6MONTH]`,
              // modalForceFq:'created_at_srt:[* TO NOW/DAY-6MONTH]'
            })
          }
        }
      })
  )

  await Promise.all(promises)
  return recommendations.filter(r=>r)
}

export const sortByTotalUsage = (metricA, metricsB) => {
  const sum = arr => arr.reduce((a,b) => a + b, 0)
  return  sum(metricsB.dataset.y)-sum(metricA.dataset.y)
}
