import React, {} from 'react';
import PropTypes from 'prop-types';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import { KadaLoadingSpinner, kLoadingSpinnerVariants } from 'kada-component-library';
import { trimText } from '../../../utilities';

const Details = props => {

  const {
    fetchList,
    state,
  } = props;

  const isExternal = state.basicData.external_url ? true : false;

  const getLatestComment = (comments) => {
    if (comments.length > 0) {
      return comments[comments.length - 1];
    }
    return null;
  }

  return (
    <div>
      <div className={"mb-10"}>
        <UpdateInputCerebrum
          url={`/api/issues/${state.basicData.id}`}
          data={state.basicData}
          label="issue"
          initialValue={state.basicData.description}
          property="description"
          placeholder="Add a description"
          multiline
          state={state}
          fetchList={fetchList}
          disableEditing={isExternal}
          collection="tags"
          subtitle='Describe the issue, its impacts and how it was found'
          enableMarkDown
          enableWidget={false}
        />
      </div>

      {
        isExternal &&
        <div tabIndex={0} className="collapse collapse-arrow mt-4">
          <input type="checkbox" defaultChecked/>
          <div className="collapse-title flex items-center">
            <span className="text-sm text-(--color-header-text)">
              EXTERNAL TICKET DETAILS
            </span>
          </div>
          <div className="collapse-content">
            {
              state.externalTicketLoading &&
              <div className="flex justify-center items-center">
                <KadaLoadingSpinner variant={kLoadingSpinnerVariants.secondary} />
              </div>
            }
            {
              state.externalTicketError &&
              <p>
                Failed to fetch external ticket details
              </p>
            }
            {
              state.externalTicketData &&
              <div className='table'>
                <tr>
                  <td>
                    JIRA ticket
                  </td>
                  <td>
                    <a href={state.basicData.external_url} target="_blank" rel="noreferrer" className='link'>
                      {state.basicData.external_url}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    JIRA status category
                  </td>
                  <td>
                    {state.externalTicketData.fields?.status?.statusCategory?.name || 'N/A'}
                  </td>
                </tr>
                <tr>
                  <td>
                    JIRA status
                  </td>
                  <td>
                    {state.externalTicketData.fields?.status?.name || 'N/A'}
                  </td>
                </tr>
                <tr>
                  <td>
                    JIRA commented by (recent)
                  </td>
                  <td>
                    {getLatestComment(state.externalTicketData.fields?.comment?.comments)?.updateAuthor?.displayName || 'N/A'}
                  </td>
                </tr>
                <tr>
                  <td>
                    JIRA comment (recent)
                  </td>
                  <td>
                    {trimText(getLatestComment(state.externalTicketData.fields?.comment?.comments)?.body || 'N/A', 200)}
                  </td>
                </tr>
              </div>
            }
          </div>
        </div>
      }

    </div>
  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
}

export default Details;
