import React, { useReducer, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/Integrations/Body/Body';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import 'url-search-params-polyfill';
import { removeUrlQueryArg } from '../../utilities';
import { addHistory } from '../../HistoryManager';
import { adminPageInfo } from '../NavBar/utils';
import { hasLLMKeySet } from '../../components/UI/UpdateInput/genAIComponents';

const styles = theme => ({
  underlineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
});

const initialState = {
  tabState:0,
  integrationList:{},
  createIntegrationStep:0,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return{
        ...state,
        tabState:action.tabState
      }
    case 'set_capabilities':
      return {
        ...state,
        capabilities:action.capabilities,
        capabilitiesLoading:action.capabilitiesLoading,
        capabilitiesError:action.capabilitiesError,
      }
    case 'set_viewed_capability':
      return {
        ...state,
        viewedCapability:action.viewedCapability
      }
    case 'set_integration_list':
      return {
        ...state,
        integrationList:action.integrationList
      }
    ////////////////////////////
    // create integration
    case 'set_create_integration_step':{
      return {
        ...state,
        createIntegrationStep:action.createIntegrationStep
      }
    }
    case 'set_integration_templates':{
      return {
        ...state,
        integrationTemplates:action.integrationTemplates,
        integrationTemplatesLoading:action.integrationTemplatesLoading,
        integrationTemplatesError:action.integrationTemplatesError
      }
    }
    case 'set_edited_integration':{
      return {
        ...state,
        editedIntegration:action.editedIntegration
      }
    }
    case 'set_has_gen_ai':{
      return {
        ...state,
        hasGenAI:action.hasGenAI
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const Integrations = props => {
  const {
    history,
    classes,
    sessionData,
  } = props;

  const [state, dispatch] = useReducer(reducer,initialState);


  const urlSearch = new URLSearchParams(window.location.search);
  const capability = urlSearch.get('capability');

  useEffect(()=>{
    if(capability){
      dispatch({type:'set_tab_state',tabState:1})
      dispatch({type:'set_viewed_capability',viewedCapability:capability})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['capability']}));
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    addHistory({
      url:window.location.pathname,
      iconLabel:adminPageInfo.integrations.icon,
      title: adminPageInfo.integrations.name,
      subTitle:adminPageInfo.integrations.description,
      type:'application',
    })
  },[])

  useEffect(()=>{
    window.scrollTo(0,0)
    if(state.tabState!==2){
      dispatch({type:'set_edited_integration'})
      dispatch({type:'set_create_integration_step',createIntegrationStep:0})
    }
  },[state.tabState])

  let buttons = [];

  const capabilityLabelMap = capability => {
    switch(capability){
      case 'ACCESS_REQUESTS':
        return 'access_role'
      case 'CHANGE_MANAGEMENT':
        return 'change';
      case 'BUSINESS_LOGIC':
      case 'ROADMAP':
      case 'NOTES':
      case 'DECISION':
        return 'note'
      case 'QUESTIONS':
        return 'question'
      case 'ISSUE_MANAGEMENT':
        return 'issue'
      case 'PROPERTY_MANAGEMENT':
        return 'info'
      default:
        return 'integration'
    }
  }

  useEffect(()=>{
    dispatch({type:'set_has_gen_ai',hasGenAI:hasLLMKeySet()})
  },[])

  let title = 'Integrations';
  let subtitle;
  let description = 'Integrate an external source (e.g. JIRA) to manage capabilities in K';
  let iconLabel = 'integration'
  let bannerVisibility, bannerCause, bannerdisplayText;


  let emailIntegration, emailSettings;
  if(state.viewedCapability==='EMAIL'){
    try{
      emailSettings = JSON.parse(localStorage.getItem('platformSettings')).items.filter(el=>el.key.includes('platform_email_smtp_'))
      emailIntegration = {};
      emailSettings.forEach(el=>{
        emailIntegration[el.key] = el.value
      })
    }catch{

    }
  }
  const hasEmail = emailIntegration && emailIntegration.platform_email_smtp_sender_email && emailIntegration.platform_email_smtp_sender_email.trim()!=='' && emailIntegration.platform_email_smtp_server && emailIntegration.platform_email_smtp_server.trim()!==''
  const hasGenAI = state.hasGenAI

  if(state.tabState===1 && state.viewedCapability){
    title = state.viewedCapability;
    description = `Integrate an external source to manage ${state.viewedCapability.toLowerCase()}`
    iconLabel = capabilityLabelMap(state.viewedCapability)
    if(state.viewedCapability==='EMAIL'){
      description = 'Integrate a SMTP service for email notification'
      iconLabel='EMAIL'
    }
    if(state.viewedCapability==='GENERATIVE AI'){
      description = 'Setup a generative AI service to generate metadata'
      iconLabel='gen_ai'
    }
    subtitle = <span className={classes.underlineOnHover} onClick={()=>dispatch({type:'set_tab_state',tabState:0})}>INTEGRATIONS</span>
    if(!['GENERATIVE AI','EMAIL'].includes(state.viewedCapability)){
      bannerVisibility = 'visible'
      bannerCause = 'access_key'
      bannerdisplayText = 'After creating your integration, add an Access Key if the integration needs to synchronise with K'
    }
    if(
      !['GENERATIVE AI','EMAIL'].includes(state.viewedCapability) ||
      (state.viewedCapability==='EMAIL' &&  !hasEmail) ||
      (state.viewedCapability==='GENERATIVE AI' && !hasGenAI)
    ){
      let isIntegrationListEmpty = state.integrationList[state.viewedCapability] && state.integrationList[state.viewedCapability]?.data?.total === 0;
      buttons.push(
        <ProfileButton
          text="NEW INTEGRATION"
          iconLabel="add"
          disabled={state.viewedCapability==='ISSUE_MANAGEMENT' && !isIntegrationListEmpty}
          tooltip={state.viewedCapability==='ISSUE_MANAGEMENT' && !isIntegrationListEmpty ? 'Only one integration is allowed for Issue Management' : ''}
          onClick={()=>{dispatch({type:'set_tab_state',tabState:2})}}
        />
      )
    }

  }

  if(state.tabState===2 && state.viewedCapability){
    title = state.editedIntegration?"Edit integration":'Create new integration';
    description = `${state.editedIntegration?'Edit existing integration':'Create a new integration'} to an external source for ${state.viewedCapability.toLowerCase()}`
    iconLabel = capabilityLabelMap(state.viewedCapability)
    if(state.viewedCapability==='EMAIL'){
      iconLabel='EMAIL'
    }
    subtitle = <span><span className={classes.underlineOnHover} onClick={()=>{dispatch({type:'set_tab_state',tabState:0});history.goBack()}}>INTEGRATIONS</span> / <span className={classes.underlineOnHover} onClick={()=>dispatch({type:'set_tab_state',tabState:1})}>{state.viewedCapability.toUpperCase()}</span></span>
  }


  if(buttons.length===0){
    buttons.push(<div style={{height:48,margin:'8px 8px 8px 16px'}}></div>)
  }


  return (
    <ProfileLayout
      header={
        <div>
          <ProfileHeader
            history={history}
            title={title}
            subtitle={subtitle || ' '}
            description={description}
            label={'integration'}
            iconLabel={iconLabel}
            buttons={buttons}
            bannerVisibility={bannerVisibility}
            bannerCause={bannerCause}
            bannerdisplayText={bannerdisplayText}
          />
        </div>
      }
      body={
        <Body
          history={history}
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
          capabilityLabelMap={capabilityLabelMap}
          emailIntegration={emailIntegration}
          hasEmail={hasEmail}
          hasGenAI={hasGenAI}
          emailSettings={emailSettings}
        />
      }
    />
  )
}


export default withStyles(styles)(Integrations);
