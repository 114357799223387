import React, { useReducer, useEffect, useState } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import {  LinearProgress, Typography } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/ToolProfile/Body/Body';
import {  handleShareClick,  setInitialState, getDispFields, removeUrlQueryArg } from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import axiosSolr from '../../axios-solr';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import axiosCerebrum from '../../axios-cerebrum'
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { checkIsFollowed, checkLineageLinkable } from '../../permissionChecker';
import 'url-search-params-polyfill';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});

const initialState = {
  tabState: 0,
  // basic table data
  basicData: null,

  insightsData: null,
  // usage
  usageData: null,
  usageError: null,
  usageLoading: null,
  //
  selectedDate: null,
  // reference
  referenceFilter:'name_srt asc',

  contentTabState:0,
  contentData:{},
  contentLoading:{},
  contentError:{},
  searchFilter:{},
  showDeleted:false,
  showReference:false,
  contentFilter:{},
  lineageData:{
    tabState:0,
    upstreamView:'basic_map',
    downstreamView:'basic_map',
    upstreamObjects:['DATABASE','TOOL'],
    downstreamObjects:['DATABASE','TOOL'],

    upstreamListSort:'LAST_USED_DESC',
    downstreamListSort:'LAST_USED_DESC'
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError: action.basicDataError,
        basicDataLoading: action.basicDataLoading
      }
    case 'set_lineage_data':
      return {
        ...state,lineageData:action.lineageData
      }
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
        insightsError: action.insightsError,
        insightsLoading: action.insightsLoading
      }
    case "set_selected_date":
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_reference_filter':
      return {...state, referenceFilter:action.referenceFilter}
    case 'set_reference_data':
      return {
        ...state,
        referenceData:action.referenceData,
        referenceError:action.referenceError,
        referenceLoading:action.referenceLoading
      }
    case 'set_content_tab_data':
      return {
        ...state,
        contentTabData:action.contentTabData,
        contentTabError:action.contentTabError,
        contentTabLoading:action.contentTabLoading
      }
    case 'set_content_data':
      return {
        ...state,
        contentData:action.contentData,
      }
    case 'set_content_error':
      return {
        ...state,
        contentError:action.contentError
      }
    case 'set_content_loading':
      return {
        ...state,
        contentLoading:action.contentLoading
      }
    case 'set_search_filter':
      return {
        ...state,
        searchFilter:action.searchFilter
      }
    case 'set_show_reference':
      return {
        ...state,
        showReference:action.showReference
      }
    case 'set_show_deleted':
      return {
        ...state,
        showDeleted:action.showDeleted
      }
    case 'set_content_filter':
      return {
        ...state,
        contentFilter:action.contentFilter
      }
    case 'set_content_tab_state':
      return {
        ...state,
        contentTabState:action.contentTabState
      }
    case 'set_usage_data':
      return {
        ...state,
        usageData: action.usageData,
        usageError: action.usageError,
        usageLoading: action.usageLoading
      }
    case 'set_usage_active_series':
      return {
        ...state,
        usageActiveSeries: action.usageActiveSeries
      }
    case 'set_usage_panel_data':
      return {
        ...state,
        usagePanelData: action.usagePanelData,
        usagePanelError: action.usagePanelError,
        usagePanelLoading: action.usagePanelLoading
      }
    case 'set_usage_task_data':
      return {
        ...state,
        usageTaskData: action.usageTaskData,
        usageTaskError: action.usageTaskError,
        usageTaskLoading: action.usageTaskLoading
      }
    case 'set_usage_panel_task_data':
      return {
        ...state,
        usagePanelTaskData: action.usagePanelTaskData,
        usagePanelTaskError: action.usagePanelTaskError,
        usagePanelTaskLoading: action.usagePanelTaskLoading
      }
    case 'set_usage_period':
      return {
        ...state,
        usagePeriod:action.usagePeriod
      }
    case 'set_usage_type':
      return {
        ...state,
        usageType:action.usageType
      }
    // case 'set_sources_data':
    //   return {
    //     ...state,
    //     sourcesData:action.sourcesData,
    //     sourcesError:action.sourcesError,
    //     sourcesLoading:action.sourcesLoading
    //   }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_is_steward_or_owner':{
      return {
        ...state, isStewardOrOwner:action.isStewardOrOwner
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const ToolProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache
  } = props;

  const [state, dispatch] = useReducer(reducer,  setInitialState(pageCache,initialState));
  const [linkModalOpen, setLinkModalOpen] = useState(false);


  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName');

  const getTabOptions = (basicData) => {
    let tabOptions = ['CONTENT', 'LINEAGE'];
    if(basicData.reference===true)tabOptions.push('REFERENCE')
    return tabOptions;
  }

  useEffect(()=>{
    if(tabName && state.basicData ){
      let tabOptions = getTabOptions(state.basicData);
      if(!tabOptions.includes(tabName.toUpperCase()))return;
      let presetTabState = tabOptions.indexOf(tabName.toUpperCase());
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
      dispatch({type:'set_tab_state',tabState:presetTabState})
    }
    // eslint-disable-next-line
  },[state.basicData])

  let isLineageLinkable = checkLineageLinkable({sessionData, isStewardOrOwner: state.isStewardOrOwner})

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])

  const loadContentTabData = fullResponse => {
    let isInactive = fullResponse.active_flag === false
    if(isInactive){
      dispatch({type:'set_show_deleted',showDeleted:true})
    }

    const getOrder = name => {
      let order = {
        CONTENT_APP:1,
        REPORT:10,
        SHEET:15,
        DATASET:20,
        DATASET_TABLE:25,
        DATASET_FIELD:26,
        DATA_PIPELINE:30,
        FILE:40,
        CODE:50,
        COLLECTION_INSTANCE:60,
      }
      return order[name]||70
    }

    axiosSolr
      .get(
        `/solr/search/select`,
        {params:{
          q:'*',
          fq:`${fullResponse.parent?.reference===false?`source_id_srt:${fullResponse.source_id}`:`parent_id_srt:${fullResponse.id}`} AND -object_type_srt:(TOOL OR COLLECTION OR COLLECTION_INSTANCE OR HOST)`,
          rows:0,
          'json.facet':{
            "object_type":{
              "type": "terms",
              "field":"object_type_srt",
              "mincount":1,
            },
          },
        }}
      )
      .then(response=>{
        let tabData = [];
        let facets = response.data.facets.object_type;
        if(facets && facets.buckets.length>0){
          facets.buckets.forEach(el=>{
            tabData.push({
              name:el.val,
              count:el.count,
            })
          })
        };

        axiosSolr.get(
          `/solr/search/select`,
          {params:{
            q:'*',
            fq:`source_id_srt:${fullResponse.source_id} AND active_srt:YES AND object_type_srt:COLLECTION_INSTANCE`,
            rows:0,
            'json.facet':{
              "collection":{
                "type": "terms",
                "field":"collection_srt",
                "mincount":1,
                "facet":{
                  collection_type:{
                    "type": "terms",
                    "field":"collection_type_srt",
                    "mincount":1,
                  }
                }
              },
            },
          }}
        )
        .then(cReponse=>{
          let facets = cReponse.data.facets.collection;
          if(facets && facets.buckets.length>0){
            facets.buckets.forEach(el=>{
              tabData.push({
                name:el.val,
                count:el.count,
                isCollection:true,
                collection_type:el.collection_type.buckets[0].val
              })
            })
          };

          tabData = tabData.sort((a,b)=>getOrder(a.name)-getOrder(b.name))
          dispatch({
            type:'set_content_tab_data',
            contentTabData:tabData
          })

        })
        .catch(error=>{
          dispatch({type:'set_content_tab_data',contentTabError:true})
          console.log(error)
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_content_tab_data',contentTabError:true})
      })
  }

  const fetchList = () => {
    dispatch({ type: 'set_basic_data', basicData:state.basicData, basicDataLoading:true })
    axiosCerebrum
      .get(`/api/tools/${match.params.id.toLowerCase()}`)
      .then(async cerebrumResponse=>{
        if(!cerebrumResponse.data)return;
        let fullResponse = cerebrumResponse.data;
        if(fullResponse.reference===true){
          dispatch({type:'set_show_reference',showReference:true})
        }
        if(fullResponse.active_flag===false){
          dispatch({type:'set_show_deleted',showDeleted:true})
        }
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${cerebrumResponse.data.source_id}`).then(response=>sourceData=response.data).catch(error=>{console.log(error)})
        addHistory({url:window.location.pathname, title: getDispFields(fullResponse,'dispTitle'), subTitle:'Tool',object:fullResponse,type:'profile'})
        dispatch({type:'set_basic_data',basicData:{...(state.basicData||{}),...fullResponse, source_data:sourceData }})
        dispatch({type:'set_content_tab_data',contentTabLoading:true});
        loadContentTabData(fullResponse)
      })
      .catch(error=>{
        console.log(error)
        dispatch({ type: 'set_basic_data', basicData:state.basicData, basicDataError:true })
      })
  }

  const followDataFetch = () => {
    checkIsFollowed({objectId:match.params.id.toLowerCase()})
      .then(followed=>{
        dispatch({type:'set_following',following:followed})
      })
  }


  useEffect(()=>{
    if(state.followData===undefined){
      followDataFetch()
    }
  // eslint-disable-next-line
  },[])

  const checkIsStewardOrOwner = () => {
    axiosCerebrum
      .get(`/api/users/${sessionData.id}/related`,{params:{
        object_id:match.params.id.toLowerCase(),
        relationship:'OWNER_OF,STEWARD_OF'
      }})
      .then(response=>{
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:response.data.total>0})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:false})
      })
  }

  useEffect(()=>{
    checkIsStewardOrOwner()
   // eslint-disable-next-line
  },[state.insightsData])

  // trigger api call when tab state is changed, if data is already fetched, do nothing
  useEffect(() => {
    if(!state.basicData){
      fetchList();
      return;
    }
    window.scrollTo(0,0)
    if(state.basicData.reference===false){
      switch (state.tabState) {
        case 1:
          // if (!state.sourcesData) sourcesFetch();
          break;
        default:
          break;
      }
    }
  // eslint-disable-next-line
  }, [state.tabState, state.basicData])



  if (state.basicDataLoading ) {
    return  (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const isShell = state.basicData.reference!==false

  let buttons = [];


  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )


  buttons.push(
    <FollowButton
      following={state.following}
      object={state.basicData}
      dispatch={dispatch}
    />
  )

  let title = state.basicData.name || state.basicData.id;


  let bannerdisplayText,bannerCause,bannerVisibility;

  const tabOptions = getTabOptions(state.basicData);

  if (isShell && state.basicData){
    if(state.basicData.reference_sources){
      if(state.basicData.reference_sources.length<=1){
        bannerdisplayText=`This Tool is referenced in ${state.basicData.reference_sources[0]?state.basicData.reference_sources[0].reference_source.name:'Unknown source'}. ${state.basicData.name} is not connected to K. Limited metadata available.`
      }else{
        bannerdisplayText=(
          <Typography>
            This Tool is referenced in <span onClick={()=>tabOptions.indexOf('REFERENCE')} style={{textDecoration:'underline',cursor:'pointer'}}>multiple Tools</span>. {state.basicData.name} is not connected to K. Limited metadata available.
          </Typography>
        )
      }
    }
    else{
      bannerdisplayText= 'This Tool is referenced in content metadata. It is not integrated to K';
    }
    bannerCause = 'shell';
    bannerVisibility = 'visible'
  }
  let data = state.basicData;
  if (data && data.source_data && data.source_data.active_flag===false) {
    bannerdisplayText = 'The source of this tool has been deactivated in K. Information on this tool is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  let sourceType;
  try{
    sourceType = state.basicData.source_data.source_template.name
  }catch{}


  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['link']}
        history={history}
      />
      <ProfileLayout
        header={(
          <ProfileHeader
            type='tool'
            title={title}
            nodeKey={state.basicData.signature}
            label={'tool'}
            toolType={state.basicData.toolType}
            shouldLoadBreadCrumb
            buttons={buttons}
            data={state.basicData}
            isShell={isShell}
            state={state}
            history={history}
            dispatch={dispatch}
            bannerdisplayText={bannerdisplayText}
            bannerVisibility={bannerVisibility}
            bannerCause={bannerCause}
            shouldLoadLinkedInstance
            onClickAddTag={()=>{
              setLinkModalOpen({
                linkableObjects:['COLLECTION_INSTANCE'],
                relations:['MEMBER_OF'],
                onLinkUpdated:()=>window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
              })
            }}
          />
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            history={history}
            tabState={state.tabState}
            basicData={state.basicData}
            // new props
            state={state}
            dispatch={dispatch}
            fetchList={fetchList}
            sessionData={sessionData}
            isLineageLinkable={isLineageLinkable}
          />
        }
        hideSideBar={['LINEAGE','ISSUES'].includes(tabOptions[state.tabState])}
        hideHeader={['LINEAGE'].includes(tabOptions[state.tabState])}
        disableMinContentHeight={['LINEAGE'].includes(tabOptions[state.tabState])}
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            data={{...state.basicData,sourceType}}
            sessionData={sessionData}
            cerebrumLabel='tools'
            fetchList={fetchList}
            mapping={isShell?['lastUpdated','lineage','upstreamSources','downstreamSources']:['sourceType','category','lineage','upstreamSources','downstreamSources','domain','verified','verifiedNot','classification','owner','stewardedBy','topSources', 'topTeams', 'lastUpdated']}
          />
        }
      />
    </div>
  )

}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(ToolProfile)));
